// import * as Type from './mutations-types'
import * as SpeakerManagement from '../apis/speakerManagement'
import * as Type from '@/store/mutations-types'

const state = {
  topData: {},
  speakerList: [],
  allSpeakerCourseList: [],
  speakerCourseList: [],
  totalCount: 0
}
const getters = {
  topData: state => state.topData,
  speakerList: state => state.speakerList,
  speakerCourseList: state => state.speakerCourseList,
  allSpeakerCourseList: state => state.allSpeakerCourseList,
  totalCount: state => state.totalCount
}
const mutations = {
  [Type.GET_KNOWLEDGE_INDEX_DATA](state, value) {
    state.topData = value
  },
  [Type.GET_SPEAKER_LIST](state, value) {
    state.speakerList = value
  },
  [Type.GET_SPEAKER_LIST](state, { data, totalCount }) {
    state.speakerList.splice(0, state.speakerList.length)
    data.forEach(item => {
      state.speakerList.push(item)
    })
    state.totalCount = totalCount
  },
  [Type.GET_SPEAKER_COURSE_LIST](state, { data, totalCount }) {
    state.speakerCourseList.splice(0, state.speakerCourseList.length)
    data.forEach(item => {
      state.speakerCourseList.push(item)
    })
    state.totalCount = totalCount
  },
  [Type.GET_ALL_SPEAKER_COURSE_LIST](state, { data, totalCount }) {
    state.allSpeakerCourseList.splice(0, state.allSpeakerCourseList.length)
    data.forEach(item => {
      state.allSpeakerCourseList.push(item)
    })
    state.totalCount = totalCount
  }
}
const actions = {
  knowledgeIndexData({ commit }, value) {
    return SpeakerManagement.knowledgeIndexData(value).then(
      res => {
        commit(Type.GET_KNOWLEDGE_INDEX_DATA, res)
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  funBeanPutList(_, value) {
    return SpeakerManagement.funBeanPutList(value).then(
      res => {
        // commit(Type.GET_FUN_BEAN_PUT_LIST, res)
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  funBeanRule(_, value) {
    return SpeakerManagement.funBeanRule(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  updateFunBeanRule(_, value) {
    return SpeakerManagement.updateFunBeanRule(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  addPutFunBean(_, value) {
    return SpeakerManagement.addPutFunBean(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  addPutTargetedFunBean(_, value) {
    return SpeakerManagement.addPutTargetedFunBean(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getSpeakerList({ commit }, value) {
    return SpeakerManagement.getSpeakerList(value).then(
      res => {
        commit(Type.GET_SPEAKER_LIST, res)
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getSpeakerDetail(_, value) {
    return SpeakerManagement.getSpeakerDetail(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getSpeakerCourseList({ commit }, value) {
    return SpeakerManagement.getSpeakerCourseList(value).then(
      res => {
        commit(Type.GET_SPEAKER_COURSE_LIST, res)
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getAllSpeakerCourseList({ commit }, value) {
    return SpeakerManagement.getAllSpeakerCourseList(value).then(
      res => {
        commit(Type.GET_ALL_SPEAKER_COURSE_LIST, res)
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  speakerCourseDetail(_, value) {
    return SpeakerManagement.speakerCourseDetail(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getTagList(_, value) {
    return SpeakerManagement.getTagList(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getCourseTagList(_, value) {
    return SpeakerManagement.getCourseTagList(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  hotTagList(_, value) {
    return SpeakerManagement.hotTagList(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getAllTagList(_, value) {
    return SpeakerManagement.getAllTagList(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  downloadDoctorList(_, value) {
    return SpeakerManagement.downloadDoctorList(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  deleteSpeakerCourse(_, value) {
    return SpeakerManagement.deleteSpeakerCourse(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  updateIntroOrKpi(_, value) {
    return SpeakerManagement.updateIntroOrKpi(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  updateCourseState(_, value) {
    return SpeakerManagement.updateCourseState(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  refuseCourse(_, value) {
    return SpeakerManagement.refuseCourse(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  updateHotCourse(_, value) {
    return SpeakerManagement.updateHotCourse(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  deleteTagList(_, value) {
    return SpeakerManagement.deleteTagList(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  addTagList(_, value) {
    return SpeakerManagement.addTagList(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  saveSpeakerCourseDetail(_, value) {
    return SpeakerManagement.saveSpeakerCourseDetail(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  checkDoctorList(_, value) {
    return SpeakerManagement.checkDoctorList(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  addNewDoctor(_, value) {
    return SpeakerManagement.addNewDoctor(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  addCourseTagList(_, value) {
    return SpeakerManagement.addCourseTagList(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  updateHotTag(_, value) {
    return SpeakerManagement.updateHotTag(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  }
}
export default {
  state,
  getters,
  actions,
  mutations
  // namespaced: true
}
