import request from '@/utils/request'
import API from './api-types'

// 获取商品池列表
export default function getPolicyDetail({ id }) {
  return request({
    url: API.getPolicyDetail,
    method: 'get',
    params: { id }
  })
}
