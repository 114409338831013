export default [
  {
    path: '/scene',
    name: 'Scene',
    component: () =>
      import(/* webpackChunkName: "risk-control" */ '@/views/risk-control/scene/list.vue'),
    meta: {
      title: '场景管理',
      keepAlive: true
    }
  },
  {
    path: '/scene-check',
    name: 'CheckList',
    component: () =>
      import(
        /* webpackChunkName: "risk-control" */ '@/views/risk-control/scene/CheckList.vue'
      ),
    meta: {
      title: '规则列表'
    }
  },
  {
    path: '/deal',
    name: 'DealList',
    component: () =>
      import(/* webpackChunkName: "risk-control" */ '@/views/risk-control/deal/list.vue'),
    meta: {
      title: '风控处理',
      keepAlive: true
    }
  },
  {
    path: '/deal-details',
    name: 'DealDealList',
    component: () =>
      import(/* webpackChunkName: "risk-control" */ '@/views/risk-control/deal/details.vue'),
    meta: {
      title: '风控详情'
    }
  },
  {
    path: '/special-user',
    name: 'SpecialUser',
    component: () =>
      import(
        /* webpackChunkName: "risk-control" */ '@/views/risk-control/specialUser/list.vue'
      ),
    meta: {
      title: '风控黑白名单'
    }
  },
  {
    path: '/gift-record',
    name: 'GiftRecord',
    component: () =>
      import(/* webpackChunkName: "risk-control" */ '@/views/risk-control/giftRecord/list'),
    meta: {
      title: '医生积分变动记录'
    }
  },
  {
    path: '/screen-control',
    name: 'ScreenControl',
    component: () =>
      import(/* webpackChunkName: "risk-control" */ '@/views/risk-control/screenControl'),
    meta: {
      title: '风控大屏'
    }
  },
  {
    path: '/apply/thaw',
    name: 'ApplyThaw',
    component: () =>
      import(/* webpackChunkName: "risk-control" */ '@/views/risk-control/applyThaw/ApplyThaw'),
    meta: {
      title: '申请解冻处理工单'
    }
  }
]
