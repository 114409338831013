<template>
  <div class="home">
    <el-form
      :inline="true"
      :model="searchData"
      class="demo-form-inline"
    >
      <el-form-item label="课程名称">
        <el-input
          v-model="searchData.courseName"
          placeholder="请输入课程名称"
        />
      </el-form-item>
      <el-form-item label="课程状态">
        <el-select
          v-model="searchData.isSale"
          placeholder="请输入课程状态"
        >
          <el-option
            v-for="item in prodState"
            :key="item.index"
            :span="12"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item class="search-button">
        <el-button
          class="qxs-btn-md"
          @click="addProd"
        >
          +新增
        </el-button>
      </el-form-item>
      <el-form-item class="search-button">
        <el-button
          type="primary"
          class="qxs-btn-md"
          icon="el-icon-search"
          @click="search(1)"
        >
          搜索
        </el-button>
      </el-form-item>
    </el-form>
    <div
      ref="table"
      class="prod-panel"
    >
      <el-table
        ref="multipleTable"
        v-loading="loading"
        :data="courseList"
        border
        style="width: 100%;"
      >
        <el-table-column
          prop="title"
          label="课程名称"
          min-width="10%"
        />
        <el-table-column
          label="视频数量"
          min-width="6%"
          prop="videoCount"
        />
        <el-table-column
          label="课程原价"
          min-width="6%"
          prop="originalPrice"
        />
        <el-table-column
          label="实际价格"
          min-width="6%"
          prop="markingPrice"
        />
        <el-table-column
          prop="courseIndex"
          label="商品排序"
          min-width="6%"
        />
        <el-table-column
          label="课程状态"
          min-width="6%"
        >
          <template slot-scope="scope">
            {{ scope.row.isSale == 0 ? '下架' : '上架' }}
          </template>
        </el-table-column>
        <el-table-column
          label="课程负责人"
          min-width="6%"
          prop="contactName"
        />
        <el-table-column
          label="创建时间"
          min-width="8%"
        >
          <template slot-scope="scoped">
            {{ $dayjs(scoped.row.createdAt).format('YYYY-MM-DD') }}
          </template>
        </el-table-column>
        <el-table-column
          prop="zip"
          label="操作"
          min-width="12%"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              @click.native.prevent="checkDetail(scope.$index, courseList)"
            >
              编辑课程
            </el-button>
            <el-button
              type="text"
              :disabled="scope.row.isSale === 1"
              @click="onClickDetail(scope, '1')"
            >
              上架课程
            </el-button>
            <el-button
              type="text"
              :disabled="scope.row.isSale === 0"
              @click="onClickDetail(scope, '0')"
            >
              下架课程
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="pageNo"
        :page-size="20"
        layout="prev, pager, next, jumper"
        :total="courseCount"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CourseManagement',
  components: {},
  data() {
    return {
      loading: true,
      name: '课程管理',
      searchData: {
        courseName: '',
        isSale: ''
      },
      prodState: [
        {
          id: null,
          name: '全部'
        },
        {
          id: 0,
          name: '下架'
        },
        {
          id: 1,
          name: '上架'
        }
      ],
      pageNo: 1
    }
  },
  computed: {
    ...mapGetters(['courseCount', 'coursePage', 'courseList'])
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions(['getCourseList', 'changeCourseSale']),
    init() {
      this.getCourseList({ pageNo: 1, pageSize: 20 }).then(() => {
        this.loading = false
      }
      )
    },
    checkDetail(index, data) {
      this.$router.push({ name: 'CourseDetail', params: { courseId: data[index].id } })
    },
    onClickDetail(options, type) {
      const Data = {
        courseId: options.row.id,
        isSale: type
      }
      if (type === '1') {
        this.$confirm('确定上架该课程吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.changeCourseSale(Data).then(() => {
              this.init()
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消上架'
            })
          })
      } else if (type === '0') {
        this.$confirm('确定下架该课程吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.changeCourseSale(Data).then(() => {
              this.init()
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消下架'
            })
          })
      }
    },
    handleCurrentChange(val) {
      this.search(val)
    },
    addProd() {
      this.$router.push({ name: 'CourseDetail' })
    },
    search(index) {
      this.loading = true
      const req = {
        courseName: this.searchData.courseName,
        isSale: this.searchData.isSale,
        pageNo: index || 1,
        pageSize: 20
      }
      this.getCourseList(req).then(() => {
        this.loading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  padding: 20px;
  .search-panel {
    padding: 20px;
    .search-name {
      position: relative;
      top: 10px;
    }
  }
}
.el-row {
  padding-top: 10px;
}
.item-title {
  display: inline-block;
}
.el-input__inner {
  display: inline-block;
}
.clean-btn {
  text-align: right;
}
.el-pagination {
  padding-top: 26px;
  text-align: center;
}
.price-name {
  padding: 10px 0 0 20px;
}
.search-button {
  float: right;
}
</style>
