<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <keep-alive v-if="!$route.meta.keepAlive" :include="$store.state.keepAlive.list">
      <router-view />
    </keep-alive>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  components: {
  }
}
</script>

<style lang="scss">
@import './styles/index';
#app {
  padding: 10px;
  margin-bottom: 80px !important;
}
</style>
