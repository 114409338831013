<template>
  <div style="font-size: 14px;">
    <el-steps :active="active" align-center>
      <el-step title="新建推送" />
      <el-step title="确认并提交审核" />
      <el-step title="推送审核并发布" />
    </el-steps>
    <div v-if="active === 0" class="content">
      <el-row>
        <h3>选择活动：</h3>
        <el-radio-group v-model="postInfo.pushType" @change="onChangePushType">
          <div class="mgn-b10">
            <el-radio
              :label="1"
              class="mgn-t20 mgn-b20"
            >
              方式一：平台活动
            </el-radio>
            <el-autocomplete
              v-if="postInfo.pushType == 1"
              v-model="postInfo.activityTitle"
              :fetch-suggestions="queryBaseSearchAsync"
              placeholder="请输入已发布活动名称"
              clearable
              @select="handleBaseSelect"
            />
          </div>
          <div class="mgn-b10">
            <el-radio
              :label="2"
              class="mgn-t20 mgn-b20"
            >
              方式二：外部自定义链接
            </el-radio>
            <el-input
              v-if="postInfo.pushType == 2"
              v-model="postInfo.activityTitle"
              placeholder="请输入活动名称"
              clearable
              style="width: 200px; margin-right: 10px;"
            />
            <el-input
              v-if="postInfo.pushType == 2"
              v-model="postInfo.outUrl"
              placeholder="请输入自定义链接"
              style="width: 200px;"
            />
          </div>
        </el-radio-group>
        <el-row type="flex" justify="start">
          <span>活动封面：</span>
          <el-upload
            class="avatar-uploader"
            :action="uploadApi"
            :headers="headers"
            name="image"
            :limit="1"
            :multiple="false"
            :disabled="postInfo.pushType == 1"
            :show-file-list="false"
            :on-success="handleSuccess"
            :before-upload="beforeUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon" />
          </el-upload>
        </el-row>
      </el-row>
      <el-row>
        <h3>分发规则：</h3>
        <el-row>
          <div v-for="(domain, index) in orgIdList" :key="index" class="mgn-b10">
            <el-select
              v-model="domain.topOrgId"
              placeholder="请选择平台企业"
              class="mgn-r10"
              @change="changeTopOrg"
            >
              <el-option
                v-for="item in topOrgList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
            <el-cascader
              :key="index"
              v-model="domain.orgIds"
              :options="orgList[domain.topOrgId]"
              :props="defaultProps"
              :show-all-levels="false"
              class="mgn-r10"
              placeholder="请选择组织"
              :disabled="domain.isSelect"
            />
            <el-button type="text" @click.prevent="addSelectOrg"> 添加 </el-button>
            <el-button v-if="index > 0" type="text" @click.prevent="removeDomain(domain)"> 删除 </el-button>
          </div>
        </el-row>
      </el-row>
      <el-row>
        <h3>分发渠道：</h3>
        <el-row class="mgn-b20">
          <span class="mgn-b10 mgn-r10">选择渠道：</span>
          <el-radio v-model="postInfo.sendType" :label="1">医生端首页banner</el-radio>
          <el-radio v-model="postInfo.sendType" :label="2">学术交流圈</el-radio>
        </el-row>
        <el-row v-if="postInfo.sendType == 1">
          <div class="mgn-b20">
            <span>设置展示时间：</span>
            <el-date-picker
              v-model="timeRange"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions"
              :default-time="['00:00:00', '23:59:59']"
              class="mgn-r10"
            />
            <span>备注：展示时间一到，该banner推送将会自动下架。</span>
          </div>
          <div class="mgn-b10">
            <span>设置展示权重：</span>
            <el-input-number
              v-model="postInfo.weight"
              :min="1"
              :max="10"
              label="请输入数字"
              class="mgn-r10"
            />
            <span>备注：权重应为1-10的整数，且数值越高展示位置越靠前。</span>
          </div>
        </el-row>
      </el-row>
      <el-footer>
        <FooterWrapper>
          <el-button @click="onCancel">取消</el-button>
          <el-button type="primary" @click="onSave(1)">保存</el-button>
          <el-button type="primary" @click="onSave(2)">下一步</el-button>
        </FooterWrapper>
      </el-footer>
    </div>
    <div v-if="active === 1 || active === 2 || active === 3" class="content">
      <el-row class="mgn-b30">
        <span class="content-title">活动主题：</span>
        <span>{{ postInfo.pushTitle }}</span>
      </el-row>
      <el-row class="mgn-b30">
        <span class="content-title">活动链接：</span>
        <span>{{ postInfo.pushType == 1 ? postInfo.brandingUrl : postInfo.outUrl }}</span>
      </el-row>
      <el-row class="mgn-b30">
        <span class="content-title">活动封面：</span>
        <img :src="postInfo.outImageUrl" class="avatar">
      </el-row>
      <el-row class="mgn-b30">
        <span class="content-title">分发规则：</span>
        <div v-for="(i, index) in postInfo.pushOrgInfos" :key="index" class="mgn-b10 mgn-t10">
          <span v-if="i.topOrgName">{{ i.topOrgName }} {{ i.chooseOrgName }}</span>
          <span v-else>全平台</span>
        </div>
      </el-row>
      <el-row class="mgn-b30">
        <span class="content-title">分发渠道：</span>
        <span>{{ postInfo.sendType === 1? '医生端首页banner' : '学术交流圈' }}</span>
      </el-row>
      <el-row v-if="postInfo.sendType === 1" class="mgn-b30">
        <span class="content-title">展示时间：</span>
        <span>{{ timeRange[0] + ' —— ' + timeRange[1] }}</span>
      </el-row>
      <el-row v-if="postInfo.sendType === 1" class="mgn-b30">
        <span class="content-title">权重：</span>
        <span>{{ postInfo.weight }}</span>
      </el-row>
      <el-row class="mgn-b30">
        <span class="content-title">投放展示人数：</span>
        <span>{{ postInfo.pushUserCount }}</span>
      </el-row>
      <el-row class="mgn-b30">
        <span class="content-title">活动预览：</span>
        <div id="qrCode" ref="qrCodeDiv" style="width: 100%; margin-top: 10px;" />
      </el-row>
      <el-footer v-if="active !== 3">
        <FooterWrapper v-if="active === 1">
          <el-button @click="onBack">返回上一页</el-button>
          <el-button type="primary" @click="onSubmitPass">提交审核</el-button>
        </FooterWrapper>
        <FooterWrapper v-if="active === 2">
          <el-button :disabled="loading" @click="onRefusePass">拒绝</el-button>
          <el-button :disabled="loading" type="primary" @click="onPass">同意</el-button>
        </FooterWrapper>
      </el-footer>
    </div>
  </div>
</template>

<script>
import {getToken} from '@/utils/auth'
import API from '@/apis/api-types'
import FooterWrapper from '@/components/common/footerWrapper.vue'
import QRCode from 'qrcodejs2'

export default {
  name: 'PostDetail',
  components: {
    FooterWrapper
  },
  data() {
    return {
      headers: {
        token: getToken()
      },
      postInfo: {},
      searchData: {
        activityTitle: '',
        pageNo: 1,
        pageSize: 10,
        status: ''
      },
      active: 0,
      topOrgList: [],
      pickerOptions: {
        disabledDate: time => {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
        }
      },
      timeRange: [],
      imageUrl: '',
      orgIdList: [{}],
      orgList: {},
      defaultProps: {
        children: 'child',
        label: 'orgName',
        value: 'orgId',
        disabled: 'isSelect',
        checkStrictly: true
      },
      id: '',
      loading: false
    }
  },
  computed: {
    uploadApi() {
      return `${API.imageUpload}?bucketType=104`
    }
  },
  mounted() {
    this.getTopOrg()
    this.id = this.$route.query.id || ''
    this.active = Number(this.$route.query.active) || 0
    if (this.id) {
      this.$axios.get(this.$API.getPostDetail, {params: {brandingPushId: this.id}}).then(res => {
        this.postInfo = { ...res, activityTitle: res.pushTitle, outImageUrl: res.imageUrl }
        this.imageUrl = res.imageUrl
        this.timeRange = [res.showStartTime, res.showEndTime]
        res.pushOrgInfos.map(v => {
          this.changeTopOrg(v.topOrgId)
        })
        this.orgIdList = res.pushOrgInfos
        if (this.active === 2 || this.active === 3) {
          this.$nextTick(() => {
            this.onGetQrCode()
          })
        }
      }, rea => {
        this.$message.error(rea.message)
      })
    }
  },
  methods: {
    getTopOrg() {
      this.$axios.get(this.$API.getOrgList).then(res => {
        this.topOrgList = [{id: '', name: '全平台'}, ...res]
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    queryBaseSearchAsync(queryString, cb) {
      let results = []
      if (queryString == '') {
        cb(results)
      } else {
        this.$axios.get(this.$API.getActivityList, {params: {brandingName: this.postInfo.activityTitle || '', status: 1, pageNo: 1, pageSize: 10} }).then(
          res => {
            if (res) {
              const result = res.data
              // 循环放到一个远程搜索需要的数组
              for (let i = 0; i < result.length; i++) {
                const element = result[i]
                results.push({
                  value: element.title,
                  id: element.id,
                  img: element.coverUrl
                })
              }
              cb(results)
            } else {
              results = []
              cb(results)
            }
          }
        )
      }
    },
    handleBaseSelect(item) {
      this.postInfo.brandingId = item.id
      this.postInfo.activityTitle = item.value
      this.imageUrl = item.img
    },
    onChangePushType() {
      this.postInfo = {...this.postInfo, activityTitle: '', brandingId: '' }
      this.imageUrl = ''
    },
    changeTopOrg(value) {
      if (!value) return
      this.$axios.get(this.$API.getTreeList, {params: {orgId: value}}).then(
        res => {
          this.$set(this.orgList, value, res.child)
        }, rea => {
          this.$message.error(rea.message)
        }
      )
    },
    handleSuccess(response) {
      this.imageUrl = response.data.middle
      this.postInfo.outImageUrl = this.imageUrl
    },
    beforeUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt50M = file.size / 1024 / 1024 < 50
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG、PNG 格式!')
      }
      if (!isLt50M) {
        this.$message.error('上传图片大小不能超过 50MB!')
      }
      return isJPG && isLt50M
    },
    addSelectOrg() {
      this.orgIdList.push({
        topOrgId: '',
        orgIds: '',
        key: Date.now()
      })
    },
    removeDomain(item) {
      const index = this.orgIdList.indexOf(item)
      if (index !== -1) {
        this.orgIdList.splice(index, 1)
      }
    },
    onCancel() {
      this.$router.back()
    },
    onValid() {
      if (!this.postInfo.pushType) {
        this.$message.error('请选择活动类型')
        return false
      }
      if (!this.postInfo.activityTitle) {
        this.$message.error('请填写活动名称')
        return false
      }
      if (this.postInfo.pushType == 1 && !this.postInfo.brandingId) {
        this.$message.error('此活动不存在')
        return false
      }
      if (this.postInfo.pushType == 2 && !this.postInfo.outUrl) {
        this.$message.error('请填写活动链接')
        return false
      }
      if (this.postInfo.pushType == 2 && !this.postInfo.outImageUrl) {
        this.$message.error('请上传活动封面')
        return false
      }
      // let showError = false
      // this.orgIdList.map(v => {
      //   if (!v.topOrgId) {
      //     showError = true
      //     return false
      //   }
      // })
      // if (showError) {
      //   this.$message.error('分发规则不能为空')
      //   return false
      // }
      if (this.postInfo.sendType == 1 && this.timeRange.length < 1) {
        this.$message.error('请设置banner展示时间')
        return false
      }
      if (this.postInfo.sendType == 1 && !this.postInfo.weight) {
        this.$message.error('请设置banner权重')
        return false
      }
      if (this.postInfo.sendType == 1 && !(/(^[1-9]\d*$)/.test(this.postInfo.weight))) {
        this.$message.error('权重应为1-10的整数')
        return false
      }
      return true
    },
    onSave(type) {
      if (!this.onValid()) return
      let orgIds = []
      this.orgIdList.map(v => {
        if (!v.orgIds || typeof v.orgIds === 'string') {
          orgIds.push(v.topOrgId)
        } else if (v.orgIds.length) {
          orgIds.push(v.orgIds[v.orgIds.length - 1])
        }
      })
      this.postInfo = {...this.postInfo, orgIds: orgIds.toString(), showStartTime: this.timeRange[0], showEndTime: this.timeRange[1] }
      this.$axios.post(this.$API.savePostDetail, {...this.postInfo}).then(res => {
        this.$message.success('保存成功')
        if (type === 1) {
          this.onCancel()
        } else {
          this.postInfo = {...this.postInfo, ...res.data, outImageUrl: res.data.imageUrl}
          this.onNext()
        }
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    onNext() {
      if (this.active++ > 2) this.active = 0
      this.$nextTick(() => {
        this.onGetQrCode()
      })
    },
    onBack() {
      this.$refs.qrCodeDiv.innerHTML = '' // 清除二维码
      if (this.active-- < 2) this.active = 0
    },
    onSubmitPass() {
      this.$axios.post(this.$API.changePostStatus, null, { params: {status: 2, brandingPushId: this.postInfo.id }}).then(() => {
        this.$message({
          type: 'success',
          message: '已提交审核'
        })
        this.onCancel()
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    onPass() {
      if (this.loading) return
      this.loading = true
      this.$confirm(`本次信息将展示给${this.postInfo.pushUserCount}名用户，请确认是否推送`, '提示', {
        confirmButtonText: '确认推送',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post(this.$API.changePostStatus, null, { params: {status: 1, brandingPushId: this.postInfo.id } }).then(() => {
          this.loading = false
          this.$message({
            type: 'success',
            message: '审核成功'
          })
          this.onCancel()
        }, rea => {
          this.loading = false
          this.$message.error(rea.message)
        })
      }).catch(() => {
        this.loading = false
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    onRefusePass() {
      if (this.loading) return
      this.loading = true
      this.$axios.post(this.$API.changePostStatus, null, { params: {status: 3, brandingPushId: this.postInfo.id }}).then(() => {
        this.loading = false
        this.$message({
          type: 'success',
          message: '已拒绝'
        })
        this.onCancel()
      }, rea => {
        this.loading = false
        this.$message.error(rea.message)
      }).catch(() => {
        this.loading = false
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    onGetQrCode() {
      this.$refs.qrCodeDiv.innerHTML = '' // 清除二维码
      let url = this.postInfo.pushType == 1 ? this.postInfo.brandingUrl : this.postInfo.outUrl
      new QRCode(this.$refs.qrCodeDiv, {
        text: url,
        width: 200,
        height: 200,
        colorDark: '#333333', // 二维码颜色
        colorLight: '#ffffff', // 二维码背景色
        correctLevel: QRCode.CorrectLevel.L// 容错率，L/M/H
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  margin: 30px;
}
.avatar-uploader :deep(.el-upload) {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-left: 10px;
  margin-bottom: 10px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 240px;
  height: 130px;
  line-height: 130px;
  text-align: center;
}
.avatar {
  width: 240px;
  height: 130px;
  display: block;
}
.delete-btn {
  margin-left: 10px;
  color: #008075;
  cursor: pointer;
}
.content-title {
  font-weight: bold;
  font-size: 1.17em;
}
</style>
