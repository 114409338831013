import * as Type from './mutations-types'
import * as Serve from '@/apis/liveAuditReq'
import { Message } from 'element-ui'

const state = {
  liveTable: [], // 直播审核table
  liveTableTotal: 0, // 总条数
  thirdSpeakerList: [], // 人力家工单table
  thirdSpeakerListTotal: 0, // 总条数
  liveDetail: {} // 直播审核详情
}
const getters = {
  liveTable: state => state.liveTable,
  liveTableTotal: state => state.liveTableTotal,
  thirdSpeakerList: state => state.thirdSpeakerList,
  thirdSpeakerListTotal: state => state.thirdSpeakerListTotal,
  liveDetail: state => state.liveDetail
}
const mutations = {
  [Type.SET_LIVE_TABLE](state, data) {
    state.liveTable = data
  },
  [Type.SET_LIVE_TABLE_TOTAL](state, data) {
    state.liveTableTotal = data
  },
  [Type.SET_THIRD_SPEAKER_LIST](state, data) {
    state.thirdSpeakerList = data
  },
  [Type.SET_THIRD_SPEAKER_LIST_TOTAL](state, data) {
    state.thirdSpeakerListTotal = data
  },
  [Type.SET_LIVE_DETAIL](state, data) {
    state.liveDetail = data
  }
}
const actions = {
  getLiveTable({ commit }, data) {
    return Serve.getLiveTable(data).then(
      res => {
        commit(Type.SET_LIVE_TABLE, res.data || [])
        commit(Type.SET_LIVE_TABLE_TOTAL, res.totalCount)
        return Promise.resolve(res)
      },
      rea => {
        Message({
          message: rea.message,
          type: 'error'
        })
        return Promise.reject(rea)
      }
    )
  },
  getThirdSpeakerList({ commit }, data) {
    return Serve.getThirdSpeakerList(data).then(
      res => {
        commit(Type.SET_THIRD_SPEAKER_LIST, res.data || [])
        commit(Type.SET_THIRD_SPEAKER_LIST_TOTAL, res.count)
        return Promise.resolve(res)
      },
      rea => {
        Message({
          message: rea.message,
          type: 'error'
        })
        return Promise.reject(rea)
      }
    )
  },
  getLiveDetail({ commit }, data) {
    return Serve.getLiveDetail(data).then(
      res => {
        commit(Type.SET_LIVE_DETAIL, res || {})
        return Promise.resolve(res)
      },
      rea => {
        commit(Type.SET_LIVE_DETAIL, {})
        Message({
          message: rea.message,
          type: 'error'
        })
        return Promise.reject(rea)
      }
    )
  },
  reviewDeal(_, data) {
    return Serve.reviewDeal(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        Message({
          message: rea.message,
          type: 'error'
        })
        return Promise.reject(rea)
      }
    )
  },
  dealWorkOrder(_, data) {
    return Serve.dealWorkOrder(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        Message({
          message: rea.message,
          type: 'error'
        })
        return Promise.reject(rea)
      }
    )
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
