<template>
  <div>
    <div class="textarea-box">
      <el-input
        v-model="textarea"
        type="textarea"
        :rows="10"
        placeholder="请输入内容"
      />
    </div>
    <el-row
      type="flex"
      :gutter="20"
      align="middle"
    >
      <el-col :span="18">
        <h2>配置跳转文案链接：</h2>
      </el-col>
      <el-col
        :span="6"
        class="text-lt"
      >
        <el-button @click="onNewOpenForm">
          新建
        </el-button>
      </el-col>
    </el-row>
    <template v-for="(item, index) in guideList">
      <el-row
        :key="item.id"
        class="list-box"
        :gutter="20"
      >
        <el-col :span="8">
          <label class="label-title">引导文案：</label>
          <el-tooltip
            v-show="!item.isEditor"
            effect="dark"
            :content="item.title"
            placement="top-start"
          >
            <span class="message-tip">{{ item.title }}</span>
          </el-tooltip>
          <el-input
            v-show="item.isEditor"
            v-model="item.title"
            placeholder="请输入引导文案"
            style="width: 70%;"
          />
        </el-col>
        <el-col :span="10">
          <label class="label-title">跳转链接：</label>
          <el-tooltip
            effect="dark"
            :content="item.url"
            placement="top-start"
          >
            <span class="message-tip">{{ item.url }}</span>
          </el-tooltip>
        </el-col>
        <el-col
          :span="6"
          class="editor-box"
        >
          <el-button @click="onEditorOpenForm(item, index)">
            {{
              !item.isEditor ? "编辑" : "完成"
            }}
          </el-button>
          <el-button @click="onEditorDel(index)">
            删除
          </el-button>
        </el-col>
      </el-row>
    </template>

    <el-dialog
      class="ft-box"
      :title="dialogTitle"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      width="30%"
      center
    >
      <el-form
        ref="formData"
        :model="formData"
        label-width="120px"
      >
        <el-form-item
          label="跳转链接"
          prop="id"
        >
          <el-select
            v-model="formData.id"
            filterable
            remote
            reserve-keyword
            placeholder="请输入关键词"
            :remote-method="remoteMethod"
            :loading="loading"
            style="width: 100%;"
            @change="onChangeSelect"
            @visible-change="onVisibleChange"
          >
            <el-option
              v-for="item in articleListClone"
              :key="item.id"
              :label="item.title"
              :value="item.itemStr"
              :disabled="item.disabled"
            />
          </el-select>
        </el-form-item>
      </el-form>
    </el-dialog>
    <div class="button-box">
      <el-button @click="onClear">
        取消
      </el-button>
      <el-button @click="onSubmit">
        保存
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'NewbieGuide',
  data() {
    return {
      textarea: '',
      id: null,
      location: 1,
      dialogTitle: '新增',
      dialogFormVisible: false,
      searchData: {
        // 搜索参数
        pageNo: 1,
        pageSize: 10,
        showAt: null,
        categoryId: '',
        secCategoryId: '',
        searchKey: '',
        status: 1
      },
      loading: false,
      initFormData: {
        id: '',
        title: ''
      },
      formData: {}
    }
  },
  computed: {
    ...mapGetters(['articleList', 'guideDetailList']),
    articleListClone: {
      get: function() {
        const idArr = this.guideList.map(item => item.id)
        const list = this.articleList.map(item => {
          return {
            id: item.id,
            title: `${item.title  }：${  item.factoryUrl}`,
            url: item.factoryUrl,
            itemStr: JSON.stringify(item),
            disabled: idArr.includes(item.id),
            isEditor: false
          }
        })
        return list || []
      },
      set: function(newValue) {
        console.log(newValue)
      }
    },
    guideList: {
      get: function() {
        return this.guideDetailList
      },
      set: function(newValue) {
        console.log(newValue)
      }
    }
  },
  mounted() {
    this.initPage()
  },
  methods: {
    ...mapActions(['getGuideDetail', 'getArticleList', 'postGuideSave']),
    initPage() {
      this.onGetGuideDetail()
      this.onGetArticleList()
    },
    onGetGuideDetail() {
      const Data = {
        location: 1
      }
      this.getGuideDetail(Data).then(res => {
        if (res) {
          this.textarea = res.content
          this.id = res.id
          this.location = res.location
        }
      })
    },
    onGetArticleList() {
      this.loading = true
      this.getArticleList(this.searchData).then(() => {
        this.loading = false
      })
    },
    onChangeSelect(options) {
      console.log(options)
      const item = JSON.parse(options)

      this.guideList = this.guideList.unshift({
        id: item.id,
        title: item.title,
        url: item.factoryUrl
      })
      this.dialogFormVisible = false
    },
    onVisibleChange() {
      if (!this.articleList.length > 0) {
        this.onGetArticleList()
      }
    },
    onNewOpenForm() {
      this.dialogFormVisible = true
      this.formData = JSON.parse(JSON.stringify(this.initFormData))
    },
    onEditorDel(index) {
      this.guideList.splice(index, 1)
    },
    onEditorOpenForm(options, index) {
      console.log(options, index)
      this.$set(this.guideList, index, Object.assign({}, options, { isEditor: !options.isEditor }))
    },
    remoteMethod(options) {
      console.log(options)
      const Data = Object.assign({}, this.searchData, { searchKey: options })
      this.loading = true
      this.getArticleList(Data).then(() => {
        this.loading = false
      })
    },
    onClear() {
      this.onGetGuideDetail()
      this.$message({ message: '取消成功', type: 'success' })
    },
    onSubmit() {
      if (!this.textarea) {
        this.$message({ message: '请输入内容', type: 'fail' })
        return false
      }
      const list = this.guideList.map(item => {
        return {
          id: item.id,
          title: item.title
        }
      })
      const Data = {
        id: this.id,
        location: this.location,
        content: this.textarea,
        items: list
      }
      this.postGuideSave(Data).then(res => {
        this.onGetGuideDetail()
        if (res) {
          this.$message({ message: '操作成功', type: 'success' })
        }
      })
    }
  }
}
</script>

<style scoped>
.label-title {
  color: #9ba6ac;
}
.list-box {
  line-height: 40px;
  margin-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px #eee solid;
}
.editor-box {
  text-align: left;
}
.button-box {
  width: 100%;
  background: #fff;
  padding: 30px;
  text-align: center;
  position: fixed;
  bottom: 0;
  z-index: 999999999;
}
.message-tip {
  display: inline-block;
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}
</style>
