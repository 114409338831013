<template>
  <el-cascader
    ref="cascader"
    v-model="valueList"
    v-bind="$attrs"
    clearable
    filterable
    :props="props"
    :options="optionsList"
    v-on="$listeners"
    @change="onChange"
  />
</template>

<script>
export default {
  name: 'OrgList',
  props: {
    value: Array,
    orgList: {
      type: [Array, String],
      default: () => []
    },
    optProps: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      valueList: [],
      optionsList: [],
      props: {
        multiple: true,
        lazy: false,
        emitPath: false,
        checkStrictly: true,
        value: 'id',
        label: 'name',
        lazyLoad: async(node, resolve) => {
          const { value: orgId } = node
          let res = await this.$axios.get(this.$API.getOrgList, { params: { orgId } })
          resolve(node.children?.length ? [] : res)
        },
        ...this.optProps
      }
    }
  },
  mounted() {
    this.getCascadeValue()
  },
  methods: {
    // 回显数据
    async getCascadeValue(orgList = this.orgList) {
      let params = []
      if (typeof orgList === 'string') {
        params.push(orgList)
      } else {
        params = orgList
      }
      // 多个 各级需要 children 的list
      let arr = []
      // 回显用的父级链路
      let fullPath = []
      try {
        if (params.length !== 0) {
          const res = await this.$axios.post(this.$API.getFullOrgList, params)
          fullPath = res?.map(item => item.split(',').reverse())
          const getTargetChildren = fullPath.map(item => item.slice(0, item.length - 1))
          let maxLength = Math.max(...getTargetChildren.map(item => item.length))
          for (let i = 0; i <= maxLength; i++) {
            arr.push([...new Set(getTargetChildren.map(fullPathItem => fullPathItem[i]).filter(fullPathItem => fullPathItem))])
            arr = arr.filter(item => item.length > 0)
          }
        }
      } catch (error) {
        console.log('%c [error]', 'font-size:15px; color:#42b983;', error)
      } finally {
        // 获取第一级组织
        let defaultOptions = await this.$axios.get(this.$API.getOrgList, { params: { orgId: '' } })
        await this.formatBaseOptionList(defaultOptions, arr)

        this.$set(this, 'optionsList', defaultOptions)
        if (params.length === 1 && !this.props.multiple) {
          fullPath = fullPath.flat(1)
        }

        this.valueList = fullPath
        this.$nextTick(() => {
          this.props.lazy = true
          this.props.emitPath = true
          this.$refs.cascader.panel.initStore()
        })
      }
    },
    async formatBaseOptionList(options, fullPath, index = 0) {
      const current = fullPath[index]
      for (let i = 0; i < options.length; i++) {
        options[i].children = []
        // 单选和多选时，判断是否需要获取children
        if (current && ((current.includes(options[i].id) && index < fullPath.length))) {
          options[i].children = await this.$axios.get(this.$API.getOrgList, { params: { orgId: options[i].id } })
          await this.formatBaseOptionList(options[i].children, fullPath, index + 1)
        }
      }
    },
    onChange(e) {
      this.$nextTick(() => {
        const obj = this.$refs['cascader'].getCheckedNodes().filter(item => item)
        let list = []
        obj.map(({ data: { id, name } }) => {
          list.push({ queryObjectId: id, queryObjectShowTitle: name })
        })
        this.$emit('choose-list', list, e)
      })
    }
  }
}
</script>

