<template>
  <el-tabs type="border-card">
    <el-tab-pane label="秒杀">
      <div class="mgn-b30 flex">
        <el-input
          v-model="title"
          clearable
          placeholder="活动名称"
          class="search"
        />
        <el-select
          v-model="campaignStatus"
          clearable
          placeholder="活动状态"
          class="add-lan"
        >
          <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <el-button class="add-lan" type="primary" @click="onSearch">搜索</el-button>
        <el-button class="add-lan" @click="onClickDetail({})">+ 创建秒杀</el-button>
      </div>
      <el-table :data="activityList" border>
        <el-table-column prop="title" label="活动名称" />
        <el-table-column prop="startAt" label="秒杀开始时间" />
        <el-table-column prop="endAt" label="秒杀结束时间" />
        <el-table-column prop="count" label="商品数量" />
        <el-table-column prop="campaignStatus" label="活动状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.campaignStatus === -1" type="info">未发布</el-tag>
            <el-tag v-if="scope.row.campaignStatus === 2" type="warning">未开始</el-tag>
            <el-tag v-if="scope.row.campaignStatus === 1" type="success">进行中</el-tag>
            <el-tag v-if="scope.row.campaignStatus === 0" type="danger">已结束</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="creator" label="创建人" />
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div>
              <el-button v-if="scope.row.campaignStatus === -1" type="text" @click="onClickPublish(scope.row)"> 发布 </el-button>
              <el-button v-if="scope.row.campaignStatus === -1 || scope.row.campaignStatus === 2" type="text" @click="onClickDetail(scope.row)"> 编辑 </el-button>
              <el-button v-if="scope.row.campaignStatus === 1 || scope.row.campaignStatus === 0" type="text" @click="onClickDetail(scope.row)"> 查看 </el-button>
              <el-button
                v-if="scope.row.campaignStatus === -1 || scope.row.campaignStatus === 2"
                type="text"
                style="color: red;"
                @click="onClickDel(scope.row)"
              >
                删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="pageNo"
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="totalCount"
        @current-change="handleCurrentChange"
      />
    </el-tab-pane>
    <el-tab-pane label="优惠券">敬请期待</el-tab-pane>
  </el-tabs>
</template>

<script>
export default {
  name: 'MarketingManagement',
  data() {
    return {
      title: '',
      campaignStatus: null,
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      activityList: [],
      isLoading: false,
      dialogTitle: '新建专区',
      statusList: [
        {
          value: -1,
          label: '未发布'
        },
        {
          value: 2,
          label: '未开始'
        },
        {
          value: 1,
          label: '进行中'
        },
        {
          value: 0,
          label: '已结束'
        }
      ]
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        campaignStatus: this.campaignStatus,
        title: this.title
      }
      this.$axios.post(this.$API.getSeckillList, data).then(res => {
        this.activityList = res.data
        this.totalCount = res.totalCount
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    onSearch() {
      this.pageNo = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNo = val
      this.getList()
    },
    onClickDetail(options) {
      this.$router.push({
        path: '/marketingManagementDetail',
        query: {
          campaignId: options.campaignId,
          type: options.campaignStatus
        }
      })
    },
    onClickDel(options) {
      this.$confirm('是否删除该活动?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (this.isLoading) return
          this.isLoading = true
          this.$axios.get(this.$API.deleteSeckill, { params: { campaignId: options.campaignId } }).then(
            () => {
              this.isLoading = false
              this.getList()
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
            },
            err => {
              this.isLoading = false
              this.$message.error(err.message)
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    onClickPublish(options) {
      this.$confirm('是否发布该活动?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (this.isLoading) return
          this.isLoading = true
          this.$axios.get(this.$API.publishSeckill, { params: { campaignId: options.campaignId } }).then(
            () => {
              this.isLoading = false
              this.getList()
              this.$message({
                type: 'success',
                message: '发布成功!'
              })
            },
            err => {
              this.isLoading = false
              this.$message.error(err.message)
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消发布'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-lan {
  margin-left: 30px;
}
.search {
  width: 200px;
}
.el-pagination {
  padding-top: 10px;
  text-align: center;
}
</style>
