<template>
  <div class="home">
    <el-row :gutter="24">
      <el-col :span="4">
        <el-input
          v-model="order.name"
          placeholder="请输入厂家或代表名称"
        />
      </el-col>
      <el-col :span="4">
        <el-input
          v-model="order.mobile"
          placeholder="请输入厂家或代表手机号"
        />
      </el-col>
      <el-col :span="3">
        <el-select
          v-model="order.contractStatus"
          placeholder="请选择订单状态"
        >
          <el-option
            v-for="item in prodState"
            :key="item.index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-select
          v-model="order.orderChannel"
          placeholder="请选择订单渠道"
        >
          <el-option
            v-for="item in prodType"
            :key="item.index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-button
          type="primary"
          class="qxs-btn-md"
          icon="el-icon-search"
          @click="search(1)"
        >
          搜索
        </el-button>
      </el-col>
      <el-col :span="3">
        <el-button
          type="primary"
          class="qxs-btn-md"
          icon="el-icon-plus"
          @click="onCreatContract"
        >
          新建培训合同
        </el-button>
      </el-col>
      <el-col :span="3">
        <el-button
          type="primary"
          class="qxs-btn-md"
          icon="el-icon-download"
          @click="onExport"
        >
          导出
        </el-button>
      </el-col>
    </el-row>
    <div
      ref="table"
      class="mgn-t30"
    >
      <el-table
        ref="multipleTable"
        v-loading="loading"
        :data="courseOrderList"
        border
        style="width: 100%;"
      >
        <el-table-column
          prop="businessName"
          label="名称"
          min-width="10%"
        />
        <el-table-column
          prop="id"
          label="订单号"
          min-width="10%"
        />
        <el-table-column
          label="课程数量"
          min-width="5%"
          prop="courseCount"
        />
        <el-table-column
          label="课程详情"
          min-width="8%"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              @click.native.prevent="openCourseDetail(scope.$index, courseOrderList)"
            >
              查看课程
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          label="课程原价"
          min-width="6%"
          prop="originalPrice"
        />
        <el-table-column
          label="实际价格"
          min-width="6%"
          prop="markingPrice"
        />
        <el-table-column
          prop="contractMoney"
          label="合同金额"
          min-width="6%"
        />
        <el-table-column
          label="打款金额"
          min-width="6%"
          prop="payAmount"
        />
        <el-table-column
          label="学习次数"
          min-width="6%"
          prop="degree"
        />
        <el-table-column
          label="状态"
          min-width="6%"
        >
          <template slot-scope="scope">
            {{ scope.row.contractStatus === 2 ? '未通过' : scope.row.contractStatus === 0 ? '待审核' : '已通过' }}
          </template>
        </el-table-column>
        <el-table-column
          label="订单渠道"
          min-width="6%"
        >
          <template slot-scope="scope">
            {{ scope.row.orderChannel === 0 ? '厂家合同' : '代表端' }}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          min-width="6%"
        >
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.orderChannel == 0 && scope.row.contractStatus == 0"
              type="text"
              @click.native.prevent="checkDetail(scope.$index, courseOrderList)"
            >
              审核订单
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="order.pageNo"
        :page-size="20"
        layout="prev, pager, next, jumper"
        :total="courseOrderCount"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
      title="查看课程"
      :visible.sync="dialogTableVisible"
    >
      <el-table :data="orderDetail">
        <el-table-column
          property="courseName"
          label="课程名称"
          min-width="150"
        />
        <el-table-column
          property="originalPrice"
          label="课程原价"
        />
        <el-table-column
          property="markingPrice"
          label="实际价格"
        />
        <el-table-column
          property="videoCount"
          label="视频数量"
        />
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'OrderManagement',
  components: {},
  data() {
    return {
      loading: true,
      name: '订单管理',
      order: {
        name: '',
        phone: '',
        contractStatus: null,
        orderChannel: null,
        pageNo: 1,
        pageSize: 20
      },
      prodState: [
        {
          id: '',
          name: '全部'
        },
        {
          id: 2,
          name: '未通过'
        },
        {
          id: 0,
          name: '待审核'
        },
        {
          id: 1,
          name: '已通过'
        }
      ],
      prodType: [
        {
          id: '',
          name: '全部'
        },
        {
          id: 0,
          name: '后台合同'
        },
        {
          id: 2,
          name: '代表端'
        }
      ],
      orderDetail: [],
      dialogTableVisible: false
    }
  },
  computed: {
    ...mapGetters(['courseOrderList', 'courseOrderIndex', 'courseOrderCount'])
  },
  mounted() {
    this.$store.dispatch('changeTitle', this.name)
    this.getCourseOrderList(this.order).then(() => {
      this.loading = false
    })
  },
  methods: {
    ...mapActions(['getCourseOrderList', 'getCourseOrderDetail', 'exportTrainOrderList']),
    checkDetail(index, data) {
      this.$router.push({ name: 'ContractManagement', query: { id: data[index].id } })
    },
    handleCurrentChange(val) {
      this.search(val)
    },
    addProd() {
      this.$router.push({ name: 'CourseDetail' })
    },
    search(index) {
      this.order.pageNo = index || 1
      this.getCourseOrderList(this.order).then(() => {
        this.loading = false
      })
    },
    onCreatContract() {
      this.$router.push({ name: 'ContractManagement' })
    },
    onExport() {
      this.$confirm('确定导出订单列表?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.exportTrainOrderList(this.order).then(() => {
          this.$message.success('数据处理可能需要一点儿时间, 请前往下载中心下载。')
        }, rea => {
          this.$message.error(rea.message)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })

    },
    openCourseDetail(index, data) {
      this.getCourseOrderDetail({ orderId: data[index].id }).then(res => {
        this.orderDetail = res
        this.dialogTableVisible = true
      }, rea => {
        this.$message.error(rea.message)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.el-input__inner {
  display: inline-block;
}
.el-pagination {
  padding-top: 26px;
  text-align: center;
}

</style>
