/*
 * @Author: your name
 * @Date: 2020-10-30 14:48:23
 * @LastEditTime: 2020-11-06 18:08:28
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /qxs-management/src/apis/face-service.js
 */
import request from '@/utils/request'
import API from './api-types'

// 获取商品池列表
export function getFaceReviewList(data) {
  return request({
    url: API.getFaceReviewList,
    method: 'get',
    params: data
  })
}
export function getFaceReviewDetail(data) {
  return request({
    url: API.getFaceReviewDetail,
    method: 'get',
    params: data
  })
}
export function saveFaceReview(data) {
  return request({
    url: API.saveFaceReview,
    method: 'post',
    data
  })
}
export function handleFaceAuthLimit(data) {
  return request({
    url: API.handleFaceAuthLimit,
    method: 'get',
    params: data
  })
}
export function getDepartmentList() {
  return request({
    url: API.getDepartmentList,
    method: 'get'
  })
}
export function faceSwitchDetail() {
  return request({
    url: API.faceSwitchDetail,
    method: 'get'
  })
}
