<template>
  <div class="w100">
    <div class="flex-start">
      <el-upload
        :disabled="disabled"
        :action="uploadApi"
        :headers="headers"
        name="image"
        :show-file-list="false"
        :before-upload="beforeAvatarUpload"
        :on-success="handleAvatarSuccess"
        list-type="picture-card"
        accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
      >
        <img
          v-if="customCoverUrl"
          :src="customCoverUrl"
          style="width: 100%; height: 100%; border-radius: 6px;"
        >
        <div
          v-else
          slot="trigger"
          class="wrapper"
        >
          <div>
            <img
              :src="require('@/assets/carmer.jpg')"
              alt=""
              class="image-w"
            >
          </div>
          <div>上传图片</div>
        </div>
      </el-upload>
      <slot v-if="$slots.leftContent" name="leftContent">
        <div class="tips-text" style="margin-left: 10px;">
          备注：图片建议尺寸16：9
        </div>
      </slot>
    </div>
    <slot v-if="$slots.bottomContent" name="bottomContent">
      <div class="tips-text">
        备注：图片建议尺寸16：9
      </div>
    </slot>
  </div>
</template>

<script>
import API from '@/apis/api-types'
import { getToken } from '@/utils/auth'

export default {
  name: 'ImageOnlyUpload',
  props: {
    initCustomCoverUrl: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 0
    },
    scaleRules: {
      type: Object,
      default() {
        return {
          isRequired: false,
          scale: [16, 9]
        }
      }
    },
    // 上传图片接口，文章相关的传 115，反馈相关的传入116，认证相关的105
    bucketType: {
      type: Number,
      default: 105
    }
  },
  data() {
    return {
      headers: {
        token: getToken()
      },
      coverUrl: ''
    }
  },
  computed: {
    uploadApi() {
      return `${API.imageUpload}?bucketType=${this.bucketType}`
    },
    customCoverUrl: {
      get() {
        return this.initCustomCoverUrl || ''
      },
      set(url) {
        this.coverUrl = url
      }
    }
  },
  methods: {
    // 图片上传
    beforeAvatarUpload(file) {
      const _this = this
      const isSize = new Promise(function(resolve, reject) {
        const width = this.scaleRules.scale.width // 限制图片尺寸为16:9
        const height = this.scaleRules.scale.height
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.onload = function() {
          if (this.scaleRules.isRequired) {
            const valid = (img.width / img.height).toFixed(2) === (width / height).toFixed(2)
            valid ? resolve() : reject()
          } else {
            resolve
          }
        }
        img.src = _URL.createObjectURL(file)
      }).then(() => {
        return file
      }, () => {
        this.scaleRules.isRequired && _this.$message.warning('建议视频封面图片尺寸16:9')
        return Promise.resolve()
      })
      return isSize
    },
    handleAvatarSuccess(res, file) {
      this.customCoverUrl = URL.createObjectURL(file.raw)
      this.cover = res.data.imageName || ''
      this.upLoadCover = res.data.middle || ''
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('uploadSuccess', res.data)
    }
  }
}
</script>

<style scope>

.flex-start {
  display: flex;
  align-items: center;
}
.line-row {
  flex-flow: column;
}
.content {
  margin: 30px 0 20px 20px;
}
.wrapper {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  line-height: 1;
  height: 100%;
  color: #008075;
  font-size: 12px;
}
.image-w {
  margin-bottom: 15px;
}
.tips-text {
  color: #c8c9ce;
  font-size: 14px;
}
.w100 {
  width: 100%;
}
</style>
