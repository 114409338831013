<template>
  <el-container
    v-loading="loading"
    class="home"
  >
    <el-header class="tab-panel">
      <el-menu 
        :default-active="activeIndex" 
        class="el-menu-demo"
        mode="horizontal"
      >
        <el-menu-item index="1">
          人工审核
        </el-menu-item>
      </el-menu>
    </el-header>
    <el-main class="prod-panel">
      <el-form
        ref="form"
        label-width="120px"
      >
        <el-form-item label="真实姓名：">
          <el-input
            :value="doctorFaceDetail.commitUserName"
            :readonly="true"
            class="elm-input"
          />
        </el-form-item>
        <el-form-item label="身份证号：">
          <el-input
            :value="doctorFaceDetail.idCard"
            :readonly="true"
            class="elm-input"
          />
        </el-form-item>
        <el-form-item label="身份证正反面">
          <el-image 
            v-if="doctorFaceDetail.idCardFront"
            class="id-card-image" 
            :src="doctorFaceDetail.idCardFront"
            :preview-src-list="previewList"
          />
          <div
            v-else
            class="placeholder-image"
          >
            暂无截图
          </div>
          <el-image 
            v-if="doctorFaceDetail.idCardBack"
            class="id-card-image" 
            :src="doctorFaceDetail.idCardBack"
            :preview-src-list="previewList"
          />
          <div
            v-else
            class="placeholder-image"
          >
            暂无截图
          </div>
        </el-form-item>
        <el-form-item label="微信人脸识别系统截图">
          <el-image 
            v-if="doctorFaceDetail.bestFrame"
            class="id-card-image"
            :src="doctorFaceDetail.bestFrame" 
            :preview-src-list="previewList"
          />
          <div
            v-else
            class="placeholder-image"
          >
            暂无截图
          </div>
        </el-form-item>
        <el-form-item label="审核">
          <el-select
            v-model="reviewState"
            placeholder="请选择"
          >
            <el-option 
              v-for="item in reviewList"
              :key="item.id"
              :span="12"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="reviewState==1"
          label="失败原因"
        >
          <el-input 
            v-model="failReason"
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            class="elm-input"
          />
        </el-form-item>
      </el-form>
      <el-form
        v-if="doctorFaceDetail.doctorInfoVO"
        ref="form"
        label-width="80px"
      >
        <el-form-item label="平台姓名：">
          <el-input
            :value="doctorFaceDetail.doctorInfoVO.name"
            :readonly="true"
            class="elm-input"
          />
        </el-form-item>
        <el-form-item label="所在医院：">
          <el-input
            :value="doctorFaceDetail.doctorInfoVO.hospital"
            :readonly="true"
            class="elm-input"
          />
        </el-form-item>
        <el-form-item label="一级科室：">
          <el-input
            :value="firstDepartmentName"
            :readonly="true"
            class="elm-input"
          />
        </el-form-item>
        <el-form-item label="二级科室：">
          <el-input
            :value="secondDepartmentName"
            :readonly="true"
            class="elm-input"
          />
        </el-form-item>
        <el-form-item label="职称：">
          <el-input
            :value="doctorFaceDetail.doctorInfoVO.titleName"
            :readonly="true"
            class="elm-input"
          />
        </el-form-item>
      </el-form>
    </el-main>
    <el-footer>
      <FooterWrapper>
        <el-button @click="onSubmit">
          保存
        </el-button>
        <el-button @click="onBack">
          返回
        </el-button>
      </FooterWrapper>
    </el-footer>
  </el-container>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue';
// import { fenToYuan } from '@/utils/util'
import FooterWrapper from '@/components/common/footerWrapper.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'FaceReviewDetail',
  components: {
    FooterWrapper
  },
  data() {
    return {
      name: '添加商品',
      loading: true,
      activeIndex: '1',
      id: '',
      reviewState: null,
      reviewList: [
        {name: '认证成功', id: 0},
        {name: '认证失败', id: 1}
      ],
      isSave: false,
      failReason: ''
        
    }
  },
  computed: {
    ...mapGetters('FaceReview', [
      'doctorFaceDetail',
      'departmentList'
    ]),
    previewList() {
      return [
        this.doctorFaceDetail.idCardFront,
        this.doctorFaceDetail.idCardBack,
        this.doctorFaceDetail.bestFrame
      ]
    },
    firstDepartmentName() {
      if (!this.doctorFaceDetail.doctorInfoVO) return ''
      const id =  this.doctorFaceDetail.doctorInfoVO.firstDepartmentId
      let name = ''
      this.departmentList.map(item => {
        if (item.code == id) {
          name = item.name
        }
      })
      return name
    },
    secondDepartmentName() {
      if (!this.doctorFaceDetail.doctorInfoVO) return ''
      const id =  this.doctorFaceDetail.doctorInfoVO.firstDepartmentId
      const childId =  this.doctorFaceDetail.doctorInfoVO.secondDepartmentId
      let name = ''
      this.departmentList.map(item => {
        if (item.code == id) {
          item.childs.map(k => {
            if (k.code == childId) {
              name = k.name
            }
          })
        }
      })
      return name
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.$store.dispatch('changeTitle', this.name)
    this.initDepartmentList()
    this.initDetail()

  },
  methods: {
    ...mapActions('FaceReview', [
      'getFaceReviewDetail',
      'saveFaceReview',
      'getDepartmentList',
      'changeFaceItemStatus'
    ]),
    onBack() {
      this.$router.back()
    },
    onSubmit() {
      if (this.isSave) return this.$message.warning('正在保存中，请稍后在操作')
      if (this.reviewState === null) {
        this.$message.error('请选择审核结果')
        return
      }
      if (this.reviewState == 1 && !this.failReason) {
        this.$message.error('请填写审核失败原因')
        return
      }
      const data = {
        recordId: this.id,
        auditResult: this.reviewState,
        rejectReason: this.failReason
      }
      this.isSave = true
      this.saveFaceReview(data).then(() => {
        this.$message.success('保存成功')
        this.changeFaceItemStatus({
          recordId: this.id,
          status: this.reviewState
        })
        this.isSave = false
      }, rea => {
        this.$message.error(rea.message)
        this.isSave = false
      })
    },
    initDetail() {
      this.getFaceReviewDetail({id: this.id}).then(() => {
        this.loading = false
      }, rea => {
        this.loading = false
        this.$message.error(rea.message)
      })
    },
    initDepartmentList() {
      this.getDepartmentList().then(() => {

      }, rea => {
        this.$message.error(rea.message)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .home {
    margin-top: 40px;
    padding: 0 20px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    .tab-panel {
      /* position: fixed; */

      /* top: 50px; */
      z-index: 1000;
      width: 100%;
      background-color: white;
    }
    .prod-panel {
      padding: 20px 10px;

      /* margin-top: 40px; */
    }
  }
  .label-tab {
    margin-top: 26px;
    margin-bottom: 16px;
    position: relative;
    left: -10px;
  }
  .elm-input {
    width: 300px;
  }
  .id-card-image {
    width: 400px;
    margin-right: 20px;
  }
  .el-form {
    display: inline-block;
    width: 50%;
    vertical-align: top;
  }
  .placeholder-image {
    width: 120px;
    height: 120px;
    background: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #666;
    margin-bottom: 10px;
  }
</style>
