/*
 * @Author: your name
 * @Date: 2022-04-25 18:20:17
 * @LastEditTime: 2022-04-26 10:34:25
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /qxs-management/src/apis/getDoctorDetail.js
 */
import request from '@/utils/request'
import API from './api-types'
export function getThridVerifyResult(recordId = '') {
  return request({
    url: API.getThridVerifyResult,
    method: 'get',
    params: {recordId}
  })
}
export function getDoctorBaseInfo(data) {
  return request({
    url: API.getDoctorBaseInfo,
    method: 'get',
    params: data
  })
}
export function setDoctorBaseInfo(data) {
  return request({
    url: API.setDoctorBaseInfo,
    method: 'post',
    data
  })
}
export function getDoctorAuthInfo(data) {
  return request({
    url: API.getDoctorAuthInfo,
    method: 'get',
    params: data
  })
}
export function setDoctorAuthInfo(data) {
  return request({
    url: API.setDoctorAuthInfo,
    method: 'post',
    data
  })
}
export function getDoctorRealNameInfo(data) {
  return request({
    url: API.getDoctorRealNameInfo,
    method: 'get',
    params: data
  })
}
export function setDoctorRealNameInfo(data) {
  return request({
    url: API.setDoctorRealNameInfo,
    method: 'post',
    data
  })
}
export function getFactoryInfo(data) {
  return request({
    url: API.getFactoryInfo,
    method: 'get',
    params: data
  })
}
export function setFactoryInfo(data) {
  return request({
    url: API.setFactoryInfo,
    method: 'post',
    data
  })
}
export function getAreaList() {
  return request({
    url: API.getAreaList,
    method: 'get'
  })
}
export function getHospitalList(data) {
  return request({
    url: API.getHospitalList,
    method: 'get',
    params: data
  })
}
