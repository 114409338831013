import request from '@/utils/request'
import API from './api-types'

// 添加商品到商品池
export default function getServicePolicy(params) {
  return request({
    url: API.getServicePolicy,
    method: 'get',
    params
  })
}
