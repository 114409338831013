import request from '@/utils/request'
import API from './api-types'

export function managementColumnList(options) {
  return request({
    url: API.managementColumnList,
    method: 'get',
    params: options
  })
}

export function modifyColumn(options) {
  return request({
    url: API.modifyColumn,
    method: 'get',
    params: options
  })
}

export function upColumn(options) {
  return request({
    url: API.upColumn,
    method: 'get',
    params: options
  })
}

export function downColumn(options) {
  return request({
    url: API.downColumn,
    method: 'get',
    params: options
  })
}