<template>
  <vue-scroll :ops="{...ops,...$attrs.ops}" v-bind="$attrs" v-on="$listeners">
    <slot />
    <div class="vue-scroll-bar-wrap">
      <slot name="vue-scroll-bar-wrap" />
    </div>
  </vue-scroll>
</template>

<script>
import vueScroll from 'vuescroll'
export default {
  name: 'VueScrollBar',
  components: {
    vueScroll
  },
  data() {
    return {
      ops: {
        wheelScrollDuration: 0,
        wheelDirectionReverse: false,
        checkShifKey: true,
        mode: 'native',
        detectResize: true,
        easing: 'easeInQuad',
        bar: {
          background: '#dddee0'
        },
        animation: 'easeInQuad'
      }
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.vue-scroll-bar-wrap {
  padding-right: 10px;
  border-top: 0.5px solid transparent;
}
</style>
