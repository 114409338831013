import request from '@/utils/request'
import API from './api-types'

// 获取商品池列表
export default function getProductList(data) {
  return request({
    url: API.getProductList,
    method: 'post',
    data: data
  })
}
