<template>
  <div
    v-loading="loading"
    class="container"
  >
    <el-header class="tab-panel">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
      >
        <el-menu-item
          index="1"
          @click="scrollTo('1')"
        >
          基础信息
        </el-menu-item>
        <el-menu-item
          v-if="showAuth"
          index="2"
          @click="scrollTo('2')"
        >
          医师认证
        </el-menu-item>
        <el-menu-item
          v-if="showReal"
          index="3"
          @click="scrollTo('3')"
        >
          实名认证
        </el-menu-item>
      </el-menu>
    </el-header>

    <div class="doctor-baseInfo pdg-t30">
      <div
        id="1"
        class="title"
      >
        |基础信息
        <el-button
          v-if="!editor"
          class="qxs-btn-sm base-editor"
          @click="editorBaseInfo"
        >
          修 改
        </el-button>
      </div>
      <el-form
        ref="doctorBaseInfo"
        :model="doctorBaseInfo"
        :rules="basicInfoRules"
        label-width="100px"
        class="base-info"
        hide-required-asterisk
      >
        <el-row>
          <el-col :span="6">
            <el-form-item
              label="姓名："
              prop="name"
            >
              <el-input
                v-if="editor"
                v-model="doctorBaseInfo.name"
                placeholder="请输入姓名"
              />
              <div v-else>
                {{ doctorBaseInfo.name }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号码：">
              <div>{{ doctorBaseInfo.mobile }}</div>
            </el-form-item>
          </el-col>
          <el-col
            :span="6"
            class="creditRating"
          >
            <el-form-item label="认证等级：">
              <div>{{ doctorBaseInfo.creditRating }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="认证状态：">
              <div v-if="doctorBaseInfo.speakerStatus >= 0">
                {{ speakStatusFilter(doctorBaseInfo.speakerStatus) }}
              </div>
              <div v-else-if="doctorBaseInfo.realNameAuthStatus > 0">
                {{ realStatusFilter(doctorBaseInfo.realNameAuthStatus) }}
              </div>
              <div v-else>
                {{ certStatusFilter(doctorBaseInfo.certifyStatus) }}
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item
              label="职称："
              prop="titleName"
            >
              <el-select
                v-if="editor"
                v-model="doctorBaseInfo.titleName"
                placeholder="请选择职称"
              >
                <el-option
                  v-for="item in doctorTitle"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
              <div v-else>
                {{ doctorBaseInfo.titleName }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <div class="hospital-select-box">
              <div>
                <el-form-item
                  label="医院信息："
                  prop="hospital"
                >
                  <el-autocomplete
                    v-if="editor"
                    v-model="baseInfo.hospital"
                    style="width: 400px;"
                    :fetch-suggestions="queryBaseSearchAsync"
                    placeholder="请输入医院信息"
                    @select="handleBaseSelect"
                  />
                  <div v-else>
                    {{ baseInfo.hospital }}
                  </div>
                </el-form-item>
              </div>
              <div v-if="editor">
                <el-button
                  class="qxs-btn-sm add-hospital"
                  @click="addHospital"
                >
                  + 新增医院
                </el-button>
                <el-button
                  v-if="doctorBaseInfo.hospitalId"
                  class="qxs-btn-sm add-hospital"
                  @click="onGoDepartmentList('base')"
                >
                  + 新增/编辑科室
                </el-button>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item
              label="一级科室："
              prop="oneLevelDepartmentId"
            >
              <el-select
                v-if="editor"
                :value="baseInfo.oneLevelDepartmentId"
                placeholder="请选择一级科室"
                :span="8"
                @change="getDepartmentListChildren"
              >
                <el-option
                  v-for="item in firstBaseDepartmentList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
              <div v-else>
                {{ baseInfo.oneLevelDepartmentName }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="二级科室：">
              <el-select
                v-if="editor"
                :value="baseInfo.twoLevelDepartmentId"
                placeholder="请选择二级科室"
                :span="8"
                @change="getDepartmentListTwoChildren"
              >
                <el-option
                  v-for="item in secondBaseDepartmentList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
              <div v-else>
                {{ baseInfo.twoLevelDepartmentName }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="三级科室：">
              <el-select
                v-if="editor"
                :value="baseInfo.threeLevelDepartmentId"
                placeholder="请选择三级科室"
                :span="8"
                @change="getDepartmentListThreeChildren"
              >
                <el-option
                  v-for="item in thirdBaseDepartmentList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
              <div v-else>
                {{ baseInfo.threeLevelDepartmentName }}
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div
        v-if="editor"
        class="save"
      >
        <el-button
          type="primary"
          class="qxs-btn-sm"
          @click="submitBasicInfoForm('doctorBaseInfo')"
        >
          保 存
        </el-button>
        <el-button
          class="qxs-btn-sm"
          @click="resetForm('doctorBaseInfo')"
        >
          取 消
        </el-button>
      </div>
    </div>

    <div
      v-if="showAuth"
      class="doctor-baseInfo pdg-t30"
    >
      <el-divider />
      <div
        id="2"
        class="title"
      >
        |医师认证
      </div>
      <div class="block">
        <div
          v-if="doctorAuthInfo.auditResult === -1 || doctorAuthInfo.auditResult === 0"
          class="img-type"
        >
          基础信息修改：（请和上方基础信息进行对比，审核通过后基础信息会更新哦）
        </div>
        <el-form
          ref="doctorAuthInfo"
          :model="doctorAuthInfo"
          label-width="100px"
          class="base-info"
          hide-required-asterisk
        >
          <div
            v-if="doctorAuthInfo.auditResult === -1 || doctorAuthInfo.auditResult === 0"
            class="auth-info"
          >
            <el-row>
              <el-col :span="8">
                <el-form-item label="姓名：">
                  <el-input
                    v-model="doctorAuthInfo.name"
                    placeholder="请输入姓名"
                    class="input"
                  />
                </el-form-item>
                <el-tag
                  v-if="doctorAuthInfo.titleIsNew"
                  type="danger"
                  effect="dark"
                  size="small"
                >
                  New
                </el-tag>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <el-form-item
                  label="职称："
                  prop="titleName"
                >
                  <el-select
                    v-model="doctorAuthInfo.titleName"
                    placeholder="请选择职称"
                  >
                    <el-option
                      v-for="item in doctorTitle"
                      :key="item.name"
                      :label="item.name"
                      :value="item.name"
                    />
                  </el-select>
                </el-form-item>
                <el-tag
                  v-if="doctorAuthInfo.titleIsNew"
                  type="danger"
                  effect="dark"
                  size="small"
                >
                  New
                </el-tag>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="hospital-select-box">
                  <div>
                    <el-form-item label="医院信息：">
                      <el-autocomplete
                        v-model="authInfo.hospital"
                        style="width: 400px;"
                        :fetch-suggestions="querySearchAsync"
                        placeholder="请输入医院信息"
                        @select="handleAuthSelect"
                      />
                    </el-form-item>
                    <el-tag
                      v-if="doctorAuthInfo.hospitalIsNew"
                      type="danger"
                      effect="dark"
                      size="small"
                    >
                      New
                    </el-tag>
                    <div class="hospital-tips">
                      {{ addHospitalId ? '(医院缺失信用编码，点击"新增医院"，先为该医生设置医院)' : '' }}
                    </div>
                  </div>
                  <div
                    v-if="!doctorAuthInfo.hospitalId"
                    style="margin: 7px 0 0 7px;"
                  >
                    (用户输入：{{ doctorAuthInfo.hospital }})
                  </div>
                  <div>
                    <el-button
                      class="qxs-btn-sm add-hospital"
                      @click="addHospital"
                    >
                      + 新增医院
                    </el-button>
                    <el-button
                      v-if="doctorAuthInfo.hospitalId"
                      class="qxs-btn-sm add-hospital"
                      @click="onGoDepartmentList('auth')"
                    >
                      + 新增/编辑科室
                    </el-button>
                  </div>
                </div>
              </el-col>
            </el-row>
            <!-- <el-row>
                            <el-col :span="8">
                                <div class="hospital-select-box">
                                    <el-form-item label="一级科室：">
                                        <el-select :value="departmentNameFir" placeholder="请选择一级科室" :span="8" @change="getAuthDepartmentListChildren">
                                            <el-option v-for="item in firstAuthDepartmentList" :key="item.id" :label="item.name" :value="item.id" />
                                        </el-select>
                                    </el-form-item>
                                    <el-tag v-if="doctorAuthInfo.departmentIsNew" type="danger" effect="dark" size="small">New</el-tag>
                                    <div v-if="!doctorAuthInfo.hospitalId">(用户输入：{{ doctorAuthInfo.hospital }})</div>
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div class="hospital-select-box">
                                    <el-form-item label="二级科室：">
                                        <el-select :value="departmentNameSec" placeholder="请选择二级科室" :span="8" @change="getAuthSecondDepartment">
                                            <el-option v-for="item in secondBaseDepartmentList" :key="item.id" :label="item.name" :value="item.id" />
                                        </el-select>
                                    </el-form-item>
                                    <el-tag v-if="doctorAuthInfo.departmentIsNew" type="danger" effect="dark" size="small">New</el-tag>
                                    <div v-if="!doctorAuthInfo.hospitalId">(用户输入：{{ doctorAuthInfo.hospital }})</div>
                                </div>
                            </el-col>
                        </el-row> -->
            <el-row>
              <el-col :span="24">
                <div class="hospital-select-box">
                  <div class="new-auth-box">
                    <el-form-item
                      label="一级科室："
                      prop="oneLevelDepartmentId"
                    >
                      <el-select
                        :value="authInfo.oneLevelDepartmentId"
                        placeholder="请选择一级科室"
                        :span="8"
                        @change="getAuthDepartmentListChildren"
                      >
                        <el-option
                          v-for="item in firstAuthDepartmentList"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        />
                      </el-select>
                    </el-form-item>
                    <el-tag
                      v-if="doctorAuthInfo.departmentIsNew"
                      type="danger"
                      effect="dark"
                      size="small"
                    >
                      New
                    </el-tag>
                  </div>
                  <div
                    v-if="!initAuthInfo.oneLevelDepartmentId"
                    style="margin: 7px 0 0 7px;"
                  >
                    (用户输入：{{ initAuthInfo.oneLevelDepartmentName }})
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="hospital-select-box">
                  <div class="new-auth-box">
                    <el-form-item label="二级科室：">
                      <el-select
                        :value="authInfo.twoLevelDepartmentId"
                        placeholder="请选择二级科室"
                        :span="8"
                        @change="getAuthDepartmentListTwoChildren"
                      >
                        <el-option
                          v-for="item in secondAuthDepartmentList"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        />
                      </el-select>
                    </el-form-item>
                    <el-tag
                      v-if="doctorAuthInfo.departmentIsNew"
                      type="danger"
                      effect="dark"
                      size="small"
                    >
                      New
                    </el-tag>
                  </div>

                  <div
                    v-if="!initAuthInfo.twoLevelDepartmentId"
                    style="margin: 7px 0 0 7px;"
                  >
                    (用户输入：{{ initAuthInfo.twoLevelDepartmentName }})
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="hospital-select-box">
                  <div class="new-auth-box">
                    <el-form-item label="三级科室：">
                      <el-select
                        :value="authInfo.threeLevelDepartmentId"
                        placeholder="请选择三级科室"
                        :span="8"
                        @change="getAuthDepartmentListThreeChildren"
                      >
                        <el-option
                          v-for="item in thirdAuthDepartmentList"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        />
                      </el-select>
                    </el-form-item>
                    <el-tag
                      v-if="doctorAuthInfo.departmentIsNew"
                      type="danger"
                      effect="dark"
                      size="small"
                    >
                      New
                    </el-tag>
                  </div>

                  <div
                    v-if="!initAuthInfo.threeLevelDepartmentId"
                    style="margin: 7px 0 0 7px;"
                  >
                    (用户输入：{{ initAuthInfo.threeLevelDepartmentName }})
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <el-col>
            <div class="img-type">
              {{ doctorAuthInfo.certifyType === 1? '医院公告栏' : doctorAuthInfo.certifyType === 2 ? '医师执业证书' : doctorAuthInfo.certifyType === 3 ? '医院官网截图' : '医院胸牌' }}
            </div>
            <el-form-item class="img-position">
              <div class="second-img">
                <el-image
                  v-if="doctorAuthInfo.certifyImg"
                  class="img-certification"
                  :src="doctorAuthInfo.certifyImg"
                  :preview-src-list="srcList"
                  @click.stop="handleClickItem"
                />
                <div
                  v-else
                  class="img-certification"
                >
                  暂无截图
                </div>
              </div>
              <div
                v-if="doctorAuthInfo.certifyType === 2"
                class="second-img"
              >
                <el-image
                  v-if="doctorAuthInfo.certifyImgExtra"
                  class="img-certification"
                  :src="doctorAuthInfo.certifyImgExtra"
                  :preview-src-list="certifyImgExtraList"
                  @click.stop="handleClickItem"
                />
              </div>
            </el-form-item>
          </el-col>
          <div v-if="doctorAuthInfo.certifyType === 2">
            <div style="color: red;">
              第三方识别辅助认证：{{ thirdCertifyStatus === 1 ? '认证成功' : '认证失败' }}
              <el-button type="text" @click="onJumpWJW">(点击此处前往查询)</el-button>
            </div>
            <el-col v-if="thirdCertifyStatus !== 1" class="mgn-b10">
              <el-upload
                v-if="doctorAuthInfo.auditResult === -1"
                class="avatar-uploader"
                action="/manager/separate/v1/upload-water-image?bucketType=105"
                name="image"
                :headers="headers"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <div v-else slot="trigger" class="avatar-uploader-icon">
                  <div>上传医师执业网查询照片</div>
                </div>
              </el-upload>
              <el-image
                v-else-if="doctorAuthInfo.checkImageUrl"
                class="avatar"
                :src="doctorAuthInfo.checkImageUrl"
                :preview-src-list="checkImageUrlList"
                @click.stop="handleClickItem"
              />
              <div
                v-else
                class="img-certification"
              >
                暂无截图
              </div>
            </el-col>
          </div>
          <el-row type="flex" style="width: 100%; justify-content: flex-start;">
            <div>
              <div v-if="doctorAuthInfo.auditResult !== -1">
                <el-form-item label="审核结果：">
                  <div>
                    {{ authResult }}
                  </div>
                </el-form-item>
              </div>
              <div v-if="doctorAuthInfo.auditResult === -1 || doctorAuthInfo.auditResult === 0">
                <el-form-item label="审核结果：">
                  <el-select
                    v-model="authResultNew"
                    placeholder="请选择审核结果"
                    :disabled="authSelect"
                  >
                    <el-option
                      v-for="item in result"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </div>
              <div v-if="(doctorAuthInfo.auditResult === -1 || doctorAuthInfo.auditResult === 0) && authResultNew === -1">
                <el-form-item
                  label="失败原因："
                  prop="rejectReason"
                >
                  <el-select
                    v-model="doctorAuthInfo.rejectReason"
                    placeholder="请选择失败原因"
                    :disabled="authSelect"
                  >
                    <el-option
                      v-for="(item, index) in rejectReasonList"
                      :key="item.id"
                      :label="item.name | orderFilter(index + 1)"
                      :value="item.name"
                    />
                  </el-select>
                </el-form-item>
              </div>
              <div v-if="doctorAuthInfo.rejectReason === '其他'">
                <el-form-item
                  prop="rejectReason"
                >
                  <el-input
                    v-model="rejectReason"
                    placeholder="请输入失败原因"
                    type="textarea"
                    class="input"
                  />
                </el-form-item>
              </div>
              <div v-if="doctorAuthInfo.auditResult !== -1 && doctorAuthInfo.auditResult !== 0 && doctorAuthInfo.rejectReason">
                <el-form-item label="失败原因：">
                  <div>
                    {{ doctorAuthInfo.rejectReason }}
                  </div>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="提交方式：">
                  <div>
                    {{ auditType }}
                  </div>
                </el-form-item>
              </div>
              <div v-if="doctorAuthInfo.representInfo">
                <el-form-item label="代表信息：">
                  <div>
                    {{ doctorAuthInfo.representInfo }}
                  </div>
                </el-form-item>
              </div>
              <div>
                <el-button
                  v-if="(doctorAuthInfo.auditResult === -1 || doctorAuthInfo.auditResult === 0) && authSave"
                  type="primary"
                  class="doctor-save qxs-btn-md"
                  @click="onSaveDoctorCert"
                >
                  确 认
                </el-button>
              </div>
            </div>
            <div class="doctor-tips">
              <span v-if="doctorAuthInfo.certifyType === 2">医生认证审核原则：<br>证书编码页+证书变更页<br>必须包含 姓名+医院（执业地点）+证书编号</span>
              <span v-else>医生认证审核原则：<br>1.照片清晰可见，至少包含医生姓名<br>2.照片上的医生姓名必须和填写的医生姓名一致<br>3.照片上的医院必须实际上和填写的医院是同一家</span>
            </div>
          </el-row>
        </el-form>
      </div>
    </div>
    <div
      v-if="showReal"
      class="face-certification"
    >
      <el-divider />
      <div
        id="3"
        class="title"
      >
        |实名认证
      </div>
      <div class="block">
        <el-form
          ref="doctorRealNameInfo"
          :model="doctorRealNameInfo"
          label-width="100px"
          class="certification-result"
        >
          <el-form-item label="真实姓名：">
            <div>{{ doctorRealNameInfo.commitUserName }}</div>
          </el-form-item>
          <el-form-item label="身份证号：">
            <div>{{ doctorRealNameInfo.idCard }}</div>
          </el-form-item>
          <span class="face-tips">
            审核原则：<br>1.真实姓名需要和填写的姓名一致<br>2.如果真实姓名和填写的姓名不一致，必须保证和工作证明照片上的姓名一致
          </span>
          <div>
            <div class="img">
              <div class="img-type">
                人脸识别截图：
              </div>
              <el-form-item class="img-position">
                <el-image
                  v-if="doctorRealNameInfo.bestFrame"
                  class="img-realName"
                  :src="doctorRealNameInfo.bestFrame"
                />
                <div
                  v-else
                  class="img-realName"
                >
                  暂无截图
                </div>
              </el-form-item>
              <div
                v-if="doctorRealNameInfo.idCardFront || doctorRealNameInfo.idCardBack"
                class="img-type pdg-t30"
              >
                身份证正反面：
              </div>
              <el-form-item class="img-position">
                <div class="second-img">
                  <el-image
                    v-if="doctorRealNameInfo.idCardFront"
                    class="img-certification"
                    :src="doctorRealNameInfo.idCardFront"
                    :preview-src-list="idCardFront"
                    @click.stop="handleClickItem"
                  />
                  <div
                    v-else
                    class="img-certification"
                  >
                    暂无截图
                  </div>
                </div>
                <div class="second-img">
                  <el-image
                    v-if="doctorRealNameInfo.idCardBack"
                    class="img-certification"
                    :src="doctorRealNameInfo.idCardBack"
                    :preview-src-list="idCardBack"
                    @click.stop="handleClickItem"
                  />
                  <div
                    v-else
                    class="img-certification"
                  >
                    暂无截图
                  </div>
                </div>
              </el-form-item>
              <div
                v-if="doctorRealNameInfo.bankCardFront || doctorRealNameInfo.bankCardBack"
                class="img-type pdg-t30"
              >
                银行卡正反面：
              </div>
              <el-form-item class="img-position">
                <div class="second-img">
                  <el-image
                    v-if="doctorRealNameInfo.bankCardFront"
                    class="img-certification"
                    :src="doctorRealNameInfo.bankCardFront"
                  />
                </div>
                <div class="second-img">
                  <el-image
                    v-if="doctorRealNameInfo.bankCardBack"
                    class="img-certification"
                    :src="doctorRealNameInfo.bankCardBack"
                  />
                </div>
              </el-form-item>
            </div>
          </div>
          <el-col>
            <el-form-item
              label="腾讯云人脸识别认证结果："
              label-width="200px"
            >
              <span>{{ txResult }}</span>
            </el-form-item>
          </el-col>
          <el-col v-if="saveRealName">
            <el-form-item
              label="审核结果："
              label-width="200px"
            >
              <el-select
                v-model="realResultNew"
                placeholder="请选择审核结果"
                :disabled="realNameSelect"
              >
                <el-option
                  v-for="item in isCredit"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="saveRealNameNew">
            <el-form-item label="审核结果：">
              <div>
                {{ auditResult }}
              </div>
            </el-form-item>
          </el-col>
          <el-col v-if="realResultNew === 4">
            <el-form-item
              label="失败原因："
              prop="reason"
              label-width="200px"
            >
              <el-input
                v-model="doctorRealNameInfo.rejectReason"
                type="textarea"
                placeholder="请输入失败原因"
                class="input"
                :disabled="realNameSelect"
              />
            </el-form-item>
          </el-col>
          <el-col v-if="saveRealName && saveRealNameBtn">
            <el-button
              type="primary"
              class="face-save qxs-btn-md"
              @click="onSaveFaceCert"
            >
              确 认
            </el-button>
          </el-col>
        </el-form>
      </div>
    </div>
    <template>
      <doctor-thrid-verify
        :record-id="doctorAuthInfo.recordId"
        :visible="showThirdVerifyDialog"
        @onSubmit="onSubmitVerify"
        @onCannel="onCloseVerify"
      />
    </template>
    <!--    <el-dialog-->
    <!--      title="提示"-->
    <!--      :visible.sync="showDialog"-->
    <!--      width="30%"-->
    <!--      center-->
    <!--    >-->
    <!--      <div>第三方辅助验证失败，该医生真实性存疑，建议前往第三方平台进行论证后给予通过，如若强制通过，责任自负。</div>-->
    <!--      <div slot="footer">-->
    <!--        <el-button @click="showDialog = false">取 消</el-button>-->
    <!--        <el-button type="primary" @click="onIgnoreWM">确认通过</el-button>-->
    <!--      </div>-->
    <!--    </el-dialog>-->
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import { scrollToElementId } from '@/utils/util'
import DoctorThridVerify from '@/components/DoctorThridVerify/Index'
import {getToken} from '@/utils/auth'
export default {
  name: 'DoctorDetailVue',
  filters: {
    orderFilter(name, index) {
      return `${ index }.${ name }`
    }
  },
  components: {
    DoctorThridVerify
  },
  data() {
    return {
      hasThirdVerify: false,
      showThirdVerifyDialog: false,
      isShowAuthInfo: false,
      initAuthInfo: {
        hospital: '',
        hospitalId: '',
        oneLevelDepartmentName: '',
        twoLevelDepartmentName: '',
        threeLevelDepartmentName: '',
        oneLevelDepartmentId: '',
        twoLevelDepartmentId: '',
        threeLevelDepartmentId: ''
      },
      authInfo: {
        hospital: '',
        hospitalId: '',
        oneLevelDepartmentName: '',
        twoLevelDepartmentName: '',
        threeLevelDepartmentName: '',
        oneLevelDepartmentId: '',
        twoLevelDepartmentId: '',
        threeLevelDepartmentId: ''
      },
      baseInfo: {
        hospital: '',
        hospitalId: '',
        oneLevelDepartmentName: '',
        twoLevelDepartmentName: '',
        threeLevelDepartmentName: '',
        oneLevelDepartmentId: '',
        twoLevelDepartmentId: '',
        threeLevelDepartmentId: ''
      },
      firstBaseDepartmentList: [],
      firstAuthDepartmentList: [],

      activeName: 'baseInfo',
      doctorBaseInfo: {
        name: '',
        mobile: '',
        creditRating: '',
        certifyStatus: '',
        realNameAuthStatus: '',
        speakerStatus: '',
        titleName: '',
        departmentFirstId: '',
        departmentSecondId: '',
        hospital: ''
      },
      basicInfoRules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        titleName: [{ required: true, message: '请选择职称', trigger: 'change' }],
        hospital: [{ required: true, message: '请输入医院信息', trigger: 'blur' }],
        departmentFirstId: [{ required: true, message: '请选择一级科室', trigger: 'change' }]
      },
      doctorAuthInfo: {
        auditResult: '',
        rejectReason: '',
        auditType: '',
        representInfo: '',
        submitResult: ''
      },
      doctorRealNameInfo: {
        commitUserName: '',
        idCard: '',
        isCredit: '',
        reason: '',
        submitResult: ''
      },
      doctorTitle: [
        { name: '主任医师' },
        { name: '副主任医师' },
        { name: '主治医师' },
        { name: '医师' },
        { name: '药师' },
        { name: '药店' },
        { name: '护理' },
        { name: '医学技师'},
        { name: '其他' }
      ],
      result: [
        {
          id: 0,
          name: '成功'
        },
        {
          id: -1,
          name: '失败'
        }
      ],
      authResultNew: '',
      realResultNew: '',
      isCredit: [
        {
          id: 1,
          name: '成功'
        },
        {
          id: 4,
          name: '失败'
        }
      ],
      rejectReasonList: [
        {
          id: 1,
          name: '请上传卫健委官网截图或医师执业证书原件照片（包含姓名、编码和执业地点）或执业证书电子版。'
        },
        {
          id: 2,
          name: '请上传执业证书+胸牌（或工作证明）进行认证。'
        },
        {
          id: 3,
          name: '部队医院的医师注册，请提交医师执业证书和非军人证明。'
        },
        {
          id: 4,
          name: '请提交清晰完整的胸牌照片。'
        },
        {
          id: 5,
          name: '请提交清晰完整的医院公告栏医师介绍照片。'
        },
        {
          id: 6,
          name: '请提交包含医院官网链接的医师介绍页面截图。'
        },
        {
          id: 7,
          name: '其他'
        }
      ],
      hospitals: [],
      saveRealName: false,
      saveRealNameNew: false,
      saveRealNameBtn: false,
      activeIndex: '2',
      txResult: '',
      loading: true,
      showAuth: false,
      showReal: false,
      editor: false,
      authSelect: false,
      authSave: true,
      realNameSelect: false,
      addHospitalId: false,
      hospitalLoading: false,
      timeout: null,
      baseTimeout: null,
      departmentTime: null,
      rejectReason: '',
      thirdCertifyStatus: '',
      imageUrl: '',
      showDialog: false,
      ignoreWM: false,
      headers: {}
    }
  },
  computed: {
    ...mapGetters('DoctorDetail', ['hospitalList']),
    ...mapGetters('FaceReview', ['departmentList']),
    srcList() {
      return [this.doctorAuthInfo.certifyImg]
    },
    certifyImgExtraList() {
      return [this.doctorAuthInfo.certifyImgExtra]
    },
    checkImageUrlList() {
      return [this.doctorAuthInfo.checkImageUrl]
    },
    idCardFront() {
      return [this.doctorRealNameInfo.idCardFront]
    },
    idCardBack() {
      return [this.doctorRealNameInfo.idCardBack]
    },
    twoLevelDepartmentName() {
      if (!this.doctorBaseInfo.departmentFirstId) return ''
      const id = this.doctorBaseInfo.departmentFirstId
      const childId = this.doctorBaseInfo.departmentSecondId
      let name = ''
      this.departmentList.map(item => {
        if (item.id == id) {
          item.childList.map(k => {
            if (k.id == childId) {
              name = k.name
            }
          })
        }
      })
      return name
    },
    departmentNameFir() {
      if (!this.doctorAuthInfo.departmentFirst) return ''
      const id = this.doctorAuthInfo.departmentFirst
      let name = ''
      this.departmentList.map(item => {
        if (item.id == id) {
          name = item.name
        }
      })
      return name
    },
    departmentNameSec() {
      if (!this.doctorAuthInfo.departmentFirst) return ''
      const id = this.doctorAuthInfo.departmentFirst
      const childId = this.doctorAuthInfo.departmentSec
      let name = ''
      this.departmentList.map(item => {
        if (item.id == id) {
          item.childList.map(k => {
            if (k.id == childId) {
              name = k.name
            }
          })
        }
      })
      return name
    },
    auditType() {
      switch (this.doctorAuthInfo.auditType) {
        case 0:
          return '医生'
        case 1:
          return '代表'
        default:
          return ''
      }
    },
    auditResult() {
      switch (this.doctorRealNameInfo.auditResult) {
        case 1:
          return '成功'
        case 4:
          return '失败'
        case 6:
          return '讲者认证成功'
        case 5:
          return '姓名不匹配'
        case -3:
          return '等待人工审核结果'
        case -2:
          return '未开启人脸识别'
        default:
          return ''
      }
    },
    authResult() {
      switch (this.doctorAuthInfo.auditResult) {
        case -2:
          return '等待医生确认'
        case -1:
          return '等待审核结果'
        case 0:
          return '审核成功'
        case 1:
          return '审核失败'
        case 2:
          return '医生拒绝'
        case 3:
          return '自动关闭'
        case 4:
          return '已上传药店认证信息'
        default:
          return ''
      }
    },
    secondBaseDepartmentList() {
      const list = [...this.firstBaseDepartmentList].filter(v => this.baseInfo.oneLevelDepartmentId === v.id)
      return list.length && list[0].childList || []
    },
    thirdBaseDepartmentList() {
      const list = [...this.secondBaseDepartmentList].filter(v => this.baseInfo.twoLevelDepartmentId === v.id)
      return list.length && list[0].childList || []
    },
    secondAuthDepartmentList() {
      const list = [...this.firstAuthDepartmentList].filter(v => this.authInfo.oneLevelDepartmentId === v.id)
      return list.length && list[0].childList || []
    },
    thirdAuthDepartmentList() {
      const list = [...this.secondAuthDepartmentList].filter(v => this.authInfo.twoLevelDepartmentId === v.id)
      return list.length && list[0].childList || []
    }
  },
  watch: {
    'baseInfo.hospitalId': function(newVal) {
      if (!newVal) return
      // this.onBaseDepartmentList(newVal)
    },
    'authInfo.hospitalId': function(newVal) {
      if (!newVal) return
      // this.onAuthDepartmentList(newVal)
    }
  },
  mounted() {
    this.id = this.$route.query.id || ''
    this.headers.token = getToken()
    this.init()
  },
  methods: {
    ...mapActions('DoctorDetail', ['getThridVerifyResult', 'getDoctorBaseInfo', 'getDoctorAuthInfo', 'getDoctorRealNameInfo', 'setDoctorBaseInfo', 'setDoctorAuthInfo', 'setDoctorRealNameInfo', 'getHospitalList']),
    ...mapActions('HospitalStore', ['departmentQuery']),
    onJumpWJW() {
      window.open('http://zgcx.nhc.gov.cn:9090/Doctor')
    },
    handleAvatarSuccess(res, file) {
      this.doctorAuthInfo.checkImageUrl = res.data.imageKey
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG/PNG 格式!')
      }
      return isJPG
    },
    onGetDoctorBaseInfo() {
      this.getDoctorBaseInfo({ id: this.id }).then(res => {
        if (res) {
          this.doctorBaseInfo = res
          this.baseInfo = {
            hospital: res.hospital,
            hospitalId: res.hospitalId,
            oneLevelDepartmentName: res.oneLevelDepartmentName,
            twoLevelDepartmentName: res.twoLevelDepartmentName,
            threeLevelDepartmentName: res.threeLevelDepartmentName,
            oneLevelDepartmentId: res.oneLevelDepartmentId,
            twoLevelDepartmentId: res.twoLevelDepartmentId,
            threeLevelDepartmentId: res.threeLevelDepartmentId
          }
          this.onBaseDepartmentList(res.hospitalId)
          if (this.doctorBaseInfo.certifyStatus > 0) {
            this.showAuth = true
          }
          if (this.doctorBaseInfo.realNameAuthStatus > 0 || this.doctorBaseInfo.speakerStatus >= 0) {
            this.showReal = true
          }
          this.loading = false
          this.$nextTick(() => {
            this.scrollTo('2')
          })
        }
      }, () => {
        this.loading = false
      }
      )
    },
    onGetDoctorAuthInfo() {
      this.getDoctorAuthInfo({ id: this.id }).then(res => {
        if (res) {
          this.doctorAuthInfo = res
          this.imageUrl = res.checkImageUrl || ''
          // if (!res.rejectReason) {
          //   if (this.doctorAuthInfo.auditResult === -1 || this.doctorAuthInfo.auditResult === 0) {
          //     this.doctorAuthInfo.rejectReason = ''
          //   }
          // }
          this.initAuthInfo = {
            hospital: res.hospital,
            hospitalId: res.hospitalId,
            oneLevelDepartmentName: res.oneLevelDepartmentName,
            twoLevelDepartmentName: res.twoLevelDepartmentName,
            threeLevelDepartmentName: res.threeLevelDepartmentName,
            oneLevelDepartmentId: res.oneLevelDepartmentId,
            twoLevelDepartmentId: res.twoLevelDepartmentId,
            threeLevelDepartmentId: res.threeLevelDepartmentId
          }
          this.authInfo = {
            hospital: res.hospital,
            hospitalId: res.hospitalId,
            oneLevelDepartmentName: res.oneLevelDepartmentName,
            twoLevelDepartmentName: res.twoLevelDepartmentName,
            threeLevelDepartmentName: res.threeLevelDepartmentName,
            oneLevelDepartmentId: res.oneLevelDepartmentId,
            twoLevelDepartmentId: res.twoLevelDepartmentId,
            threeLevelDepartmentId: res.threeLevelDepartmentId
          }
          this.onAuthDepartmentList(res.hospitalId)
          if (res.hospital && !res.hospitalId) {
            this.addHospitalId = true
          }
          this.loading = true
          this.getThridVerifyResult(this.doctorAuthInfo.recordId).then(res => {
            this.loading = false
            this.thirdCertifyStatus = res.certifyStatus
          }, rea => {
            this.loading = false
            this.$message.error(rea.message)
          })
        }
      }, () => {
      })
    },
    init() {
      this.onGetDoctorBaseInfo()
      this.onGetDoctorAuthInfo()
      this.getDoctorRealNameInfo({ id: this.id }).then(res => {
        if (res) {
          this.doctorRealNameInfo = res
          if (this.doctorRealNameInfo.auditResult === -3 || this.doctorRealNameInfo.auditResult === 5) {
            this.saveRealName = true
            this.saveRealNameBtn = true
          }
          if (this.doctorRealNameInfo.auditResult === 1 || this.doctorRealNameInfo.auditResult === 4 || this.doctorRealNameInfo.auditResult === 2 || this.doctorRealNameInfo.auditResult === 6 || this.doctorRealNameInfo.auditResult === 3) {
            this.saveRealNameNew = true
          }
          this.txResult = this.checkResult()
        }
      }, () => {
      })
    },
    checkResult() {
      if (this.doctorRealNameInfo.auditResult === -1) {
        return '等待腾讯云审核结果'
      } else if (this.doctorRealNameInfo.auditResult === 0) {
        return '腾讯云人脸核身审核成功'
      } else if (this.doctorRealNameInfo.auditResult === 5) {
        return '姓名不匹配'
      } else if (this.doctorRealNameInfo.auditResult === 3) {
        return '腾讯云人脸核身失败3次及以上'
      } else if (this.doctorRealNameInfo.auditResult === 2) {
        return '腾讯云人脸核身失败'
      }
    },
    certStatusFilter(status) {
      switch (status) {
        case 1:
          return '工作证明认证完成'
        case 3:
          return '工作证明认证未通过'
        case 2:
          return '工作证明认证审核中'
        default:
          return '未认证'
      }
    },
    realStatusFilter(status) {
      switch (status) {
        case 0:
          return '未实名认证'
        case 1:
          return '实名认证完成'
        case 2:
          return '实名认证未通过'
        case 3:
          return '实名认证审核中'
        default:
          return ''
      }
    },
    speakStatusFilter(status) {
      switch (status) {
        case 1:
          return '讲者认证完成'
        case 0:
          return '讲者认证审核中'
        case 2:
          return '讲者认证未通过'
        case -1:
          return '未讲者认证'
        default:
          return ''
      }
    },
    // 切换
    changeResult(parentId) {
      this.doctorRealNameInfo = { ...this.doctorRealNameInfo, auditResult: parentId }
    },
    changeAuthResult(parentId) {
      this.doctorAuthInfo = { ...this.doctorAuthInfo, auditResult: parentId }
    },
    scrollTo(id) {
      scrollToElementId(id)
    },
    // 关闭预览
    handleClickItem() {
      this.$nextTick(() => {
        const domImageMask = document.querySelector('.el-image-viewer__mask')  // 获取遮罩层dom
        if (!domImageMask) {
          return
        }
        domImageMask.addEventListener('click', () => {
          // 点击遮罩层时调用关闭按钮的 click 事件
          document.querySelector('.el-image-viewer__close').click()
        })
      })
    },
    // 科室
    initDepartmentList(id) {
      clearTimeout(this.departmentTime)
      this.departmentTime = setTimeout(() => {
        this.departmentQuery({hospitalId: id}).then(() => {
        }, rea => {
          this.$message.error(rea.message)
        })

      }, 500)
    },
    onBaseDepartmentList(id) {
      if (!id) {
        this.firstBaseDepartmentList = []
        return
      }
      this.departmentQuery({hospitalId: id}).then(res => {
        this.firstBaseDepartmentList = res
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    onAuthDepartmentList(id) {
      if (!id) {
        this.firstAuthDepartmentList = []
        return
      }
      this.departmentQuery({hospitalId: id}).then(res => {
        this.firstAuthDepartmentList = res
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    getDepartmentListChildren(parentId) {
      this.baseInfo = Object.assign({}, this.baseInfo, {
        oneLevelDepartmentName: this.getHospitalName(parentId, this.firstBaseDepartmentList),
        twoLevelDepartmentName: '',
        threeLevelDepartmentName: '',
        oneLevelDepartmentId: parentId,
        twoLevelDepartmentId: '',
        threeLevelDepartmentId: ''
      })
      this.doctorBaseInfo = Object.assign({}, this.doctorBaseInfo, this.baseInfo)
    },
    getDepartmentListTwoChildren(parentId) {
      this.baseInfo = Object.assign({}, this.baseInfo, {
        twoLevelDepartmentName: this.getHospitalName(parentId, this.secondBaseDepartmentList),
        threeLevelDepartmentName: '',
        twoLevelDepartmentId: parentId,
        threeLevelDepartmentId: ''
      })
      this.doctorBaseInfo = Object.assign({}, this.doctorBaseInfo, this.baseInfo)
    },
    getDepartmentListThreeChildren(parentId) {
      this.baseInfo = Object.assign({}, this.baseInfo, {
        threeLevelDepartmentName: this.getHospitalName(parentId, this.thirdBaseDepartmentList),
        threeLevelDepartmentId: parentId
      })
      this.doctorBaseInfo = Object.assign({}, this.doctorBaseInfo, this.baseInfo)
    },
    getAuthDepartmentListChildren(parentId) {
      this.authInfo = Object.assign({}, this.authInfo, {
        oneLevelDepartmentName: this.getHospitalName(parentId, this.firstAuthDepartmentList),
        twoLevelDepartmentName: '',
        threeLevelDepartmentName: '',
        oneLevelDepartmentId: parentId,
        twoLevelDepartmentId: '',
        threeLevelDepartmentId: ''
      })
      this.doctorAuthInfo = Object.assign({}, this.doctorAuthInfo, this.authInfo)
    },
    getAuthDepartmentListTwoChildren(parentId) {
      this.authInfo = Object.assign({}, this.authInfo, {
        twoLevelDepartmentName: this.getHospitalName(parentId, this.secondAuthDepartmentList),
        threeLevelDepartmentName: '',
        twoLevelDepartmentId: parentId,
        threeLevelDepartmentId: ''
      })
      this.doctorAuthInfo = Object.assign({}, this.doctorAuthInfo, this.authInfo)
    },
    getAuthDepartmentListThreeChildren(parentId) {
      this.authInfo = Object.assign({}, this.authInfo, {
        threeLevelDepartmentName: this.getHospitalName(parentId, this.thirdAuthDepartmentList),
        threeLevelDepartmentId: parentId
      })
      this.doctorAuthInfo = Object.assign({}, this.doctorAuthInfo, this.authInfo)
    },
    // 医院
    queryBaseSearchAsync(queryString, cb) {
      clearTimeout(this.baseTimeout)
      let results = []
      if (queryString == '') {
        cb(results)
      } else {
        this.getHospitalList({ keywords: queryString, hospitalType: this.doctorBaseInfo.identity }).then(
          res => {
            if (res) {
              const result = res
              // 循环放到一个远程搜索需要的数组
              for (let i = 0; i < result.length; i++) {
                const element = result[i]
                results.push({
                  value: element.name,
                  id: element.id
                })
              }
              cb(results)
            } else {
              results = []
              cb(results)
            }
          }
        )
      }
    },
    handleBaseSelect(item) {
      console.log(item, '------------------ 选择医院 ---------------------------')
      if (item.id === this.baseInfo.hospitalId) {
        this.onBaseDepartmentList(item.id)
      } else {
        this.baseInfo = Object.assign({}, this.baseInfo, {
          hospital: item.value,
          hospitalId: item.id,
          oneLevelDepartmentName: '',
          twoLevelDepartmentName: '',
          threeLevelDepartmentName: '',
          oneLevelDepartmentId: '',
          twoLevelDepartmentId: '',
          threeLevelDepartmentId: ''
        })
        this.onBaseDepartmentList(item.id)
        this.doctorBaseInfo = Object.assign({}, this.doctorBaseInfo, this.baseInfo)
      }
    },
    querySearchAsync(queryString, cb) {
      clearTimeout(this.timeout)
      let results = []
      if (queryString == '') {
        cb(results)
      } else {
        this.getHospitalList({ keywords: queryString, hospitalType: this.doctorAuthInfo.identity }).then(
          res => {
            if (res) {
              const result = res
              // 循环放到一个远程搜索需要的数组
              for (let i = 0; i < result.length; i++) {
                const element = result[i]
                results.push({
                  value: element.name,
                  id: element.id
                })
              }
              cb(results)
            } else {
              results = []
              cb(results)
            }
          }
        )
      }
    },
    handleAuthSelect(item) {
      if (item.id === this.authInfo.hospitalId) {
        this.onAuthDepartmentList(item.id)
      } else {
        this.authInfo = Object.assign({}, this.authInfo, {
          hospital: item.value,
          hospitalId: item.id,
          oneLevelDepartmentName: '',
          twoLevelDepartmentName: '',
          threeLevelDepartmentName: '',
          oneLevelDepartmentId: '',
          twoLevelDepartmentId: '',
          threeLevelDepartmentId: ''
        })
        this.doctorAuthInfo = Object.assign({}, this.doctorAuthInfo, this.authInfo)
        this.onAuthDepartmentList(item.id)
      }
    },
    onGoDepartmentList(type) {
      let obj = {}
      if (type === 'base') {
        obj = this.$router.resolve({path: `/department/list/${this.doctorBaseInfo.hospitalId}?hospitalName=${this.baseInfo.hospital}`})

      } else {
        obj = this.$router.resolve({path: `/department/list/${this.doctorAuthInfo.hospitalId}?hospitalName=${this.authInfo.hospital}`})

      }
      window.open(obj.href, '_blank')
    },
    addHospital() {
      let host = 'http://192.168.3.204:9999'
      if (window.location.hostname === 'qxs-manager.yaomaitong.cn') {
        host = 'https://manager.yaomaitong.cn'
      }
      if (window.location.hostname === 'qxs-manager.yaomaitong-pre.cn') {
        host = 'http://manager.yaomaitong-pre.cn'
      }
      window.open(`${host}/ksh/hospital/edit-hospital`)
    },
    hospitalId(id) {
      let name
      this.hospitalList.map(item => {
        if (item.id == id) {
          name = item.name
        }
      })
      return name
    },
    hospital(name) {
      let id
      this.hospitalList.map(item => {
        if (item.name == name) {
          id = item.id
        }
      })
      return id
    },
    getHospitalName(id, list) {
      let name = ''
      for (const item of list) {
        if (item.id === id) {
          name = item.name
          break
        }
      }
      return name
    },
    // 提交
    submitBasicInfoForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.onSaveBasicInfo()
        } else {
          return false
        }
      })
    },
    editorBaseInfo() {
      this.editor = true
    },
    resetForm() {
      this.editor = false
      this.onGetDoctorBaseInfo()
    },
    onSaveBasicInfo() {
      if (!this.doctorBaseInfo.oneLevelDepartmentId) {
        this.$message.error('请选择科室')
        return false
      }
      if (!this.doctorBaseInfo.hospital) {
        this.$message.error('请输入医院信息')
        return false
      }
      this.setDoctorBaseInfo(this.doctorBaseInfo).then(() => {
        this.editor = false
        this.$message.success('保存成功')
        this.onGetDoctorBaseInfo()
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    onCloseVerify() {
      this.showThirdVerifyDialog = false
    },
    onSubmitVerify() {
      this.hasThirdVerify = true
      this.showThirdVerifyDialog = false
      this.onSaveDoctorCert()
    },
    onValidDoctorCert() {
      if (this.authResultNew === -1 && !this.doctorAuthInfo.rejectReason || this.authResultNew === -1 && this.doctorAuthInfo.rejectReason === '其他' && !this.rejectReason) {
        this.$message.error('请输入失败原因')
        return false
      }
      if (this.doctorAuthInfo.rejectReason === '其他') {
        this.doctorAuthInfo = { ...this.doctorAuthInfo, rejectReason: this.rejectReason }
      }
      if (this.authResultNew === 0 && (!this.doctorAuthInfo.name || !this.doctorAuthInfo.hospitalId || !this.doctorAuthInfo.oneLevelDepartmentId || !this.doctorAuthInfo.titleName)) {
        this.$message.error('医师认证信息不能为空')
        return false
      }
      if (this.authResultNew !== 0 && this.authResultNew !== -1) {
        this.$message.error('请选择审核结果')
        return false
      }
      // if (this.doctorAuthInfo.certifyType === 2 && this.thirdCertifyStatus !== 1 && this.authResultNew === 0 && !this.imageUrl) {
      //   this.$message.error('请上传医师执业网查询照片')
      //   return false
      // }
      // 医师认证，通过审核时：
      // 1. 如果实名认证通过，匹配基础信息姓名和医师认证基础信息姓名是否一致
      // 2. 如果实名认证审核中 并且 腾讯云人脸识别认证成功,匹配基础信息姓名和医师认证基础信息姓名是否一致
      if (this.authResultNew == 0) {
        if (this.doctorBaseInfo.realNameAuthStatus == 1 || this.doctorBaseInfo.realNameAuthStatus == 3 && this.doctorRealNameInfo.auditResult == 0) {
          if (this.doctorRealNameInfo.commitUserName && this.doctorAuthInfo.name !== this.doctorRealNameInfo.commitUserName) {
            this.$message.error('医生姓名不一致')
            return false
          }
        }
      }
      // if (!this.hasThirdVerify && this.authResultNew === 0) {
      //   // 选择审核成功步骤后，需要无码科技介入
      //   this.showThirdVerifyDialog = true
      //   return false
      // }
      return true
    },
    onSaveDoctorCert() {
      if (!this.onValidDoctorCert()) return
      const reqObj = Object.assign({}, this.doctorAuthInfo, {submitResult: this.authResultNew === 0 ? 1 : this.authResultNew === -1 ? 0 : '' })
      this.setDoctorAuthInfo(reqObj).then(() => {
        this.$message.success('保存成功')
        this.authSelect = true
        this.authSave = false
        this.onGetDoctorBaseInfo()
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    // onIgnoreWM() {
    //   this.showDialog = false
    //   this.ignoreWM = true
    //   this.onSaveDoctorCert()
    // },
    onSaveFaceCert() {
      if (this.realResultNew == 4 && !this.doctorRealNameInfo.rejectReason) {
        this.$message.error('请输入失败原因')
        return false
      }
      // 如果选了通过，校验医生真是姓名和基础信息姓名是否一致
      if (this.realResultNew == 1) {
        if (this.doctorRealNameInfo.commitUserName !== this.doctorBaseInfo.name) {
          this.$message.error('医生姓名不一致')
          return false
        }
      }
      const req = {
        recordId: this.doctorRealNameInfo.recordId,
        submitResult: this.realResultNew == 4 ? 0 : 1,
        rejectReason: this.doctorRealNameInfo.rejectReason
      }
      this.setDoctorRealNameInfo(req).then(() => {
        this.$message.success('保存成功')
        this.realNameSelect = true
        this.saveRealNameBtn = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 30px;
}
.title {
  font-size: 20px;
  padding: 20px 0 30px 0;
}
.doctor-baseInfo {
  position: relative;
  overflow: hidden;
}
.base-info {
  overflow: hidden;
}
.base-editor {
  margin-left: 10px;
}
.save {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.doctor-save {
  margin: 30px 0 100px 80px;
}
.face-save {
  margin: 30px 0 0 240px;
}
.doctor-certification {
  overflow: hidden;
}
.face-certification {
  padding-top: 30px;
}
.img-certification {
  width: 300px;
  height: 200px;
  background: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
  margin-bottom: 40px;
}
.img-realName {
  width: 300px;
  height: 350px;
  background: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
  margin-bottom: 40px;
}
.img-type {
  padding-bottom: 20px;
  padding-left: 10px;
}
.face-tips {
  float: right;
  padding-top: 150px;
}
.doctor-tips {
  color: red;
  margin-left: 100px;
}
.input {
  width: 193px;
}
.certification-result {
  margin-top: 30px;
}
.img {
  padding-top: 30px;
  padding-left: 10px;
}
.img-position {
  margin-left: -100px;
}
.second-img {
  display: inline-block;
  margin-right: 20px;
}
.hospital-tips {
  color: red;
  padding-left: 10px;
}
.el-tag {
  margin-left: 100px;
  margin-bottom: 10px;
}
.add-hospital {
  margin-left: 20px;
}
.hospital-select-box {
  display: flex;
  justify-content: flex-start;
}
.new-auth-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  span {
    display: block;
    width: 40px;
  }
}
.avatar-uploader .el-upload {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  border-radius: 6px;
  margin-top: 10px;
  border: 1px solid #d9d9d9;
  color: #8c939d;
  width: 200px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}
.avatar {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 200px;
  height: 150px;
  display: block;
}
</style>
