<template>
  <div class="home">
    <div class="tab-panel">
      <el-menu
        class="el-menu-demo"
        mode="horizontal"
      >
        <el-menu-item
          index="1"
          @click="scrollTo('1')"
        >
          基础信息
        </el-menu-item>
        <el-menu-item
          index="2"
          @click="scrollTo('2')"
        >
          基础说明
        </el-menu-item>
        <el-menu-item
          index="3"
          @click="scrollTo('3')"
        >
          图文详情
        </el-menu-item>
      </el-menu>
    </div>
    <div class="prod-panel">
      <div class="base-detail">
        <el-form
          ref="form"
          :model="policyDetail"
          label-width="80px"
        >
          <div
            id="1"
            class="label-tab"
          >
            | 基础信息
          </div>
          <PanelWrapper>
            <el-form-item label="政策名称">
              <el-input
                v-model="policyDetail.name"
                class="elm-input"
                placeholder="请输入内容"
              />
            </el-form-item>
            <el-form-item label="适用的服务商">
              <el-select
                v-model="policyDetail.supplier"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in supplierList"
                  :key="item.index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </PanelWrapper>

          <div
            id="2"
            class="label-tab"
          >
            | 基础说明
          </div>
          <PanelWrapper>
            <el-table
              :data="policyDetail.descs"
              border
            >
              <el-table-column
                prop="name"
                label="名称"
                width="300px"
              />
              <el-table-column
                prop="value"
                label="内容"
                width="700px"
              />
              <el-table-column
                prop="address"
                label="操作"
                width="200px"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    @click.native.prevent="removeContent(scope.$index, policyDetail.descs)"
                  >
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-input
              v-model="inputName"
              class="elm-input input-name"
              placeholder="请输入名称"
            />
            <el-input
              v-model="inputValue"
              class="elm-input input-value"
              placeholder="请输入内容"
            />
            <el-button
              class="add-btn"
              @click="addContent"
            >
              + 添加
            </el-button>
          </PanelWrapper>

          <div
            id="3"
            class="label-tab"
          >
            | 图文详情
          </div>
          <PanelWrapper>
            <editor
              :content="policyDetail.copyWriting"
              @update="upDataContent($event)"
            />
          </PanelWrapper>

          <FooterWrapper>
            <el-button
              v-if="id"
              type="primary"
              @click="onSubmit"
            >
              保存
            </el-button>
            <el-button
              v-else
              type="primary"
              @click="onSubmit"
            >
              创建
            </el-button>
            <el-button @click="() => $router.back()">取消</el-button>
          </FooterWrapper>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Editor from '@/components/Editor.vue'
import PanelWrapper from '@/components/common/panelWrapper.vue'
import { scrollToElementId } from '@/utils/util'
import FooterWrapper from '@/components/common/footerWrapper.vue'
import getPolicyDetail from '@/apis/getPolicyDetail'
import updatePolicy from '@/apis/updatePolicy'

export default {
  components: {
    Editor,
    FooterWrapper,
    PanelWrapper
  },
  data() {
    return {
      name: '服务政策',
      id: '',
      inputName: '',
      inputValue: '',
      policyDetail: {
        copyWriting: '',
        descs: [],
        id: '',
        isDefault: 0,
        name: '',
        supplier: null
      }
    }
  },
  computed: {
    ...mapState(['supplierList'])
  },
  mounted() {
    console.log('this.name ', this.name)
    const id = this.$route.query.id
    if (id) {
      this.$store.dispatch('changeTitle', this.name)
      this.id = id
      getPolicyDetail({id: id}).then(response => {
        if (response) {
          this.policyDetail = response
        }
      }).catch(error => {
        // this.$message.error(error.message)
        console.log(error)

      })
    } else {
      this.name = '新建服务政策'
      this.$store.dispatch('changeTitle', this.name)
    }
  },
  methods: {
    addContent() {
      this.policyDetail.descs.push({
        name: this.inputName,
        value: this.inputValue
      })
      this.inputName = ''
      this.inputValue = ''
    },
    removeContent(index, data) {
      data.splice(index, 1)
    },
    upDataContent(data) {
      this.policyDetail.copyWriting = data
    },
    scrollTo(id) {
      scrollToElementId(id)
    },
    onSubmit() {
      updatePolicy({
        copyWriting: this.policyDetail.copyWriting,
        descs: this.policyDetail.descs,
        id: this.id,
        isDefault: this.policyDetail.isDefault,
        name: this.policyDetail.name,
        supplier: this.policyDetail.supplier
      }).then(response => {
        if (response) {
          const msg = this.id ? '保存成功' : '新建成功'
          this.$message({
            message: msg,
            type: 'success'
          })
          this.$router.push({ name: 'servicePolicy'})
        }
      }).catch(error => {
        // this.$message.error(error.message)
        console.log(error)

      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .home {
    padding: 20px;
    .base-detail {
      padding: 20px;
    }
  }
  .input-name {
    width: 300px !important;
  }
  .input-value {
    width: 700px !important;
  }
  .add-btn {
    width: 200px !important;
  }
  .label-tab {
    margin-top: 26px;
    margin-bottom: 16px;
    position: relative;
    left: -10px;
  }
  .elm-input {
    width: 300px;
  }
</style>
