<template>
  <div>
    <div>
      <h3>1.填写活动信息</h3>
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        style="width: 60%;"
      >
        <el-form-item label="活动名称" prop="title" :label-width="formLabelWidth">
          <el-input v-model="ruleForm.title" maxlength="20" show-word-limit />
        </el-form-item>
        <el-form-item label="活动时间" prop="timeRange" :label-width="formLabelWidth">
          <el-date-picker
            v-model="ruleForm.timeRange"
            type="datetimerange"
            value-format="yyyy.MM.dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <el-form-item label="活动规则" prop="desc" :label-width="formLabelWidth">
          <editor :content="ruleForm.desc" :text-only="true" @update="upDataContent($event)" />
          <div v-if="texLeng >= 400" class="el-form-item__error">规则最多400字</div>
        </el-form-item>
        <el-form-item label="活动用户" prop="radio" :label-width="formLabelWidth">
          <el-radio v-model="ruleForm.radio" label="1">全部用户</el-radio>
        </el-form-item>
      </el-form>
    </div>
    <div class="mgn-b100">
      <h3>2.选择活动商品</h3>
      <div class="mgn-b20">
        <span class="mgn-r10">选择商品</span>
        <el-autocomplete
          v-model="keywords"
          class="mgn-r100"
          placeholder="搜索商品名称/商品ID"
          prefix-icon="el-icon-search"
          value-key="name"
          clearable
          :fetch-suggestions="querySearchAsync"
          @select="handleBaseSelect"
        >
          <template slot-scope="{ item }">
            <el-tooltip
              effect="dark"
              :content="item.name | formatTooltip"
              placement="right-end"
            >
              <div class="tooltip">{{ item.name }}</div>
            </el-tooltip>
          </template>
        </el-autocomplete>
        <span class="mgn-r10">批量操作</span>
        <el-input
          v-model="stock"
          type="number"
          :min="1"
          placeholder="批量设置秒杀库存"
          style="width: 200px; margin-right: 10px;"
          @change="batchChange('stock')"
        />
        <el-input
          v-model="secondKillPrice"
          type="number"
          :min="0"
          placeholder="批量设置秒杀价"
          style="width: 200px; margin-right: 10px;"
          @change="batchChange('secondKillPrice')"
        />
        <el-input
          v-model="purchaseLimit"
          type="number"
          :min="1"
          placeholder="批量设置限购"
          style="width: 200px; margin-right: 10px;"
          @change="batchChange('purchaseLimit')"
        />
      </div>
      <div>
        <el-table
          :data="commodityVOS"
          stripe
          border
          style="width: 100%;"
        >
          <el-table-column prop="stockUnitId" label="商品ID" />
          <el-table-column prop="coverUrl" label="商品图片">
            <template slot-scope="scope">
              <img :src="scope.row.coverUrl" alt="" style="width: 50px; height: 50px;">
            </template>
          </el-table-column>
          <el-table-column prop="name" label="商品名称" />
          <el-table-column prop="supplier" label="供应商">
            <template slot-scope="scope">
              <span>{{ supplierName(scope.row.supplier) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="sellPrice" label="售价" />
          <el-table-column prop="originPrice" label="原价" />
          <el-table-column prop="channelPrice" label="采购价" />
          <el-table-column prop="inventory" label="库存" />
          <el-table-column prop="stock" label="秒杀库存">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.stock"
                placeholder="请输入秒杀库存"
                type="number"
                :min="1"
                style="width: 100%;"
              />
            </template>
          </el-table-column>
          <el-table-column prop="secondKillPrice" label="秒杀价格">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.secondKillPrice"
                type="number"
                :min="0"
                :max="scope.row.sellPrice"
                placeholder="请输入秒杀价格"
                style="width: 100%;"
                @change="onSecondKillPriceChange(scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column prop="purchaseLimit" label="每人限购">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.purchaseLimit"
                placeholder="请输入每人限购"
                type="number"
                :min="1"
                style="width: 100%;"
              />
            </template>
          </el-table-column>
          <el-table-column prop="productIndex" label="排序值">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.productIndex"
                placeholder="请输入排序值"
                type="number"
                :min="0"
                style="width: 100%;"
              />
            </template>
          </el-table-column>
          <el-table-column prop="state" label="状态">
            <template slot-scope="scope">
              <span>{{ scope.row.state === 0 ? '下架' : '上架' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" style="color: red;" @click="onDel(scope.row)"> 删除 </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <FooterWrapper class="mgn-t30">
      <el-button @click="onBack">返回</el-button>
      <el-button v-if="type === '1'" type="primary" @click="onEnd">结束活动</el-button>
      <el-button
        v-if="type !== '0' && type !== '1'"
        type="primary"
        :loading="loading"
        @click="onSubmit('save')"
      >
        保存
      </el-button>
      <el-button
        v-if="type !== '0' && type !== '1'"
        type="primary"
        :loading="publishLoading"
        @click="onSubmit('publish')"
      >
        保存并发布
      </el-button>
    </FooterWrapper>
  </div>
</template>

<script>
import Editor from '@/components/Editor.vue'
import FooterWrapper from '@/components/common/footerWrapper.vue'
import { fenToYuanInt, YuanToFen } from '@/utils/util'
export default {
  name: 'MarketingManagementDetail',
  components: {
    Editor,
    FooterWrapper
  },
  filters: {
    formatTooltip(val) {
      // 如果字符长度超过100，截取前100个字符并加上省略号，否则直接返回
      if (val.length > 100) {
        return `${val.substring(0, 100)  }...`
      }
      return val
    }
  },
  data() {
    return {
      rules: {
        title: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        timeRange: [{ required: true, message: '请选择活动时间', trigger: 'change' }],
        desc: [{ required: true, message: '请输入活动规则', trigger: 'change' }],
        radio: [{ required: true }]
      },
      formLabelWidth: '120px',
      showAddProd: false,
      campaignId: '',
      type: '',
      commodityVOS: [],
      keywords: '',
      ruleForm: {
        title: '',
        desc: '',
        radio: '1',
        timeRange: []
      },
      stock: '',
      secondKillPrice: '',
      purchaseLimit: '',
      editorHtml: null,
      texLeng: 0,
      loading: false,
      publishLoading: false
    }
  },
  mounted() {
    this.type = this.$route.query.type
    this.campaignId = this.$route.query.campaignId || ''
    if (this.campaignId) {
      this.getDetail()
    }
  },
  methods: {
    getDetail() {
      this.$axios.get(this.$API.getSeckillDetail, { params: { campaignId: this.campaignId } }).then(
        res => {
          this.commodityVOS = res.commodityVOS
          // 对commodityVOS里的价格进行分转元处理
          this.commodityVOS.forEach(item => {
            item.sellPrice = fenToYuanInt(item.sellPrice)
            item.originPrice = fenToYuanInt(item.originPrice)
            item.channelPrice = fenToYuanInt(item.channelPrice)
            item.secondKillPrice = fenToYuanInt(item.secondKillPrice)
          })
          this.ruleForm = {
            title: res.title,
            desc: res.desc,
            radio: '1',
            timeRange: [res.startAt, res.endAt]
          }
        },
        err => {
          this.$message.error(err.message)
        }
      )
    },
    supplierName(val) {
      switch (val) {
        case 0:
          return '京东'
        case 1:
          return '一只猫'
        case 2:
          return '官方'
        case 3:
          return '旧订单'
        case 5:
          return 'WE_CHAT'
        case 8:
          return '鲜通'
        case 9:
          return '美团'
        case 10:
          return '十鹿'
        default:
          return '其他'
      }
    },
    batchChange(type) {
      // 批量设置commodityVOS里的秒杀库存、秒杀价、每人限购
      if (this.commodityVOS.length === 0) {
        this.$message.error('请先添加商品')
        return
      }
      let showErr = false
      this.commodityVOS.forEach(item => {
        // 秒杀价不能高于原价
        if (type === 'secondKillPrice') {
          if (this.secondKillPrice > item.sellPrice) {
            showErr = true
            return
          }
          if (!showErr) {
            this.$set(item, 'secondKillPrice', this.secondKillPrice)
          }
        } else if (type === 'stock') {
          this.$set(item, 'stock', this.stock)
        } else if (type === 'purchaseLimit') {
          this.$set(item, 'purchaseLimit', this.purchaseLimit)
        }
      })
      if (showErr) {
        this.$message.error('秒杀价不能高于当前售价')
        return
      }
    },
    onDel(row) {
      this.commodityVOS.splice(this.commodityVOS.indexOf(row), 1)
    },
    upDataContent(e) {
      let reg = /<[^<>]+>/g // 去标签
      let value = e.replace(reg, '')
      value = value.replace(/&nbsp;/gi, '') // 将空格全部替换
      this.texLeng = value.length
      if (this.texLeng <= 400) {
        this.editorHtml = e
        this.ruleForm.desc = this.editorHtml
      }
      if (this.texLeng > 400) {
        this.ruleForm.desc = ''
        // 当长度大于400时,只截取400之前的内容并赋值
        this.$nextTick(() => {
          this.ruleForm.desc = this.editorHtml
        })
        return false
      }
    },
    querySearchAsync(queryString, cb) {
      let results = []
      if (queryString == '') {
        cb(results)
      } else {
        this.$axios.get(this.$API.getListForStock, { params: { keywords: queryString } }).then(res => {
          if (res.data.length > 0) {
            cb(res.data)
          } else {
            results = []
            cb(results)
          }
        })
      }
    },
    handleBaseSelect(item) {
      // 最多添加50个商品
      if (this.commodityVOS.length >= 50) {
        this.$message.error('最多添加50个商品')
        return
      }
      // 重复添加商品
      if (this.commodityVOS.some(i => i.stockUnitId === item.id)) {
        this.$message.error('请勿重复添加商品')
        return
      }
      let list = {
        name: item.name,
        sellPrice: fenToYuanInt(item.sellPrice),
        stock: item.stock,
        channelPrice: fenToYuanInt(item.channelPrice),
        coverUrl: item.imageUrl,
        supplier: item.supplier,
        inventory: item.inventory,
        stockUnitId: item.id,
        productUnitId: item.productUnitId,
        originPrice: fenToYuanInt(item.originalPrice)
      }
      // 合并并去重
      this.commodityVOS = [...new Set([list, ...this.commodityVOS])]
      this.secondKillPrice = ''
      this.stock = ''
      this.purchaseLimit = ''
    },
    onSecondKillPriceChange(row) {
      // 秒杀价不能高于原价
      if (row.secondKillPrice > row.sellPrice) {
        this.$message.error('秒杀价不能高于当前售价')
        row.secondKillPrice = ''
        return
      }
    },
    onBack() {
      if (this.type === 'edit') {
        this.$confirm('确定返回?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.$router.back()
          })
          .catch(() => {})
      } else {
        this.$router.back()
      }
    },
    onEnd() {
      this.$confirm('确定结束?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$axios.get(this.$API.endSeckill, {params: { campaignId: this.campaignId }}).then(
            () => {
              this.$message.success('结束成功')
              this.$router.back()
            },
            rea => {
              this.$message.error(rea.message)
            }
          )
        })
        .catch(() => {})
    },
    onSubmit(type) {
      if (this.loading || this.publishLoading) return
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.commodityVOS.length === 0) {
            this.$message.error('请先添加商品')
            return
          }
          // 判断商品列表是否填写完整
          let showErr = false
          let message = ''
          this.commodityVOS.forEach(item => {
            // 秒杀库存、秒杀价、每人限购、商品排序可能为数字类型，也可能是字符串类型
            if (item.stock === '' || item.secondKillPrice === '' || item.purchaseLimit === '' || item.productIndex === '') {
              showErr = true
              message = '请完善商品信息'
              return
            }
            // 秒杀库存、秒杀价、每人限购、商品排序需为正整数
            if (!/^[1-9]\d*$/.test(item.stock) || !/^[0-9]\d*$/.test(item.secondKillPrice) || !/^[1-9]\d*$/.test(item.purchaseLimit) || !/^[0-9]\d*$/.test(item.productIndex)) {
              showErr = true
              message = '商品信息填写有误'
              return
            }
          })
          if (showErr) {
            this.$message.error(message)
            return
          }
          let commodityVOS = []
          this.commodityVOS.forEach(item => {
            commodityVOS.push({
              id: item.id,
              stockUnitId: item.stockUnitId,
              productUnitId: item.productUnitId,
              secondKillStock: item.stock,
              secondKillPrice: YuanToFen(item.secondKillPrice),
              buyLimit: item.purchaseLimit,
              commodityIndex: item.productIndex
            })
          })
          let params = {
            title: this.ruleForm.title,
            campaignDesc: this.editorHtml || this.ruleForm.desc,
            startAt: this.ruleForm.timeRange[0],
            endAt: this.ruleForm.timeRange[1],
            commodityBOList: commodityVOS
          }
          if (this.campaignId) {
            params.id = this.campaignId
          }
          this.loading = true
          this.$axios.post(this.$API.saveSeckill, params).then(
            res => {
              this.loading = false
              this.campaignId = res
              if (type === 'publish') {
                this.onPublish()
                return
              } else {
                this.$message.success('保存成功')
                this.$router.back()
              }
            },
            err => {
              this.loading = false
              this.$message.error(err.message)
            }
          )
        } else {
          return false
        }
      })
    },
    onPublish() {
      if (this.publishLoading) return
      this.$confirm('确定发布?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.publishLoading = true
          this.$axios.get(this.$API.publishSeckill, { params: { campaignId: this.campaignId } }).then(
            () => {
              this.publishLoading = false
              this.$message.success('发布成功')
              this.$router.back()
            },
            err => {
              this.publishLoading = false
              this.$message.error(err.message)
            }
          )
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scope>
.w-e-text-container {
  height: 300px !important;
}
.tooltip {
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
