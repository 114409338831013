<template>
  <div class="home">
    <div class="tab-panel">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
      >
        <el-menu-item index="1">
          人脸识别审核
        </el-menu-item>
      </el-menu>
    </div>
    <div class="search-panel">
      <el-row
        :gutter="10"
        type="flex"
        style="flex-wrap: wrap;"
      >
        <el-col :span="3">
          <el-input
            v-model="doctorName"
            placeholder="请输入医生姓名"
          />
        </el-col>
        <el-col :span="3">
          <el-input
            v-model="factoryName"
            placeholder="请输入厂家名称"
          />
        </el-col>
        <el-col :span="3">
          <el-select
            v-model="certificationState"
            placeholder="请选择"
          >
            <el-option
              v-for="item in certificationList"
              :key="item.id"
              :span="12"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-col>
        <el-col :span="7">
          <el-date-picker
            v-model="datePickValue"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          />
        </el-col>
        <el-col
          :span="8"
          style="white-space: nowrap;"
        >
          <!-- <el-button @click="onClear" class="qxs-btn-md">清空</el-button> -->
          <el-button
            type="primary"
            class="qxs-btn-md"
            icon="el-icon-search"
            @click="loadList(1)"
          >
            搜索
          </el-button>
          <el-button
            class="qxs-btn-md"
            @click="onDownload"
          >
            下载数据
          </el-button>
        </el-col>
      </el-row>
    </div>
    <div
      ref="table"
      class="prod-panel"
    >
      <el-table
        ref="multipleTable"
        v-loading="loading"
        :data="faceReviewList"
        border
        style="width: 100%;"
      >
        <el-table-column
          prop="handle"
          label="操作"
          min-width="10%"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click.native.prevent="onReview(scope.row)"
            >
              人工审核
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="userName"
          label="平台姓名"
          min-width="8%"
        />
        <el-table-column
          prop="commitUserName"
          label="真实姓名"
          min-width="8%"
        />
        <el-table-column
          prop="mobile"
          label="手机号"
          min-width="8%"
        />
        <el-table-column
          prop="idCard"
          label="身份证"
          min-width="8%"
        />
        <el-table-column
          prop="checkType"
          label="认证方式"
          min-width="6%"
        >
          <template slot-scope="scope">
            {{ handleCheckType(scope.row.checkType) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="认证状态"
          min-width="6%"
        >
          <template slot-scope="scope">
            {{ handleStatus(scope.row.status) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="auditTime"
          label="认证申请日期"
          min-width="10%"
        />
        <el-table-column
          prop="remarkFactory"
          label="备注厂家"
          min-width="10%"
        />
        <el-table-column
          prop="sign"
          label="标记"
          min-width="10%"
        />
      </el-table>
      <el-pagination
        class="pageination"
        :current-page="facePageNo"
        :page-size="facePageSize"
        :page-sizes="[100, 200, 500, 1000]"
        layout="total, prev, pager, next, jumper,sizes"
        :total="faceTotalCount"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { JsonToExcel } from '@/utils/util'
export default {
  name: 'FaceReviewList',
  components: {},
  data() {
    return {
      loading: true,
      activeIndex: '1',
      name: '人脸识别审核',
      doctorName: '', // 医生姓名
      factoryName: '', // 手机号
      certificationState: null, // 认证状态
      certificationList: [
        { id: null, name: '所有状态' },
        { id: -3, name: '等待人工审核结果' },
        { id: -1, name: '等待腾讯云审核结果' },
        { id: 0, name: '腾讯云人脸核身成功' },
        { id: 1, name: '人工审核成功' },
        { id: 2, name: '腾讯云人脸核身审核失败' },
        { id: 3, name: '腾讯云人脸核身失败3次及以上' },
        { id: 4, name: '人工审核失败' },
        { id: 5, name: '姓名不匹配' }
      ], // 状态列表
      datePickValue: [], // 开始时间，结束时间
      pickerOptions: {
        disabledDate: time => {
          return time.getTime() > Date.now()
        }
      },
      openAuthState: false
    }
  },
  computed: {
    ...mapGetters('FaceReview', [
      'faceReviewList',
      'facePageNo',
      'facePageSize',
      'faceTotalCount'
    ])
  },
  mounted() {
    const end = new Date().toLocaleDateString().split('/').join('-')
    let start = new Date()
    start.setMonth(start.getMonth() - 1)
    start = new Date(start).toLocaleDateString().split('/').join('-')
    this.datePickValue = [start, end]
    this.$store.dispatch('changeTitle', this.name)
    this.loadList(1)
  },
  updated() {
    window.scrollTo(0, 0)
  },
  methods: {
    ...mapActions('FaceReview', [
      'getFaceReviewList',
      'handleFaceAuthLimit',
      'faceSwitchDetail'
    ]),
    onReview(data) {
      this.$router.push({ name: 'doctorDetail', query: { id: data.userId } })
    },
    handleCurrentChange(val) {
      this.loadList(val)
    },
    onClear() {
      this.factoryName = ''
      this.name = ''
      this.certificationState = null
      this.datePickValue = []
    },
    loadList(index, pageSize) {
      const reqData = {
        name: this.doctorName,
        page: index || 1,
        limit: pageSize || this.facePageSize,
        startTime: this.datePickValue[0],
        endTime: this.datePickValue[1],
        factoryName: this.factoryName,
        auditResult: this.certificationState
      }
      this.getFaceReviewList(reqData).then(() => {
        this.loading = false
      }, rea => {
        this.$message({
          message: rea.message,
          type: 'error',
          duration: 5 * 1000
        })
      })
    },
    onDownload() {
      this.$message.info('正在努力下载中，请耐心等待')
      const json = this.faceReviewList.map(item => {
        return [
          item.userName,
          item.commitUserName,
          item.mobile,
          item.idCard,
          this.handleCheckType(item.checkType),
          this.handleStatus(item.status),
          item.auditTime,
          item.remarkFactory,
          item.sign
        ]
      })
      const header = ['平台姓名', '真实姓名', '手机号', '身份证', '认证方式', '认证状态', '认证申请日期', '备注厂家', '标记']
      JsonToExcel('人脸识别审核数据', [header, ...json])
    },
    handleSizeChange(val) {
      this.loadList(1, val)
    },
    onChangeAuthState(val) {
      const data = {
        on: val ? 1 : 0
      }
      this.handleFaceAuthLimit(data).then(() => {
        this.openAuthState = val
        this.$message.success('操作成功')
      }, rea => {
        this.openAuthState = !val
        this.$message.error(rea.message)
      })
    },
    handleCheckType(val) {
      switch (val) {
        case 0:
          return '腾讯云'
        case 1:
          return '人工'
        default:
          return ''
      }
    },
    handleStatus(val) {
      switch (val) {
        case -1:
          return '审核中'
        case 1:
          return '成功'
        case 0:
          return '失败'
        default:
          return ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  padding: 20px;
  .search-panel {
    padding: 20px;
    .search-name {
      position: relative;
      top: 10px;
    }
  }
}
.el-pagination {
  padding-top: 26px;
  text-align: center;
}
.switch-text {
  margin-left: 20px;
}
</style>
