<template>
  <div>
    <div class="mgn-b30">
      <el-button @click="onClear">
        返回
      </el-button>
      <el-button @click="onAddClassify">
        新增
      </el-button>
    </div>
    <el-table
      v-loading="loading"
      :data="categoryList"
      style="width: 100%; margin-bottom: 20px;"
      row-key="id"
      border-f
      :tree-props="{ children: 'childes', hasChildren: 'hasChildren' }"
    >
      <el-table-column
        prop="name"
        :show-overflow-tooltip="true"
        label="标题"
        min-width="180"
      />
      <el-table-column
        prop="id"
        label="标题ID"
        min-width="180"
      />
      <el-table-column
        prop="icon"
        label="图标"
        min-width="180"
      >
        <template slot-scope="scope">
          <img
            v-if="scope.row.normalIconVo"
            :src="scope.row.normalIconVo.small"
            fit="cover"
            class="avatar-scope"
          >
          <img
            v-if="scope.row.highlightIconVo"
            :src="scope.row.highlightIconVo.small"
            class="avatar-scope"
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="position"
        label="权重"
        min-width="180"
      />
      <el-table-column
        prop="showAt"
        label="显示端"
        min-width="180"
      >
        <template slot-scope="scope">
          <p>{{ scope.row.showAt | getShowAtStr }}</p>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        fixed="right"
        min-width="180"
      >
        <template slot-scope="scope">
          <el-button
            class="mgn-r10"
            type="text"
            @click="onClickDetail(scope, 'editor')"
          >
            编辑
          </el-button>
          <el-button
            type="text"
            @click="onClickDel(scope)"
          >
            删除
          </el-button>
          <el-button
            v-if="scope.row.parentId * 1 === 0"
            type="text"
            @click="onClickDetail(scope, 'new')"
          >
            新增
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      class="ft-box"
      :title="dialogTitle"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      width="30%"
      center
    >
      <el-form
        ref="ruleForm"
        :model="form"
        :rules="rules"
      >
        <el-form-item
          label="标题"
          prop="name"
          :label-width="formLabelWidth"
        >
          <el-input v-model="form.name" />
        </el-form-item>
        <el-form-item
          label="默认图标"
          prop="normalIcon"
          :label-width="formLabelWidth"
        >
          <!-- 上传图片接口，文章相关的传 115，反馈相关的传入116 -->
          <el-upload
            class="avatar-uploader"
            action="/manager/separate/v1/upload-image?bucketType=115"
            :headers="headers"
            name="image"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="imageUrl"
              :src="imageUrl"
              class="avatar"
            >
            <i
              v-else
              class="el-icon-plus avatar-uploader-icon"
            />
            <div
              slot="tip"
              class="el-upload__tip"
            >
              只能上传jpg/png文件，且不超过2M
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="高亮图标"
          prop="highlightIcon"
          :label-width="formLabelWidth"
        >
          <!-- 上传图片接口，文章相关的传 115，反馈相关的传入116 -->
          <el-upload
            class="avatar-uploader"
            action="/manager/separate/v1/upload-image?bucketType=115"
            :headers="headers"
            name="image"
            :show-file-list="false"
            :on-success="handleAvatarSuccessTwo"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="imageUrlTwo"
              :src="imageUrlTwo"
              class="avatar"
            >
            <i
              v-else
              class="el-icon-plus avatar-uploader-icon"
            />
            <div
              slot="tip"
              class="el-upload__tip"
            >
              只能上传jpg/png文件，且不超过2M
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="权重"
          prop="position"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="form.position"
            type="number"
            min="0"
          />
        </el-form-item>
        <el-form-item
          label="显示端"
          prop="showPort"
          :label-width="formLabelWidth"
        >
          <el-checkbox-group v-model="form.showPort">
            <el-checkbox label="直播助手" />
            <el-checkbox label="厂家端" />
            <el-checkbox label="代表端" />
            <el-checkbox label="医生端" />
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogFormVisible = false">
          取 消
        </el-button>
        <el-button
          type="primary"
          @click="onSubmit('ruleForm')"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { getShowAtStr, getShowAtNum } from '../utils/util'
import { getToken } from '../utils/auth'
export default {
  name: 'ManageClassify',
  filters: {
    getShowAtStr
  },
  data() {
    return {
      loading: true,
      // ----------------------------- 上传图片 ----------------
      imageUrl: '',
      imageUrlTwo: '',
      num: 1,
      headers: {},
      formData: {},
      // ----------------------------- 弹窗 ---------------------
      dialogTitle: '新增',
      dialogFormVisible: false,
      formLabelWidth: '120px',
      initForm: {
        id: '',
        name: '',
        normalIcon: '',
        highlightIcon: '',
        position: '',
        showPort: [],
        showAt: 0,
        parentId: ''
      },
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: '请输入文章标题',
            trigger: 'blur'
          }
        ],
        position: [
          {
            required: true,
            message: '请输入权重',
            trigger: 'blur'
          }
        ],
        showPort: [
          {
            required: true,
            message: '请选择显示端',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['categoryList']),
    dataObj() {
      return this.formData
    }
  },
  mounted() {
    this.getCategoryList().then(() => {
      this.loading = false
    })
    this.headers = {
      token: getToken()
    }
  },
  methods: {
    ...mapActions(['getCategoryList', 'postCategoryAdd', 'getCategoryDel']),
    // ----------------------- 上传图片 -------------------
    handleAvatarSuccess(res, file) {
      console.log(res)
      this.imageUrl = URL.createObjectURL(file.raw)
      this.$set(this.form, 'normalIcon', res.data.imageName)
    },
    handleAvatarSuccessTwo(res, file) {
      console.log(res)
      this.imageUrlTwo = URL.createObjectURL(file.raw)
      this.$set(this.form, 'highlightIcon', res.data.imageName)
    },
    beforeAvatarUpload(file) {
      console.log(file, '---------------- before ---------------')
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG/PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    // ----------------------- end ------------------------
    onAddClassify() {
      this.imageUrl = ''
      this.imageUrlTwo = ''
      this.form = JSON.parse(JSON.stringify(this.initForm))
      this.dialogTitle = '新增'
      this.dialogFormVisible = true
    },
    onClickDetail(options, type) {
      console.log(options, type)
      const row = options.row
      if (type === 'editor') {
        this.dialogTitle = '编辑'
        this.dialogFormVisible = true
        const sources = getShowAtStr(row.showAt)
        this.imageUrl = row.normalIconVo && row.normalIconVo.middle
        this.imageUrlTwo = row.highlightIconVo && row.highlightIconVo.middle
        this.form = Object.assign(
          {},
          {
            id: row.id,
            name: row.name,
            normalIcon: row.normalIconVo && row.normalIconVo.imageName,
            highlightIcon: row.highlightIconVo && row.highlightIconVo.imageName,
            position: row.position,
            showPort: sources.split('、'),
            showAt: row.showAt,
            parentId: row.parentId
          }
        )
      } else if (type === 'new') {
        this.dialogTitle = '新增'
        this.dialogFormVisible = true
        this.imageUrl = ''
        this.imageUrlTwo = ''
        this.form = Object.assign({}, this.initForm, {
          parentId: row.id
        })
      }
    },
    onClickDel(options) {
      const row = options.row
      this.$confirm('确定删除该标题吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const Data = {
            categoryId: row.id
          }
          this.getCategoryDel(Data).then(() => {
            this.getCategoryList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    onClear() {
      this.$router.go(-1)
    },
    onSubmit(formName) {
      this.form = Object.assign({}, this.form, { showAt: getShowAtNum(this.form.showPort) })
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.dialogFormVisible = false
          const { id, name, normalIcon, highlightIcon, position, showAt, parentId } = this.form
          const Data = { id, name, normalIcon, highlightIcon, position, showAt, parentId }
          this.postCategoryAdd(Data).then(() => {
            this.getCategoryList()
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-scope {
  width: 50px;
  height: 50px;
  padding: 5px 5px;
}
</style>
