<template>
  <div class="home">
    <el-menu
      :default-active="activeIndex"
      :disabled="loading"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="1">服务政策</el-menu-item>
      <el-menu-item index="2">售后原因</el-menu-item>
    </el-menu>
    <template v-if="activeIndex === '1'">
      <div class="tab-panel">
        <el-button
          type="primary"
          class="qxs-btn-md"
          @click="addPolicy"
        >
          +新增
        </el-button>
      </div>
      <div class="prod-panel">
        <el-table
          v-loading="loading"
          :data="servicePolicy"
          border
          style="width: 100%;"
          height="450"
        >
          <el-table-column
            prop="name"
            label="名称"
            min-width="10%"
          />
          <el-table-column
            prop="supplier"
            label="供应商"
            min-width="10%"
          >
            <template slot-scope="scope">
              {{ supplierStr(scope.row.supplier) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="descs"
            label="基础说明"
            min-width="50%"
          >
            <template slot-scope="scope">
              <p
                v-for="(detail,ind) in scope.row.descs"
                :key="ind"
              >
                {{ detail.name }} : {{ detail.value }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            prop="isDefault"
            label="是否为默认政策"
            min-width="10%"
          >
            <template slot-scope="scope">
              {{ scope.row.isDefault === 1 ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column
            prop="zip"
            label="操作"
            min-width="20%"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click.native.prevent="checkDetail(scope.$index, servicePolicy)"
              >
                编辑
              </el-button>
              <el-button
                v-if="!scope.row.isDefault"
                type="text"
                size="small"
                @click.native.prevent="setDefault(scope.$index, servicePolicy)"
              >
                设为默认
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </template>
    <template v-if="activeIndex === '2'">
      <div>
        <el-row style="margin-top: 20px;">
          <el-col :span="20">
            <el-form
              ref="form"
              :inline="true"
              :model="searchObj"
              label-width="80px"
            >
              <el-row>
                <el-form-item label="售后类型">
                  <el-select
                    v-model="searchObj.afterSaleType"
                    placeholder="请选择售后类型"
                    clearable
                  >
                    <el-option
                      v-for="item in serviceTypeList"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="适用的服务商">
                  <el-select
                    v-model="searchObj.supplierType"
                    placeholder="请选择适用的服务商"
                    clearable
                  >
                    <el-option
                      v-for="item in supplierList"
                      :key="item.index"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
                <el-button
                  type="primary"
                  class="qxs-btn-md"
                  icon="el-icon-search"
                  :disabled="loading"
                  @click="onGetAfterSaleReasonList"
                >
                  搜索
                </el-button>
              </el-row>
            </el-form>
          </el-col>
          <el-col :span="4" style="text-align: right;">
            <el-button
              type="primary"
              class="qxs-btn-md"
              @click="editService"
            >
              编辑
            </el-button>
          </el-col>
        </el-row>
        <div class="prod-panel">
          <el-table
            v-loading="loading"
            :data="afterSalesList"
            border
            style="width: 100%;"
            height="450"
          >
            <el-table-column
              prop="name"
              label="售后类型"
              min-width="10%"
            >
              <template slot-scope="scope">
                {{ afterSaleTypeStr(scope.row.afterSaleType) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="supplierType"
              label="供应商"
              min-width="10%"
            >
              <template slot-scope="scope">
                {{ supplierStr(scope.row.supplierType) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="reason"
              label="售后原因"
              min-width="50%"
            />
          </el-table>
          <el-pagination
            class="pageination"
            :current-page="searchObj.pageNo"
            :page-size="searchObj.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="totalCount"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import setDefaultPolicy from '@/apis/setDefaultPolicy'

export default {
  components: {},
  beforeRouteLeave(to, from, next) {
    console.log(to, from)
    if (['policyDetail', 'ServiceSalesDetail'].includes(to.name)) {
      this.isSavaPage = true
    } else {
      this.isSavaPage = false
    }
    next()
  },
  data() {
    return {
      isSavaPage: false,
      loading: true,
      activeIndex: '1',
      name: '服务政策',
      input: '',
      totalNumber: '',
      currentPage: 0,
      poolId: '1',
      serviceTypeList: [
        {
          name: '退款退货',
          code: 10
        },
        {
          name: '换货',
          code: 20
        },
        {
          name: '维修',
          code: 30
        },
        {
          name: '漏发货物',
          code: 40
        }
      ],
      supplierList: [
        {
          id: 0,
          name: '京东'
        },
        {
          id: 1,
          name: '一只猫'
        },
        {
          id: 8,
          name: '鲜通'
        }
      ],
      searchObj: {
        pageNo: 1,
        pageSize: 20,
        afterSaleType: null,
        supplierType: null
      },
      afterSalesList: [],
      totalCount: 0
    }
  },
  computed: {
    ...mapState(['servicePolicy'])
  },
  activated() {
    if (!this.isSavaPage) {
      this.onInitPage()
    }
    console.log('this.name ', this.name)
    this.$store.dispatch('changeTitle', this.name)
    this.handleSelect()
  },
  methods: {
    supplierStr(value) {
      let obj = {
        JD: '京东',
        CAT: '一只猫',
        OFFICIAL: '红包话费',
        XT: '鲜通',
        '0': '京东',
        '1': '一只猫',
        '2': '红包话费',
        '8': '鲜通',
        '10': '十鹿'
      }
      return obj[String(value)] || '一只猫'
    },
    afterSaleTypeStr(value) {
      let obj = {
        '10': '退货退款',
        '20': '换货',
        '30': '维修',
        '40': '漏发货物'
      }
      return obj[String(value)] || ''
    },
    onInitPage() {
      Object.assign(this.$data, this.$options.data())
    },
    handleSelect(key = this.activeIndex) {
      this.$set(this, 'activeIndex', key)
      if (this.activeIndex === '1') {
        this.$store.dispatch('getServicePolicy').then(() => {
          this.loading = false
        })
      } else {
        this.onGetAfterSaleReasonList()
      }
    },
    addPolicy() {
      this.$router.push({ name: 'policyDetail'})
    },
    editService() {
      this.$router.push({name: 'ServiceSalesDetail'})
    },
    checkDetail(index, data) {
      console.log(`id: ${data[index].id}`)
      this.$router.push({ name: 'policyDetail', query: { id: data[index].id }})
    },
    setDefault(index, data) {
      setDefaultPolicy({
        id: data[index].id,
        supplier: data[index].supplier
      }).then(response => {
        if (response) {
          const msg = '设置成功'
          this.$message({
            message: msg,
            type: 'success'
          })
          this.$store.dispatch('getServicePolicy')
        }
      }).catch(error => {
        this.$message.error(error.message)
        console.log(error)

      })
    },
    handleCurrentChange(val) {
      this.searchObj.pageNo = val
      this.onGetAfterSaleReasonList()
    },
    onGetAfterSaleReasonList() {
      if (this.loading) return
      this.loading = true
      this.$axios.get(this.$API.afterSaleReasonList, {params: this.searchObj}).then(res => {
        this.loading = false
        this.afterSalesList = res?.data || []
        this.totalCount = res?.totalCount || 0
      }, rea => {
        this.$message.error(rea.message)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../styles/var";
  .home {
    padding: 20px;
    .search-panel {
      padding: 20px;
      .search-name {
        position: relative;
        top: 10px;
      }
    }
    .tab-panel {
      text-align: right;
      padding: 10px;
    }
    .prod-panel {
      /* width: 1200px; */
    }
  }
  .item-title {
    display: inline-block;
  }
  .el-input__inner {
    display: inline-block;
  }
  .el-pagination {
    padding-top: 26px;
    text-align: center;
  }
</style>
