import * as Type from './mutations-types'
import * as CourseManagement from '../apis/getCourseList'

const state = {
  courseList: [],
  courseOrderList: [],
  factoryList: [],
  courseCount: 0,
  coursePage: 1,
  contactList: [],
  courseOrderCount: 0,
  courseOrderIndex: 1
}
const getters = {
  courseList: state => state.courseList,
  courseOrderList: state => state.courseOrderList,
  factoryList: state => state.factoryList,
  contactList: state => state.contactList,
  coursePage: state => state.coursePage,
  courseCount: state => state.courseCount,
  courseOrderCount: state => state.courseOrderCount,
  courseOrderIndex: state => state.courseOrderIndex
}
const mutations = {
  [Type.GET_COURSE_LIST](state, { data, totalCount, pageNo }) {
    state.courseList.splice(0, state.courseList.length)
    data.forEach(item => {
      state.courseList.push(item)
    })
    state.courseCount = totalCount
    state.coursePage = pageNo
  },
  [Type.GET_COURSE_ORDER_LIST](state, { data, totalCount, pageNo }) {
    state.courseOrderList.splice(0, state.courseOrderList.length)
    data.forEach(item => {
      state.courseOrderList.push(item)
    })
    state.courseOrderCount = totalCount
    state.courseOrderIndex = pageNo
  },
  [Type.GET_FACTORY_LIST](state, value) {
    state.factoryList = value
  },
  [Type.GET_CONTACT_LIST](state, value) {
    state.contactList = value
  }
}
const actions = {
  getCourseList({ commit }, value) {
    return CourseManagement.getCourseList(value).then(
      res => {
        commit(Type.GET_COURSE_LIST, res)
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  saveCourseData(_, value) {
    return CourseManagement.saveCourseData(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getCourseData(_, value) {
    return CourseManagement.getCourseData(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getContactList({ commit }, value) {
    return CourseManagement.getContactList(value).then(
      res => {
        commit(Type.GET_CONTACT_LIST, res.data)
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  changeCourseSale(_, value) {
    return CourseManagement.changeCourseSale(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  onTrainCreateExam(_, value) {
    return CourseManagement.onTrainCreateExam(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getCourseOrderList({ commit }, value) {
    return CourseManagement.getCourseOrderList(value).then(
      res => {
        commit(Type.GET_COURSE_ORDER_LIST, res)
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getCourseOrderDetail(_, value) {
    return CourseManagement.getCourseOrderDetail(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  saveContractData(_, value) {
    return CourseManagement.saveContractData(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getContractData(_, value) {
    return CourseManagement.getContractData(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getContractAudit(_, value) {
    return CourseManagement.getContractAudit(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getFactoryList({ commit }, value) {
    return CourseManagement.getFactoryList(value).then(
      res => {
        commit(Type.GET_FACTORY_LIST, res)
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  onUploadVideo(_, data) {
    // 视频上传
    return CourseManagement.upLoadVideo(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  onUpdateTrainVideo(_, data) {
    // 编辑视频
    return CourseManagement.updateTrainVideo(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  onGetTrainVideoDetail(_, data) {
    // 视频详情
    return CourseManagement.getTrainVideoDetail(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getVideoCut(_, data) {
    return CourseManagement.getVideoCut(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  exportTrainOrderList(_, data) {
    return CourseManagement.exportTrainOrderList(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  }
}
export default {
  state,
  getters,
  actions,
  mutations
  // namespaced: true
}
