<template>
  <div class="home">
    <div class="search-panel">
      <el-row :gutter="24">
        <el-col :span="4">
          <el-row>
            <el-col :span="8" class="search-name"> 供应商 </el-col>
            <el-col :span="16">
              <el-select v-model="supplierId" placeholder="请选择">
                <el-option
                  v-for="item in supplier"
                  :key="item.id"
                  :span="12"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" class="search-name"> 商品类型 </el-col>
            <el-col :span="16">
              <el-select v-model="productTypeId" placeholder="请选择">
                <el-option
                  v-for="item in prodType"
                  :key="item.id"
                  :span="12"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" class="search-name"> 用户类型 </el-col>
            <el-col :span="14">
              <el-select v-model="userTypeId" placeholder="请选择">
                <el-option
                  v-for="item in userType"
                  :key="item.id"
                  :span="12"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="4">
          <el-row>
            <el-col :span="8" class="search-name"> 订单状态 </el-col>
            <el-col :span="16">
              <el-select v-model="orderStatusId" placeholder="请选择">
                <el-option
                  v-for="item in defaultOrderStatus"
                  :key="item.id"
                  :span="12"
                  :label="item.name"
                  :value="item.id"
                />
                <el-option
                  v-for="item in orderStatus"
                  :key="item.id"
                  :span="12"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" class="search-name"> 订单编号 </el-col>
            <el-col :span="15">
              <el-input v-model="orderId" placeholder="请输入订单编号" />
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="11">
          <el-row>
            <el-date-picker
              v-model="timeRange"
              class="data"
              type="datetimerange"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right"
            />
          </el-row>
          <el-row>
            <el-col :span="4" class="search-name"> 用户手机号 </el-col>
            <el-col :span="7">
              <el-input v-model="doctorMobile" placeholder="请输入用户手机号" />
            </el-col>
            <el-col id="space" :span="5" class="search-name"> 收货人手机号 </el-col>
            <el-col :span="8">
              <el-input v-model="userPhone" placeholder="请输入收货人手机号" />
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="3">
          <el-row class="clean-btn">
            <el-button
              :disabled="![0, 1, 8, 10].includes(supplierId)"
              class="qxs-btn-md"
              icon="el-icon-download"
              @click="download"
            >
              导出订单
            </el-button>
          </el-row>
          <el-row class="clean-btn">
            <el-upload
              class="upload-demo"
              action=""
              :limit="10"
              :multiple="false"
              :on-exceed="handleExceed"
              :before-upload="beforeUpload"
              :show-file-list="false"
              :file-list="fileList"
            >
              <el-button class="qxs-btn-sm" icon="el-icon-upload2"> 批量发货 </el-button>
            </el-upload>
          </el-row>
        </el-col>
        <el-col :span="2">
          <el-row class="clean-btn">
            <el-button class="qxs-btn-md" icon="el-icon-delete" @click="clear"> 清空 </el-button>
          </el-row>
          <el-row class="clean-btn">
            <el-button
              type="primary"
              class="qxs-btn-md"
              icon="el-icon-search"
              @click="search(1)"
            >
              搜索
            </el-button>
          </el-row>
          <el-row class="clean-btn">
            <el-button class="qxs-btn-md" @click="dialogForm.dialogVisible = true">回滚订单状态</el-button>
          </el-row>
          <el-row class="clean-btn">
            <el-button class="qxs-btn-md" @click="mobileMoney.isShowDialog= true">话费比例配置</el-button>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div ref="table" class="prod-panel">
      <el-table
        ref="multipleTable"
        v-loading="loading"
        :data="orderList"
        border
        style="width: 100%;"
      >
        <el-table-column prop="orderCreateTime" label="创建时间" min-width="8%" />
        <el-table-column prop="orderId" label="订单编号" min-width="12%" />
        <el-table-column label="订单状态" min-width="8%">
          <template slot-scope="scope">
            {{ orderStatus[scope.row.orderStatus] ? orderStatus[scope.row.orderStatus].name : '' }}
          </template>
        </el-table-column>
        <el-table-column label="商品" min-width="25%">
          <template slot-scope="scope">
            <div v-for="(prod, ind) in scope.row.items" :key="ind" class="prod-detail">
              <el-image class="prod-img" style="width: 20px; height: 20px;" :src="prod.itemImg" />
              <span class="prod-name">{{ prod.itemName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="商品类别" min-width="8%">
          <template slot-scope="scope">
            {{ scope.row.itemType === 0 ? '实体商品' : '虚拟商品' }}
          </template>
        </el-table-column>
        <el-table-column label="供应商" min-width="6%">
          <template slot-scope="scope">
            {{ supplierStr(scope.row.orderType) }}
          </template>
        </el-table-column>
        <el-table-column label="用户类型" min-width="6%">
          <template slot-scope="scope">
            {{ scope.row.userType === 1 ? '医生' : scope.row.userType === 2 ? '代表' : '用友' }}
          </template>
        </el-table-column>
        <el-table-column label="商品总价" min-width="6%">
          <template slot-scope="scope">
            {{ fenToYuan(scope.row.price) }}
          </template>
        </el-table-column>
        <el-table-column label="返利" min-width="6%">
          <template slot-scope="scope">
            {{ fenToYuanFan(scope.row.rebateCents) }}
          </template>
        </el-table-column>
        <el-table-column label="运费" min-width="6%">
          <template slot-scope="scope">
            {{ fenToYuan(scope.row.distributeFee) }}
          </template>
        </el-table-column>
        <el-table-column label="收货人" min-width="6%">
          <template slot-scope="scope">
            <p>{{ scope.row.userName }}</p>
            <!--            <p>{{ scope.row.userPhone }}</p>-->
          </template>
        </el-table-column>
        <el-table-column prop="userAddress" label="收货地址" min-width="6%" />
        <el-table-column prop="userPhone" label="收货人手机号" min-width="6%" />
        <el-table-column label="是否已开发票" min-width="6%">
          <template slot-scope="scope">
            {{ scope.row.isInvoiced === 0 ? '否' : '是' }}
          </template>
        </el-table-column>
        <el-table-column prop="zip" label="操作" min-width="10%">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click.native.prevent="checkDetail(scope.$index, orderList)"> 编辑/查看 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pageination"
        :current-page="orderListIndex"
        :page-size="20"
        layout="total, prev, pager, next, jumper"
        :total="orderTotalCount"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog title="回滚订单状态" :visible.sync="dialogForm.dialogVisible" width="40%">
      <el-form label-width="100px" label-suffix="：">
        <el-form-item label="订单id">
          <el-input v-model="dialogForm.orderId" placeholder="请输入订单id" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogForm.dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="dialogForm.loading" @click="onSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 话费比例配置 -->
    <el-dialog title="话费比例配置" :visible.sync="mobileMoney.isShowDialog">
      <el-form label-width="100px" label-suffix="：">
        <el-form-item label="十鹿话费">
          <el-input-number v-model="mobileMoney.formObj.tenDeer" placeholder="请输入比例" />
        </el-form-item>
        <el-form-item label="禾秀话费">
          <el-input-number v-model="mobileMoney.formObj.heShow" placeholder="请输入比例" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="mobileMoney.isShowDialog = false">取 消</el-button>
        <el-button type="primary" @click="onMobileSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue';
import { mapState } from 'vuex'
import { fenToYuan, fenToYuanFan } from '@/utils/util'
import XLSX from 'xlsx'
import downloadOrderList from '@/apis/downloadOrderList'
import submitDistributes, { changeOrderStatus } from '@/apis/submitDistributes'
import initTcccSDK from '@/utils/tccc'

export default {
  components: {},
  data() {
    return {
      loading: true,
      name: '订单管理',
      supplier: [
        {
          id: null,
          name: '全部'
        },
        {
          id: 0,
          name: '京东'
        },
        {
          id: 1,
          name: '一只猫'
        },
        {
          id: 3,
          name: '红包话费'
        },
        {
          id: 8,
          name: '鲜通'
        },
        {
          id: 10,
          name: '十鹿'
        }
      ],
      prodType: [
        {
          id: null,
          name: '全部'
        },
        {
          id: 0,
          name: '实体商品'
        },
        {
          id: 1,
          name: '虚拟商品'
        }
      ],
      userType: [
        {
          id: null,
          name: '全部'
        },
        {
          id: 1,
          name: '医生'
        },
        {
          id: 2,
          name: '代表'
        },
        {
          id: 9,
          name: '用友'
        }
      ],
      orderStatusId: null,
      supplierId: null,
      userTypeId: null,
      orderId: null,
      userPhone: null,
      doctorMobile: null,
      fileList: [],
      productTypeId: null,
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      timeRange: [],
      dialogForm: {
        dialogVisible: false,
        loading: false,
        orderId: ''
      },
      mobileMoney: {
        isShowDialog: false,
        formObj: {
          tenDeer: '',
          heShow: ''
        }
      }
    }
  },
  computed: {
    ...mapState(['orderList', 'orderListIndex', 'orderTotalCount', 'orderStatus', 'defaultOrderStatus'])
  },
  mounted() {
    this.$store.dispatch('changeTitle', this.name)
    this.search(1)
    initTcccSDK()
  },
  updated() {
    window.scrollTo(0, 0)
  },
  methods: {
    supplierStr(value) {
      let obj = {
        JD: '京东',
        CAT: '一只猫',
        OFFICIAL: '红包话费',
        XT: '鲜通',
        0: '京东',
        1: '一只猫',
        2: '红包话费',
        8: '鲜通',
        10: '十鹿'
      }
      return obj[String(value)] || '一只猫'
    },
    fenToYuan(fen) {
      return fenToYuan(fen)
    },
    fenToYuanFan(fen) {
      return fenToYuanFan(fen)
    },
    checkDetail(index, data) {
      console.log(`orderId: ${data[index].orderId}`)
      this.$router.push({
        name: 'orderDetail',
        query: { orderId: data[index].orderId }
      })
    },
    handleCurrentChange(val) {
      this.search(val)
    },
    clear() {
      this.timeRange.splice(0, this.timeRange.length)
      this.orderId = null
      this.doctorMobile = null
      this.userPhone = null
      this.orderStatusId = null
      this.supplierId = null
      this.productTypeId = null
      this.userId = null
      this.userTypeId = null
    },
    addProd() {
      this.$router.push({
        name: 'productDetail',
        query: { model: 'add' }
      })
    },
    search(index) {
      this.loading = true
      const startTime = this.timeRange[0]
      const endTime = this.timeRange[1]
      this.$store
        .dispatch('getOrderList', {
          pageNo: index,
          startTime: startTime ? startTime.getTime() : null,
          endTime: endTime ? endTime.getTime() : null,
          orderId: this.orderId,
          doctorMobile: this.doctorMobile,
          userPhone: this.userPhone,
          orderStatus: this.orderStatusId,
          productType: this.productTypeId,
          supplierType: this.supplierId,
          userId: '',
          userType: this.userTypeId
        })
        .then(() => {
          this.loading = false
        })
    },
    download() {
      const startTime = this.timeRange[0]
      const endTime = this.timeRange[1]
      if (!startTime || !endTime) {
        this.$message.error('请选择开始时间和结束时间')
        return
      }
      downloadOrderList({
        pageNo: 0,
        startTime: startTime ? startTime.getTime() : null,
        endTime: endTime ? endTime.getTime() : null,
        orderId: this.orderId,
        orderStatus: this.orderStatusId,
        supplierType: this.supplierId,
        productType: this.productTypeId,
        userId: ''
      })
        .then(response => {
          if (response) {
            this.$message({
              message: `导出成功，${response}`,
              type: 'success'
            })
          } else {
            this.$message.error(response.message)
          }
        })
        .catch(error => {
          error.code && this.$message.error(error.message)
          console.log(error)
        })
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeUpload(file) {
      console.log('file.type, ', file.type)
      const newData = []
      const self = this
      // 用FileReader来读取
      const fileReader = new FileReader()
      fileReader.onload = function(ev) {
        console.log(ev, 'ev')
        try {
          const data = ev.target.result
          const workbook = XLSX.read(data, {
            type: 'binary'
          }) // 以二进制流方式读取得到整份excel表格对象
          let persons = [] // 存储获取到的数据
          // 表格的表格范围，可用于判断表头是否数量是否正确
          let fromTo = ''
          // 遍历每张表读取
          for (const sheet in workbook.Sheets) {
            // eslint-disable-next-line no-prototype-builtins
            if (workbook.Sheets.hasOwnProperty(sheet)) {
              fromTo = workbook.Sheets[sheet]['!ref']
              console.log(fromTo)
              persons = persons.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]))
              break // 如果只取第一张表，就取消注释这行
            }
          }
          console.log('persons: ', persons)
          persons.forEach(item => {
            console.log('aa: ', item)
            newData.push({
              orderId: item['订单编号'].toString(),
              deliverCompany: item['物流公司'].toString(),
              deliverNo: item['快递单号'].toString()
            })
          })
          console.log('newData, ', newData)
          console.log('newData.length ', newData.length)
          if (newData.length > 0) {
            submitDistributes(newData)
              .then(response => {
                if (response) {
                  self.$message({
                    message: '批量发货成功',
                    type: 'success'
                  })
                } else {
                  self.$message.error(response.message)
                }
              })
              .catch(error => {
                // self.$message.error(error.message)
                console.log(error)
              })
          } else {
            self.$message.error('未能读取到数据')
          }
        } catch (e) {
          this.$message.error('文件类型不正确')
        }
      }
      // 以二进制方式打开文件
      fileReader.readAsBinaryString(file)
      return false
    },
    toYuan(value) {
      return fenToYuan(value)
    },
    onMobileSubmit() {
      console.log('this.mobile, ', this.mobileMoney)
    },
    async onSubmit() {
      try {
        const { orderId } = this.dialogForm
        if (!orderId) return this.$message.error('请输入订单ID')
        const res = await changeOrderStatus({ orderId })
        console.log(res)
        this.$message({
          message: '操作成功',
          type: 'success'
        })
        this.dialogForm.orderId = ''
        this.dialogForm.dialogVisible = false
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  padding: 20px;
  .search-panel {
    padding: 20px;
    .search-name {
      position: relative;
      top: 10px;
    }
  }
}
.prod-detail {
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
  overflow: hidden;
  .prod-name {
    position: relative;
    top: -5px;
  }
}
.qxs-btn-sm {
  width: 140px;
}
.el-input__inner {
  line-height: 30px;
  display: inline-block;
}
.el-row {
  padding-top: 10px;
}
.item-title {
  display: inline-block;
}
.clean-btn {
  text-align: right;
}
.el-pagination {
  padding-top: 26px;
  text-align: center;
}
#space {
  padding-left: 2%;
}
.data {
  width: 100%;
}
</style>
