<template>
  <el-upload
    v-loading="loading"
    element-loading-target=".el-upload-loading.el-upload"
    class="el-upload-loading"
    element-loading-text="文件上传中"
    :accept="accept"
    action=""
    :http-request="onHttpRequest"
    :show-file-list="false"
    :before-upload="onBeforeUpload"
  >
    <slot />
  </el-upload>
</template>
<script>
import { excelToJson } from '@/utils/util'
export default {
  name: 'UploadSheetToJson',
  props: {
    start: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xlsx, .xls',
      loading: false
    }
  },
  methods: {
    onHttpRequest() {},
    onBeforeUpload(file) {
      try {
        this.loading = true
        const reader = new FileReader()
        reader.readAsBinaryString(file)
        reader.onload = e => {
          setTimeout(() => {
            excelToJson(e.target.result, res => {
              // eslint-disable-next-line vue/custom-event-name-casing
              this.$emit('onUpload', res)
              this.loading = false
              return { json: res, name: file.name.split('.')[0] }
            }, this.start)
          }, 400)
        }
      } catch (error) {
        this.loading = false
      }
    }
  }
}
</script>
