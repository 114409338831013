<template>
  <div class="home">
    <div class="search-panel">
      <el-menu
        :default-active="supplierId.toString()"
        class="el-menu-demo"
        mode="horizontal"
        @select="
          e => {
            supplierId = Number(e)
            onSearch(1)
          }
        "
      >
        <el-menu-item
          v-for="item in supplier"
          :key="item.index"
          :disabled="loading"
          :index="item.id.toString()"
        >
          {{ item.name }}
        </el-menu-item>
      </el-menu>
      <search-wrap>
        <el-form
          label-suffix="："
          label-width="120px"
        >
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="商品名称">
                <el-input v-model="prodName" placeholder="请输入商品名称" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="商品类型">
                <el-select v-model="productTypeId" style="width: 100%;" placeholder="请选择">
                  <el-option
                    v-for="item in productType"
                    :key="item.index"
                    :span="12"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="进货价变动">
                <el-select v-model="priceChangeStateId" style="width: 100%;" placeholder="请选择">
                  <el-option
                    v-for="item in priceChangeState"
                    :key="item.index"
                    :span="12"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="商品编号">
                <el-input v-model="code" placeholder="请输入商品编号" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="商品分类">
                <el-cascader
                  v-model="commodityTypeId"
                  style="width: 100%;"
                  :props="commodityProps"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="商品状态">
                <el-select v-model="prodStateId" style="width: 100%;" placeholder="请选择">
                  <el-option
                    v-for="item in prodState"
                    :key="item.index"
                    :span="12"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="品牌名称">
                <el-input v-model="brandName" placeholder="请输入品牌名称" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="采购价格区间">
                <div style="display: flex; gap: 10px;">
                  <el-input
                    v-model="minChannelPrice"
                    type="number"
                    min="0"
                    @change="channelChange"
                  />到<el-input
                    v-model="maxChannelPrice"
                    type="number"
                    min="0"
                    @change="channelChange"
                  />
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="参考售价区间">
                <div style="display: flex; gap: 10px;">
                  <el-input
                    v-model="minFormalPrice"
                    type="number"
                    min="0"
                    @change="formalChange"
                  />到<el-input
                    v-model="maxFormalPrice"
                    type="number"
                    min="0"
                    @change="formalChange"
                  />
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-button
                type="primary"
                class="qxs-btn-md"
                icon="el-icon-search"
                @click="onSearch(1)"
              >
                搜索
              </el-button>
              <el-button class="qxs-btn-md" @click="clear"> 清空 </el-button>
            </el-col>
          </el-row>
        </el-form>
      </search-wrap>
      <div class="operating-bar">
        <div class="batch">
          <el-button
            v-if="!isActive"
            type="primary"
            class="qxs-btn-md"
            @click="onActiveOpen"
          >
            批量操作
          </el-button>
          <template v-else>
            <el-button type="" class="qxs-btn-md" @click="onClose"> 取消批量操作 </el-button>
            <el-button type="primary" class="qxs-btn-md" @click="onOpenDialog"> 批量更换分类 </el-button>
            <UploadSheetToJson v-if="supplierId == 10" @onUpload="onUpload">
              <el-button class="qxs-btn-md"> 批量上传 </el-button>
            </UploadSheetToJson>
          </template>
          <el-button class="qxs-btn-md" @click="exportShop">导出商品</el-button>
        </div>
        <el-button class="qxs-btn-md" @click="addProd"> +新增商品 </el-button>
      </div>
    </div>
    <div ref="table" class="prod-panel">
      <el-table
        ref="multipleTable"
        v-loading="loading"
        :data="prodList"
        border
        style="width: 100%;"
        @selection-change="onSelectionChange"
      >
        <el-table-column v-if="isActive" type="selection" width="55" />
        <el-table-column prop="id" label="商品ID" min-width="8%" />
        <el-table-column prop="imageUrl" label="商品图片" min-width="12%">
          <template slot-scope="scope">
            <el-image style="width: 100px; height: 100px;" :src="scope.row.imageUrl" />
          </template>
        </el-table-column>
        <el-table-column
          v-if="supplierId === 10"
          label="产品编码"
          prop="code"
          min-width="8%"
        />
        <el-table-column
          v-else
          prop="code"
          label="编号"
          min-width="8%"
        />
        <el-table-column prop="brandName" label="品牌名称" min-width="6%" />
        <el-table-column prop="name" label="商品名称" min-width="20%" />
        <!-- <el-table-column
          label="供应商"
          min-width="8%"
        >
          <template slot-scope="scope">
            {{ supplierStr(scope.row.supplier) }}
          </template>
        </el-table-column> -->
        <el-table-column label="类型" min-width="6%">
          <template slot-scope="scope">
            {{ scope.row.type === 0 ? '实体商品' : '虚拟商品' }}
          </template>
        </el-table-column>
        <el-table-column prop="catName" label="分类" min-width="6%" />
        <el-table-column label="用户" min-width="6%">
          <template slot-scope="scope">
            {{ scope.row.commodityUserType === 0 ? '公共' : scope.row.commodityUserType === 1 ? '医生' : '代表' }}
          </template>
        </el-table-column>
        <el-table-column label="售价" min-width="6%">
          <template slot-scope="scope">
            {{ toYuan(scope.row.sellPrice) }}
          </template>
        </el-table-column>
        <el-table-column label="原价" min-width="6%">
          <template slot-scope="scope">
            {{ toYuan(scope.row.originalPrice) }}
          </template>
        </el-table-column>
        <el-table-column label="采购价" min-width="6%">
          <template slot-scope="scope">
            {{ toYuan(scope.row.channelPrice) }}
          </template>
        </el-table-column>
        <el-table-column label="参考售价" min-width="6%">
          <template slot-scope="scope">
            {{ toYuan(scope.row.formalPrice) }}
          </template>
        </el-table-column>
        <el-table-column prop="profit" label="利润空间" min-width="6%" />
        <el-table-column label="库存" min-width="6%">
          <template slot-scope="scope">
            {{ scope.row.inventory ? scope.row.inventory : '' }}
          </template>
        </el-table-column>
        <el-table-column prop="productIndex" label="权重" min-width="6%" />
        <el-table-column prop="saleNumber" label="销量" min-width="6%" />
        <el-table-column label="状态" min-width="6%">
          <template slot-scope="scope">
            {{ scope.row.state === 0 ? '下架' : '上架' }}
          </template>
        </el-table-column>
        <el-table-column prop="zip" label="操作" min-width="10%">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click.native.prevent="checkDetail(scope.$index, prodList)"> 编辑/查看 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-pagination
        class="pageination"
        :current-page="prodListIndex"
        :page-size="20"
        layout="total, prev, pager, next, jumper"
        :total="prodTotalCount"
        @current-change="handleCurrentChange"
      /> -->
      <el-pagination
        class="pageination"
        :current-page="prodListIndex"
        :page-size="currentPageSize"
        :page-sizes="[20, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="prodTotalCount"
        @current-change="handleCurrentChange"
        @size-change="handleCurrentSizeChange"
      />
    </div>
    <!-- 弹窗 -->
    <el-dialog :visible.sync="isOpenDialog" title="更换商品分类">
      <div>商品原分类将替换成新分类</div>
      <br>
      <div>
        <span>请选择商品分类：</span>
        <el-cascader v-model="selectCommodityId" :props="{ ...commodityProps, checkStrictly: false }" clearable />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="onClose">取 消</el-button>
        <el-button type="primary" @click="onUpdateCommodity">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'
import { fenToYuan } from '@/utils/util'

export default {
  name: 'ProdList',
  data() {
    return {
      loading: true,
      name: '商品管理',
      code: '',
      prodName: '',
      currentPage: 1,
      currentPageSize: 20,
      supplier: [
        {
          id: -1,
          name: '全部'
        },
        {
          id: 0,
          name: '京东'
        },
        {
          id: 1,
          name: '一只猫'
        },
        {
          id: 8,
          name: '鲜通'
        },
        {
          id: 10,
          name: '十鹿'
        }
      ],
      priceChangeState: [
        {
          id: -1,
          name: '全部'
        },
        {
          id: 0,
          name: '否'
        },
        {
          id: 1,
          name: '是'
        }
      ],
      prodState: [
        {
          id: -1,
          name: '全部'
        },
        {
          id: 0,
          name: '下架'
        },
        {
          id: 1,
          name: '上架'
        }
      ],
      productType: [
        {
          id: -1,
          name: '全部'
        },
        {
          id: 0,
          name: '实体'
        },
        {
          id: 1,
          name: '虚拟'
        }
      ],
      commodityTypeId: [],
      supplierId: -1,
      priceChangeStateId: null,
      prodStateId: null,
      productTypeId: null,
      brandName: '',
      minChannelPrice: '',
      maxChannelPrice: '',
      minFormalPrice: '',
      maxFormalPrice: '',
      commodityProps: {
        checkStrictly: true,
        lazy: true,
        lazyLoad: (node, resolve) => {
          const { level, value = '888888' } = node
          this.onGetCommodityList(level + 1, value).then(res => {
            resolve(res)
          })
        }
      },
      isOpenDialog: false,
      isActive: false,
      productIds: [],
      selectCommodityId: []
    }
  },
  computed: {
    ...mapGetters(['prodList', 'prodTotalCount', 'prodListIndex'])
  },
  mounted() {
    this.$store.dispatch('changeTitle', this.name)
    this.$store.dispatch('getProductList', {
      code: null,
      dicCommodityType: null,
      name: null,
      pageNo: 1,
      pageSize: this.currentPageSize,
      priceChange: null,
      productType: null,
      state: null,
      supplier: null
    }).then(() => {
      this.loading = false
    })

    this.$eventBus.$on('reload-prod-list', () => {
      this.onSearch(1)
    })
  },
  updated() {
    window.scrollTo(0, 0)
  },
  methods: {
    async onUpload(row) {
      const maps = {
        商品名称: 'name',
        属性名称: 'attributeName',
        属性值: 'attributeValue',
        品牌名称: 'brand',
        供应商: 'supplier',
        产品编码: 'code',
        售价: 'sellPrice',
        原价: 'originalPrice',
        采购价: 'channelPrice',
        参考售价: 'formalPrice',
        使用方式: 'useStyle',
        分类: 'dicCommodityType',
        商品图片: 'imageUrl',
        使用说明: 'detail',
        充值账号类型: 'chargeAccountType',
        最大购买值: 'highestBuy'
      }
      const arr = row.map(item => {
        let obj = {}
        for (let key in item) {
          obj[maps[key]] = item[key]
        }
        return obj
      })
      try {
        const {data} = await this.$axios.post(this.$API.batchImportProduct, { virtualProductBOS: arr })
        if (data) {
          this.$message.success('导入成功')
          this.onSearch(1)
        }
      } catch (error) {
        error.message && this.$message.error(error.message)
      }
    },
    onGetCommodityList(level = 0, parentId = '888888') {
      const params = { level, parentId }
      return this.$axios.get(this.$API.getCommodityType, { params }).then(res => {
        const nodes = (res || []).map(item => ({
          value: item.id,
          label: item.name,
          leaf: level >= 2
        }))
        return nodes
      })
    },
    onSelectionChange(options) {
      this.productIds = options.map(item => item.id)
    },
    onActiveOpen() {
      this.isActive = true
    },
    onOpenDialog() {
      if (!this.productIds.length) return this.$message.error('请选择要分类的商品')
      this.isOpenDialog = true
    },
    onClose() {
      this.isOpenDialog = false
      this.isActive = false
      this.productIds = []
      this.selectCommodityId = []
      this.$refs.multipleTable.clearSelection()
    },
    onUpdateCommodity() {
      if (!this.selectCommodityId.length) return this.$message.error('请选择商品分类')
      const data = {
        productIds: this.productIds,
        dicCommodityTypeId: this.selectCommodityId[0],
        secondDicCommodityTypeId: this.selectCommodityId[1]
      }
      this.$axios.post(this.$API.batchUpdateProductCommodityType, data).then(
        () => {
          this.onClose()
          this.$message.success('批量更新成功')
          this.onSearch()
        },
        rea => {
          this.$message.error(rea.message)
        }
      )
    },
    checkDetail(index, data) {
      console.log(`id: ${data[index].id}`)
      this.$router.push({ name: 'productDetail', query: { spuId: data[index].id } })
    },
    handleCurrentChange(val) {
      this.onSearch(val)
    },
    handleCurrentSizeChange(val) {
      this.onSearch(1, val)
    },
    clear() {
      this.prodName = ''
      this.code = ''
      this.commodityTypeId = []
      this.supplierId = -1
      this.priceChangeStateId = null
      this.prodStateId = null
      this.productTypeId = null
      this.brandName = ''
      this.minChannelPrice = ''
      this.maxChannelPrice = ''
      this.minFormalPrice = ''
      this.maxFormalPrice = ''
    },
    addProd() {
      this.$router.push({ name: 'productDetail', query: { model: 'add' } })
    },
    channelChange() {
      this.minFormalPrice = ''
      this.maxFormalPrice = ''
    },
    formalChange() {
      this.minChannelPrice = ''
      this.maxChannelPrice = ''
    },
    onSearch(index, size = this.currentPageSize) {
      this.currentPageSize = size
      this.loading = true
      this.$store
        .dispatch('getProductList', {
          code: this.code,
          name: this.prodName,
          brandName: this.brandName,
          minChannelPrice: this.minChannelPrice,
          maxChannelPrice: this.maxChannelPrice,
          minFormalPrice: this.minFormalPrice,
          maxFormalPrice: this.maxFormalPrice,
          pageNo: index || 1,
          pageSize: size,
          dicCommodityType: this.commodityTypeId[0],
          secondDicCommodityType: this.commodityTypeId[1],
          priceChange: this.priceChangeStateId >= 0 ? this.priceChangeStateId : null,
          productType: this.productTypeId >= 0 ? this.productTypeId : null,
          state: this.prodStateId >= 0 ? this.prodStateId : null,
          supplier: this.supplierId >= 0 ? this.supplierId : null
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 导出商品
    async exportShop() {
      let params = {
        supplier: this.supplierId >= 0 ? this.supplierId : null,
        code: this.code,
        productType: this.productTypeId >= 0 ? this.productTypeId : null,
        name: this.prodName,
        state: this.prodStateId >= 0 ? this.prodStateId : null,
        dicCommodityType: this.commodityTypeId[0],
        secondDicCommodityType: this.commodityTypeId[1],
        priceChange: this.priceChangeStateId >= 0 ? this.priceChangeStateId : null,

        brandName: this.brandName,
        minChannelPrice: this.minChannelPrice,
        maxChannelPrice: this.maxChannelPrice,
        minFormalPrice: this.minFormalPrice,
        maxFormalPrice: this.maxFormalPrice
      }
      try {
        const res = await this.$axios.post(this.$API.exportProductManager, params)
        this.$message.success(res)
      } catch (error) {
        this.$message.error(error.message)
        console.log('%c [error]', 'font-size:15px; color:#42b983;', error)
      }

    },
    toYuan(value) {
      return fenToYuan(value)
    },
    supplierStr(value) {
      let obj = {
        JD: '京东',
        CAT: '一只猫',
        OFFICIAL: '红包话费',
        XT: '鲜通',
        0: '京东',
        1: '一只猫',
        2: '红包话费',
        8: '鲜通'
      }
      return obj[String(value)] || '一只猫'
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  .search-panel {
    margin-bottom: 10px;
    .search-name {
      position: relative;
      top: 10px;
    }
  }
}
.el-pagination {
  padding-top: 26px;
  text-align: center;
}
.operating-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .batch {
    display: flex;
    align-items: center;
    >div {
      margin: 0 10px;
    }
  }
}
</style>
