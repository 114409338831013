<template>
  <div class="container">
    <div class="channelSelection">
      <div class="titleOne">
        渠道选择
      </div>
      <el-radio-group v-model="channelType">
        <el-radio :label="1">
          微信公众号
        </el-radio>
        <el-radio :label="0">
          短信通知
        </el-radio>
        <el-radio :label="3">
          产品弹窗（应用内打开）
        </el-radio>
      </el-radio-group>
      <el-divider />
    </div>
    <div class="targetSelection">
      <div class="title">
        目标选择
      </div>
      <el-radio-group v-model="userType">
        <el-radio :label="3">
          医生用户
        </el-radio>
        <el-radio :label="2">
          代表用户
        </el-radio>
      </el-radio-group>
      <el-upload
        class="upload-xsl"
        action="/manager/separate/v1/upload-image?bucketType=116"
        :on-change="handleChange"
        :on-remove="handleExRemove"
        :on-exceed="handleExExceed"
        :limit="1"
        accept=".xls,.xlsx"
        :auto-upload="false"
      >
        <el-button
          size="small"
          type="primary"
        >
          导入EXCEL表格
        </el-button>
        <span
          slot="tip"
          class="el-upload__tip"
        >备注：EXCEL表格抬头：手机号或者用户ID</span>
      </el-upload>
      <el-divider />
    </div>
    <div v-if="channelType === 3">
      <span class="title">弹窗样式：</span>
      <div class="box">
        <div class="center">
          标题:{{ '*****' }}
        </div>
        <div>内容:{{ '****************************' }}</div>
        <div class="know">
          按钮名称
        </div>
      </div>
    </div>
    <div class="contentEditing">
      <div class="title">
        内容编辑
      </div>
      <div class="tab">
        <el-tabs
          v-model="contentType"
          type="card"
        >
          <el-tab-pane
            v-if="channelType !== 3"
            label="文本（链接）"
            name="0"
            :span="12"
          >
            <el-form
              ref="form"
              :model="msg"
              label-width="100px"
              class="text-content"
              :rules="textRules"
            >
              <el-form-item label="标题内容">
                <el-input
                  v-model="msg.title"
                  placeholder="请输入通知标题"
                />
              </el-form-item>
              <el-form-item label="通知内容">
                <el-input
                  v-model="msg.content"
                  type="textarea"
                  rows="6"
                  placeholder="请输入通知内容"
                  resize="none"
                />
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane
            v-if="channelType === 1"
            label="图片"
            name="1"
            :span="12"
          >
            <el-upload
              class="upload-img"
              :action="uploadUrl"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              :before-upload="beforeUpload"
              multiple
              name="image"
              :limit="1"
              :headers="headers"
              :on-exceed="handleExceed"
              :file-list="fileList"
              :on-success="handleSuccess"
            >
              <el-button
                size="small"
                type="primary"
              >
                上传图片
              </el-button>
              <span
                slot="tip"
                class="upload__tip"
              >请先进行目标选择，再上图片！且仅支持jpg/png文件。</span>
            </el-upload>
          </el-tab-pane>
        </el-tabs>
        <div
          v-if="channelType === 3"
          label="弹窗"
        >
          <el-form
            ref="ruleForm"
            :model="ruleForm"
            :rules="rules"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-col :span="12">
              <el-form-item label="活动标题">
                <el-input
                  v-model="ruleForm.title"
                  placeholder="请输入通知标题"
                  class="input"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="活动学分">
                <el-select
                  v-model="ruleForm.isCredit"
                  placeholder="请选择活动积分"
                >
                  <el-option
                    label="有"
                    value="有"
                  />
                  <el-option
                    label="无"
                    value="无"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="通知内容">
                <el-input
                  v-model="ruleForm.content"
                  placeholder="请输入通知内容（字数限制30字）"
                  type="textarea"
                  rows="4"
                  class="input"
                  resize="none"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="学分发放方式">
                <el-select
                  v-model="ruleForm.grantId"
                  placeholder="请选择学分发放方式"
                  :span="12"
                >
                  <el-option
                    v-for="item in grant"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
                <el-input
                  v-if="ruleForm.grantId === 0"
                  v-model="ruleForm.credit"
                  placeholder="请输入人均学分数"
                  :span="12"
                  class="input-b"
                />
              </el-form-item>
              <el-form-item label="学分领取方式">
                <el-select
                  v-model="ruleForm.receive"
                  placeholder="请选择学分领取方式"
                >
                  <el-option
                    label="直接发送"
                    value="直接发送"
                  />
                  <el-option
                    label="点击按钮领取"
                    value="点击按钮领取"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="按钮名称">
                <el-input
                  v-model="ruleForm.button"
                  placeholder="请输入按钮名称"
                  class="input"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="选择活动账户">
                <el-select
                  v-model="ruleForm.account"
                  placeholder="请选择活动账户"
                >
                  <el-option
                    label="趣学术官方"
                    value="趣学术官方"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="按钮功能">
                <el-select
                  v-model="ruleForm.buttonFunctionId"
                  placeholder="请选择按钮功能"
                  class="button"
                >
                  <el-option
                    v-for="item in buttonFunction"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-input
                v-if="ruleForm.buttonFunctionId === 0"
                v-model="ruleForm.link"
                placeholder="请输入活动跳转链接(选填)"
                class="input-a"
              />
            </el-col>
          </el-form>
        </div>
      </div>
    </div>
    <div class="push">
      <el-divider />
      <div class="title">
        推送设置
      </div>
      <el-radio
        v-model="pushTime"
        label="1"
      >
        立即推送
      </el-radio>
      <el-radio
        v-model="pushTime"
        label="2"
      >
        定时推送
      </el-radio>
      <div
        v-if="pushTime != 1"
        class="block"
      >
        <span class="demonstration">推送时间</span>
        <el-date-picker
          v-model="pushDateStr"
          type="datetime"
          placeholder="选择日期时间"
        />
      </div>
    </div>
    <div class="button-box">
      <el-button @click="onCancel">
        取消
      </el-button>
      <el-button
        type="primary"
        @click="onSubmit()"
      >
        保存
      </el-button>
    </div>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import XLSX from 'xlsx'
import { mapActions } from 'vuex'

export default {
  name: 'NewMessage',
  data() {
    return {
      img: false,
      textLink: false,
      popup: true,
      image: true,
      channelType: 1,
      contentType: 0,
      userType: 3,
      pushDateStr: '',
      pushTime: '1',
      msg: {},
      fileList: [],
      textRules: {
        title: [{ required: true, message: '请输入通知标题', trigger: 'blur' }],
        content: [{ required: true, message: '请输入通知内容', trigger: 'blur' }]
      },
      ruleForm: {
        title: '',
        isCredit: '',
        credit: '',
        content: '',
        grantId: 0,
        button: '',
        receive: '',
        account: '',
        buttonFunctionId: 0,
        link: ''
      },
      rules: {},
      users: [],
      headers: {},
      url: '',
      user: { userType: this.userType },
      grant: [
        {
          id: 0,
          name: '人均'
        },
        {
          id: 1,
          name: 'Execl表格导入'
        }
      ],
      buttonFunction: [
        {
          id: 0,
          name: '跳转链接'
        },
        {
          id: 1,
          name: '取消弹窗'
        }
      ]
    }
  },
  computed: {
    uploadUrl() {
      return `/manager/separate/wx/upload-file?userType=${this.userType}`
    }
  },
  watch: {},
  mounted() {
    this.headers.token = getToken()
  },
  methods: {
    ...mapActions(['creatMessage']),
    // 上传文件时处理方法
    handleChange(file) {
      const files = { 0: file.raw }
      this.readExcel1(files)
    },
    readExcel1(files) { // 表格导入
      if (files.length <= 0) { // 如果没有文件名
        return false
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$message({
          type: 'warning',
          message: '附件格式错误，请删除后重新上传！'
        })
        // this.$Message.error('上传格式不正确，请上传xls或者xlsx格式')
        return false
      }
      const fileReader = new FileReader()
      fileReader.onload = ev => {
        try {
          const data = ev.target.result
          const workbook = XLSX.read(data, {
            type: 'binary'
          })
          const wsname = workbook.SheetNames[0]// 取第一张表
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname])// 生成json表格内容
          if (ws) {
            let newList = []
            ws.map(item => {
              const obj = {}
              obj.userId = item['用户ID']
              obj.openId = item['微信ID']
              obj.mobile = item['手机号']
              newList = [...newList, obj]
            })
            console.log(newList)
            this.users = newList
          }
        } catch (e) {
          return false
        }
      }
      // const reader = fileReader.readAsBinaryString(files[0])
    },
    handleExExceed() {
      this.$message({
        type: 'warning',
        message: '超出最大上传文件数量的限制！'
      })
      return
    },
    // 移除文件的操作方法
    handleExRemove() {
      this.fileTemp = null
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handleExceed() {
      this.$message.warning('当前限制选择 1 个文件')
    },
    handleSuccess(res) {
      this.msg.mediaId = res.data.model.mediaId
    },
    beforeUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG、PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    onCancel() {
      this.$router.push({
        name: 'messagePush'
      })
    },
    initPageData() {
      this.channelType = 1
      this.contentType = 0
      this.userType = 3
      this.pushDateStr = ''
      this.user = []
      this.msg = {}
    },
    onSubmit() {
      let pushDate = new Date()
      pushDate = new Date(pushDate).getTime()
      if (this.channelType === 1) {
        if (this.contentType == 0) {
          if (!this.msg.title || !this.msg.content) {
            this.$message({ message: '请输入内容', type: 'fail' })
            return false
          }
        } else {
          if (!this.msg.mediaId) {
            this.$message({ message: '请上传图片', type: 'fail' })
            return false
          }
        }
      }
      if (this.channelType === 0) {
        if (!this.msg.title || !this.msg.content) {
          this.$message({ message: '请输入内容', type: 'fail' })
          return false
        }
      }
      const Data = {
        channelType: this.channelType,
        contentType: this.contentType,
        userType: this.userType,
        pushDateStr: pushDate,
        users: this.users,
        msg: this.msg
      }
      const msgBO = {
        msgBO: Data
      }
      this.creatMessage(msgBO).then(res => {
        if (res) {
          this.$message({ message: '操作成功', type: 'success' })
          // this.onCancel()
        }
      })
    },
    mounted() {
      this.initPageData()
    }
  }
}
</script>

<style scoped>
.container {
  padding: 30px;
}
.title {
  padding: 20px 0 30px 0;
}
.titleOne {
  padding-bottom: 30px;
}
.contentEditing {
  height: 350px;
}
.text-content {
  width: 800px;
}
.upload-img {
  padding-top: 30px;
}
.el-upload__tip {
  padding-left: 10px;
}
.upload__tip {
  padding-left: 20px;
  color: #005b53;
  font-weight: 700;
}
.block {
  padding: 20px 0;
}
.demonstration {
  padding-right: 20px;
}
.upload-xsl {
  height: 100px;
  padding: 30px 0;
}
.button-box {
  width: 100%;
  background: #fff;
  padding: 30px;
  text-align: center;
  position: fixed;
  bottom: 0;
}
.input {
  width: 193px;
}
.input-a {
  width: 293px;
}
.input-b {
  padding-left: 10px;
  width: 200px;
}
.box {
  position: relative;
  border: 1px solid;
  border-radius: 10px;
  width: 270px;
  height: 150px;
  padding: 20px;
  margin-left: 100px;
  margin-bottom: 30px;
}
.center {
  text-align: center;
}
.know {
  position: absolute;
  border: 1px solid;
  border-radius: 8px;
  width: 75px;
  text-align: center;
  left: 0;
  bottom: 10px;
  right: 0;
  margin: auto;
  padding: 2px;
}
</style>
