export default [
  {
    path: '/auxiliary-tool',
    name: 'AuxiliaryTool',
    component: () => import(/* webpackChunkName: "auxiliary-tool" */ '@/views/auxiliary-tool/index.vue'),
    meta: {
      title: '辅助工具'
    }
  }
]
