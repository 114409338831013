// import Cookies from 'js-cookie'
import { getQueryVariable } from './util'

// const TokenKey = 'Admin-Token'

export function getToken() {
  // return Cookies.get(TokenKey)
  return window.localStorage.getItem('_token') || getQueryVariable('token')
}

export function setToken(token) {
  // Cookies.set(TokenKey, token, { expires: 7 });
  window.localStorage.setItem('_token', token)
}

export function removeToken() {
  // return Cookies.remove(TokenKey)
  window.localStorage.removeItem('_token')
}
