<template>
  <div>
    <div class="pdg-t30">
      <div class="header-box">
        <el-row :gutter="20">
          <el-col :span="4">
            <el-select
              v-model="sourceValue"
              placeholder="请选择显示端"
              @change="onSourceChange"
            >
              <el-option
                v-for="item in sourceOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-cascader
              :options="classifyList"
              placeholder="请选择分类"
              @change="onCascaderChange"
            />
          </el-col>
          <el-col :span="4">
            <el-select
              v-model="statusVal"
              placeholder="请选择状态"
              @change="onStatusChange"
            >
              <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-col>
          <el-col :span="12">
            <div class="search-box">
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-input
                    v-model="inputVal"
                    width="200"
                    placeholder="请输入推送标题"
                    clearable
                    @input="onInputChange"
                  />
                </el-col>
                <el-col :span="16">
                  <el-button
                    class="mgn-r10"
                    @click="onSearch"
                  >
                    搜索
                  </el-button>
                  <el-button @click="onManageClassify">
                    管理分类
                  </el-button>
                  <el-button
                    class="mgn-r10"
                    @click="onAddArticle"
                  >
                    新增
                  </el-button>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="table-box">
        <el-table
          v-loading="loading"
          :data="articleList"
          border
          style="width: 100%;"
        >
          <el-table-column
            prop="title"
            :show-overflow-tooltip="true"
            label="标题"
          />
          <el-table-column
            prop="createdAt"
            label="创建时间"
          />
          <el-table-column
            prop="publishAt"
            label="发布时间"
          />
          <el-table-column
            prop="categoryName"
            :show-overflow-tooltip="true"
            label="一级分类"
          />
          <el-table-column
            prop="secCategoryName"
            :show-overflow-tooltip="true"
            label="二级分类"
          />
          <el-table-column
            prop="browseCount"
            label="阅读量"
          />
          <el-table-column
            prop="position"
            label="权重值"
          />
          <el-table-column
            prop="showAt"
            label="显示端"
            width="180"
          >
            <template slot-scope="scope">
              <p>{{ scope.row.showAt | getShowAtStr }}</p>
            </template>
          </el-table-column>
          <el-table-column
            prop="likeCount"
            label="有用"
          />
          <el-table-column
            prop="hateCount"
            label="无用"
          />
          <el-table-column
            prop="status"
            label="状态"
          >
            <template slot-scope="scope">
              <p>
                {{ scope.row.status === 0 ? "草稿" : scope.row.status === 1 ? "发布" : "下架" }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            fixed="right"
            min-width="180"
          >
            <template slot-scope="scope">
              <el-button
                class="mgn-r10"
                type="text"
                @click="onClickDetail(scope, 'editor')"
              >
                编辑
              </el-button>
              <el-button
                type="text"
                :disabled="scope.row.status === 1"
                @click="onClickDetail(scope, 'update')"
              >
                发布
              </el-button>
              <el-button
                type="text"
                :disabled="scope.row.status === 2"
                @click="onClickDetail(scope, 'down')"
              >
                下架
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination-com
          :current-page="searchData.pageNo"
          :page-size="searchData.pageSize"
          :total="total"
          @current-change="onCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import paginationCom from '@/components/common/paginationCom'
import { mapActions, mapGetters } from 'vuex'
import { getShowAtStr, getShowAtNum } from '../utils/util'

export default {
  name: 'ProblemClassify',
  components: {
    paginationCom
  },
  filters: {
    getShowAtStr
  },
  data() {
    return {
      activeName: 'manufacturer',
      loading: true,
      searchData: {
        // 搜索参数
        pageNo: 1,
        pageSize: 10,
        showAt: null,
        categoryId: '',
        secCategoryId: '',
        status: ''
      },
      total: 0,
      // ---------------------- source select --------------
      sourceValue: '',
      sourceOptions: [
        {
          value: 'all',
          label: '全部来源'
        },
        {
          value: 'speakerTerminal',
          label: '直播助手'
        },
        {
          value: 'manufacturer',
          label: '厂家端'
        },
        {
          value: 'representative',
          label: '代表端'
        },
        {
          value: 'doctor',
          label: '医生端'
        }
      ],
      // ------------------- statusList ----------------
      statusVal: '',
      statusList: [
        {
          value: 'all',
          label: '全部状态'
        },
        {
          value: '0',
          label: '草稿'
        },
        {
          value: '1',
          label: '发布'
        },
        {
          value: '2',
          label: '下架'
        }
      ],
      // ------------------- input ---------------
      inputVal: ''
    }
  },
  computed: {
    ...mapGetters(['categoryList', 'articleList']),
    classifyList() {
      const list = [
        {
          value: 'all',
          label: '全部分类'
        }
      ]
      let newList = []
      if (this.categoryList && this.categoryList.length > 0) {
        newList = this.categoryList.map(item => {
          let childrenList = []
          if (item.childes) {
            childrenList = item.childes.map(it => {
              return {
                value: it.id,
                label: it.name
              }
            })
          }
          return childrenList.length > 0
            ? {
              value: item.id,
              label: item.name,
              children: childrenList
            }
            : {
              value: item.id,
              label: item.name
            }
        })
      }
      return list.concat(newList)
    }
  },
  activated() {
    this.initPageData()
  },
  destroyed() { },
  methods: {
    ...mapActions(['getCategoryList', 'getArticleList', 'getArticleChangeStatus']),
    initPageData() {
      this.getCategoryList()
      this.onGetArticleList()
    },
    onGetArticleList() {
      this.getArticleList(this.searchData).then(res => {
        console.log(res)
        this.loading = false
        this.total = res.totalCount
      })
    },
    onClickDetail(options, type) {
      console.log(options, type)
      const Data = {
        articleId: options.row.id,
        toStatus: type === 'update' ? '1' : '2'
      }
      if (type === 'editor') {
        this.$router.push({ path: `/problemEditor/${options.row.id}` })
      } else if (type === 'update') {
        this.$confirm('确定发布该文章吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.getArticleChangeStatus(Data).then(() => {
              this.onGetArticleList()
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消发布'
            })
          })
      } else if (type === 'down') {
        this.$confirm('确定下架该文章吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.getArticleChangeStatus(Data).then(() => {
              this.onGetArticleList()
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          })
      }
    },
    onCurrentChange(options) {
      this.$set(this.searchData, 'pageNo', options)
      this.onGetArticleList()
    },
    onInputChange(options) {
      this.$set(this.searchData, 'searchKey', options)
    },
    onSourceChange(options) {
      const showAt = getShowAtNum([options])
      this.$set(this.searchData, 'showAt', showAt)
    },
    onStatusChange(options) {
      console.log(options)
      if (options === 'all') {
        this.$set(this.searchData, 'status', '')
        return
      }
      this.$set(this.searchData, 'status', options)
    },
    onCascaderChange(options) {
      this.searchData = Object.assign({}, this.searchData, {
        categoryId: '',
        secCategoryId: ''
      })
      if (options.length === 1 && options[0] !== 'all') {
        this.$set(this.searchData, 'categoryId', options[0])
      } else if (options.length === 2) {
        this.searchData = Object.assign({}, this.searchData, {
          categoryId: options[0],
          secCategoryId: options[1]
        })
      }
    },
    onSearch() {
      this.$set(this.searchData, 'pageNo', 1)
      this.onGetArticleList()
    },
    onManageClassify() {
      console.log('管理分类')
      this.$router.push({ name: 'manageClassify' })
    },
    onAddArticle() {
      this.$router.push({ path: '/problemEditor/new' })
    }
  }
}
</script>

<style scoped>
.table-box {
  padding-top: 30px;
}
</style>
