<template>
  <div>
    <div>平台科室管理</div>
    <el-tabs
      v-model="activeName"
      @tab-click="onChangeTab"
    >
      <el-tab-pane
        label="未归类科室"
        name="noClassify"
      >
        <div class="add-classify-box">
          <div>共有{{ totalCount }}个未归类科室</div>
          <el-button
            type="primary"
            size="large"
            @click="onAddDepartment"
          >
            添加平台科室
          </el-button>
        </div>
        <el-table
          v-loading="loading"
          border
          :data="noClassifyList"
        >
          <el-table-column
            prop="name"
            label="科室名称"
            align="center"
          />
          <!-- <el-table-column prop="hospitalName" label="来源医院" align="center" /> -->
          <el-table-column
            prop="suggestDepartmentName"
            label="建议归类于平台科室"
            align="center"
          />
          <el-table-column
            fixed="right"
            label="操作"
            width="200px"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="onAdviceDepartmentOne(scope.row)"
              >
                归入建议科室
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="onAdviceDepartment(scope.row)"
              >
                选择归类科室
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="pageination"
          :current-page="pageNo"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="totalCount"
          @current-change="handleCurrentChange"
        />
      </el-tab-pane>
      <el-tab-pane
        label="平台科室列表"
        name="alreadyClassify"
      >
        <div class="add-classify-box">
          <div>共有{{ totalCount }}个平台科室</div>
          <el-button
            type="primary"
            size="large"
            @click="onAddDepartment"
          >
            添加平台科室
          </el-button>
        </div>
        <el-table
          v-loading="platformClassifyLoading"
          border
          :data="platformClassifyList"
        >
          <el-table-column
            prop="id"
            label="科室编号"
            width="270"
            align="center"
          />
          <el-table-column
            prop="oneLevelDepartmentName"
            label="一级科室"
            width="150"
            align="center"
          />
          <el-table-column
            prop="twoLevelDepartmentName"
            label="二级科室"
            width="150"
            align="center"
          />
          <el-table-column
            prop="childDepartmentNameList"
            show-overflow-tooltip
            label="包含的科室"
            align="center"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.childDepartmentNameList.length" class="child-list-box">
                {{ scope.row.childDepartmentNameList.join(` `) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="250"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="onManageDepartment(scope.row)"
              >
                管理子科室
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="onEditDepartment(scope.row)"
              >
                修改名称
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="onDeletePlatDepartment(scope.row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="pageination"
          :current-page="pageNo"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="totalCount"
          @current-change="handleCurrentChange"
        />
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      :title="deleteType==='plat' ? '删除平台科室' : `删除${manageObj.name}子科室`"
      :visible.sync="isShowDelDepartment"
      width="30%"
      center
    >
      <div>
        <div class="department-input-box">
          <label class="label-name">科室名称：</label>
          <span>{{ deleteType != 'platChild' ? manageObj.name : hospitalDepartmentName }}</span>
        </div>
        <div class="department-input-box mgn-t30">
          删除科室后，对应数据和报表会受影响，请谨慎操作
        </div>
      </div>

      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="isShowDelDepartment = false">
          取 消
        </el-button>
        <el-button
          type="primary"
          @click="onDelDepartmentCon"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="修改平台科室"
      :visible.sync="isEditDepartment"
      width="30%"
      center
    >
      <div class="department-input-box">
        <label class="label-name">科室名称：</label>
        <el-input
          v-model="inputVal"
          placeholder="请填写科室名称"
        />
      </div>
      <div class="department-input-box mgn-t30">
        修改科室后，对应数据和报表会受影响，请谨慎操作
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="isEditDepartment = false">
          取 消
        </el-button>
        <el-button
          type="primary"
          @click="onEditDepartmentCon"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="添加平台科室"
      :visible.sync="isAddDepartment"
      width="30%"
      center
    >
      <div class="department-input-box">
        <label class="label-name">科室名称：</label>
        <el-input
          v-model="departmentVal"
          placeholder="请填写科室名称"
        />
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="isAddDepartment = false">
          取 消
        </el-button>
        <el-button
          type="primary"
          @click="onConfirmBtn"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="选择归类到哪个科室"
      :visible.sync="isAdviceDepartment"
      width="40%"
    >
      <div class="content-box">
        <div
          class="department-input-box"
          style="justify-content: flex-start;"
        >
          <label class="label-name">未分类科室名称：</label>
          <span>{{ noDepartmentName }}</span>
        </div>
        <div
          class="department-input-box mgn-t30"
          style="justify-content: flex-start;"
        >
          <label class="label-name">归类到平台科室：</label>
          <el-cascader
            :value="[form.oneLevelPlatformDepartmentId,form.twoLevelPlatformDepartmentId]"
            filterable
            popper-class="cascader-popper"
            :options="allPlatformClassifyList"
            :props="{
              checkStrictly: true,
              value: 'id',
              label: 'name',
              children: 'childList'
            }"
            @input="(e) => {form.oneLevelPlatformDepartmentId = e[0]; form.twoLevelPlatformDepartmentId = e[1]}"
          />
        </div>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="isAdviceDepartment = false">
          取 消
        </el-button>
        <el-button
          type="primary"
          @click="onAdviceDepartmentCom"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="管理子科室"
      :visible.sync="isManageDepartment"
      width="80%"
      center
    >
      <div class="content-box">
        <div
          class="department-input-box"
          style="justify-content: flex-start;"
        >
          <label class="label-name">平台科室名称：</label>
          <span>{{ manageObj.name }}</span>
        </div>
        <div
          class="department-input-box mgn-t30"
          style="justify-content: flex-start; align-items: baseline;"
        >
          <label class="label-name">子科室：</label>
          <div class="department-chlid-box">
            <template v-for="item in manageObj.childDepartmentNameList">
              <div
                :key="item"
                class="department-classify-item-box"
              >
                <span>{{ item }}</span>
                <el-button
                  class="del-btn"
                  type="text"
                  icon="el-icon-error"
                  @click="onDeleteChildDepartment(item)"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="isManageDepartment = false"
        >
          关 闭
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'DepartmentClassify',
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      name: '',
      activeName: 'noClassify',
      noClassifyList: [],
      platformClassifyList: [],
      allPlatformClassifyList: [],
      departmentName: '',
      isShowDelDepartment: false,
      inputVal: '',
      isEditDepartment: false,
      departmentVal: '',
      isAddDepartment: false,
      departmentList: [{value: 'neike', label: '内科'}],
      selectDepartmentVal: '',
      hospitalDepartmentName: '',
      isAdviceDepartment: false,
      noDepartmentName: '',
      isManageDepartment: false,
      manageObj: {},
      deleteType: 'plat',          // plat  -> 删除平台科室; platChild -> 删除平台子科室
      form: {
        oneLevelPlatformDepartmentId: '',
        twoLevelPlatformDepartmentId: ''
      },
      loading: false,
      platformClassifyLoading: false
    }
  },
  mounted() {
    this.onChangeTab()
  },
  methods: {
    ...mapActions('HospitalStore', [
      'platDepartmentList',
      'waitPlatDepartmentList',
      'platDepartmentAdd',
      'platDepartmentUpdata',
      'platDepartmentDelete',
      'platDepartmentDeleteBind'
    ]),
    handleCurrentChange(options) {
      this.pageNo = options
      if (this.activeName === 'alreadyClassify') {
        this.onGetPlatDepartmentList()
      } else {
        this.onGetWaitPlatDepartmentList()
      }
    },
    onChangeTab() {
      this.pageNo = 1
      this.pageSize = 10
      this.totalCount = 0
      if (this.activeName === 'alreadyClassify') {
        this.onGetPlatDepartmentList()
      } else {
        this.onGetWaitPlatDepartmentList()
        this.onGetAllPlatDepartmentList()
      }
    },
    onDeletePlatDepartment(options) {
      this.deleteType = 'plat'
      this.manageObj = options
      this.isShowDelDepartment = true
    },
    onDeletePlatDepartmentCom() {
      this.platDepartmentDelete({id: this.manageObj.id}).then(() => {
        this.$message({
          message: '删除成功',
          type: 'success'
        })
        this.onGetPlatDepartmentList()
        this.isShowDelDepartment = false

      })
    },
    onDeleteChildDepartment(options) {
      this.deleteType = 'platChild'
      this.hospitalDepartmentName = options
      this.isShowDelDepartment = true
    },
    onDeleteChildDepartmentCom() {
      const data = {
        platformDepartmentId: this.manageObj.id,
        hospitalDepartmentName: this.hospitalDepartmentName
      }
      this.platDepartmentDeleteBind(data).then(() => {
        this.$message({
          message: '删除成功,科室会重新回到未分配科室类别，请重新为科室分类',
          type: 'success'
        })
        this.onGetPlatDepartmentList()

        this.isShowDelDepartment = false

      })
    },
    onGetManageObj() {
      if (Object.keys(this.manageObj).length) {

        for (const item of this.platformClassifyList) {
          if (item.id === this.manageObj.id) {
            console.log(item, '1111111111111111111')
            this.manageObj = item
            return
          }
        }
      }
    },
    onDelDepartmentCon() {
      if (this.deleteType === 'plat') {
        this.onDeletePlatDepartmentCom()
      } else {
        this.onDeleteChildDepartmentCom()
      }

    },
    onEditDepartment(options) {
      this.inputVal = options.name
      this.manageObj = JSON.parse(JSON.stringify(options))
      this.isEditDepartment = true
    },
    onEditDepartmentCon() {
      if (!this.inputVal) return this.$message.error('请填写科室名称！')
      this.platDepartmentUpdata({id: this.manageObj.id, name: this.inputVal}).then(() => {
        this.$message({
          message: '编辑成功',
          type: 'success'
        })
        this.onGetPlatDepartmentList()
        this.isEditDepartment =  false
      }).catch(error => {
        this.$message.error(error.message)
      })
    },
    onAddDepartment() {
      this.isAddDepartment = true
    },
    onConfirmBtn() {
      if (!this.departmentVal) return this.$message.error('请填写科室名称！')
      this.isAddDepartment = false
      this.onPlatDepartmentAdd()
    },
    onAdviceDepartment(options) {
      console.log(options)
      this.noDepartmentName = options.name
      this.hospitalDepartmentName = options.name
      this.isAdviceDepartment = true
      this.form.oneLevelPlatformDepartmentId = options.oneLevelPlatformDepartmentId
      this.form.twoLevelPlatformDepartmentId = options.twoLevelPlatformDepartmentId
    },
    onAdviceDepartmentOne(options) {
      if (!options.suggestDepartmentId) {
        return this.$message({
          message: '没有建议归类，请手动选择',
          type: 'error'
        })
      }
      if (options.name === this.hospitalDepartmentName) {
        return this.$message({
          message: '正在归入建议科室，请耐心等待',
          type: 'warning'
        })
      }
      this.noDepartmentName = options.name
      this.hospitalDepartmentName = options.name
      this.form.oneLevelPlatformDepartmentId = options.suggestDepartmentId
      this.onAdviceDepartmentCom()
    },
    onAdviceDepartmentCom() {
      const params = {
        ...this.form,
        hospitalDepartmentName: this.hospitalDepartmentName
      }
      const message = this.$message({
        showClose: true,
        message: '任务处理时间较长,请耐心等待',
        type: 'warning',
        duration: '60000'
      })
      this.$axios({
        url: this.$API.platDepartmentBind,
        method: 'post',
        params
      }).then(() => {
        message.close()
        this.$message({
          message: '归类成功',
          type: 'success'
        })
        this.onGetWaitPlatDepartmentList()
      })

    },
    onManageDepartment(options) {
      this.manageObj = JSON.parse(JSON.stringify(options))
      this.isManageDepartment = true
    },
    onGetPlatDepartmentList() {
      const data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        name: this.name,
        isPage: true
      }
      this.platformClassifyLoading = true
      this.platDepartmentList(data).then(res => {
        this.platformClassifyList = res.data.map(item => {
          if (item.level === 1) {
            item.oneLevelDepartmentName = item.name
          }
          if (item.level === 2) {
            item.oneLevelDepartmentName = item.parentName
            item.twoLevelDepartmentName = item.name
          }
          return item
        })
        this.totalCount = res.totalCount
        this.onGetManageObj()
      }).finally(() => {
        this.platformClassifyLoading = false
      })
    },
    onGetAllPlatDepartmentList() {
      this.platDepartmentList({isPage: false}).then(res => {
        this.$nextTick(() => {
          this.allPlatformClassifyList = res.data
        })
      })
    },
    onGetWaitPlatDepartmentList() {
      const data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }
      this.loading = true
      this.waitPlatDepartmentList(data).then(res => {
        this.noClassifyList = res.data
        this.totalCount = res.totalCount
        this.isAdviceDepartment = false
      }).finally(() => {
        this.loading = false
      })
    },
    onPlatDepartmentAdd() {
      this.platDepartmentAdd({name: this.departmentVal}).then(() => {
        this.$message({
          message: '新增成功',
          type: 'success'
        })
        if (this.activeName === 'alreadyClassify') {
          this.onGetPlatDepartmentList()
        } else {
          this.onGetAllPlatDepartmentList()
        }
        this.isAddDepartment = false
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.add-classify-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.department-input-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.label-name {
  flex-shrink: 0;
  flex-grow: 0;
}
.cascader-popper {
  :global(.el-scrollbar__wrap) {
    min-height: 300px;
    .el-cascader-node {
      min-width: 240px;
    }
  }
}
.content-box {
  padding: 0 40px;
}
.mgn-t30 {
  margin-top: 30px;
}
.department-chlid-box {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}
.department-classify-item-box {
  min-width: 120px;
  height: 40px;
  line-height: 39px;
  padding: 0 25px;
  border: 1px solid #21610b;
  color: #21610b;
  text-align: center;
  border-radius: 20px;
  margin-bottom: 10px;
  margin-right: 10px;
  position: relative;
}
.del-btn {
  font-size: 24px;
  color: #969696;
  position: absolute;
  padding: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}
.pageination {
  text-align: right;
  margin-top: 30px;
}
.child-list-box {
  width: 100%;
  overflow: hidden;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
</style>
