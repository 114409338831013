import request from '@/utils/request'
import API from './api-types'

// 获取商品池列表
export default function getOrderDetail({ orderId }) {
  return request({
    url: API.getOrderDetail,
    method: 'get',
    params: { orderId }
  })
}
