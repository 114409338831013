<template>
  <div class="freeze-dialog-box">
    <el-dialog
      :title="title"
      v-bind="$attrs"
      :visible.sync="dialogFormVisible"
      v-on="$listeners"
    >
      <div class="steps-box">
        <div>
          <div class="out-line-left" :class="{ 'is-already': isUpload }" />
          <div class="pdg-t10" :class="{ 'is-already-text': isUpload }">1.上传Excel</div>
        </div>
        <div>
          <div class="out-line-right" :class="{ 'is-already': isCheck }" />
          <div class="pdg-t10" :class="{ 'is-already-text': isCheck }">2.检验数据</div>
        </div>
      </div>
      <!-- 上传Excel -->
      <div v-show="isUpload && !isCheck" class="upload-content-box">
        <div>
          <span>请</span>
          <el-button type="text" @click="onDownModal">点击此处</el-button>
          <span>下载{{ modelTitle }}模板，填写后完成上传文件</span>
        </div>
        <div class="vHtml" v-html="tipUpload" />
        <div class="upload-model-box">
          <span>上传文件：</span>
          <div>
            <el-upload
              :action="uploadApi"
              :headers="headers"
              name="file"
              :show-file-list="false"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xlsx, .xls"
              list-type="picture-card"
              :on-progress="onUploadProgress"
              :on-success="onUploadSuccess"
              :on-error="onUploadError"
            >
              <template>
                <div class="upload-cover">
                  <img v-if="uploadFlag" :src="imageUrl" alt="">
                  <img v-else-if="isSuccess" :src="require('@/assets/success.png')" alt="">
                  <img v-else :src="require('@/assets/fail.png')" alt="">
                  <span>{{ uploadText }}</span>
                </div>
              </template>
            </el-upload>
            <div v-show="uploadProgress" class="out-progress">
              <div class="in-progress" :style="{ width: uploadProgress + '%' }" />
            </div>
          </div>
        </div>
      </div>
      <!-- 检测数据 -->
      <div v-show="isUpload && isCheck">
        <div class="check-info-box">
          <div style="text-align: center;">
            <img
              :src="!isCheckPass?require('@/assets/fail.png'):require('@/assets/success.png')"
              alt=""
              style="width: 50px; margin-bottom: 25px;"
            >
          </div>
          <span>共导入{{ responseObj.totalCount || 0 }}条数据，其中{{ responseObj.successCount || 0 }}条有效数据校验成功，{{ responseObj.errorCount || 0 }}条有效数据校验失败。</span>
          <template v-if="responseObj.errorExcelUrl">
            <span>请</span>
            <el-button type="text" size="mini" @click="onDownError">下载文件</el-button>
            <span>查看校验结果，修改后可重新上传校验。</span>
          </template>
          <template v-if="isHasHelp">
            <span>如有疑问，请</span>
            <el-button type="text" size="mini" @click="onJumpHelp">点击此处</el-button>
            <span>查看解决方法。</span>
          </template>
          <div class="vHtml" v-html="tipContent" />
        </div>
      </div>
      <!-- 对应步骤按钮 -->
      <div v-show="isUpload && isCheck" slot="footer" class="dialog-footer upload-btn-box">
        <el-button v-if="!isCheckPass" type="primary" @click="onBackSteps">重新上传校验</el-button>
        <el-button v-else type="primary" @click="onConfirm">{{ btnText }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getToken } from '@/utils/auth'
import apiObj from '@/apis/apiDescription/api-objs.js'
export default {
  name: 'ImportExcelCheckDialog',
  components: {},
  props: {
    isShowPop: {
      type: Boolean,
      default: false
    },
    itemObj: {
      type: Object,
      default: () => {
        return {}
      }
    },
    modelType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      initModelType: {
        checkPushDoctor: {
          title: '推送校验',            // 弹窗标题
          modelTitle: '推送校验',           // 下载模版名称
          uploadApi: apiObj.uploadPushCheckModelFile.apiPath,    // 上传地址
          isHasHelp: false,                  // 是否有帮助中心
          url: '',   // 帮助中心地址
          downFn: apiObj.downPushCheckModelFile,       // 下载模版函数
          confirmFn: null,      // 导入函数
          completeFn: null,           // 完成函数
          isGoDownCenter: true,       // 是否去下载中心
          template: false,             // 是否有额外信息展示
          reqObj: {}                    // 额外的请求参数
        }
      },
      uploadData: {},
      isUpload: true,
      isCheck: false,
      isCheckPass: false,
      headers: {
        token: getToken()
      },
      uploadFlag: true,
      isSuccess: false,
      uploadProgress: 0,
      responseObj: {},
      file: null,
      loading: false,
      uploadedList: []
    }
  },
  computed: {
    title() {
      return this.initModelType[this.modelType]?.title || '批量导入'
    },
    modelTitle() {
      return this.initModelType[this.modelType]?.modelTitle || '批量'
    },
    uploadApi() {
      return this.initModelType[this.modelType]?.uploadApi || ''
    },
    isHasHelp() {
      return this.initModelType[this.modelType]?.isHasHelp
    },
    btnText() {
      switch (this.modelType) {
        default:
          return '我知道了'
      }
    },
    dialogFormVisible: {
      get() {
        return this.isShowPop
      },
      set(val) {
        if (this.loading) return true
        this.$emit('on-close', val)
      }
    },
    imageUrl() {
      return require('@/assets/file_excel.png')
    },
    uploadText() {
      return '上传文件'
    },
    tipUpload() {
      switch (this.modelType) {
        case 'checkPushDoctor': {
          let str = `
            <span>备注：建议推送前，先校验用户的最近推送时间，避免过度骚扰用户</span>
          `
          return str
        }
        default:
          return '若无异议，请点击“开始导入”'
      }
    },
    tipContent() {
      switch (this.modelType) {
        default:
          return ''
      }
    }
  },
  methods: {
    // ---------- 工具 ------------------
    onDownFunction(funApi) {
      // 下载模板
      const params = this.initModelType[this.modelType].reqObj || {}
      funApi &&
        this.$requireApi(funApi, params).then(
          res => {
            if (this.initModelType[this.modelType].isGoDownCenter) {
              this.$message.success(res.data)
            } else {
              window.open(res.data)
            }
          },
          rea => {
            this.$message({
              message: rea.message,
              type: 'error'
            })
          }
        )
    },
    onUploadConfirm(funApi, data) {
      //  导入表格确认
      if (this.loading) return
      this.loading = true
      funApi &&
        this.$requireApi(funApi, data).then(
          res => {
            this.loading = false
            this.$emit('on-complete', res)
          },
          rea => {
            this.loading = false
            this.$message({
              message: rea.message,
              type: 'error'
            })
          }
        )
    },
    // ----------------------------------
    onJumpHelp() {
      // 跳转帮助中心
      let url = ''
      if (this.initModelType[this.modelType]?.url) {
        url = this.$router.resolve(this.initModelType[this.modelType]?.url)
      }
      window.open(url.href, '_blank')
    },
    onUploadProgress(event, file) {
      // 文件本地上传进度
      this.uploadFlag = true
      this.$nextTick(() => {
        this.uploadProgress = file.percentage.toFixed(0) * 1
      })
    },
    onUploadSuccess(response, file) {
      // 校验结果
      if (response.data) {
        this.isSuccess = true
        this.uploadFlag = false
        this.isCheck = true
        this.responseObj = response.data
        this.isCheckPass = !(response.data.errorCount > 0)
        this.file = file.raw
      } else {
        this.$message({
          message: response.message,
          type: 'error'
        })
        this.onUploadError()
      }
    },
    onUploadError() {
      // 校验错误显示
      this.isSuccess = false
      this.uploadFlag = false
      this.isCheckPass = false
    },
    onDownModal() {
      // 下载模板
      const fn = this.initModelType[this.modelType]?.downFn
      fn && this.onDownFunction(fn)
    },
    onDownError() {
      // 下载失败模板
      if (this.responseObj.errorExcelUrl) {
        window.open(this.responseObj.errorExcelUrl)
      }
    },
    onBackSteps() {
      // 重新上传
      this.isUpload = true
      this.isImport = false
      this.isSuccess = false
      this.uploadFlag = true
      this.isCheck = false
      this.responseObj = {}
      this.isCheckPass = false
      this.file = null
      this.loading = false
      this.uploadProgress = 0
    },
    onConfirm() {
      // 导入表格确认
      if (!this.responseObj?.successCount) {
        this.onCloseModel()
        return this.$message.error('请按模板填写有效数据')
      }
      const fn = this.initModelType[this.modelType]?.confirmFn
      let data = null
      if (['addOrg', 'addStaff', 'allotKnowledge'].includes(this.modelType)) {
        data = this.file
      } else {
        data = {
          dataId: this.responseObj.dataId || ''
        }
      }
      fn && this.onUploadConfirm(fn, data)
      !fn && this.$emit('on-complete', data)
    },
    onCloseModel() {
      // 完成
      this.dialogFormVisible = false
    }
  }
}
</script>
<style lang="scss">
.upload-model-box .el-upload--picture-card {
  width: 160px;
  height: 90px;
}
.upload-btn-box .el-upload--picture-card {
  width: auto;
  height: auto;
  line-height: inherit;
  border: none;
}
</style>
<style lang="scss" scoped>
.freeze-dialog-box {
  :deep(.el-dialog) .el-dialog__header {
    background-color: #fff;
    text-align: left;
    border-bottom: 1px solid #e1e1e1;
    .el-dialog__title {
      color: #0f0f0f;
      font-size: 14px;
    }
    .el-dialog__close {
      color: #909399 !important;
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.center {
  text-align: center;
}
.steps-box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.out-line-left {
  border-radius: 100px 0 0 100px;
}
.out-line-left,
.out-line-center,
.out-line-right {
  width: 140px;
  height: 10px;
  background-color: #d8d8d8;
  margin: 0 5px;
}
.out-line-right {
  border-radius: 0 100px 100px 0;
}
.is-already {
  background-color: #30c691;
}
.is-already-text {
  color: #30c691;
}
.upload-content-box {
  padding: 50px;
  width: fit-content;
  margin: auto;
  color: #5a5a5a;
}
.upload-model-box {
  display: flex;
  padding-top: 16px;
}
.upload-cover {
  width: 100%;
  height: 100%;
  background-color: #e1e1e1;
  color: #787878;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  img {
    width: 30px;
  }
  span {
    height: 30px;
    line-height: 30px;
  }
}
.out-progress {
  width: 160px;
  height: 6px;
  margin-top: 10px;
  background: #e1e1e1;
  border-radius: 20px;
  overflow: hidden;
}
.in-progress {
  height: 100%;
  background: #30c691;
  border-radius: 20px;
}
.check-info-box {
  padding: 60px 100px;
  color: #5a5a5a;
  text-align: center;
}
.vHtml {
  color: #5a5a5a;
  text-align: center;
  margin-top: 10px;
}
.import-info {
  text-align: center;
  padding: 50px;
}
.pdg-t10 {
  padding-top: 10px;
}
.mgn-r20 {
  margin-right: 20px;
}
</style>
