<template>
  <el-main>
    <div class="course-title">
      课程销售数据：
    </div>
    <div class="course-count content-data">
      <div class="data-item">
        <div class="number">
          {{ topData.totalDealAmount }}
        </div>
        <div>总成交额（元）</div>
      </div>
      <div class="data-item">
        <div class="number">
          {{ topData.totalSaleCount }}
        </div>
        <div>累计售出（次）</div>
      </div>
      <div class="data-item">
        <div class="number">
          {{ topData.usedRMB }}
        </div>
        <div>使用现金（元）</div>
      </div>
      <div class="data-item">
        <div class="number">
          {{ topData.usedGift }}
        </div>
        <div>使用积分（个）</div>
      </div>
      <div class="data-item">
        <div class="number">
          {{ topData.funBeanPercent }}
        </div>
        <div>趣豆比例（%）</div>
      </div>
      <div class="data-item">
        <div class="number">
          {{ topData.purchasedDoctorCount }}
        </div>
        <div>购买的医生数（人）</div>
      </div>
    </div>
    <div class="course-title">
      趣豆累计投放：
    </div>
    <div class="course-count content-data">
      <div class="data-item">
        <div class="number">
          {{ topData.totalPutIn }}
        </div>
        <div>累计投入（颗）</div>
      </div>
      <div class="data-item">
        <div class="number">
          {{ topData.hasPutIn }}
        </div>
        <div>已发放（颗）</div>
      </div>
      <div class="data-item">
        <div class="number">
          {{ topData.notPutIn }}
        </div>
        <div>未发放（颗）</div>
      </div>
      <div class="data-item">
        <div class="number">
          {{ topData.notUsed }}
        </div>
        <div>未使用（颗）</div>
      </div>
      <div class="data-item">
        <div class="number">
          {{ topData.hasUsed }}
        </div>
        <div>已使用（颗）</div>
      </div>
      <div class="data-item">
        <div class="number">
          {{ topData.receivedDoctorCount }}
        </div>
        <div>领取的医生数（人）</div>
      </div>
    </div>
    <div class="add-button">
      <div class="course-title">
        趣豆投放批次：
      </div>
      <div>
        <el-button @click="showDirectionalPut">
          + 定向投放
        </el-button>
        <el-button
          type="primary"
          @click="showNewPut"
        >
          + 新增投放
        </el-button>
      </div>
    </div>
    <div
      ref="table"
      class="prod-panel"
    >
      <el-table
        :data="tableData"
        border
        style="width: 100%;"
      >
        <el-table-column
          prop="putInTime"
          label="投放时间"
        >
          <template slot-scope="scoped">
            {{ $dayjs(scoped.row.putInTime).format('YYYY-MM-DD HH:mm:ss') }}
          </template>
        </el-table-column>
        <el-table-column
          prop="putInAmount"
          label="投放总额"
        />
        <el-table-column
          prop="putInRule"
          label="投放名单"
        >
          <template slot-scope="scoped">
            <div v-if="scoped.row.putInRule === 1">
              {{ '所有医生' }}
            </div>
            <el-button
              v-if="scoped.row.putInRule === 2"
              type="text"
              @click="uploadDoctorList(scoped.row)"
            >
              下载
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-size="limit"
        layout="total,prev, pager, next, jumper"
        :total="total"
        class="pagination"
        @current-change="handleCurrentChange"
      />
    </div>
    <div class="course-title">
      限定课参与医生管理：
    </div>
    <div class="rules">
      参与医生数：{{ topData.limitedCourseDoctorCount }}
      <el-button
        style="margin-left: 20px;"
        @click="showAddNewDoctor"
      >
        + 新增参与的医生
      </el-button>
    </div>
    <div class="course-title">
      用户激励规则：
    </div>
    <el-row
      v-for="item in rule"
      :key="item.id"
      type="flex"
    >
      <div
        v-if="item.type !== 6 && item.type !== 7"
        class="rules"
      >
        <span
          class="label-limit"
        >{{ item.type | labelFilter }}</span>
        <el-input
          v-model="item.param"
          type="number"
          :min="1"
          class="input"
        />
        {{ item.type | endFilter }}
      </div>
      <div
        v-if="item.type === 6"
        class="rules"
      >
        {{ item.type | labelFilter }}
        <el-switch
          v-model="item.param"
          class="input"
          :active-value="1"
          :inactive-value="0"
        />
        {{ item.type | endFilter }}
      </div>
      <div v-if="item.type === 7">
        <div class="course-title">
          恶意刷单风控：
        </div>
        <div class="rules">
          某个讲师所有课程销售收入中，趣豆部分超过KPI：
          <el-input
            v-model="item.param"
            class="input"
            type="number"
            :min="1"
          />
          %触发放刷单风控，活动课程自动变为普通课程，不可以使用趣豆
        </div>
      </div>
    </el-row>
    <div class="footer">
      <!--            <el-button @click="cancel">取消</el-button>-->
      <el-button
        type="primary"
        @click="saveRules"
      >
        保存
      </el-button>
    </div>
    <el-dialog
      title="新增投放批次"
      :visible.sync="newDialogVisible"
      width="32%"
      center
    >
      <div class="">
        <div>
          投放总数：
          <el-input
            v-model="putInAmount"
            class="put-input"
            type="number"
            :min="1"
          />
        </div>
        <div>所有医生，都可以通过规则获得趣豆</div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="cancelModel">取 消</el-button>
        <el-button
          type="primary"
          @click="onSaveNewPut"
        >确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="新增定向投放"
      :visible.sync="directionalDialogVisible"
      width="34%"
      center
    >
      <div class="title-limit">
        投放名单：
      </div>
      <el-upload
        class="upload-xsl"
        action=""
        :multiple="false"
        :on-exceed="handleExceed"
        :before-upload="beforeUpload"
        :limit="1"
        accept=".xls,.xlsx"
        style="display: inline-block;"
      >
        <el-button
          type="primary"
          style="margin-bottom: 10px;"
        >
          {{ uploadSuccess ? '继续上传' : '上传投放名单' }}
        </el-button>
      </el-upload>
      <el-button
        type="text"
        style="margin-left: 10px;"
      >
        <a
          href="./doctor_excel.xlsx"
          download="名单模版"
        >名单模版下载</a>
      </el-button>
      <div
        v-if="uploadSuccess"
        class="put-result"
      >
        <div>{{ successDoctorList.length }}个医生校验成功</div>
        <div>{{ failDoctorList.length }}个医生校验失败 <span v-if="failDoctorList.length">，原因：手机号未注册</span></div>
        <div
          v-if="failDoctorList.length"
          style="color: #008075; font-weight: bold; cursor: pointer;"
          @click="onDownloadFailDoctor"
        >
          下载失败医生名单
        </div>
      </div>
      <div>
        <div class="title-limit">
          每个医生投放数：
        </div>
        <el-input
          v-model="putInPerDoctorCount"
          type="number"
          :min="1"
          class="put-input"
        />
      </div>
      <div>
        <div class="title-limit">
          投放规则：
        </div>
        直接发放到名单上医生的趣豆余额
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="cancelModel">取 消</el-button>
        <el-button
          type="primary"
          @click="onSaveDirectionalPut"
        >确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="新增限定课程参与的医生"
      :visible.sync="newDoctorVisible"
      width="32%"
      center
    >
      <div class="title-limit">
        投放名单：
      </div>
      <el-upload
        class="upload-xsl"
        action=""
        :multiple="false"
        :on-exceed="handleDoctorExceed"
        :before-upload="beforeDoctorUpload"
        :limit="1"
        accept=".xls,.xlsx"
        style="display: inline-block;"
      >
        <el-button
          type="primary"
          style="margin-bottom: 10px;"
        >
          {{ uploadSuccess ? '继续上传' : '上传投放名单' }}
        </el-button>
      </el-upload>
      <el-button
        type="text"
        style="margin-left: 10px;"
      >
        <a
          href="./doctor_excel.xlsx"
          download="名单模版"
        >名单模版下载</a>
      </el-button>
      <div
        v-if="uploadSuccess"
        class="put-result"
      >
        <div>{{ successDoctorList.length }}个医生校验成功</div>
        <div>{{ failDoctorList.length }}个医生校验失败 <span v-if="failDoctorList.length">，原因：手机号未注册</span></div>
        <div
          v-if="failDoctorList.length"
          style="color: #008075; font-weight: bold; cursor: pointer;"
          @click="onDownloadFailDoctor"
        >
          下载失败医生名单
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="cancelModel">取 消</el-button>
        <el-button
          type="primary"
          @click="onAddNewDoctor"
        >确定新增</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import XLSX from 'xlsx'
import { JsonToExcel } from '@/utils/util'

export default {
  name: 'SpeakerCourseIndex',
  filters: {
    labelFilter(type) {
      switch (type) {
        case 1:
          return '新用户注册奖励：'
        case 2:
          return '用户每日登录奖励：'
        case 3:
          return '用户邀请奖励：'
        case 4:
          return '学术任务奖励：奖励本次学术任务医生获得积分数额'
        case 5:
          return '购买课程赠送积分：'
        case 6:
          return '奖励弹窗是否弹出：'
      }
    },
    endFilter(type) {
      switch (type) {
        case 1:
          return '颗'
        case 2:
          return '颗'
        case 3:
          return '颗'
        case 4:
          return '%数量的趣豆'
        case 5:
          return '%的积分，可以在积分商城兑换商品时使用'
        case 6:
          return '活动课程不足时，不弹出弹窗可以淡化趣豆和积分商城'
      }
    }
  },
  data() {
    return {
      tableData: [],
      fileList: [],
      successDoctorList: [],
      failDoctorList: [],
      page: 1,
      limit: 20,
      total: 0,
      newDialogVisible: false,
      directionalDialogVisible: false,
      newDoctorVisible: false,
      uploadSuccess: false,
      rule: [],
      ruleList: [],
      putInAmount: '',
      putInPerDoctorCount: '',
      doctorList: []
    }
  },
  computed: {
    ...mapGetters(['topData'])
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions(['funBeanPutList', 'knowledgeIndexData', 'funBeanRule', 'updateFunBeanRule', 'addPutFunBean', 'addPutTargetedFunBean', 'checkDoctorList', 'addNewDoctor', 'downloadDoctorList']),
    init() {
      this.getFunBeanPutList()
      this.funBeanRule().then(res => {
        this.rule = res
      })
      this.knowledgeIndexData()
    },
    getFunBeanPutList() {
      this.funBeanPutList().then(res => {
        this.total = res.totalCount
        this.tableData = res.data
      })
    },
    handleCurrentChange() {
    },
    showDirectionalPut() {
      this.directionalDialogVisible = true
    },
    showNewPut() {
      this.newDialogVisible = true
    },
    showAddNewDoctor() {
      this.newDoctorVisible = true
    },
    onSaveNewPut() {
      if (!this.putInAmount) {
        return this.$message.error('请输入投放总数')
      }
      if (!(/^[1-9]\d*$/.test(this.putInAmount))) {
        return this.$message.error('投放总数需为大于0的整数')
      }
      this.addPutFunBean({ putInAmount: this.putInAmount }).then(() => {
        this.$message.success('投放成功')
        this.cancelModel()
        this.getFunBeanPutList()
        this.knowledgeIndexData()
      })
    },
    onSaveDirectionalPut() {
      if (this.successDoctorList.length === 0) {
        return this.$message.error('请上传要投放的医生名单')
      }
      if (!this.putInPerDoctorCount) {
        return this.$message.error('请输入每个医生投放数')
      }
      if (!(/^[1-9]\d*$/.test(this.putInPerDoctorCount))) {
        return this.$message.error('每个医生投放数需为大于0的整数')
      }
      this.addPutTargetedFunBean({
        doctorNameAndMobileList: this.successDoctorList,
        putInPerDoctorCount: this.putInPerDoctorCount
      }).then(() => {
        this.$message.success('投放成功')
        this.cancelModel()
        this.getFunBeanPutList()
      })
    },
    onAddNewDoctor() {
      if (this.successDoctorList.length === 0) {
        return this.$message.error('请上传要投放的医生名单')
      }
      this.addNewDoctor(this.successDoctorList).then(() => {
        this.$message.success('操作成功')
        this.cancelModel()
        this.knowledgeIndexData()
      })
    },
    cancelModel() {
      this.successDoctorList = []
      this.failDoctorList = []
      this.uploadSuccess = false
      this.newDialogVisible = false
      this.directionalDialogVisible = false
      this.newDoctorVisible = false
    },
    saveRules() {
      let showError = false
      this.rule.map(v => {
        if (v.param && v.type !== 6 && !(/^[1-9]\d*$/.test(v.param))) {
          showError = true
        }
      })
      if (showError) {
        return this.$message.error('规则可以不设置或设置为正整数')
      }
      this.updateFunBeanRule(this.rule).then(() => {
        this.$message.success('规则设置成功')
      })
    },
    onDownloadFailDoctor() {
      this.$message.info('正在努力下载中，请耐心等待')
      const json = this.failDoctorList.map(item => {
        return [
          item.stringVal1,
          item.stringVal2
        ]
      })
      const header = ['医生姓名', '医生手机号']
      JsonToExcel('失败医生名单', [header, ...json])
    },
    handleExceed() {
      this.$message({
        type: 'warning',
        message: '超出最大上传文件数量的限制！'
      })
      return
    },
    beforeUpload(file) {
      // const self = this
      // 用FileReader来读取
      const fileReader = new FileReader()
      fileReader.onload = ev => {
        try {
          const data = ev.target.result
          const workbook = XLSX.read(data, {
            type: 'binary'
          }) // 以二进制流方式读取得到整份excel表格对象
          let persons = [] // 存储获取到的数据
          // 表格的表格范围，可用于判断表头是否数量是否正确
          let fromTo = ''
          // 遍历每张表读取
          for (const sheet in workbook.Sheets) {
            // eslint-disable-next-line no-prototype-builtins
            if (workbook.Sheets.hasOwnProperty(sheet)) {
              fromTo = workbook.Sheets[sheet]['!ref']
              console.log(fromTo)
              persons = persons.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]))
              break // 如果只取第一张表，就取消注释这行
            }
          }
          this.doctorList = persons.map(item => {
            const newData = [{
              stringVal1: (item['医生姓名'] || '').toString(),
              stringVal2: (item['医生手机号'] || '').toString()
            }]
            this.uploadSuccess = true
            this.getCheckDoctorList(newData)
          })
        } catch (e) {
          console.log(e)
        }
      }
      // 以二进制方式打开文件
      fileReader.readAsBinaryString(file)
      return false
    },
    getCheckDoctorList(list) {
      this.checkDoctorList(list).then(res => {
        const resData = res
        this.successDoctorList = [...resData.successDoctorList, ...this.successDoctorList]
        this.failDoctorList = [...resData.failDoctorList, ...this.failDoctorList]
        this.$message.success('上传成功')
      })
    },
    handleDoctorExceed() {
      this.$message({
        type: 'warning',
        message: '超出最大上传文件数量的限制！'
      })
      return
    },
    beforeDoctorUpload(file) {
      // const self = this
      // 用FileReader来读取
      const fileReader = new FileReader()
      fileReader.onload = ev => {
        try {
          const data = ev.target.result
          const workbook = XLSX.read(data, {
            type: 'binary'
          }) // 以二进制流方式读取得到整份excel表格对象
          let persons = [] // 存储获取到的数据
          // 表格的表格范围，可用于判断表头是否数量是否正确
          let fromTo = ''
          // 遍历每张表读取
          for (const sheet in workbook.Sheets) {
            // eslint-disable-next-line no-prototype-builtins
            if (workbook.Sheets.hasOwnProperty(sheet)) {
              fromTo = workbook.Sheets[sheet]['!ref']
              console.log(fromTo)
              persons = persons.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]))
              break // 如果只取第一张表，就取消注释这行
            }
          }
          this.doctorList = persons.map(item => {
            const newData = [{
              stringVal1: (item['医生姓名'] || '').toString(),
              stringVal2: (item['医生手机号'] || '').toString()
            }]
            this.uploadSuccess = true
            this.getCheckDoctorList(newData)
          })
        } catch (e) {
          console.log(e)
        }
      }
      // 以二进制方式打开文件
      fileReader.readAsBinaryString(file)
      return false
    },
    uploadDoctorList(row) {
      this.downloadDoctorList({ putInRecordId: row.putInRecordId }).then(res => {
        this.$message.success(res)
      })
    }
  }
}
</script>

<style scoped>
.course-title {
  margin: 10px 0 20px 0;
  font-weight: bold;
}
.course-count {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}
.content-data {
  margin: 40px 50px;
}
.add-button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.input {
  width: 100px;
  margin: 0 5px;
}
.rules {
  margin: 10px 50px;
}
.number {
  font-weight: bold;
  font-size: 40px;
}
.put-input {
  width: 200px;
  margin-bottom: 10px;
}
.title-limit {
  display: inline-block;

  /* min-width: 110px; */
}
.data-item {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.put-result {
  margin-left: 110px;
  margin-bottom: 10px;
}
.pagination {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}
.footer {
  margin: 10px 100px;
}
</style>
