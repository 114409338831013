<template>
  <div class="page-body-box">
    <el-row :gutter="12">
      <el-col :span="4">
        <el-select
          v-model="searchData.status"
          class="mgn-r20"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-input
          v-model="searchData.realName"
          placeholder="查询姓名"
          clearable
        />
      </el-col>
      <el-col :span="4">
        <el-input
          v-model="searchData.mobile"
          placeholder="查询手机号"
          clearable
        />
      </el-col>
    </el-row>
    <div class="table-box">
      <el-table
        :data="thirdSpeakerList"
        border
        style="width: 100%;"
      >
        <el-table-column
          fixed="left"
          label="操作"
          width="150"
        >
          <template slot-scope="scope">
            <el-button
              class="default"
              :class="{success:scope.row.status === 0, default:scope.row.status === 1}"
              type="text"
              @click="handleClick(scope.row)"
            >
              {{ scope.row.status === 0 ? '处理' : '已处理' }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="realName"
          min-width="180"
          label="真实姓名"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.realName || '—' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          min-width="180"
          label="医生名"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.name || '—' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="mobile"
          min-width="150"
          label="注册手机号"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.mobile || '—' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="thirdPartyValue"
          min-width="150"
          label="人力家签约手机号"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.thirdPartyValue || '—' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="idCardNo"
          min-width="180"
          label="身份证号"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.idCardNo || '—' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="bankCardNo"
          min-width="180"
          label="银行卡号"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.bankCardNo || '—' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="speakerRenlijiaStatus"
          min-width="180"
          label="认证状态"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.speakerRenlijiaStatus === null ? '未提交认证' :scope.row.speakerRenlijiaStatus === 0 ? '审核中' : scope.row.speakerRenlijiaStatus === 1 ? '审核通过' : '审核失败' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          min-width="180"
          label="处理状态"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.status === 0 ? ' 未处理' : '已处理' }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination-com
      :current-page="searchData.page"
      :page-size="searchData.limit"
      :total="thirdSpeakerListTotal"
      @current-change="onCurrentChange"
    />
    <el-dialog
      title="处理工单"
      :visible.sync="isShowAuditDialog"
      :close-on-click-modal="false"
    >
      是否要将此条记录变为已处理？
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="isShowAuditDialog = false">
          取 消
        </el-button>
        <el-button
          type="primary"
          @click="onsubmit"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import paginationCom from '@/components/common/paginationCom'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'SpeakerWorkOrder',
  components: {
    paginationCom
  },
  data() {
    return {
      // ------------------- selectList ---------------
      options: [{
        value: '',
        label: '请选择工单状态'
      }, {
        value: '1',
        label: '已处理'
      }, {
        value: '0',
        label: '待处理'
      }],
      // -------------------- 搜索、页数 -----------------
      timerId: null,
      searchData: {
        realName: '',
        mobile: '',
        status: '',
        thirdParty: 2,
        page: 1,
        limit: 10
      },
      isShowAuditDialog: false,
      status: 1,
      orderId: ''
    }
  },
  computed: {
    ...mapGetters('LiveAudit', ['thirdSpeakerList', 'thirdSpeakerListTotal']),
    initSearchData() {
      return JSON.stringify(this.searchData)
    }
  },
  watch: {
    initSearchData: {
      handler(newVal, oldVal) {
        if (JSON.parse(newVal).page === JSON.parse(oldVal).page) {
          this.$set(this.searchData, 'page', 1)
        }
        clearTimeout(this.timerId)
        this.timerId = setTimeout(() => {
          this.getThirdSpeakerList(this.searchData)
        }, 500)
      },
      deep: true
    }
  },
  mounted() {
    this.getThirdSpeakerList(this.searchData)
  },
  methods: {
    ...mapActions('LiveAudit', ['getThirdSpeakerList', 'getLiveDetail', 'dealWorkOrder']),
    onSearch() {
      if (this.searchData.page === 1) {
        this.getThirdSpeakerList(this.searchData)
      }
      this.$set(this.searchData, 'page', 1)
    },
    handleClick(row) {
      if (row.status === 1)
        return
      this.orderId = row.id
      this.isShowAuditDialog = true
    },
    onCurrentChange(options) {
      this.$set(this.searchData, 'page', options)
    },
    onClose() {
      this.isShowAuditDialog = false
    },
    onsubmit() {
      const Data = {
        orderId: this.orderId,
        status: 1
      }
      this.isShowAuditDialog = false
      this.dealWorkOrder(Data).then(() => {
        this.getThirdSpeakerList(this.searchData)
      }, rea => {
        this.$message.error(rea.message)
      })
    }
  }
}
</script>
<style>
.warnMessage .el-message-box__content {
  font-size: 16px;
  color: red;
}
</style>
<style scoped>
.table-box {
  margin-top: 60px;
}
.default {
  color: #606266;
}
.success {
  color: #008075;
}
</style>
