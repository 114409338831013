<template>
  <el-main>
    <el-button
      type="text"
      style="float: right;"
      icon="el-icon-back"
      @click="goBack"
    >
      返回上一页
    </el-button>
    <el-descriptions
      title="基础信息"
      :column="2"
    >
      <el-descriptions-item label="姓名">
        {{ speakerDetail.name }}
      </el-descriptions-item>
      <el-descriptions-item label="职称">
        {{ speakerDetail.titleName }}
      </el-descriptions-item>
      <el-descriptions-item label="科室">
        {{ speakerDetail.department }}
      </el-descriptions-item>
      <el-descriptions-item label="医院">
        {{ speakerDetail.hospital }}
      </el-descriptions-item>
      <el-descriptions-item
        v-if="!showIntro"
        label="简介"
      >
        {{ speakerDetail.intro }}
        <span
          class="editor"
          @click="showIntro = true"
        > 编辑</span>
      </el-descriptions-item>
      <el-descriptions-item
        v-if="showIntro"
        label="简介"
      >
        <el-input
          v-model="speakerDetail.intro"
          type="textarea"
          autosize
          maxlength="500"
          style="max-width: 300px;"
        />
        <span
          class="editor save-text-editor"
          @click="updateInfo('0')"
        > 保存</span>
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions
      title="收入信息"
      :column="2"
      class="descriptions"
    >
      <el-descriptions-item label="账户余额">
        <span style="font-weight: bold;">{{ speakerDetail.accountAmount || 0 }}</span>
      </el-descriptions-item>
      <el-descriptions-item
        v-if="!showKpi"
        label="KPI"
      >
        <el-tooltip
          class="item"
          effect="dark"
          content="签约讲师月营收指导目标"
          placement="top-start"
        >
          <i class="el-icon-question" />
        </el-tooltip>
        {{ speakerDetail.kpi }}
        <span
          class="editor"
          @click="showKpi = true"
        > 修改</span>
      </el-descriptions-item>
      <el-descriptions-item
        v-if="showKpi"
        label="KPI"
        class="save-intro-kpi"
      >
        <el-input
          v-model="speakerDetail.kpi"
          type="number"
          :min="0"
          style="width: 150px;"
        />
        <span
          class="editor save-editor"
          @click="updateInfo('1')"
        > 保存</span>
      </el-descriptions-item>
    </el-descriptions>
    <div class="income-list">
      <div class="income-item">
        <div class="income-count">
          {{ speakerDetail.totalIncome }}
        </div>
        <div>累计收入（元）</div>
      </div>
      <div class="income-item">
        <div class="income-count">
          {{ speakerDetail.totalPurchaseFee }}
        </div>
        <div>平台采购（元）</div>
      </div>
      <div class="income-item">
        <div class="income-count">
          {{ speakerDetail.courseSaleAmount }}
        </div>
        <div>课程销售（元）</div>
      </div>
      <div class="income-item">
        <div class="income-count">
          {{ speakerDetail.totalSaleCount }}
        </div>
        <div>累计售出（次）</div>
      </div>
      <div class="income-item">
        <div class="income-count">
          {{ speakerDetail.currentMonthIncome }}
        </div>
        <div>当月累计收入（元）</div>
      </div>
      <div class="income-item">
        <div class="income-count">
          {{ speakerDetail.currentMonthPurchase }}
        </div>
        <div>当月平台采购（元）</div>
      </div>
      <div class="income-item">
        <div class="income-count">
          {{ speakerDetail.currentMonthSale }}
        </div>
        <div>当月课程销售（元）</div>
      </div>
      <div class="income-item">
        <div class="income-count">
          {{ speakerDetail.currentMonthTotalSaleCount }}
        </div>
        <div>当月累计售出（次）</div>
      </div>
    </div>
    <el-descriptions
      title="所有课程"
      class="descriptions"
    />
    <el-table
      :data="speakerCourseList"
      border
      center
      style="width: 100%;"
    >
      <el-table-column
        prop="date"
        label="课程封面"
        min-width="100"
      >
        <template slot-scope="scoped">
          <img
            :src="scoped.row.coverUrl.middle"
            alt=""
            style="width: 100px; height: 100px;"
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="courseName"
        label="课程名称"
        min-width="120"
      />
      <el-table-column
        prop="address"
        label="课程类型"
      >
        <template slot-scope="scoped">
          <div class="income-item">
            <div>{{ scoped.row.courseNum === 1 ? '单节课程' : '系列课程' }}</div>
            <div v-if="scoped.row.courseNum !== 1">
              {{ '（共' + scoped.row.courseNum + '节）' }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="upShelfTime"
        label="上架时间"
        min-width="120"
      >
        <template slot-scope="scoped">
          {{
            scoped.row.upShelfTime ? $dayjs(scoped.row.upShelfTime).format('YYYY-MM-DD HH:mm:ss') : '/'
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="purchaseAmount"
        label="平台采购"
        min-width="150"
      >
        <template slot-scope="scoped">
          <div class="income-item">
            <div>
              {{
                scoped.row.purchaseStatus === 1 ? scoped.row.purchaseAmount + '元' : scoped.row.purchaseStatus === 0 ? '待采购' : '已拒绝'
              }}
            </div>
            <div v-if="scoped.row.purchaseStatus === -1">
              (拒绝原因：{{ scoped.row.rejectReason }})
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="saleCount"
        label="课程销售"
        min-width="100"
      >
        <template slot-scope="scoped">
          {{
            scoped.row.purchaseStatus === 1 && scoped.row.saleCount ? scoped.row.saleCount + '次（' + (scoped.row.totalSaleAmount || 0) + '元）' : '/'
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="salePrice"
        label="销售单价（元）"
      >
        <template slot-scope="scoped">
          {{ scoped.row.salePrice ? scoped.row.salePrice + '元' : '/' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="address"
        label="状态"
      >
        <template slot-scope="scoped">
          {{ scoped.row.upShelfStatus === 1 ? '已上架' : '未上架' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="weight"
        label="权重"
      />
      <el-table-column
        prop="address"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            @click.native.prevent="checkDetail(scope.$index, speakerCourseList)"
          >
            {{ scope.row.purchaseStatus === 1 ? '查看' : '编辑' }}
          </el-button>
          <el-button
            v-if="scope.row.purchaseStatus !== 1"
            type="text"
            @click.native.prevent="onClickDel(scope)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="page"
      :page-size="limit"
      layout="total, prev, pager, next, jumper"
      :total="totalCount"
      class="el-pagination"
      @current-change="handleCurrentChange"
    />
  </el-main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SpeakerDetail',
  beforeRouteLeave(to, from, next) {
    if (to.name == 'SpeakerCourseDetail') {
      this.isSavePage = true
    } else {
      this.isSavePage = false
    }
    next()
  },
  data() {
    return {
      tableData: [],
      speakerDetail: {},
      page: 1,
      limit: 20,
      showIntro: false,
      showKpi: false,
      isSavePage: false,
      speakerId: ''
    }
  },
  computed: {
    ...mapGetters(['speakerCourseList', 'totalCount'])
  },
  activated() {
    if (this.isSavePage) {
      this.getList()
    } else {
      this.speakerId = this.$route.params.speakerId
      this.init()
    }
  },
  mounted() {
  },
  methods: {
    ...mapActions(['getSpeakerDetail', 'deleteSpeakerCourse', 'getSpeakerCourseList', 'updateIntroOrKpi']),
    init() {
      this.showIntro = false
      this.showKpi = false
      this.getSpeakerDetail({ speakerId: this.speakerId }).then(res => {
        this.speakerDetail = res
      })
      this.getList()
    },
    getList() {
      this.getSpeakerCourseList({ speakerId: this.speakerId, page: this.page, limit: this.limit }).then(() => {
      })
    },
    checkDetail(index, data) {
      this.$router.push({ name: 'SpeakerCourseDetail', params: { courseId: data[index].courseId } })
    },
    onClickDel(options) {
      const row = options.row
      this.$confirm('确定删除该课程吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const Data = {
            speakerId: this.speakerId,
            courseId: row.id
          }
          this.deleteSpeakerCourse(Data).then(() => {
            this.getSpeakerCourseList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    updateInfo(type) {
      if (type === '1' && !/^[0-9]\d*$/.test(this.speakerDetail.kpi)) {
        return this.$message.error('KPI需为非负整数')
      }
      this.updateIntroOrKpi({
        speakerId: this.speakerId,
        intro: this.speakerDetail.intro,
        kpi: this.speakerDetail.kpi
      }).then(() => {
        this.$message.success('修改成功')
        if (type === '0') {
          this.showIntro = false
        } else {
          this.showKpi = false
        }
      })
    },
    handleCurrentChange(val) {
      this.page = val
      this.getList()
    },
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.income-list {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px;
  margin-top: 30px;
}
.income-item {
  display: flex;
  flex-flow: column;
  align-items: center;
}
.income-count {
  font-size: 28px;
  font-weight: bold;
}
.descriptions {
  margin-top: 50px;
}
.editor {
  cursor: pointer;
  color: #008075;
  margin-left: 10px;
}

/* .save-editor { */

/*    position: absolute; */

/*    margin-top: 20px; */

/* } */

/* .save-text-editor { */

/*    position: absolute; */

/*    margin-top: 75px; */

/* } */
.el-pagination {
  padding-top: 26px;
  text-align: center;
}
.save-intro-kpi {
  /* position: relative; */
}
</style>
