import request from '@/utils/request'
import API from './api-types'

// 获取商品池列表
export default function getCommodityType() {
  return request({
    url: API.getCommodityType,
    method: 'get',
    params: {
      level: 1,
      parentId: '888888'
    }
  })
}
