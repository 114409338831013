/*
 * @Author: your name
 * @Date: 2020-10-30 14:25:41
 * @LastEditTime: 2020-11-06 18:05:20
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /qxs-management/src/store/face-review.js
 */
import * as Type from './mutations-types'
import * as FaceService from '../apis/face-service'

const state = {
  faceReviewList: [], // 人脸识别审核列表
  facePageNo: 1, // 人脸识别审核列表页码
  facePageSize: 100, // 人脸识别审核列表页数
  faceTotalCount: 0, // 人脸识别审核列表总页数
  doctorFaceDetail: {}, // 人脸识别审核医生详情
  departmentList: []// 科室list数据
}

const getters = {
  faceReviewList: state => state.faceReviewList,
  facePageNo: state => state.facePageNo,
  facePageSize: state => state.facePageSize,
  faceTotalCount: state => state.faceTotalCount,
  doctorFaceDetail: state => state.doctorFaceDetail,
  departmentList: state => state.departmentList
}

const mutations = {
  [Type.SET_FACE_REVIEW_LIST](state, value) {
    const { list, page, count, limit } = value
    state.faceReviewList = list
    state.facePageNo = page
    state.facePageSize = limit
    state.faceTotalCount = count
  },
  [Type.SET_FACE_REVIEW_DETAIL](state, value) {
    state.doctorFaceDetail = value
  },
  [Type.SET_DEPARTMENT_LIST](state, value) {
    state.departmentList = value
  },
  [Type.CHANGE_FACE_ITEM_STATUS](state, data) {
    const newList = [...state.faceReviewList].map(item => {
      if (item.recordId == data.recordId) {
        item.status = data.status
      }
      return item
    })
    state.faceReviewList = newList
  }
}

const actions = {
  getFaceReviewList({ commit }, value) {
    // 人脸识别审核列表
    return FaceService.getFaceReviewList(value).then(
      res => {
        commit(Type.SET_FACE_REVIEW_LIST, { ...res, limit: value.limit, page: value.page })
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getFaceReviewDetail({ commit }, data) {
    // 人脸识别审核列表
    return FaceService.getFaceReviewDetail(data).then(
      res => {
        commit(Type.SET_FACE_REVIEW_DETAIL, res)
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  saveFaceReview(_, value) {
    // 人脸识别审核列表
    return FaceService.saveFaceReview(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  handleFaceAuthLimit(_, data) {
    return FaceService.handleFaceAuthLimit(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getDepartmentList({ commit }, data) {
    return FaceService.getDepartmentList(data).then(
      res => {
        commit(Type.SET_DEPARTMENT_LIST, res)
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  changeFaceItemStatus({ commit }, data) {
    commit(Type.CHANGE_FACE_ITEM_STATUS, data)
  },
  faceSwitchDetail() {
    return FaceService.faceSwitchDetail().then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}