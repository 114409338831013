<template>
  <el-form
    :size="size"
    class="handle-btn-wrap"
  >
    <el-form-item class="left">
      <slot name="left" />
    </el-form-item>
    <el-form-item class="right">
      <slot name="right" />
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'HandleBtnWrap',
  props: {
    size: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.handle-btn-wrap {
  display: flex;
  justify-content: space-between;
}
</style>