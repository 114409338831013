<template>
  <div class="video-upload-page-box">
    <el-dialog
      :visible.sync="visible"
      :close-on-click-modal="false"
      :show-close="false"
      title="上传视频"
    >
      <div class="content">
        <div class="flex-start">
          <div class="grow-shrink0 ">
            视频名称：
          </div>
          <div class="w100v">
            <el-input
              v-model="title"
              type="text"
              placeholder="输入视频名称"
              maxlength="20"
              show-word-limit
            />
          </div>
        </div>
        <div class="flex-start vertical-start mgn-t30">
          <div class="grow-shrink0">
            上传视频：
          </div>
          <div class="w100v">
            <el-upload
              action=""
              :http-request="onHttpRequest"
              name="video"
              :disabled="isDisableUpload"
              :show-file-list="false"
              :before-upload="beforeUploadVideo"
              list-type="picture-card"
              class="upload-video"
            >
              <img
                v-if="videoCoverUrl&&isDisableUpload"
                :src="videoCoverUrl"
                style="width: 100%; height: 100%;"
              >
              <div
                v-else-if="upLoadSuccess"
                slot="trigger"
                class="wrapper"
                :class="{'upload-success':upLoadSuccess}"
              >
                <div>
                  <img
                    src="../../assets/success.png"
                    alt=""
                    class="image-w"
                  >
                </div>
                <div>上传成功</div>
              </div>
              <div
                v-else-if="upLoading"
                slot="trigger"
                class="wrapper"
              >
                <div class="pdg-b15">
                  <el-progress
                    type="circle"
                    :percentage="percent"
                    :width="50"
                  />
                </div>
                <div>上传中...</div>
              </div>
              <div
                v-else-if="upLoadError"
                slot="trigger"
                class="wrapper"
                :class="{'upload-error':upLoadError}"
              >
                <div>
                  <img
                    src="../../assets/fail.png"
                    alt=""
                    class="image-w"
                  >
                </div>
                <div>上传失败</div>
              </div>
              <div
                v-else
                slot="trigger"
                class="wrapper"
              >
                <div>
                  <img
                    src="../../assets/carmer.jpg"
                    alt=""
                    class="image-w"
                  >
                </div>
                <div>上传视频</div>
              </div>
              <div
                v-if="isDisableUpload||!title"
                style="position: absolute; top: 0; width: 160px; height: 90px; border-radius: 6px;"
                @click="onDisabledVideo"
              />
            </el-upload>
            <el-upload
              v-if="upLoaded"
              action=""
              :http-request="onHttpRequest"
              name="video"
              :disabled="isDisableUpload"
              :show-file-list="false"
              :on-progress="uploadVideoProcess"
              :before-upload="beforeUploadVideo"
            >
              <el-button type="text">
                重新上传
              </el-button>
            </el-upload>
          </div>
        </div>
        <div
          v-if="showCover"
          class="flex-start vertical-start mgn-t30"
        >
          <div class="grow-shrink0">
            视频封面：
          </div>
          <div class="w100v flex-start">
            <el-upload
              :action="uploadApi"
              :headers="headers"
              name="image"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              :on-success="handleAvatarSuccess"
              list-type="picture-card"
              accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
            >
              <img
                v-if="customCoverUrl"
                :src="customCoverUrl"
                style="width: 100%; height: 100%; border-radius: 6px;"
              >
              <div
                v-else
                slot="trigger"
                class="wrapper"
              >
                <div>
                  <img
                    src="../../assets/carmer.jpg"
                    alt=""
                    class="image-w"
                  >
                </div>
                <div>上传封面</div>
              </div>
            </el-upload>
            <div class="tips">
              备注：图片建议尺寸16：9
            </div>
          </div>
        </div>
      </div>
      <div
        slot="footer"
        class="flex-center mgn-t20"
      >
        <el-button @click="onClose">
          取消
        </el-button>
        <el-button
          :loading="btnLoading"
          type="primary"
          @click="onSubmit"
        >
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from '@/apis/api-types'
import { getToken } from '@/utils/auth'
import { mapActions } from 'vuex'

export default {
  name: 'VideoUpload',
  props: {
    visible: {       // 是否显示，默认true
      type: Boolean,
      default: true
    },
    showCover: {       // 是否显示，默认true
      type: Boolean,
      default: true
    },
    isDisableUpload: {
      type: Boolean,
      default: false
    },
    initVideoObj: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      headers: {
        token: getToken()
      },
      // -------------------- submit ----------------
      btnLoading: false,
      videoCoverUrl: '',
      customCoverUrl: '',
      upLoadCover: '',
      cover: '',
      title: '',
      // ---------------------- upLoad ------------------
      uploader: null,
      aliVideoAuthDto: {},
      percent: 0,
      upLoading: false,
      upLoaded: false,
      upLoadSuccess: false,
      upLoadError: false
    }
  },
  computed: {
    uploadApi() {
      return `${API.uploadImg}?bucketType=104`
    }
  },
  watch: {
    visible(newVal) {
      if (newVal && !this.isDisableUpload) {
        this.initPage()
        this.initUploader()
      } else if (newVal && this.isDisableUpload) {
        this.initPage()
        this.onGetTrainVideoDetail({ videoId: this.initVideoObj.videoId }).then(res => {
          this.title = res.title
          this.videoCoverUrl = res.coverUrl
          this.customCoverUrl = res.customCoverUrl
          this.upLoadCover = res.fileKey
        })
      }
    }
  },
  mounted() {
  },
  methods: {
    ...mapActions(['onUploadVideo', 'onGetTrainVideoDetail', 'onUpdateTrainVideo']),
    onClose() {
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('onClose', false)
    },
    onSubmit() {
      console.log('------------------- onSubmit -------------------------')
      const Data = {
        videoId: this.isDisableUpload ? this.initVideoObj.videoId : this.aliVideoAuthDto.videoId,
        coverUrl: this.cover,
        title: this.title
      }
      this.onUpdateTrainVideo(Data).then(res => {
        res.coverUrl = this.upLoadCover
        // eslint-disable-next-line vue/custom-event-name-casing
        this.$emit('onUpload', [res || {}])
        this.$message({
          message: '视频文件已上传成功',
          type: 'success',
          duration: 2000
        })
        this.onClose()
      })
    },
    initPage() {
      this.title = ''
      this.videoCoverUrl = ''
      this.customCoverUrl = ''
      this.btnLoading = false
      this.aliVideoAuthDto = {}
      this.percent = 0
      this.upLoading = false
      this.upLoaded = false
      this.upLoadSuccess = false
      this.upLoadError = false
    },
    initUploader() {
      this.uploader = null
      const _this = this
      const AliyunUpload = window.AliyunUpload
      this.uploader = new AliyunUpload.Vod({
        // 文件上传失败
        'onUploadFailed': function(uploadInfo, code, message) {
          _this.upLoading = false
          _this.upLoaded = true
          _this.upLoadSuccess = false
          _this.upLoadError = true
          _this.$message.error(message || '上传失败，请稍后再试')
        },
        // 文件上传完成
        'onUploadSucceed': function(uploadInfo) {
          _this.upLoading = false
          _this.upLoaded = true
          _this.upLoadSuccess = true
          _this.upLoadError = false
          _this.$message.success('上传成功')
          console.log(`onUploadSucceed: ${  uploadInfo.file.name  }, endpoint:${  uploadInfo.endpoint  }, bucket:${  uploadInfo.bucket  }, object:${  uploadInfo.object}`)
        },
        // 文件上传进度
        'onUploadProgress': function(uploadInfo, totalSize, percent) {
          _this.upLoading = true
          _this.upLoaded = false
          _this.upLoadSuccess = false
          _this.upLoadError = false
          _this.percent = Math.floor(percent * 100)
        },
        // STS临时账号会过期，过期时触发函数
        'onUploadTokenExpired': function() {
          _this.$message.success('上传凭证过期，请重试')
        },
        // 开始上传
        'onUploadstarted': function(uploadInfo) {
          if (!_this.aliVideoAuthDto.uploadAuth) return
          _this.percent = 0
          _this.upLoading = true
          _this.upLoaded = false
          _this.upLoadSuccess = false
          _this.upLoadError = false
          _this.uploader.setUploadAuthAndAddress(uploadInfo, _this.aliVideoAuthDto.uploadAuth, _this.aliVideoAuthDto.uploadAddress)
        }
      })
      this.uploader.init()
    },
    handleAvatarSuccess(res, file) {
      this.customCoverUrl = URL.createObjectURL(file.raw)
      this.cover = res.data.imageName || ''
      this.upLoadCover = res.data.middle || ''
    },
    onDisabledVideo() {
      if (!this.title) {
        this.$message.error({ message: '请先填写视频名称！' })
        return false
      }
      this.$message.error({ message: '视频不可编辑！' })
    },
    doUpload() {
      this.uploader.startUpload()
    },
    onHttpRequest() {
      // 覆盖默认行为
    },
    // 上传前回调
    beforeUploadVideo(file) {
      console.log(file, '---------------- beforeUploadVideo --------------')
      if (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb', 'video/mov'].indexOf(file.type) == -1) {
        this.$message.error('请上传正确的视频格式')
        return false
      }
      // var fileSize = file.size / 1024 / 1024 > 300
      // if (fileSize) {
      //     this.$message.error('视频大小不能超过300MB')
      //     return false
      // }
      const userData = '{"Vod":{"UserData":{"IsShowWaterMark":"false","Priority":"7"}}}'
      this.uploader.addFile(file, null, null, null, userData)
      this.onUploadVideo({ fileName: file.name, title: this.title, sourceType: 0 }).then(res => {
        console.log(res, '---------------- onUploadVideo -------------')
        this.aliVideoAuthDto = res || {}
        this.doUpload()
      })
    },
    // 进度条
    uploadVideoProcess(event, file) {
      this.videoFlag = true
      this.videoUploadPercent = file.percentage.toFixed(0) * 1
    },
    // 图片上传
    beforeAvatarUpload(file) {
      const _this = this
      const isSize = new Promise(function(resolve, reject) {
        const width = 16 // 限制图片尺寸为16:9
        const height = 9
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.onload = function() {
          const valid = (img.width / img.height).toFixed(2) === (width / height).toFixed(2)
          valid ? resolve() : reject()
        }
        img.src = _URL.createObjectURL(file)
      }).then(() => {
        return file
      }, () => {
        _this.$message.warning('建议视频封面图片尺寸16:9')
        return Promise.resolve()
      })
      return isSize
    }
  }
}
</script>

<style>
.video-upload-page-box .el-dialog {
  padding: 10px 25px;
  width: 850px;
}
.video-upload-page-box .el-dialog__body {
  padding: 0;
}
.video-upload-page-box .el-upload--picture-card {
  width: 160px;
  height: 90px;
}
</style>
<style scope>
.btn-item {
  width: 140px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #198cff;
  border-radius: 4px;
  border: 1px solid #198cff;
  margin-right: 10px;
}
.upload-success {
  color: #09bb07;
  background: #e7fce6;
  border-radius: 6px;
}
.upload-error {
  color: #f6625e;
  background: #ffe8ea;
  border-radius: 6px;
}
.flex-start {
  display: flex;
  align-items: center;
}
.grow-shrink0 {
  flex-grow: 0;
  flex-shrink: 0;
}
.content {
  margin: 30px 0 20px 20px;
}
.wrapper {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  line-height: 1;
  height: 100%;
  color: #008075;
  font-size: 12px;
}
.image-w {
  margin-bottom: 15px;
}
.tips {
  color: #c8c9ce;
  margin-left: 10px;
  font-size: 14px;
}
.w100v {
  width: 100vh;
}
.upload-video {
  position: relative;
}
</style>
