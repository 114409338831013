import XLSX from 'xlsx'
import store from '@/store/index'

/**
 * 页面滚动到指定element ID处
 * @param targetId: 页面元素的ID
 * @return null
 */
export function scrollToElementId(targetId) {
  if (window && document) {
    document.getElementById(targetId).scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    })
  }
}

/**
 * int 分单位转换为元单位
 * @param fen(int):
 * @return (int)
 */
export const fenToYuan = fen => {
  const num = `${fen }`
  const intNum = num.slice(0, -2)
  const dotNum = num.slice(-2)
  const lastNum = num.slice(-1)
  if (Number(dotNum) > 0) {
    if (Number(lastNum) === 0) {
      return `${intNum  }.${  dotNum.slice(0, 1)}`
    }
    return `${intNum}.${dotNum}`
  }
  return Number(intNum)
}
export function judgeClient() {
  let client = ''
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    // 判断iPhone|iPad|iPod|iOS
    client = 'iOS'
  } else if (/(Android)/i.test(navigator.userAgent)) {
    // 判断Android
    client = 'Android'
  } else {
    client = 'PC'
  }
  return client
}
/**
 * int 分单位转换为元单位
 * @param fen(int):
 * @return (int)
 */
export const fenToYuanFan = fen => {
  if (fen || fen === 0) {
    const num = `${fen  }`
    const intNum = num.slice(0, -2)
    const dotNum = num.slice(-2)
    const lastNum = num.slice(-1)
    if (Number(dotNum) > 0) {
      if (Number(lastNum) === 0) {
        return `${intNum  }.${  dotNum.slice(0, 1)}`
      }
      return `${intNum  }.${  dotNum}`
    }
    return Number(intNum)
  } else {
    return '无'
  }
}

/**
 * int 分单位转换为整数的元单位（int）
 * @param fen(int):
 * @return (int)
 */
export const fenToYuanInt = fen => {
  const num = `${fen  }`
  const intNum = num.slice(0, -2) // 整数位
  return Number(intNum)
}
/*
  * 判断obj是否为一个整数
  */
function isInteger(obj) {
  return Math.floor(obj) === obj
}
/*
  * 将一个浮点数转成整数，返回整数和倍数。如 3.14 >> 314，倍数是 100
  * @param floatNum {number} 小数
  * @return {object}
  * {times:100, num: 314}
  */
function toInteger(floatNum) {
  let ret = {times: 1, num: 0}
  if (isInteger(floatNum)) {
    ret.num = floatNum
    return ret
  }
  let strfi = `${floatNum  }`
  let dotPos = strfi.indexOf('.')
  let len = strfi.substr(dotPos + 1).length
  let times = Math.pow(10, len)
  let intNum = parseInt(floatNum * times + 0.5, 10)
  ret.times = times
  ret.num = intNum
  return ret
}

/**
 * int 元转分
 * @param yuan(int):
 * @return (int)
 */
export const YuanToFen = yuan => {
  const { num, times } = toInteger(yuan)
  return num * 100 / times
}

/**
 * 某个对象是否在数组里,并返回index
 * @param arr(array):
 * @param key(string):
 * @param val:
 * @return index (int)
 */
export const objIndexOf = (arr, key, val) => {
  let index = -1
  arr.forEach((item, ind) => {
    if (item[key] === val) {
      index = ind
    }
  })
  return index
}

/**
 * 从url中获取参数
 * @param variable string:
 * @return string
 */
export function getQueryVariable(variable) {
  const query = window.location.search.substring(1)
  const vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    if (pair[0] == variable) {
      return pair[1]
    }
  }
  return false
}
// todo @all 新老版本接口返回格式有所不一致，暂时这么处理，后续需要和服务端协商统一返回。
export function handleSuccess(url, data) {
  if (url.indexOf('qxs-api/') > -1 || url.indexOf('api/mall') > -1) {
    return data
  } else {
    return data.data
  }
}
/**
 * shouAt的值表示哪一端，医生端：1,代表端：2---即1<<1，厂家端：4----即1<<2
 * @param showAt number
 * @return string
 *
 */
export function getShowAtStr(showAt = 0) {
  const doctor = store.getters.doctor
  const representative = store.getters.representative
  const manufacturer = store.getters.manufacturer
  const speakerTerminal = store.getters.speakerTerminal
  const showAtNUM = showAt * 1
  const sources = []
  const speakArr = (showAtNUM & speakerTerminal) > 0 ? ['直播助手'] : []
  const manArr = (showAtNUM & manufacturer) > 0 ? ['厂家端'] : []
  const repArr = (showAtNUM & representative) > 0 ? ['代表端'] : []
  const docArr = (showAtNUM & doctor) > 0 ? ['医生端'] : []
  return sources.concat(speakArr, manArr, repArr, docArr).join('、')
}
export function getShowAtNum(sources = []) {
  const doctor = store.getters.doctor
  const representative = store.getters.representative
  const manufacturer = store.getters.manufacturer
  const speakerTerminal = store.getters.speakerTerminal
  if (sources.includes('all')) {
    return null
  }
  const speakerNUM =
    sources.includes('直播助手') || sources.includes('speakerTerminal') ? speakerTerminal : 0
  const manNUM = sources.includes('厂家端') || sources.includes('manufacturer') ? manufacturer : 0
  const repNUM =
    sources.includes('代表端') || sources.includes('representative') ? representative : 0
  const docNUM = sources.includes('医生端') || sources.includes('doctor') ? doctor : 0
  return speakerNUM + manNUM + repNUM + docNUM
}

/**
 * json数据生成excel文件
 * @param {String} title
 * @param {Array} json
 * @param {(string[]|(string|[{trigger: string, message: string, required: boolean}]|[{trigger: string, message: string, required: boolean}]|mobile|string)[])[]} merge
 */
export function JsonToExcel(title = '', json = null, merge) {
  try {
    if (!json) return
    // 创建worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(json)
    if (merge) {
      worksheet['!merges'] = merge
    }
    // 新建空workbook，加入worksheet
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, '')
    XLSX.writeFile(workbook, `${title}.xlsx`)
  } catch (e) {
    Promise.reject('数据导出失败')
  }
}

export function excelToJson(data, cb, start) {
  const workbook = XLSX.read(data, {type: 'binary'})
  // 遍历每张表读取
  const json = []
  for (const sheet in workbook.Sheets) {
    // eslint-disable-next-line no-prototype-builtins
    if (workbook.Sheets.hasOwnProperty(sheet)) {
      let arr = []
      const fromTo = workbook.Sheets[sheet]['!ref']
      const end = fromTo.split(':')
      const space = start + end[1].toString()
      // json = json.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
      arr = arr.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet], {header: 1, range: start ? space : fromTo}))
      const titleArr = arr[0]
      arr.shift()
      arr = arr.map(item => {
        const obj = {}
        titleArr.forEach((titleItem, index) => {
          obj[titleItem] = item[index]
        })
        return obj
      })
      json.push(...arr)
    }
  }
  cb && cb(json)
}
/** *
 * 秒数 转 分秒
 * @param seconds Number    // 秒数
 * 00：00格式
 */

export function timeMinutes(seconds) {
  if (!seconds) {
    return '暂无'
  }
  const number = Number(seconds)
  const M = parseInt(number / 60)
  const S = number % 60
  return `${M >= 10 ? M : `0${  M}`  }:${  S >= 10 ? S : `0${  S}`}`
}
/** *
 * 秒数 转 分秒
 * @param value Number    // 秒数
 * 文字格式
 */

export function changeTime(value) {
  if (value === 0) return ''
  let secondTime = parseInt(value) // 秒
  let minuteTime = 0 // 分
  let hourTime = 0 // 小时
  if (secondTime > 60) { // 如果秒数大于60，将秒数转换成整数
    // 获取分钟，除以60取整数，得到整数分钟
    minuteTime = parseInt(secondTime / 60)
    // 获取秒数，秒数取佘，得到整数秒数
    secondTime = parseInt(secondTime % 60)
    // 如果分钟大于60，将分钟转换成小时
    if (minuteTime > 60) {
      // 获取小时，获取分钟除以60，得到整数小时
      hourTime = parseInt(minuteTime / 60)
      // 获取小时后取佘的分，获取分钟除以60取佘的分
      minuteTime = parseInt(minuteTime % 60)
    }
  }
  let time = `${parseInt(secondTime)}秒`
  if (minuteTime > 0) {
    time = `${parseInt(minuteTime)}分${time}`
  }
  if (hourTime > 0) {
    time = `${parseInt(hourTime)}小时${time}`
  }
  return time
}
/** *
 * 将url？后的参数转换为对象
 * @param url String  // 完整链接
 */
export function urlObject(url) {
  // 将url？后的参数转换为对象
  const obj = {}
  if (/\?/.test(url)) {
    const urlString = url.substring(url.indexOf('?') + 1)
    const urlArray = urlString.split('&')
    for (let i = 0, len = urlArray.length; i < len; i++) {
      const urlItem = urlArray[i]
      const item = urlItem.split('=')
      obj[item[0]] = item[1]
    }
    return obj
  }
  return null
}

/**
 * @desc 函数防抖---“立即执行版本” 和 “非立即执行版本” 的组合版本
 * @param func 需要执行的函数
 * @param wait 延迟执行时间（毫秒）
 * @param immediate---true 表立即执行，false 表非立即执行
 **/
export function debounce(func, wait, immediate) {
  let timer
  return function() {
    const context = this
    const args = arguments

    if (timer) clearTimeout(timer)
    if (immediate) {
      const callNow = !timer
      timer = setTimeout(() => {
        timer = null
      }, wait)
      if (callNow) func.apply(context, args)
    } else {
      timer = setTimeout(function() {
        func.apply(context, args)
      }, wait)
    }
  }
}

// 获取地图数据（GeoJson)
export function getGeoJsonData(options) {
  return new Promise(resolve => {
    // 实例化DistrictSearch
    let opt = {
      showbiz: false,
      extensions: 'all',
      ...options
    }
    let district = new AMap.DistrictSearch(opt)
    // 行政区查询
    district.search(options.adcode, function(status, result) {
      window.AMapUI.loadUI(['geo/DistrictExplorer'], function(DistrictExplorer) {
        // 创建一个实例
        let districtExplorer = new DistrictExplorer()
        districtExplorer.loadAreaNode(result.districtList[0].adcode, function(error, areaNode) {
          if (error) {
            console.error(error)
            return
          }
          let mapJson = {}
          // geoJson必须这种格式
          mapJson.features = areaNode.getSubFeatures()
          resolve(mapJson)
        })
      })
    })
  })
}
// 默认厂家id
export function defaultOrgId() {
  // 初始化默认厂家
  let orgId = ''
  if (process.env.NODE_ENV === 'production') {
    orgId = [{ queryObjectId: '7da9ee2b77214beaa5bffe13f8ef6992', queryObjectShowTitle: '趣学术（业务演示）' }]
  } else {
    orgId = [{ queryObjectId: '808afa83fc12444aa5ec1326f6e211cd', queryObjectShowTitle: '' }]
  }
  return orgId
}

// 获取数据类型
export const getValType = (val = 0) => {
  let type = typeof val
  // object需要使用Object.prototype.toString.call判断
  if (type === 'object') {
    let typeStr = Object.prototype.toString.call(val)
    // 解析[object String]
    typeStr = typeStr.split(' ')[1]
    type = typeStr.substring(0, typeStr.length - 1)
  }
  return type
}

export const lowcodeDoctorInfo = () => {
  const { NODE_ENV } = process.env
  return {
    doctorUrl: NODE_ENV === 'development' ? 'https://kshdoctor-dev.yaomaitong.net' : NODE_ENV === 'pre' ? 'https://kshdoctor.yaomaitong-pre.cn' : NODE_ENV === 'production' ? 'https://kshdoctor.yaomaitong.cn' : '',
    lowcodeUrl: NODE_ENV === 'development' ? 'https://lowcode-dev.yaomaitong.net' : NODE_ENV === 'pre' ? 'https://lowcode.yaomaitong-pre.cn' : NODE_ENV === 'production' ? 'https://lowcode.yaomaitong.cn' : ''
  }
}

