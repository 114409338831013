export default [
  {
    path: '/chart-config',
    name: 'ChartConfig',
    redirect: {
      name: 'StatementList'
    }
  },
  // 数据报表
  {
    path: '/chart-config/statement-list',
    name: 'StatementList',
    component: () => import(/* webpackChunkName: "StatementList" */ '@/views/chart-config/statement/List.vue'),
    meta: {
      title: '数据报表管理',
      cache: ['StatementCreateOrEdit']
    }
  },
  {
    path: '/chart-config/statement-create-or-chart',
    name: 'StatementCreateOrEdit',
    component: () => import(/* webpackChunkName: "StatementList" */ '@/views/chart-config/statement/CreateOrEdit.vue'),
    meta: {
      title: '编辑报表'
    }
  },
  // 数据报告模板
  {
    path: '/chart-config/report-template-list',
    name: 'ReportTemplateList',
    component: () => import(/* webpackChunkName: "ReportTemplateList" */ '@/views/chart-config/report-template/List.vue'),
    meta: {
      title: '数据报告模板管理'
    }
  },
  {
    path: '/chart-config/report-template-create-or-edit',
    name: 'ReportTemplateCreateOrEdit',
    component: () => import(/* webpackChunkName: "ReportTemplateList" */ '@/views/chart-config/report-template/CreateOrEdit.vue'),
    meta: {
      title: '创建/编辑数据报告模板'
    }
  },
  // 数据报告
  {
    path: '/chart-config/report-list',
    name: 'ReportList',
    component: () => import(/* webpackChunkName: "ReportList" */ '@/views/chart-config/report/List.vue'),
    meta: {
      title: '数据报告管理'
    }
  },
  {
    path: '/chart-config/report-create-or-edit',
    name: 'ReportCreateOrEdit',
    component: () => import(/* webpackChunkName: "ReportList" */ '@/views/chart-config/report/CreateOrEdit.vue'),
    meta: {
      title: '创建/编辑数据报告'
    }
  },
  {
    path: '/chart-config/detail',
    name: '',
    component: () => import(/* webpackChunkName: "ReportList" */ '@/views/chart-config/report/details.vue')
  },
  // 报表配置
  {
    path: '/chart-config/activity-management',
    name: 'ChartActivityManagement',
    component: () => import(/* webpackChunkName: "ChartActivityManagement"*/'@/views/chart-config/activity/Management.vue'),
    meta: {
      title: '报表活动管理'
    }
  },
  {
    path: '/chart-config/activity-detail',
    name: 'ChartActivityDetail',
    component: () => import(/* webpackChunkName: "ChartActivityManagement"*/'@/views/chart-config/activity/Detail.vue'),
    meta: {
      title: '报表活动详情'
    }
  },
  {
    path: '/chart-config/new-activity',
    name: 'ChartNewActivity',
    component: () => import(/* webpackChunkName: "ChartActivityManagement"*/'@/views/chart-config/activity/New.vue'),
    meta: {
      title: '新建报表活动'
    }
  },
  {
    path: '/chart-config/report-push',
    name: 'ReportPush',
    component: () => import(/* webpackChunkName: "ReportPush"*/'@/views/chart-config/report-push/Index.vue'),
    meta: {
      title: '报表推送管理'
    }
  },
  // 数据源管理
  {
    path: '/chart-config/data-source-list',
    name: 'DataSourceList',
    component: () => import(/* webpackChunkName: "DataSourceList" */ '@/views/chart-config/data-source/List.vue'),
    meta: {
      title: '数据源管理'
    }
  }, {
    // 查看数据列
    path: '/chart-config/data-source-column',
    name: 'DataSourceColumn',
    component: () => import(/* webpackChunkName: "DataSourceList" */ '@/views/chart-config/data-source/Column.vue'),
    meta: {
      title: '数据列管理'
    }
  }
]
