<template>
  <div class="card-wrap">
    <div
      v-if="title"
      class="card-wrap-title"
    >
      {{ title }}
    </div>
    <div class="wrap">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardWrap',
  props: {
    title: {
      type: String
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
// scss
.card-wrap {
  margin-bottom: 30px;
  .card-wrap-title {
    font-size: 14px;
    padding-left: 10px;
    border-left: 2px solid #008075;
    margin-bottom: 15px;
    color: #7784a1;
    line-height: 18px;
  }
}
</style>