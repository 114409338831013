<template>
  <div class="home">
    <div style="margin-bottom: 10px;">
      <el-button
        type="primary"
        class="qxs-btn-sm"
        @click="onOpenSelect"
      >
        {{ isShowSelectDol ? '取消批量操作' : '批量操作' }}
      </el-button>
      <el-button
        v-show="isShowSelectDol"
        type="primary"
        plain
        class="qxs-btn-sm"
        @click="onOpenActionDol"
      >
        批量上架
      </el-button>
    </div>
    <div
      ref="table"
      class="prod-panel"
    >
      <el-table
        ref="multipleTable"
        v-loading="loading"
        :data="prodRelated"
        border
        style="width: 100%;"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          v-if="isShowSelectDol"
          type="selection"
          width="55"
        />
        <el-table-column
          prop="skuId"
          label="SKUID"
          min-width="8%"
        />
        <el-table-column
          prop="imageUrl"
          label="商品图片"
          min-width="8%"
        >
          <template slot-scope="scope">
            <el-image
              style="width: 100px; height: 100px;"
              :src="scope.row.imageUrl | imageUrlFilter"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="spuId"
          label="商品编号"
          min-width="8%"
        />
        <el-table-column
          prop="name"
          label="名称"
          min-width="20%"
        />
        <el-table-column
          prop="firstCid"
          label="分类"
          min-width="6%"
        />
        <el-table-column
          prop="isSelf"
          label="京东自营"
          min-width="6%"
        />
        <el-table-column
          label="参考售价"
          min-width="6%"
        >
          <template slot-scope="scope">
            {{ toYuan(scope.row.formalPrice) }}
          </template>
        </el-table-column>
        <el-table-column
          label="原价"
          min-width="6%"
        >
          <template slot-scope="scope">
            {{ toYuan(scope.row.marketPrice) }}
          </template>
        </el-table-column>
        <el-table-column
          label="采购价"
          min-width="6%"
        >
          <template slot-scope="scope">
            {{ toYuan(scope.row.price) }}
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          prop="profit"
          label="利润空间"
          min-width="6%"
        />
        <el-table-column
          fixed="right"
          prop="zip"
          label="操作"
          min-width="10%"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click.native.prevent="checkDetail(scope.row)"
            >
              添加到商品库
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-footer>
        <el-button
          type="primary"
          @click="backToPool()"
        >
          返回商品池
        </el-button>
      </el-footer>
    </div>
    <!-- 弹窗 -->
    <el-dialog
      title="批量上架-商品策略"
      :visible.sync="isOpenDialog"
      :before-close="dialogBeforeClose"
      center
    >
      <el-form :model="uploadForm">
        <el-form-item label="售价策略：">
          <el-radio-group v-model="uploadForm.percentageType" @input="onChangeRadio">
            <el-radio label="percent">百分比</el-radio>
            <el-radio label="int">单价</el-radio>
          </el-radio-group>

          <div v-if="uploadForm.percentageType === 'percent'" style="padding-left: 82px;">
            <span>售价=采购价x(100%+</span>
            <el-input-number
              v-model="uploadForm.inputVal"
              :min="0"
              :step="1"
              size="small"
              label="label"
            />
            <span>%)</span>
            <span style="padding-left: 30px;">备注：若有小数，向上取整</span>
          </div>
          <div v-else style="padding-left: 82px;">
            <span>售价=采购价+</span>
            <el-input-number
              v-model="uploadForm.inputVal"
              :min="0"
              :step="1"
              :precision="0"
              size="small"
              label="label"
            />
            <span style="padding-left: 30px;">备注：只能输入正整数</span>
          </div>
        </el-form-item>
        <el-form-item label="关联服务政策：">
          <el-select v-model="uploadForm.servicePolicyId" placeholder="请选择服务政策">
            <el-option
              v-for="item in servicePolicy"
              :key="item.index"
              :span="12"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="商品分类：">
          <el-cascader
            v-model="uploadForm.commoditySelect"
            :props="commodityProps"
            clearable
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="isOpenDialog = false">取 消</el-button>
        <el-button type="primary" :loading="isLoading" @click="onUploadAll">批量上架</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue';
import { mapState } from 'vuex'
import { fenToYuan } from '@/utils/util'

export default {
  components: {},
  filters: {
    imageUrlFilter(url) {
      let index = url.indexOf('http')
      if (index > -1) {
        return url
      }
      return `https://img30.360buyimg.com/sku/${url}`
    }
  },
  data() {
    return {
      loading: true,
      imgUrl: 'https://img30.360buyimg.com/sku/g7/M03/08/0C/rBEHZVBziAMIAAAAAABUEjw0EYQAABo1gF1VYEAAFQq465.jpg',
      name: '商品推荐页',
      prodName: '',
      skuId: '',
      supplier: '',
      currentPage: 0,
      poolId: null,

      isShowSelectDol: false,
      isOpenDialog: false,
      isLoading: false,
      selectList: [],
      uploadForm: {
        servicePolicyId: '',
        commoditySelect: [],
        percentageType: 'percent',
        inputVal: 0
      },
      commodityProps: {
        lazy: true,
        lazyLoad: (node, resolve) => {
          const { level, value = '888888' } = node
          this.onGetCommodityList(level + 1, value).then(res => {
            resolve(res)
          }, rea => console.log(rea))
        }
      }
    }
  },
  computed: {
    ...mapState(['prodRelated', 'servicePolicy'])
  },
  mounted() {
    console.log('this.name ', this.name)
    const { skuId, supplier } = this.$route.query
    this.skuId = skuId
    this.supplier = supplier
    this.$store.dispatch('changeTitle', this.name)
    this.onGetList()
    this.supplierChange()
  },
  updated() {
    window.scrollTo(0, 0)
  },
  methods: {
    onGetList() {
      this.$store.dispatch('searchBySkuId', {'skuId': this.skuId, supplier: this.supplier}).then(() => {
        this.loading = false
      })
    },
    onUploadAll() {
      console.log(this.uploadForm)
      if (!this.selectList.length) { return this.$message.error('请先选择上架的商品') }
      this.isLoading = true
      const data = {
        codeList: this.selectList.map(item => item.skuId),
        servicePolicyId: this.uploadForm.servicePolicyId,
        supplierType: this.supplier,
        dicCommodityType: this.uploadForm.commoditySelect[0],
        secondDicCommodityType: this.uploadForm.commoditySelect[1],
        percentage: this.uploadForm.percentageType === 'percent',
        priceIncrementCents: this.uploadForm.percentageType === 'percent' ? 0 : this.uploadForm.inputVal,
        priceIncrementPercent: this.uploadForm.percentageType === 'percent' ? this.uploadForm.inputVal : 0
      }
      this.$axios.post(this.$API.batchAddProduct, data).then(() => {
        this.isOpenDialog = false
        this.isLoading = false
      }, rea => {
        this.isLoading = false
        this.$message.error(rea.message)
      })
    },
    handleSelectionChange(val) {
      console.log(val, '====== handleSelectionChange ========')
      this.selectList = val
    },
    supplierChange() {
      this.$store.dispatch('getServicePolicy', { supplier: this.supplier })
    },
    onGetCommodityList(level = 0, parentId = '888888') {
      const params = {level, parentId}
      return this.$axios.get(this.$API.getCommodityType, { params }).then(res => {
        const nodes = (res || []).map(item => ({
          value: item.id,
          label: item.name,
          leaf: level >= 2
        }))
        return nodes
      })
    },
    onOpenSelect() {
      this.isShowSelectDol = !this.isShowSelectDol
      this.selectList = []
    },
    onOpenActionDol() {
      console.log('批量上传')
      if (!this.selectList.length) { return this.$message.error('请先选择上架的商品') }
      this.isOpenDialog = true
      this.uploadForm = {
        servicePolicyId: '',
        commoditySelect: [],
        percentageType: 'percent',
        inputVal: 0
      }
    },
    dialogBeforeClose(hide) {
      hide(true)
    },
    onChangeRadio() {
      this.uploadForm.inputVal = 0
    },
    checkDetail(row) {
      // console.log(`SKUID: ${data[index].skuId}`);
      this.$router.push({ name: 'prodDetail', query: { skuId: row.skuId, supplier: row.supplier } })
    },
    toYuan(value) {
      return fenToYuan(value)
    },
    backToPool() {
      this.$router.push({ name: 'prodPool' })
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  padding: 20px;
  .search-panel {
    padding: 20px;
    .search-name {
      position: relative;
      top: 10px;
    }
  }
}
.item-title {
  display: inline-block;
}
.el-input__inner {
  display: inline-block;
}
.el-pagination {
  padding-top: 26px;
  text-align: center;
}
.footer {
  margin-top: 10px;
  text-align: center;
}
</style>
