import request from '@/utils/request'
import API from './api-types'

// 获取商品池列表
export default function getProductDetail({ spuId }) {
  return request({
    url: API.getProductDetail,
    method: 'get',
    params: { spuId }
  })
}
