import * as Type from './mutations-types'
import * as DoctorDetail from '../apis/getDoctorDetail'

const state = {
  areaList: [], // 医生职称
  hospitalList: []// 医院

}
const getters = {
  areaList: state => state.areaList,
  hospitalList: state => state.hospitalList

}
const mutations = {
  [Type.GET_AREA_LIST](state, value) {
    state.areaList = value
  },
  [Type.GET_HOSPITAL_LIST](state, value) {
    state.hospitalList = value
  }

}
const actions = {
  getThridVerifyResult(_, value) {
    return DoctorDetail.getThridVerifyResult(value).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getAreaList({ commit }, value) {
    return DoctorDetail.getAreaList(value).then(
      res => {
        commit(Type.GET_AREA_LIST, res)
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getHospitalList({ commit }, value) {
    return DoctorDetail.getHospitalList(value).then(
      res => {
        commit(Type.GET_HOSPITAL_LIST, res)
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getDoctorBaseInfo(_, data) {
    return DoctorDetail.getDoctorBaseInfo(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  setDoctorBaseInfo(_, data) {
    return DoctorDetail.setDoctorBaseInfo(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getDoctorAuthInfo(_, data) {
    return DoctorDetail.getDoctorAuthInfo(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  setDoctorAuthInfo(_, data) {
    return DoctorDetail.setDoctorAuthInfo(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },

  getDoctorRealNameInfo(_, data) {
    return DoctorDetail.getDoctorRealNameInfo(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  setDoctorRealNameInfo(_, data) {
    return DoctorDetail.setDoctorRealNameInfo(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  getFactoryInfo(_, data) {
    return DoctorDetail.getFactoryInfo(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  },
  setFactoryInfo(_, data) {
    return DoctorDetail.setFactoryInfo(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        return Promise.reject(rea)
      }
    )
  }
  // getAreaList({ commit }, data) {
  //   return DoctorDetail.getAreaList(data).then(res => {
  //     commit
  //     return Promise.resolve(res)
  //   }, rea => {
  //     return Promise.reject(rea)
  //   })
  // },
}
export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
