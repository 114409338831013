<template>
  <Editor
    :init="defaultSetting"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue' 
import 'tinymce/themes/silver/theme'
import 'tinymce/icons/default/icons'
import 'tinymce/models/dom'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/image'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/media'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/table'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/code'
import 'tinymce/plugins/searchreplace'
export default {
  name: 'EditingTools',
  components: {
    Editor
  },
  props: {
    classify: {
      type: [String, Number],
      default: 109
    },
    initOption: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      editorId: `tinymce-editor-${this.$nanoid()}`,
      colorScheme: 'light',
      editor: null
    }
  },
  computed: {
    defaultSetting() {
      return {
        // selector: `#${this.editorId}`,
        language_url: 'tinymce/langs/zh-Hans.js',
        language: 'zh-Hans',
        skin_url: this.colorScheme === 'light' ? 'tinymce/skins/ui/oxide' : 'tinymce/skins/ui/oxide-dark',
        content_css: this.colorScheme === 'light' ? 'tinymce/skins/content/default/content.min.css' : 'tinymce/skins/content/dark/content.min.css',
        min_height: 300,
        max_height: 600,
        plugins: `autolink autoresize fullscreen image insertdatetime link lists media preview table wordcount code searchreplace${this.initOption.plugins ? ` ${this.initOption.plugins}` : ''}`,
        toolbar: `undo redo | blocks | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | forecolor backcolor removeformat | link image media table insertdatetime searchreplace | preview code fullscreen ${this.initOption.toolbar}`,
        branding: false,
        menubar: false,
        toolbar_mode: 'sliding',
        insertdatetime_formats: ['%Y年%m月%d日', '%H点%M分%S秒', '%Y-%m-%d', '%H:%M:%S'],
        // https://www.tiny.cloud/docs/tinymce/6/file-image-upload/#images_upload_handler
        images_upload_handler: async(blobInfo, progress) => {
          // blobInfo, succFun, failFun, progress
          let files = blobInfo.blob()
          const formData = new FormData()
          formData.append('image', files)
          try {
            const { large } = await this.$axios({
              method: 'post',
              url: `${this.$API.imageUpload}?bucketType=${this.classify}`,
              data: formData,
              onUploadProgress: e => {
                progress((e.loaded / e.total) * 100)
              }
            })
            return large
          } catch (error) {
            return ''
          }
        },
        setup: editor => {
          // 监听 TinyMCE 编辑器内容的变化，将变化的内容同步到 v-model 绑定的值
          // editor.on('change', () => {
          //   // 记录当前编辑器的内容
          //   // this.currentContent = tinymce.activeEditor.getContent()
          //   // this.$emit('input', this.currentContent)
          // })
          this.initOption.setup?.(editor)
        },
        init_instance_callback: () => {
          this.editor = tinymce.activeEditor
        }
      }
    }
  },
  mounted() {
    tinymce.init({})
  }
}
</script>

<style lang="scss" scoped>
:deep(.tox-tinymce) {
  height: 100% !important;
}
:global(.tox-tinymce-aux) {
  z-index: 2100 !important;
}
</style>
