export const API = process.env.NODE_ENV === 'development' ? '/gateway/33-mall' : '/gateway/mall'
export const API_BASE_V2 = process.env.NODE_ENV === 'development' ? '/gateway/178-qxs-api' : '/gateway/qxs-api'
export const Manager = '/manager/separate'

export default {
  // 商品池相关接口
  getPoolNumbers: `${API}/productPool/getPoolNum`, // 获取商品池列表
  batchAddProduct: `${API}/productPool/batchAddProduct`, // 商品池批量上架
  getCommodityType: `${API}/product/getCommodityType`, // 获取商品池列表
  batchUpdateProductCommodityType: `${API}/productManager/batchUpdateProductCommodityType`, // 批量更换商品类型
  submitDistributes: `${API}/order/manage/submit/distributes`, // 上传批量发货
  getAfterSaleOrderList: `${API}/order/manage/list`, // 获取售后单列表
  getJdPoolProductDetail: `${API}/productPool/getDetailJd`,
  getAfterSaleOrderDetail: `${API}/order/manage/queryDetail`,
  getProductDetail: `${API}/productManager/getDetail`,
  createProduct: `${API}/productManager/addProduct`,
  updateProduct: `${API}/productManager/updateProduct`,
  getServicePolicy: `${API}/policy/getServicePolicyByPage`,
  getPolicyDetail: `${API}/policy/getServicePolicyDetail`,
  setDefaultPolicy: `${API}/policy/setDefault`,
  saveAfterSaleDeliverData: `${API}/order/manage/save/after/sale/deliver`, // 保存售后单寄回信息
  updatePolicy: `${API}/policy/addOrUpdateServicePolicy`,
  saveDeliverData: `${API}/order/manage/save/deliver`, // 保存自营订单的物流信息
  verifyAfterSaleOrder: `${API}/order/manage/save/after/sale/review`, // 自营商品审核成功
  // closeOrder: API + "/mall/order/manage/close/order", // 关闭自营订单并退款
  updateRemark: `${API}/order/manage/submit/remark`, // 更新订单备注
  deliverBack: `${API}/order/manage/after/sale/reverse/deliver`, // 更新订单备注
  addProduct: `${API}/productPool/addProduct`, // 从商品池添加到商品库
  // searchBySkuId: API + '/mall/productPool/selectJdProductBySkuId',  // 根据SKU搜索
  searchBySkuId: `${Manager}/product-pool/getSimilarProduct`, // 根据商品编码查询关联未上架商品
  batchImportProduct: `${Manager}/product-manager/batch-import-product`, // 批量导入鲜通虚拟卡券
  exportProductManager: `${Manager}/product-manager/export-product-manager`, // 获取趣学术-积分商城管理-商城商品管理

  getJdProductPool: `${Manager}/product-pool/list`, // 获取某个商品池下的商品列表
  getOrderList: `${Manager}/points-mall/query-order-list`, // 获取订单列表
  downloadOrderList: `${Manager}/points-mall/export-order-file`, // 下载订单列表
  getOrderDetail: `${Manager}/points-mall/order-detail`, // 订单详情
  getOrderRebate: `${Manager}/points-mall/rebate-single-order`, // 订单返利
  confirmJdOrder: `${Manager}/points-mall/confirm-jd-order`, // 手动确认京东订单
  cancelOrder: `${Manager}/points-mall/cancel-order`, // 取消未确认的订单
  getProductList: `${Manager}/product-manager/getProductList`, // 获取某个商品池下的商品列表
  changeOrderStatus: `${Manager}/points-mall/change-order-status`, // 回滚订单状态

  // 人脸识别
  getFaceReviewList: `${Manager}/ksh/faceid/getList`, // 人脸识别审核列表
  getFaceReviewDetail: `${Manager}/ksh/faceid/getDetail`, // 单个医生人脸识别审核详情
  saveFaceReview: `${Manager}/ksh/faceid/auditRecord`, // 保存人脸识别审核
  handleFaceAuthLimit: `${Manager}/ksh/faceid/switch`, // 开启关闭人脸识别限制
  faceSwitchDetail: `${Manager}/ksh/faceid/getSwitch`, // 人脸识别门槛状态

  // 公共
  getDepartmentList: `${Manager}/ksh/getDepartment`, // 科室list
  getAreaList: `${Manager}/ksh/getArea`, // 省市list
  getHospitalList: `${Manager}/v1/hospital/hospital-list`, // 医院list
  departmentQuery: `${Manager}/hospital/department/query`,       // 医院科室查询
  departmentAdd: `${Manager}/hospital/department/add`, // 新增医院科室
  departmentDelete: `${Manager}/hospital/department/delete`,       // 删除医院科室
  departmentUpdate: `${Manager}/hospital/department/update`,       // 更新科室名称
  platDepartmentList: `${Manager}/platform/department/list`,      // 平台科室列表查询
  waitPlatDepartmentList: `${Manager}/platform/department/no-class-list`,   // 查询未分类科室列表
  platDepartmentAdd: `${Manager}/platform/department/add`,            // 增加平台科室
  platDepartmentUpdata: `${Manager}/platform/department/update`,      // 更新平台科室
  platDepartmentDelete: `${Manager}/platform/department/delete`,      // 删除平台科室
  platDepartmentBind: `${Manager}/platform/department/no-class/bind`,     // 归类医院科室
  platDepartmentDeleteBind: `${Manager}/platform/department/no-class/unbind`,     // 从平台科室中删除医院科室

  // 管理分类相关
  categoryList: `${Manager}/ksh/article/category-list`, // GET 趣学术文章分类列表
  categoryAdd: `${Manager}/ksh/article/category-save`, // POST 编辑趣学术文章分类
  categoryDel: `${Manager}/ksh/article/category-del`, // GET 删除趣学术文章分类
  articleList: `${Manager}/ksh/article/list`, // GET 获取趣学术文章列表
  articleDetail: `${Manager}/ksh/article/detail`, // GET 获取趣学术文章内容
  articleSave: `${Manager}/ksh/article/save`, // POST 保存趣学术文章
  articleChangeStatus: `${Manager}/ksh/article/change-status`, // GET 发布|下架趣学术文章
  platformFeedbackList: `${Manager}/ksh/platform-feedback/list`, // GET 获取平台反馈列表
  markFixed: `${Manager}/ksh/platform-feedback/mark-fixed`, // GET 标记为已解决
  guideDetail: `${Manager}/ksh/guide/detail`, // GET 获取新手引导详情
  guideSave: `${Manager}/ksh/guide/save`, // POST 保存引导内容

  // 图片上传
  imageUpload: `${Manager}/v1/upload-image`, // POST 图片上传

  // 直播审核
  getLiveTable: `${Manager}/live/review-list`, // 直播审核table
  getLiveDetail: `${Manager}/live/review-detail`, // 直播审核detail
  reviewDeal: `${Manager}/live/review-deal`, // 直播审核操作
  getThirdSpeakerList: `${Manager}/user-third-party-info/list`, // 直播审核table
  dealWorkOrder: `${Manager}/user-third-party-info/deal-work-order`, // 处理工单

  verifyLiveId: `${Manager}/live/verifyLiveId`, // 验证直播ID
  uploadVideo: `${Manager}/live/uploadVideo`, // 上传直播回放视频
  rollbackLiveSpeaker: `${Manager}/live/rollbackLiveSpeaker`, // 审核
  creditFail: `${Manager}/doctor/doctor/credit_fail`, //

  // 消息系统
  uploadFile: `${Manager}/wx/upload-file`, // 上传文件到微信公众号
  getMessageList: `${Manager}/ksh/message/search`, // 消息列表
  creatMessage: `${Manager}/ksh/message/create`, // 创建消息
  sendMessage: `${Manager}/ksh/message/send`, // 发送消息
  breakMessage: `${Manager}/ksh/message/break-off`, // 中断消息
  deleteMessage: `${Manager}/ksh/message/delete`, // 删除消息

  // 商城管理-栏目管理
  managementColumnList: `${Manager}/column-management/column-list`, // 查询栏目列表
  addColumn: `${Manager}/column-management/add-column`, // 新增栏目信息
  modifyColumn: `${Manager}/column-management/modify-column`, // 修改栏目信息
  delColumn: `${Manager}/column-management/del-column`, // 删除栏目信息
  upColumn: `${Manager}/column-management/up-column`, // 栏目上移
  downColumn: `${Manager}/column-management/down-column`, // 栏目下移

  // 医生管理
  getDoctorBaseInfo: `${Manager}/doctor/v1/base-info`, // 医生基础信息
  setDoctorBaseInfo: `${Manager}/doctor/v1/base-info/save`, // 保存医生基础信息
  getDoctorAuthInfo: `${Manager}/doctor/v1/auth-info`, // 医生认证信息
  setDoctorAuthInfo: `${Manager}/doctor/v1/audit-modify-record`, // 保存医生认证信息
  getDoctorRealNameInfo: `${Manager}/doctor/v1/real-name-info`, // 医生实名认证信息
  tempPage: `${Manager}/temp-page`,  // 后台中转页面
  setDoctorRealNameInfo: `${Manager}/doctor/v1/real-name/audit`, // 保存医生实名认证信息
  getFactoryInfo: `${Manager}/org/v1/info`, // 药企信息
  setFactoryInfo: `${Manager}/org/v1/save`, // 保存药企信息
  getThridVerifyResult: `${Manager}/doctor/v1/doctor-third-certify`, // 无码科技识别

  // 风控管理
  getSceneList: `${Manager}/risk-system/scene-list`, // 获取风控场景列表
  saveScene: `${Manager}/risk-system/save-scene`, // 保存风控场景
  changeSceneStatus: `${Manager}/risk-system/change-scene-status`, // 场景上下架
  sceneRuleList: `${Manager}/risk-system/scene-rule-list`, // 获取场景下对应的规则列表
  saveRule: `${Manager}/risk-system/save-rule`, // 保存规则
  deleteRule: `${Manager}/risk-system/delete-rule`, // 删除规则
  changeRuleStatus: `${Manager}/risk-system/change-rule-status`, // 上下架规则
  recordList: `${Manager}/risk-system/record-list`, // 获取风控记录列表
  dealRecord: `${Manager}/risk-system/deal-record`, // 人工处理风控记录
  specialUserList: `${Manager}/risk-system/special-user-list`, // 获取黑白名单列表
  specialLevelList: `${Manager}/risk-system/special-level-list`, // 等级列表
  specialTargetList: `${Manager}/risk-system/special-target-list`, // 风控目标列表
  saveSpecialUser: `${Manager}/risk-system/save-special-user`, // 添加特殊用户
  deleteSpecialUser: `${Manager}/risk-system/delete-special-user`, // 删除特殊用户
  giftRecordList: `${Manager}/gift-record/list`, // 医生的积分变动记录
  syncRuleConfig: `${Manager}/risk-system/sync-rule-config`,
  recordContextData: `${Manager}/risk-system/record-context-data`, // 风控记录上下文数据
  speakerFeeList: `${Manager}/speaker-fee/list`, // 医生的讲课费收支列表
  getOrgRiskData: `${Manager}/risk-system/data/get-org-risk-data`, // 厂家风险监控
  getRiskRecordTrend: `${Manager}/risk-system/data/get-risk-record-trend`, // 风险趋势
  getDoctorIncomeRank: `${Manager}/risk-system/data/get-doctor-income-rank`, // 获取医生收入排名
  getHeadAverageTrend: `${Manager}/risk-system/data/get-head-average-trend`, // 头部平均数变化趋势 /重复视频，重复题目，月平均收入
  getDoctorAuthenticityTrend: `${Manager}/risk-system/data/get-doctor-authenticity-trend`, // 获取医生真实度
  getBaseRiskData: `${Manager}/risk-system/data/get-base-risk-data`, // 获取基础风控信息
  getLiveTimeTrend: `${Manager}/risk-system/data/get-live-time-trend`, // 获取直播时长趋势
  getDoctorFactory: `${Manager}/risk-system/data/get_doctor_factory`, // 获取医生关联厂家列表

  blockRecordList: `${Manager}/risk-system/un-block-record-list`,  // 申请解冻工单列表
  reviewBlockRecord: `${Manager}/risk-system/review-un-block-special-user-record`,    // 审核记录
  blockRecorddetail: `${Manager}/risk-system/un-block-record-detail`,    // 审核记录

  // 平台数据大屏
  platformBaseInfo: `${Manager}/risk-system/data/platform-base-info`, // 趣学术平台数据大屏-基础数据
  addLossWarningList: `${Manager}/risk-system/data/add-loss-warning-list`, // 厂家流失增加预警
  getOrgDoctorDataTrend: `${Manager}/risk-system/data/get-org-doctor-data-trend`, // 获取厂家趋势数据

  // 课程管理
  getCourseList: `${Manager}/train/course-list`, // 获取课程列表
  saveCourseData: `${Manager}/train/modify-course`, // 新建/编辑课程
  getCourseData: `${Manager}/train/course-detail`, // 查看课程详情
  getContactList: `${Manager}/train/contact-list`, // 获取课程绑定人员列表
  changeCourseSale: `${Manager}/train/course-sale`, // 代表培训课程上下架
  onTrainCreateExam: `${Manager}/train/v1/exam/create`, // 创建代表培训题目
  getCourseOrderList: `${Manager}/train/order-list`, // 获取订单列表
  getCourseOrderDetail: `${Manager}/train/order/course-info`, // 查询订单中课程的价格和视频数
  saveContractData: `${Manager}/train/create-contract`, // 新建合同
  getContractData: `${Manager}/train/contract/order-detail`, // 订单信息查看
  getContractAudit: `${Manager}/train/contract-audit`, // 代表培训合同审核
  getFactoryList: `${Manager}/factory-list`, // 获取厂家列表（模糊搜索 ）
  getUploadVideoInfo: `${API_BASE_V2}/public/v1/video/get-create-video`, // 阿里云视频上传
  updateVideo: `${API_BASE_V2}/public/v1/video/update`, // 编辑视频
  getVideoDetail: `${API_BASE_V2}/public/v1/video/show`, // 视频详情
  uploadImg: `${API_BASE_V2}/public/v1/upload-image`, // 上传图片
  getVideoCut: `${API_BASE_V2}/public/video/proved-editing`, // 视频剪成3分钟试看
  uploadPdf: `${Manager}/upload/pdf`, // 上传pdf合同
  exportTrainOrderList: `${Manager}/train/order-list-export`, // 导出订单

  // 知识商城
  knowledgeIndexData: `${Manager}/knowledge/course-manage-index/top-data`, // 管理首页顶部数据
  funBeanPutList: `${Manager}/knowledge/fun-bean/put-in-list`, // 趣豆投放批次列表
  funBeanRule: `${Manager}/knowledge/course-manage-index/fun-bean-action-rule`, // 趣豆行为规则列表
  updateFunBeanRule: `${Manager}/knowledge/course-manage-index/save-or-update-fun-bean-action-rule`, // 趣豆行为规则&防刷设置
  addPutFunBean: `${Manager}/knowledge/fun-bean/add-put-in`, // 新增趣豆投放批次（投放给所有医生）
  addPutTargetedFunBean: `${Manager}/knowledge/fun-bean/add-targeted-put-in`, // 新增定向投放
  getSpeakerList: `${Manager}/knowledge/special-speaker/list`, // 讲者管理-讲者列表
  getSpeakerDetail: `${Manager}/knowledge/special-speaker/detail`, // 讲者信息详情（只包括基础信息和收入信息）
  getSpeakerCourseList: `${Manager}/knowledge/special-speaker/course-list`, // 某个讲者的所有课程列表
  deleteSpeakerCourse: `${Manager}/knowledge/special-speaker/delete-course`, // 删除讲者的某个课程
  updateIntroOrKpi: `${Manager}/knowledge/special-speaker/update-intro-or-kpi`, // 更新特约讲者简介或KPI
  getAllSpeakerCourseList: `${Manager}/knowledge/course/list`, // 所有讲者的课程列表（包括已购/未购和热门课程，根据类型区分）
  updateCourseState: `${Manager}/knowledge/course/up-or-down-shelf`, // 课程上/下架
  refuseCourse: `${Manager}/knowledge/course/purchase-or-refuse`, // 课程确认/拒绝采购
  speakerCourseDetail: `${Manager}/knowledge/course/detail`, // 查看课程详情
  saveSpeakerCourseDetail: `${Manager}/knowledge/course/save`, // 保存/修改课程（知识商城）
  updateHotCourse: `${Manager}/knowledge/course/set-or-remove-hot-course`, // 设置/移除热门课程
  getTagList: `${Manager}/knowledge/tag/page-list`, // 标签列表
  addTagList: `${Manager}/knowledge/tag/add`, // 添加标签
  deleteTagList: `${Manager}/knowledge/tag/delete`, // 删除标签
  checkDoctorList: `${Manager}/knowledge/course-manage-index/check-doctor-list`, // 检查导入前的医生名单
  addNewDoctor: `${Manager}/knowledge/course-manage-index/add-limited-course-doctor`, // 新增限定课程参与的医生
  getCourseTagList: `${Manager}/knowledge/tag/query-course-tag-list`, // 某个课程的标签列表
  addCourseTagList: `${Manager}/knowledge/tag/bind-course-tag`, // 给某个课程添加标签列表
  updateHotTag: `${Manager}/knowledge/tag/set-or-remove-hot-tag`, // 给某个标签设置/移除热门
  hotTagList: `${Manager}/knowledge/tag/hot-tag-list`, // 热门标签列表
  getAllTagList: `${Manager}/knowledge/tag/list`, // 所有标签列表（不分页）
  downloadDoctorList: `${Manager}/knowledge/fun-bean/download-targeted-put-in-doctor-list`, // 下载定向投放的医生数据
  getLiveSquare: `${Manager}/live/square`,
  // 报表相关
  addModel: `${Manager}/report/model/save-or-update-smart-model`, // 添加模板
  deleteSmartModel: `${Manager}/report/model/delete-smart-model`, // 根据报表id删除智能报表
  getSmartReportFormList: `${Manager}/report/model/smart-model-list`, // 获取智能报表模版列表
  getModelDetail: `${Manager}/report/model/detail`, // 查看报表模板详情
  updateSmartModelStatus: `${Manager}/report/model/update-smart-model-status`, // 更改智能报表的发布状态
  getColumnValues: `${Manager}/report/table-unit/get-column-values`, // 根据表名和列名查询该列的枚举值字符串
  saveOrUpdateCategory: `${Manager}/report/category/save-or-update-category`, // 新增/编辑分类
  getListCategory: `${Manager}/report/category/list-category`, // 模板分类列表（支持根据分类名称查单个分类信息）
  deleteCategory: `${Manager}/report/category/delete-category`, // 根据分类id删除分类
  getTableList: `${Manager}/report/table-unit/get-table-list`, // 列出基表有效数据列的所有表及其描述
  getColumnList: `${Manager}/report/table-unit/get-column-list`,
  getRequestSqlMemberList: `${Manager}/report/model/get-request-sql-member-list`,
  updateReportPublishStatus: `${Manager}/report-model/update-report-publish-status`, // 根据数据报告模板id删除报告模板
  queryModelDataDirect: `${Manager}/report/model/query-model-data-direct`, // 根据报表条件直接查询某个厂家的报表数据
  queryModelDataByWhere: `${Manager}/report/model/query-model-data-by-where`, // 根据报表过滤条件查询某个字段的所有不重复值
  queryModelDataByHaving: `${Manager}/report/model/query-model-data-by-having`, // 根据报表过滤条件查询聚合项的所有不重复值
  queryPlaceholder: `${Manager}/report/model/query-placeholder`, // 占位条件查询
  getSameColumnList: `${Manager}/report/table-unit/get-same-column-list`, // 查询多表共同操作列
  queryOrgUser: `${Manager}/report/dataServicePush/query-org-user`, // 查询组织用户
  // 报告模板
  saveOrUpdate: `${Manager}/report-model/save-or-update`, // 新增/编辑数据报告模板
  updateStatus: `${Manager}/report-model/update-status`, // 更改数据报告模板的发布状态
  reportModelPageList: `${Manager}/report-model/page-list`, // 数据报告模板分页列表
  reportModelDelete: `${Manager}/report-model/delete`, // 根据数据报告模板id删除报告模板
  detail: `${Manager}/report-model/detail`, // 根据报告id查询报告模板详情
  updateReportStatus: `${Manager}/report/update-report-status`, // 更改数据报告的发布状态
  editTableColUnit: `${Manager}/report/dataSource/edit-tableColUnit`, // 编辑数据列

  // 报告
  getOrgList: `${Manager}/ksh/org/get-org-list`, // 组织列表
  getFullOrgList: `${Manager}/org/v1/org-list`, // 组织列表
  findAllParentOrgIdList: `${Manager}/ksh/org/find-all-parent-org-id-list`, // 查询某个组织的所有父级组织id列表
  reportPageList: `${Manager}/report/page-list`, // 数据报告分页列表
  reportSaveOrUpdate: `${Manager}/report/save-or-update`, // 新增/编辑数据报告
  reportDetail: `${Manager}/report/detail`, // 根据报告id查询报告详情
  reportDelete: `${Manager}/report/delete`, // 根据数据报告模板id删除报告模板
  queryModelData: `${Manager}/report/model/query-model-data`, // 根据报表id查询报表列表数据
  getEmail: `${Manager}/user/get-email`, // 获取当前登录用户邮箱

  // 数据源管理
  allTableName: `${Manager}/report/dataSource/all-tableName`, // 查询所有数据表
  deleteDataTable: `${Manager}/report/dataSource/delete-data-table`, // 删除相关表的全部数据
  selectList: `${Manager}/report/dataSource/select-list`, // 查询单个表明细数据
  deleteTableColUnit: `${Manager}/report/dataSource/delete-tableColUnit`, // 删除相关表的单个数据
  saveTableColUnit: `${Manager}/report/dataSource/save-tableColUnit`, // 新增或者修改数据列
  updateIsPublic: `${Manager}/report/dataSource/update-isPublic`, // 修改公开状态

  // 运行辅助工具接口
  uploadImage: `${Manager}/ksh/image`, // 上传图片

  // 售后配置
  getAllReason: `${API}/policy/getAllReason`,   // 获取可配置理由列表
  afterSaleReasonList: `${API}/policy/afterSaleReasonList`,   // 售后理由列表
  updateAfterSaleReason: `${API}/policy/updateAfterSaleReason`,   // 更新售后理由
  getAfterSaleReasonListBySupplierAndServiceType: `${API}/policy/getAfterSaleReasonListBySupplierAndServiceType`,   // 根据供应商和售后服务类型获取可使用的售后列表

  // 品牌宣传
  getActivityList: `${Manager}/branding/list`, // 活动列表
  delActivity: `${Manager}/branding/del`, // 删除活动
  changeActivityStatus: `${Manager}/branding/change-status`, // 活动状态
  getPostList: `${Manager}/branding/vote-push-record`, // 推送列表
  getBrandingDetailView: `${Manager}/branding/detail-view`,     // 查看品宣基础信息
  getBrandingAccessRecord: `${Manager}/branding/branding-access-record`,     // 访问记录
  getBrandingVoteRecord: `${Manager}/branding/vote-record`,     // 投票记录
  getBrandingStatus: `${Manager}/branding/change-status`,     // 活动状态
  getBrandingDel: `${Manager}/branding/del`,    // 活动删除
  delPost: `${Manager}/branding/vote-push-del`, // 删除推送
  getPostDetail: `${Manager}/branding/branding-push-view`, // 推送详情
  savePostDetail: `${Manager}/branding/branding-push-create`, // 创建推送
  changePostStatus: `${Manager}/branding/change-push-status`, // 编辑推送状态
  brandingSave: `${Manager}/branding/save`, // 新建/编辑活动
  getTreeList: `${Manager}/org/v1/tree-list`, // 通用部门架构列表
  detailViewAll: `${Manager}/branding/detail-view-all`, // 查看品宣详细信息

  // 数据服务
  dataServiceSaveOrUpdate: `${Manager}/report/dataService/save-or-update`, // 新增/编辑数据服务
  dataServiceQueryList: `${Manager}/report/dataService/query-list`, // 查询数据服务列表
  dataServiceDel: `${Manager}/report/dataService/del`, // 删除数据服务
  changePublishStatus: `${Manager}/report/dataService/change-publish-status`, // 修改数据服务发布状态
  dataServiceDetail: `${Manager}/report/dataService/detail`, // 查看数据服务详情
  dataServicePushSaveOrUpdate: `${Manager}/report/dataServicePush/save-or-update`, // 新增/编辑数据推送
  dataServicePushQueryList: `${Manager}/report/dataServicePush/query-list`, // 查询数据推送服务列表
  dataServicePushChangeAuthSataus: `${Manager}/report/dataServicePush/change-auth-status`, // 修改数据服务状态
  dataServiceQueryAccessList: `${Manager}/report/dataService/query-access-list`, // 查询数据服务访问记录
  dataServicePushDel: `${Manager}/report/dataServicePush/del`, // 删除数据推送服务
  dataServicePushDetail: `${Manager}/report/dataServicePush/detail`, // 查看数据服务推送详情
  changeCategoryStatus: `${Manager}/report/category/change-category-status`, // 根据分类id修改发布状态

  // ==================== 推送管理 ==========================
  // 推送列表
  pushRecordList: `${Manager}/ksh/message/v1/push-record-list`,
  // 新增推送
  addPushRecord: `${Manager}/ksh/message/v1/add-push-record`,
  // 获取推送信息详情
  getPushRecordDetail: `${Manager}/ksh/message/v1/get-record-detail`,
  // 更新推送
  updatePushRecord: `${Manager}/ksh/message/v1/update-push-record`,
  // 获取文件模版
  downPushCheckModelFile: `${Manager}/ksh/message/v1/get/model-file`,
  // 上传校验用户模版
  uploadPushCheckModelFile: `${Manager}/ksh/message/v1/valid/model-file`,
  // 获取上传的推送用户信息
  getUploadPushDoctorInfo: `${Manager}/ksh/message/v1/get/data-by-id`,
  // 获取物流信息
  getOrderDistributeInfo: `${Manager}/points-mall/get-order-distribute-info`,
  // 下载推送数据
  downPushMsg: `${Manager}/ksh/message/v1/download-push-msg`,
  // 删除推送数据
  delPushMsg: `${Manager}/ksh/message/v1/delete-push-record`,

  // 盘古云脑
  saveOrUpdateUnit: `${Manager}/cloud-brain/v2/save-or-update-unit`,
  selectUnitPage: `${Manager}/cloud-brain/v2/select-unit-page`,
  selectOrgModule: `${Manager}/cloud-brain/v2/select-org-module`,
  unitDetail: `${Manager}/cloud-brain/v2/delete-unit`,
  selectOrgModuleAndUnit: `${Manager}/cloud-brain/v2/select-org-module-and-unit`,
  editModuleAndUnitRelation: `${Manager}/cloud-brain/v2/edit-module-and-unit-relation`,
  selectModuleAndUnitCount: `${Manager}/cloud-brain/v2/select-module-and-unit-count`,
  saveModuleAndUnitRelation: `${Manager}/cloud-brain/v2/save-module-and-unit-relation`,
  updateOrgModule: `${Manager}/cloud-brain/v2/update-org-module`,
  initialization: `${Manager}/cloud-brain/v2/initialization`,
  setOrgModuleIndex: `${Manager}/cloud-brain/v2/set-org-module-index`,
  editOrgModuleUnit: `${Manager}/cloud-brain/v2/edit-org-module-unit`,
  saveOrgModuleUnit: `${Manager}/cloud-brain/v2/save-org-module-unit`,
  saveOrUpdateSpecial: `${Manager}/cloud-brain/v2/save-or-update-special`,
  selectSpecialConfig: `${Manager}/cloud-brain/v2/select-special-config`,
  initializationBatch: `${Manager}/cloud-brain/v2/initialization-batch`,
  // 商城秒杀相关
  getSeckillList: `${Manager}/market-campaign/second-kill/v1/list`, // 秒杀列表
  getSeckillDetail: `${Manager}/market-campaign/second-kill/v1/detail`, // 秒杀详情
  saveSeckill: `${Manager}/market-campaign/second-kill/v1/save`, // 保存/编辑秒杀
  deleteSeckill: `${Manager}/market-campaign/second-kill/v1/delete`, // 删除秒杀
  publishSeckill: `${Manager}/market-campaign/second-kill/v1/publish`, // 发布秒杀
  endSeckill: `${Manager}/market-campaign/second-kill/v1/end`, // 结束秒杀
  // 活动专区
  getActivityAreaList: `${Manager}/activity-area/v1/list`, // 活动专区列表
  getActivityAreaDetail: `${Manager}/activity-area/v1/detail`, // 活动专区详情
  saveActivityArea: `${Manager}/activity-area/v1/save-or-update`, // 保存/编辑活动专区
  deleteActivityArea: `${Manager}/activity-area/v1/delete`, // 删除活动专区
  getListForStock: `${Manager}/product-manager/list-for-stock`, // 获得商品库存单位列表

  // ==================== 厂家设置 ==========================
  setDoctorCertifyRule: `${Manager}/org/v1/doctor/update-certify-rule`, // 提交医生认证规则列表
  getDoctorCertifyRule: `${Manager}/org/v1/doctor/certify-rule`, // 获取医生认证规则
  getUnpurchasedList: `${Manager}/org/v1/doctor/unpurchased-list`, // 查询厂家未完成的预报账单

  // 云联络中心
  createTcccSDKLoginToken: `${Manager}/tccc/createSDKLoginToken`,

  // 自定义权限
  getPermissionList: `${Manager}/permission/list`, // 获取自定义权限列表
  addOrUpdatePermission: `${Manager}/permission/add-or-update`, // 新增或编辑分组
  deletePermission: `${Manager}/permission/delete`, // 删除分组
  sectionPermissionDetail: `${Manager}/permission/section-permission-detail`,
  unAssignPermissionList: `${Manager}/permission/get-un-assign-permission-list`,
  assignPermission: `${Manager}/permission/assign-permission`, // 分配权限给原生角色
  setPermissionClass: `${Manager}/permission/class`,
  cleanUpCachePermission: `${Manager}/permission/clean-up-cache-permission`, // 清除缓存权限
  roleList: `${Manager}/permission/role-list`, // 角色列表
  assignAblePermissionList: `${Manager}/permission/role/assign-able-permission-list`, // 权限列表管理员可分配
  assignPermissionToRole: `${Manager}/permission/role/assign-permission-to-role` // 分配权限给原生角色
}
