import request from '@/utils/request'
import API from './api-types'

// 趣学术文章分类列表
export function categoryList(params) {
  return request({
    url: API.categoryList,
    method: 'get',
    params
  })
}

// 编辑趣学术文章分类
export function categoryAdd(data) {
  return request({
    url: API.categoryAdd,
    method: 'post',
    data
  })
}

// 删除趣学术文章分类
export function categoryDel(params) {
  return request({
    url: API.categoryDel,
    method: 'get',
    params
  })
}

// 获取趣学术文章列表
export function articleList(params) {
  return request({
    url: API.articleList,
    method: 'get',
    params
  })
}

// 获取趣学术文章内容
export function articleDetail(params) {
  return request({
    url: API.articleDetail,
    method: 'get',
    params
  })
}

// 保存趣学术文章
export function articleSave(data) {
  return request({
    url: API.articleSave,
    method: 'post',
    data
  })
}

// 发布|下架趣学术文章
export function articleChangeStatus(params) {
  return request({
    url: API.articleChangeStatus,
    method: 'get',
    params
  })
}

// 获取平台反馈列表
export function platformFeedbackList(params) {
  return request({
    url: API.platformFeedbackList,
    method: 'get',
    params
  })
}

// 标记为已解决
export function markFixed(params) {
  return request({
    url: API.markFixed,
    method: 'get',
    params
  })
}

// 获取新手引导详情
export function guideDetail(params) {
  return request({
    url: API.guideDetail,
    method: 'get',
    params
  })
}

// 保存引导内容
export function guideSave(data) {
  return request({
    url: API.guideSave,
    method: 'post',
    data
  })
}
