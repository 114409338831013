<template>
  <div>
    <div
      ref="editor"
      style="text-align: left;"
    />
  </div>
</template>

<script>
// import E from "wangeditor";
import E from '@/static/release/wangEditor.js'
import { getToken } from '@/utils/auth'

export default {
  name: 'Editor',
  props: {
    content: {
      type: String,
      default: ''
    },
    classify: {
      type: String,
      default: '109'
    },
    textOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editor: null,
      editorContent: null,
      isChange: false // 加个判断值
    }
  },
  watch: {
    content(val) {
      if (!this.isChange) {
        if (!val) {
          this.editorContent = val
          this.editor.txt.html(val)
        }
        if (val !== this.editor.txt.html()) {
          this.editorContent = val
          this.editor.txt.html(val)
        }
      }
      this.isChange = false
    }
  },
  mounted() {
    const self = this
    const editor = new E(this.$refs.editor)
    // 下面两个配置，使用其中一个即可显示“上传图片”的tab。但是两者不要同时使用！！！
    // editor.customConfig.uploadImgShowBase64 = true   // 使用 base64 保存图片
    editor.customConfig.uploadImgServer =
      `/manager/separate/v1/upload-image?bucketType=${self.classify}` // 上传图片到服务器 商品：classify=109、 文章：classify= 115
    editor.customConfig.withCredentials = true
    editor.customConfig.uploadImgHooks = {
      before: function(xhr, edit, files) {
        const formData = new FormData()
        formData.append('image', files[0])
        console.log(formData, 11)
        console.log(files)
        xhr.open('POST', `/manager/separate/v1/upload-image?bucketType=${  self.classify}`)
        xhr.setRequestHeader('token', getToken())
        xhr.send(formData)
      },
      // 如果服务器端返回的不是 {errno:0, data: [...]} 这种格式，可使用该配置
      customInsert: function(insertImg, result) {
        console.log('result', result)
        const url = result.data.large
        insertImg(url)
      }
    }
    editor.customConfig.onchange = html => {
      this.isChange = true
      this.editorContent = html
      self.$emit('update', this.editorContent)
    }
    if (this.textOnly) {
      editor.customConfig.menus = [
        'head', 'bold', 'fontSize', 'fontName', 'italic', 'underline', 'strikeThrough',
        'foreColor', 'backColor', 'link', 'list', 'justify', 'quote', 'undo', 'redo'
      ]
    }
    editor.create()
    setTimeout(() => {
      editor.txt.html(this.content)
    }, 1000)
    this.editor = editor
  },
  methods: {
    getContent() {
    }
  }
}
</script>

<style>
.w-e-text-container {
  height: 800px !important;
}
</style>
