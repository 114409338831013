<template>
  <div class="home">
    <div class="prod-panel">
      <div class="base-detail">
        <el-form
          ref="form"
          label-width="80px"
        >
          <div class="label-tab">
            | 订单状态
          </div>
          <el-steps
            v-if="orderDetail.orderStatus !== 5"
            :active="orderDetail.orderStatus + 1"
          >
            <!--<el-step title="已下单" description="这是一段很长很长很长的描述性文字"></el-step>-->
            <el-step title="已下单，未付款" />
            <el-step title="已付款" />
            <el-step title="已下单，待发货" />
            <el-step title="已发货，待收货" />
            <el-step title="已完成" />
            <el-step
              v-if="orderDetail.orderStatusHistory && orderDetail.orderStatusHistory.indexOf(6) >= 0"
              title="部分退款"
            />
          </el-steps>
          <el-steps
            v-else
            :active="orderDetail.orderStatus + 1"
          >
            <!--<el-step title="已下单" description="这是一段很长很长很长的描述性文字"></el-step>-->
            <el-step
              v-if="orderDetail.orderStatusHistory && orderDetail.orderStatusHistory.indexOf(0) >= 0"
              title="已下单，未付款"
            />
            <el-step
              v-if="orderDetail.orderStatusHistory && orderDetail.orderStatusHistory.indexOf(1) >= 0"
              title="已付款"
            />
            <el-step
              v-if="orderDetail.orderStatusHistory && orderDetail.orderStatusHistory.indexOf(2) >= 0"
              title="已下单，待发货"
            />
            <el-step
              v-if="orderDetail.orderStatusHistory && orderDetail.orderStatusHistory.indexOf(3) >= 0"
              title="已发货，待收货"
            />
            <el-step
              v-if="orderDetail.orderStatusHistory && orderDetail.orderStatusHistory.indexOf(4) >= 0"
              title="已完成"
            />
            <el-step title="订单关闭" />
          </el-steps>

          <div class="label-tab">
            | 订单信息
          </div>
          <el-row>
            <el-col :span="8">
              <el-form-item label="订单编号">
                {{ orderDetail.orderId }}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="下单时间">
                {{ orderDetail.orderCreateTime }}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="供应商">
                {{ supplierStr(orderDetail.supplierType) }}
                <span v-show="[0,8].includes(orderDetail.supplierType)">（单号：{{ orderDetail.outOrderId }}）</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="客户姓名">
                {{ orderDetail.customerName }}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="客户手机">
                {{ orderDetail.customerMobile }}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="是否已开发票">
                {{ orderDetail.isInvoiced === 0 ? '否' : '是' }}
              </el-form-item>
            </el-col>
          </el-row>
          <div v-if="orderDetail.orderStatus === 1 && [0,8].includes(orderDetail.supplierType) & testButton">
            <div class="label-tab">
              | 测试人员专用
            </div>
            <el-button @click="confirmOrder">
              发货
            </el-button>
          </div>
          <div class="label-tab">
            | 商品信息
          </div>
          <el-table
            ref="multipleTable"
            v-loading="loading"
            :data="orderDetail.itemList"
            border
            style="width: 100%;"
          >
            <el-table-column
              prop="skuId"
              label="商品ID"
              min-width="8%"
            />
            <el-table-column
              prop="code"
              label="商品编号"
              min-width="8%"
            />
            <el-table-column
              label="商品图片"
              min-width="12%"
            >
              <template slot-scope="scope">
                <el-image
                  style="width: 100px; height: 100px;"
                  :src="scope.row.itemImg"
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="itemName"
              label="商品名称"
              min-width="20%"
            />
            <el-table-column
              label="商品规格"
              min-width="6%"
            >
              <template slot-scope="scope">
                {{ scope.row.desc }}
              </template>
            </el-table-column>
            <el-table-column
              prop="count"
              label="数量"
              min-width="6%"
            />
            <el-table-column
              label="供应商"
              min-width="8%"
            >
              <template slot-scope="scope">
                {{ supplierStr(scope.row.supplierType) }}
              </template>
            </el-table-column>
            <!--<el-table-column-->
            <!--prop="catName"-->
            <!--label="分类"-->
            <!--min-width="6%">-->
            <!--</el-table-column>-->
            <el-table-column
              label="销售价"
              min-width="6%"
            >
              <template slot-scope="scope">
                {{ fenToYuan(scope.row.price) }}
              </template>
            </el-table-column>
            <el-table-column
              label="采购价"
              min-width="6%"
            >
              <template slot-scope="scope">
                {{ fenToYuan(scope.row.channelPrice) }}
              </template>
            </el-table-column>
            <!--<el-table-column-->
            <!--label="利润"-->
            <!--min-width="6%">-->
            <!--<template slot-scope="scope">-->
            <!--{{  fenToYuan(scope.row.originalPrice) }}-->
            <!--</template>-->
            <!--</el-table-column>-->
            <!--<el-table-column-->
            <!--prop="catName"-->
            <!--label="售后政策"-->
            <!--min-width="6%">-->
            <!--</el-table-column>-->
            <el-table-column
              prop="remark"
              label="用户备注"
              min-width="6%"
            />
          </el-table>

          <div class="label-tab">
            | 订单备注
          </div>
          <el-input
            v-if="orderDetail.orderStatus !== 5"
            v-model="orderDetail.remark"
            class="elm-input"
            placeholder="请输入内容"
          />
          <el-button
            v-if="orderDetail.orderStatus !== 5"
            @click="saveRemark"
          >
            保存
          </el-button>

          <div
            v-if="orderDetail.supplierType !== 2"
            class="label-tab"
          >
            | 运费
          </div>
          <el-form-item
            v-if="orderDetail.supplierType !== 2"
            label="运费"
          >
            {{ fenToYuan(orderDetail.distributeFee) }} 元
          </el-form-item>

          <div class="label-tab">
            | 收货地址
          </div>
          <el-row v-if="orderDetail.distributes&&orderDetail.distributes[0]">
            <el-col :span="8">
              <el-form-item label="收货人">
                {{ orderDetail.distributes[0].name + ": " + orderDetail.distributes[0].phone }}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="收货地址">
                {{ orderDetail.distributes[0].address }}
              </el-form-item>
            </el-col>
          </el-row>

          <div
            v-if="orderDetail.supplierType !== 2"
            class="label-tab"
          >
            | 物流信息
            <template v-if="[0].includes(orderDetail.supplierType)">
              <el-tabs v-model="deliverTab" class="company-tab" type="card">
                <template v-for="(item,index) in orderDetail.distributes || []">
                  <el-tab-pane :key="item.deliverNo" :label="'物流'+(index+1)" :name="String(index)">
                    <div>
                      <el-form-item label="物流公司">
                        {{ item.deliverCompany }}
                      </el-form-item>
                      <el-form-item label="物流单号">
                        {{ item.deliverNo }}
                      </el-form-item>
                      <el-table
                        v-if="item.distributeData"
                        :data="item.distributeData"
                        stripe
                        style="width: 800px; padding: 0 20px;"
                      >
                        <el-table-column
                          prop="content"
                          label="物流动态"
                          width="800px"
                        />
                      </el-table>
                    </div>
                  </el-tab-pane>
                </template>
              </el-tabs>
            </template>

            <div v-else>
              <div v-if="orderDetail.supplierType === 1 && orderDetail.orderStatus === 2">
                <el-form-item label="物流公司">
                  <el-input
                    v-model="deliverCompany"
                    class="elm-input"
                    placeholder="物流公司"
                  />
                </el-form-item>
                <el-form-item label="物流单号">
                  <el-input
                    v-model="deliverNo"
                    class="elm-input"
                    placeholder="物流单号"
                  />
                </el-form-item>
                <el-button
                  class="save-deliver-btn"
                  @click="saveDeliver"
                >
                  确认发货
                </el-button>
              </div>
              <div v-else-if="showDistributes && orderDetail.orderStatus !== 5">
                <el-button
                  v-if="[7].includes(orderDetail.commodityStyle)"
                  type="text"
                  style="margin-left: 15px;"
                  @click="onShowNum"
                >
                  {{ isShowNum ? '隐藏' : "查看" }}
                </el-button>
                <template v-for="(item,index) in showDistributes || []">
                  <div :key="item.deliverNo+index">
                    <el-form-item v-if="item.deliverCompany" label="物流公司">
                      {{ item.deliverCompany }}
                    </el-form-item>
                    <el-form-item :label="item.deliverCompany?'物流单号':`密码${index+1}`">
                      {{ item.deliverNo }}
                    </el-form-item>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <FooterWrapper>
            <el-button
              type="primary"
              @click="onSubmit"
            >
              返回订单列表
            </el-button>
            <el-button
              v-if="(orderDetail.supplierType === 1 && orderDetail.orderStatus < 3)"
              @click="closeOrder"
            >
              关闭订单
            </el-button>
            <el-button
              v-if="orderDetail.orderStatus === 4 && orderDetail.supplierType !== 2 && !orderDetail.rebateCents && cent"
              @click="openDialog"
            >
              返积分
            </el-button>
          </FooterWrapper>
        </el-form>
      </div>
    </div>
    <el-dialog
      title="返积分"
      :visible.sync="dialogVisible"
      width="30%"
      :center="true"
      :close-on-click-modal="false"
    >
      <el-tabs
        v-model="activeName"
        class="dialog-content"
      >
        <el-tab-pane
          label="按比例返"
          name="first"
        >
          <span>返还订单总金额</span>
          <el-select
            v-model="percent"
            class="dialog"
          >
            <el-option
              label="5%"
              value="0.05"
            />
            <el-option
              label="8%"
              value="0.08"
            />
            <el-option
              label="10%"
              value="0.10"
            />
            <el-option
              label="11%"
              value="0.11"
            />
            <el-option
              label="12%"
              value="0.12"
            />
            <el-option
              label="15%"
              value="0.15"
            />
            <el-option
              label="20%"
              value="0.20"
            />
            <el-option
              label="100%"
              value="1.00"
            />
          </el-select>
          <span>的学分到用户学分余额</span>
        </el-tab-pane>
        <el-tab-pane
          label="按数额返"
          name="second"
        >
          <span>返还</span>
          <el-input
            v-model="rebateFee"
            class="dialog"
            placeholder="请输入"
          />
          <span>学分到用户学分余额</span>
        </el-tab-pane>
        <span class="subject">返利说明</span>
        <el-input
          v-model="subject"
          placeholder="请输入"
          style="width: 150px;"
        />
      </el-tabs>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="closeDialogVisible">取 消</el-button>
        <el-button
          type="primary"
          @click="backIntegral"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import FooterWrapper from '@/components/common/footerWrapper.vue'
import { mapState, mapActions } from 'vuex'
import { fenToYuan } from '@/utils/util'
import saveDeliverData from '@/apis/saveDeliverData'
import updateRemark from '@/apis/updateRemark'
import confirmJdOrder from '@/apis/confirmJdOrder'
import cancelOrder from '@/apis/cancelJdOrder'

export default {
  components: {
    FooterWrapper
  },
  data() {
    return {
      loading: true,
      deliverTab: '0',
      activeName: 'first',
      name: '订单详情',
      activeIndex: '1',
      orderId: '',
      spUid: '15465456464',
      deliverCompany: '',
      deliverNo: '',
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      dialogVisible: false,
      rebateFee: '',
      percent: '',
      subject: '',
      formLabelWidth: '20px',
      testButton: true,
      cent: true,
      isShowNum: false,
      distributes: null
    }
  },
  computed: {
    ...mapState(['orderDetail', 'orderStatus']),
    showDistributes() {
      return this.isShowNum ? this.distributes : this.orderDetail.distributes
    }
  },
  mounted() {
    console.log('this.name ', this.name)
    this.$store.dispatch('changeTitle', this.name)
    this.orderId = this.$route.query.orderId
    this.$store.dispatch('getOrderDetail', { orderId: this.orderId}).then(() => {
      this.loading = false
    })
  },
  methods: {
    ...mapActions(['getOrderRebate']),
    onShowNum() {
      this.isShowNum = !this.isShowNum
      if (!this.isShowNum) return
      this.$axios.get(this.$API.getOrderDistributeInfo, {params: {orderId: this.orderId}}).then(res => {
        this.distributes = res || []
      }, rea => this.$message.error(rea.message))
    },
    supplierStr(value) {
      let obj = {
        JD: '京东',
        CAT: '一只猫',
        OFFICIAL: '红包话费',
        XT: '鲜通',
        '0': '京东',
        '1': '一只猫',
        '2': '红包话费',
        '8': '鲜通',
        '10': '十鹿'
      }
      return obj[String(value)] || '一只猫'
    },
    openDialog() {
      this.dialogVisible = true
    },
    closeDialogVisible() {
      this.dialogVisible = false
      this.subject = ''
      this.percent = ''
      this.rebateFee = ''
      this.activeName = 'first'
    },
    backIntegral() {
      if (this.activeName == 'first') {
        this.rebateFee = ''
      } else {
        if (!(/(^[1-9]\d*$)/.test(this.rebateFee))) {
          return this.$message.error('请输入正整数')
        }
        this.percent = ''
      }
      if (this.subject) {
        const req = {
          orderId: this.orderId,
          subject: this.subject,
          percent: this.percent,
          rebateFee: this.rebateFee
        }
        this.getOrderRebate(req).then(() => {
          this.closeDialogVisible()
          this.$message.success('操作成功')
          this.cent = false
        }, rea => {
          this.$message.error(rea.message)
        })
      } else {
        return this.$message.error('请输入返利类型')
      }
    },
    confirmOrder() {
      this.$confirm('确认发货？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        confirmJdOrder({
          orderId: this.orderId
        }).then(response => {
          if (response) {
            this.testButton = false
            this.$message({
              message: '发货成功',
              type: 'success'
            })
          }
        }).catch(error => {
          console.log(error)

          // this.$message.error(error.message)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        })
      })
    },
    closeOrder() {
      this.$confirm('关闭订单将退还所有积分给用户，此操作无法恢复，确认要取消么？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        cancelOrder({
          orderId: this.orderId
        }).then(response => {
          if (response) {
            this.$message({
              message: '关闭成功',
              type: 'success'
            })
            this.$router.push({
              name: 'orderList'
            })
          }
        }).catch(error => {
          // this.$message.error(error.message)
          console.log(error)

        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        })
      })
    },
    fenToYuan(fen) {
      return fenToYuan(fen)
    },
    handleSelect() {
      this.set('activeIndex', '2')
    },
    checkDetail(index, data) {
      console.log(`SKUID: ${data[index].skuID}`)
      this.$router.push({
        name: 'detail',
        query: { skuId: data[index].skuID }
      })
    },
    onSubmit() {
      this.$router.push({
        name: 'orderList'
      })
    },
    saveRemark() {
      updateRemark({
        remark: this.orderDetail.remark,
        orderId: this.orderId
      }).then(response => {
        if (response) {
          this.$message({
            message: '备注保存成功',
            type: 'success'
          })
        }
      }).catch(error => {
        // this.$message.error(error.message)
        console.log(error)

      })
    },
    saveDeliver() {
      if (this.deliverCompany && this.deliverNo) {
        saveDeliverData({
          deliverCompany: this.deliverCompany,
          deliverNo: this.deliverNo,
          orderId: this.orderId
        }).then(response => {
          if (response) {
            this.$message({
              message: '发货成功',
              type: 'success'
            })
          }
        }).catch(error => {
          // this.$message.error(error.message)
          console.log(error)

        })
      } else {
        this.$message.error('请输入')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  padding: 20px;
  .base-detail {
    padding: 20px;
  }
}
.save-deliver-btn {
  position: relative;
  left: 30px;
}
.label-tab {
  margin-top: 26px;
  margin-bottom: 16px;
  position: relative;
  left: -10px;
}
.elm-input {
  width: 300px;
}
.dialog-content {
  margin: 0 30px;
  font-weight: 700;
}
.dialog {
  width: 100px;
  margin: 20px;
}
.subject {
  padding-right: 20px;
}
.company-tab {
  padding: 20px;
}
</style>
