/*
 * @Author: your name
 * @Date: 2020-10-29 15:01:17
 * @LastEditTime: 2020-11-10 16:03:52
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /qxs-management/src/store/mutations-types.js
 */

// main
export const CHANGE_TITLE = 'CHANGE_TITLE'
export const SET_LOADING = 'SET_LOADING'

// 商品池
export const SET_POOL_NUMBERS = 'SET_POOL_NUMBERS'
export const SET_PROD_POOL = 'SET_PROD_POOL'
export const SET_PROD_RELATED = 'SET_PROD_RELATED'
export const SET_PROD_LIST = 'SET_PROD_LIST'
export const SET_PROD_POOL_ONE = 'SET_PROD_POOL_ONE'
export const SET_PROD_DETAIL = 'SET_PROD_DETAIL'
export const SET_PRODUCT_DETAIL = 'SET_PRODUCT_DETAIL'
export const REMOVE_IMG = 'REMOVE_IMG'
export const SET_MAIN_IMG = 'SET_MAIN_IMG'

export const SET_COMMODITY_TYPE = 'SET_COMMODITY_TYPE'
export const UPDATE_DETAIL_CONTENT = 'UPDATE_DETAIL_CONTENT'

// 商品库
export const SET_SERVICE_POLICY = 'SET_SERVICE_POLICY'
export const ADD_IMG = 'ADD_IMG'
export const ADD_PRODUCT_IMG = 'ADD_PRODUCT_IMG'
export const REMOVE_PRODUCT_IMG = 'REMOVE_PRODUCT_IMG'
export const SET_PRODUCT_MAIN_IMG = 'SET_PRODUCT_MAIN_IMG'
export const SET_PRODUCT_SKU_IMG = 'SET_PRODUCT_SKU_IMG'

// 订单列表
export const SET_ORDER_LIST = 'SET_ORDER_LIST'
export const GET_ORDER_DETAIL = 'GET_ORDER_DETAIL'

// 售后单列表
export const SET_AFTER_SALE_ORDER_LIST = 'SET_AFTER_SALE_ORDER_LIST'
export const GET_AFTER_SALE_ORDER_DETAIL = 'GET_AFTER_SALE_ORDER_DETAIL'

// 人脸识别
export const SET_FACE_REVIEW_LIST = 'SET_FACE_REVIEW_LIST'
export const SET_FACE_REVIEW_DETAIL = 'SET_FACE_REVIEW_DETAIL'
export const SET_DEPARTMENT_LIST = 'SET_DEPARTMENT_LIST'
export const CHANGE_FACE_ITEM_STATUS = 'CHANGE_FACE_ITEM_STATUS'
export const SET_FACE_SWITCH_DETAIL = 'SET_FACE_SWITCH_DETAIL'

// 分页器组件
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE'
export const SET_TOTAL = 'SET_TOTAL'

// 文章管理分类相关
export const SET_FEEDBACK_LIST = 'SET_FEEDBACK_LIST'
export const SET_CATEGORY_LIST = 'SET_CATEGORY_LIST'
export const SET_ARTICLE_LIST = 'SET_ARTICLE_LIST'
export const SET_GUIDE_DETAIL_LIST = 'SET_GUIDE_DETAIL_LIST'
export const SET_SHOW_AT = 'SET_SHOW_AT'
export const SET_CATEGORY_ID = 'SET_CATEGORY_ID'
export const SET_SECCATEGORY_ID = 'SET_SECCATEGORY_ID'
export const SET_STATUS = 'SET_STATUS'
export const SET_SEARCH_KEY = 'SET_SEARCH_KEY'
export const SET_DETAIL_OBJ = 'SET_DETAIL_OBJ'

// 直播审核
export const SET_LIVE_TABLE = 'SET_LIVE_TABLE' // 直播审核table
export const SET_LIVE_TABLE_TOTAL = 'SET_LIVE_TABLE_TOTAL'
export const SET_THIRD_SPEAKER_LIST = 'SET_THIRD_SPEAKER_LIST' // 人力家工单table
export const SET_THIRD_SPEAKER_LIST_TOTAL = 'SET_THIRD_SPEAKER_LIST_TOTAL'
export const SET_LIVE_DETAIL = 'SET_LIVE_DETAIL'    // 直播审核详情

// 消息系统
export const SET_MESSAGE_LIST = 'SET_MESSAGE_LIST'
// 商城管理-栏目管理
export const MANAGEMENT_COLUMN_LIST = 'MANAGEMENT_COLUMN_LIST'

// 医生管理
export const GET_AREA_LIST = 'GET_AREA_LIST'
export const GET_HOSPITAL_LIST = 'GET_HOSPITAL_LIST'

// 课程管理
export const GET_COURSE_LIST = 'GET_COURSE_LIST'
export const GET_COURSE_ORDER_LIST = 'GET_COURSE_ORDER_LIST'
export const GET_FACTORY_LIST = 'GET_FACTORY_LIST'
export const GET_CONTACT_LIST = 'GET_CONTACT_LIST'

// 讲者管理
export const GET_KNOWLEDGE_INDEX_DATA = 'GET_KNOWLEDGE_INDEX_DATA'
export const GET_SPEAKER_LIST = 'GET_SPEAKER_LIST'
export const GET_SPEAKER_COURSE_LIST = 'GET_SPEAKER_COURSE_LIST'
export const GET_ALL_SPEAKER_COURSE_LIST = 'GET_ALL_SPEAKER_COURSE_LIST'
