<template>
  <div class="container">
    <div class="title">
      药企详情
    </div>
    <el-form
      ref="factoryInfo"
      v-loading="loading"
      :model="factoryInfo"
      label-width="150px"
      :rules="rules"
    >
      <el-form-item
        label="公司名称"
        prop="name"
      >
        <el-input
          v-model="factoryInfo.name"
          placeholder="请输入公司名称"
          :validate-event="false"
        />
      </el-form-item>
      <el-form-item
        label="组织类型"
        prop="orgType"
      >
        <el-radio-group
          v-model="factoryInfo.orgType"
          @change="resetForm('factoryInfo')"
        >
          <el-radio
            :label="2"
            :disabled="editor"
          >
            厂家
          </el-radio>
          <el-radio
            :label="1"
            :disabled="editor"
          >
            公司
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <div
        v-show="factoryInfo.orgType == 1"
        class="company"
      >
        <el-col
          v-if="editor"
          :span="12"
        >
          <el-form-item
            label="累计金额"
            class="input"
          >
            <el-input
              v-model="factoryInfo.allRecharge"
              :readonly="editor"
              :disabled="editor"
            />
          </el-form-item>
        </el-col>
        <el-col
          v-if="editor"
          :span="12"
        >
          <el-form-item
            label="账户余额"
            class="input"
          >
            <el-input
              v-model="factoryInfo.giftCount"
              :readonly="editor"
              :disabled="editor"
            />
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item
            label="总部地址"
            prop="provinceId"
          >
            <el-select
              :value="province"
              placeholder="请选择省份"
              :span="12"
              @change="getAreaListChildren"
            >
              <el-option
                v-for="item in areaList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              />
            </el-select>
            <el-select
              :value="city"
              placeholder="请选择城市"
              :span="12"
              @change="getCity"
            >
              <el-option
                v-for="item in cityList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="规模等级"
            prop="scale"
          >
            <el-select
              v-model="factoryInfo.scale"
              placeholder="请选择规模等级"
            >
              <el-option
                v-for="item in scale"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="销售团队类型"
            prop="marketTeam"
          >
            <el-select
              v-model="factoryInfo.marketTeam"
              placeholder="请选择销售团队类型"
            >
              <el-option
                v-for="item in marketTeam"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="创新偏向"
            prop="innovationType"
          >
            <el-select
              v-model="factoryInfo.innovationType"
              placeholder="请选择创新偏向"
            >
              <el-option
                v-for="item in innovationType"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="主要产品类别"
            prop="medicineType"
          >
            <el-select
              v-model="factoryInfo.medicineType"
              placeholder="请选择主要产品类别"
            >
              <el-option
                v-for="item in medicineType"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="是否上市">
            <el-switch
              v-model="factoryInfo.listed"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="主要产品是否进集采">
            <el-switch
              v-model="factoryInfo.centralPurchase"
            />
          </el-form-item>
        </el-col>
      </div>
      <div class="factory">
        <el-col :span="12">
          <el-form-item
            label="企业号"
            prop="creditCode"
          >
            <el-input
              v-model="factoryInfo.creditCode"
              placeholder="请输入统一社会信用代码或营业执照号"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="对公账户">
            <el-input
              v-model="factoryInfo.corporateAccount"
              placeholder="请输入对公账户"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开户行">
            <el-input
              v-model="factoryInfo.openingBank"
              placeholder="请输入开户行"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开户行地址">
            <el-input
              v-model="factoryInfo.bankAddress"
              placeholder="请输入开户行地址"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="帐户证明">
            <el-upload
              action="/manager/separate/v1/upload-image?bucketType=108"
              :headers="headers"
              name="image"
              :limit="1"
              list-type="picture-card"
              :multiple="false"
              :show-file-list="true"
              :on-exceed="handleExceed"
              :before-upload="beforeUpload"
              :on-success=" handleSuccess"
              :before-remove="beforeRemove"
            >
              <i class="el-icon-plus" />
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="营业执照">
            <el-upload
              action="/manager/separate/v1/upload-image?bucketType=108"
              :headers="headers"
              name="image"
              :limit="1"
              list-type="picture-card"
              :multiple="false"
              :show-file-list="true"
              :on-exceed="handleSecondExceed"
              :before-upload="beforeSecondUpload"
              :on-success=" handleSecondSuccess"
              :before-remove="beforeSecondRemove"
            >
              <i class="el-icon-plus" />
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="最低分配">
            <el-select
              v-model="factoryInfo.minGift"
              placeholder="请选择最低分配"
            >
              <el-option
                label="0"
                value="0"
              />
              <el-option
                label="10"
                value="10"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="能否二答">
            <el-switch
              v-model="factoryInfo.answerTwice"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="对接人"
            prop="contact"
          >
            <el-input
              v-model="factoryInfo.contact"
              placeholder="请输入对接人"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="联系电话"
            prop="mobile"
          >
            <el-input
              v-model="factoryInfo.mobile"
              placeholder="请输入联系电话"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="销售经理"
            prop="saleManager"
          >
            <el-input
              v-model="factoryInfo.saleManager"
              placeholder="请输入B端销售经理"
            />
          </el-form-item>
        </el-col>
        <el-col
          v-if="!editor"
          :span="12"
        >
          <el-form-item
            label="账户后缀"
            prop="suffix"
          >
            <el-input
              v-model="factoryInfo.suffix"
              placeholder="请输入账户后缀（无需添加@）"
            />
          </el-form-item>
        </el-col>
        <el-col
          v-if="!editor"
          :span="12"
        >
          <el-form-item
            label="用户名"
            prop="userName"
          >
            <el-input
              v-model="factoryInfo.userName"
              placeholder="请输入用户名"
            />
          </el-form-item>
        </el-col>
        <el-col
          v-if="!editor"
          :span="12"
        >
          <el-form-item
            label="登录名"
            prop="login"
          >
            <el-input
              v-model="factoryInfo.login"
              placeholder="请输入登录名"
            />
          </el-form-item>
        </el-col>
        <el-col
          v-if="!editor"
          :span="12"
        >
          <el-form-item
            label="密码"
            prop="password"
          >
            <el-input
              v-model="factoryInfo.password"
              placeholder="请输入密码"
            />
          </el-form-item>
        </el-col>
      </div>
      <div>
        <el-form-item>
          <el-button
            type="primary"
            class="button qxs-btn-md"
            @click="submitForm('factoryInfo')"
          >
            保 存
          </el-button>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'FactoryDetailVue',
  data() {
    return {
      headers: {},
      factoryInfo: {
        name: '',
        orgType: 2,
        allRecharge: '',
        giftCount: '',
        provinceId: '',
        cityId: '',
        scale: '',
        marketTeam: '',
        innovationType: '',
        medicineType: '',
        listed: false,
        centralPurchase: false,
        creditCode: '',
        corporateAccount: '',
        openingBank: '',
        bankAddress: '',
        minGift: '',
        answerTwice: false,
        contact: '',
        mobile: '',
        saleManager: '',
        suffix: '',
        userName: '',
        login: '',
        password: '',
        accountImg: '',
        licenseImg: ''
      },
      baseRules: {
        name: [
          { required: true, message: '请输入公司名称' }
        ],
        orgType: [
          { required: true, message: '请选择组织类型', trigger: 'change' }
        ],
        creditCode: [
          { required: true, message: '请输入企业号', trigger: 'blur' }
        ],
        contact: [
          { required: true, message: '请输入对接人', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入联系电话', trigger: 'blur' }
        ],
        saleManager: [
          { required: true, message: '请输入销售经理', trigger: 'blur' }
        ],
        suffix: [
          { required: true, message: '请输入账户后缀', trigger: 'blur' }
        ],
        userName: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        login: [
          { required: true, message: '请输入登录名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      },
      marketTeam: [
        {
          id: 1,
          name: '自有'
        }, {
          id: 2,
          name: '代理'
        }, {
          id: 3,
          name: '综合'
        }
      ],
      innovationType: [
        {
          id: 1,
          name: '创新型偏向'
        }, {
          id: 2,
          name: '仿制型偏向'
        }, {
          id: 3,
          name: '综合型'
        }
      ],
      medicineType: [
        {
          id: 1,
          name: '生物药'
        }, {
          id: 2,
          name: '化学药'
        }, {
          id: 3,
          name: '中成药'
        }, {
          id: 4,
          name: '器材'
        }, {
          id: 5,
          name: '基因检测'
        }, {
          id: 6,
          name: '综合'
        }
      ],
      scale: [
        {
          id: 1,
          name: '大'
        },
        {
          id: 2,
          name: '中'
        },
        {
          id: 3,
          name: '小'
        }
      ],
      dialogVisible: false,
      editor: false,
      cityList: [],
      accountUrl: '',
      licenseUrl: '',
      loading: false,
      companyRules: {
        scale: [
          { required: true, message: '请选择规模等级', trigger: 'change' }
        ],
        marketTeam: [
          { required: true, message: '请选择销售团队类型', trigger: 'change' }
        ],
        innovationType: [
          { required: true, message: '请选择创新偏向', trigger: 'change' }
        ],
        medicineType: [
          { required: true, message: '请选择主要产品类别', trigger: 'change' }
        ],
        provinceId: [
          { required: true, message: '请选择总部地址' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters('DoctorDetail', ['areaList']),
    rules() {
      if (this.factoryInfo.orgType == 2) {
        return this.baseRules
      } else {
        return { ...this.baseRules, ...this.companyRules }
      }
    },
    province() {
      if (!this.factoryInfo.provinceId) return ''
      const id = this.factoryInfo.provinceId
      let name = ''
      this.areaList.map(item => {
        if (item.code == id) {
          name = item.name
        }
      })
      return name
    },
    city() {
      if (!this.factoryInfo.cityId) return ''
      const id = this.factoryInfo.provinceId
      const cityId = this.factoryInfo.cityId
      let name = ''
      this.areaList.map(item => {
        if (item.code == id) {
          item.childs.map(k => {
            if (k.code == cityId) {
              name = k.name
            }
          })
        }
      })
      return name
    }
  },
  mounted() {
    this.id = this.$route.query.id || ''
    this.headers.token = getToken()
    this.getAreaList()
    this.init()
  },
  methods: {
    ...mapActions('DoctorDetail', ['getFactoryInfo', 'getAreaList', 'setFactoryInfo']),
    init() {
      if (!this.id) {
        return
      }
      this.loading = true
      this.getFactoryInfo({ id: this.id }).then(res => {
        this.loading = false
        this.factoryInfo = {
          ...res,
          accountImg: res.accountImg || '',
          licenseImg: res.licenseImg || ''
        }
        if (res.id) {
          this.editor = true
        }
      }, () => {
        this.loading = false
      })
    },
    // 账户证明上传
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length +
          fileList.length} 个文件`
      )
    },
    handleSuccess(response) {
      const { imageName, middle } = response.data
      this.factoryInfo.accountImg = imageName
      this.accountUrl = middle
    },
    beforeUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG、PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    // 营业执照上传
    handleSecondExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length +
          fileList.length} 个文件`
      )
    },
    handleSecondSuccess(response) {
      const { imageName, middle } = response.data
      this.factoryInfo.licenseImg = imageName
      this.licenseUrl = middle
    },
    beforeSecondUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG、PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    beforeSecondRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    // 省市
    getAreaListChildren(parentId) {
      this.cityList = [...this.areaList].filter(v => parentId === v.code)[0].childs || []
      this.factoryInfo.provinceId = parentId
      this.factoryInfo.cityId = ''
    },
    getCity(childrenId) {
      this.factoryInfo.cityId = childrenId

    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].clearValidate()
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.onSubmit()
        } else {
          return false
        }
      })
    },
    onSubmit() {
      if (this.factoryInfo.orgType === 1) {
        if (!this.factoryInfo.provinceId || !this.factoryInfo.cityId) {
          this.$message.error('请选择总部地址')
          return false
        }
      }
      this.setFactoryInfo(this.factoryInfo).then(() => {
        this.$message.success('保存成功')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 30px;
}
.title {
  padding-bottom: 50px;
}
.input {
  width: 340px;
}
.button {
  position: sticky;
  margin-top: 50px;
  left: 45%;
}
.company {
  overflow: hidden;
}
.factory {
  overflow: hidden;
}
</style>
