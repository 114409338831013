import axios from 'axios'
import { Message } from 'element-ui'
import { getToken } from '@/utils/auth'

// import BASE_CONFIG from 'BASE_CONFIG'
// 创建axios实例
const service = axios.create({
  // baseURL: BASE_CONFIG.BASE_URL, // api 的 base_url
  baseURL: '/', // api 的 base_url
  timeout: 60000 // 请求超时时间
})
// request拦截器
service.interceptors.request.use(
  config => {
    config.headers['token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    return config
  },
  error => {
    // Do something with request error
    Promise.reject(error)
  }
)
// response 拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    const { data, code, message } = res
    if (code === 0) {
      return Promise.resolve(data)
    }
    if (code === 401) {
      let host = 'http://192.168.3.204:9999'
      if (window.location.hostname === 'qxs-manager.yaomaitong.cn') {
        host = 'https://manager.yaomaitong.cn'
      }
      if (window.location.hostname === 'qxs-manager.yaomaitong-pre.cn') {
        host = 'http://manager.yaomaitong-pre.cn'
      }
      console.log(host)
      // window.open(`${host}/login`, '_top')
      Message.error(message)
    } else {
      // Message.error(message)
      // todo 异常处理逻辑
      return Promise.reject({
        code: code,
        message: message
      })
    }
  },
  error => {
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)
export default service
