import request from '@/utils/request'
import API from './api-types'

// 下载商品列表文件
export default function submitDistributes(data) {
  return request({
    url: API.submitDistributes,
    method: 'post',
    data: data
  })
}

export function changeOrderStatus(params) {
  return request({
    url: API.changeOrderStatus,
    method: 'get',
    params
  })
}
