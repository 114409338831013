<template>
  <div class="container">
    <video
      id="my-video"
      class="video-js vjs-default-skin"
      controls
      preload="auto"
      poster="../assets/logo.png"
    >
      <source
        src="https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8"
        type="application/x-mpegURL"
      >
    </video>
  </div>
</template>

<script>
import videojs from 'video.js'
import 'videojs-contrib-hls'

export default {
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
    videojs('my-video', {
      bigPlayButton: false,
      textTrackDisplay: false,
      posterImage: true,
      errorDisplay: false,
      controlBar: true
    }, function() {
      this.play()
    })
  },
  methods: {
    onPlayerPlay() {
      alert('play')
    },
    onPlayerPause() {
      alert('pause')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style type="text/css" scoped>
  .container {
    background-color: #efefef;
    min-height: 100%;
  }
</style>
