<!--
 * @Author: your name
 * @Date: 2022-04-26 10:13:56
 * @LastEditTime: 2022-04-26 14:11:02
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /qxs-management/src/components/DoctorThridVerify/Index.vue
-->
<template>
  <el-dialog
    :visible="visible"
    title="第三方识别辅助认证《无码科技》"
    width="30%"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <!-- <div class="">第三方识别辅助认证《无码科技》</div> -->
    <div class="text-item">认证状态：{{ certifyText }}</div>
    <div slot="footer" class="flex-center mgn-t20">
      <el-button
        class="w140"
        :loading="loading"
        :type="certifyStatus === 1 ? 'primary': 'default'"
        @click="onSubmit"
      >
        {{ certifyStatus === 0 ? '继续认证成功' : '确定' }}
      </el-button>
      <el-button
        v-if="certifyStatus === 0"
        :type="certifyStatus === 0 ? 'primary': 'default'"
        class="w140"
        @click="onClose"
      >
        返回修改
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  name: 'DoctorThridVerify',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    recordId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      certifyStatus: ''
    }
  },
  computed: {
    certifyText() {
      switch (this.certifyStatus) {
        case 1:
          return '认证成功'
        case 0:
          return '认证失败'
        default:
          return ''
      }
    }
  },
  watch: {
    visible(isShow) {
      if (isShow) {
        this.loading = true
        this.getThridVerifyResult(this.recordId).then(res => {
          this.loading = false
          this.certifyStatus = res.certifyStatus
        }, rea => {
          this.loading = false
          this.$message.error(rea.message)
        })
      }
    }
  },
  methods: {
    ...mapActions('DoctorDetail', ['getThridVerifyResult']),
    onSubmit() {
      if (this.loading) return this.$message.info('正在处理中，请稍后！')
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('onSubmit')
    },
    onClose() {
      if (this.loading) return this.$message.info('正在处理中，请稍后！')
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('onCannel')
    }
  }
}
</script>
<style lang="scss" scoped>
  .w140 {
    width: 140px;
  }
  .text-item {
    font-size: 16px;
    color: #1e1e1e;
  }
</style>
