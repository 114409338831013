<template>
  <div class="home">
    <div class="tab-panel">
      <el-menu
        class="el-menu-demo"
        mode="horizontal"
      >
        <el-menu-item
          index="1"
          @click="scrollTo('1')"
        >
          基础信息
        </el-menu-item>
        <el-menu-item
          index="2"
          @click="scrollTo('2')"
        >
          基础说明
        </el-menu-item>
      </el-menu>
    </div>
    <div class="prod-panel">
      <div class="base-detail">
        <el-form
          ref="form"
          :model="searchObj"
          label-width="80px"
        >
          <div
            id="1"
            class="label-tab"
          >
            | 基础信息
          </div>
          <PanelWrapper>
            <el-form-item label="售后类型">
              <el-select
                v-model="searchObj.afterSaleType"
                placeholder="请选择售后类型"
                @change="() => isHeadSearch = false"
              >
                <el-option
                  v-for="item in serviceTypeList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="适用的服务商">
              <el-select
                v-model="searchObj.supplierType"
                placeholder="请选择适用的服务商"
                @change="() => isHeadSearch = false"
              >
                <el-option
                  v-for="item in supplierList"
                  :key="item.index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-button
              type="primary"
              class="qxs-btn-md"
              icon="el-icon-search"
              @click="onGetDetailList"
            >
              搜索
            </el-button>
          </PanelWrapper>

          <div
            id="2"
            class="label-tab"
          >
            | 基础说明
          </div>
          <PanelWrapper>
            <el-table
              :data="serviceDetailList.reasons"
              border
            >
              <el-table-column
                prop="reason"
                label="售后原因"
              />
              <el-table-column
                prop="address"
                label="操作"
                width="200px"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    @click.native.prevent="removeContent(scope.$index)"
                  >
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-select
              v-model="selectReasonsType"
              placeholder="请选择售后原因"
            >
              <el-option
                v-for="item in reasonsList"
                :key="item.id"
                :label="item.reason"
                :value="item.id"
              />
            </el-select>
            <el-button
              class="add-btn"
              @click="addContent"
            >
              + 添加
            </el-button>
          </PanelWrapper>

          <FooterWrapper>
            <el-button
              type="primary"
              @click="onSubmit"
            >
              保存
            </el-button>
            <el-button @click="() => $router.back()">取消</el-button>
          </FooterWrapper>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import PanelWrapper from '@/components/common/panelWrapper.vue'
import { scrollToElementId } from '@/utils/util'
import FooterWrapper from '@/components/common/footerWrapper.vue'

export default {
  name: 'ServiceSalesDetail',
  components: {
    FooterWrapper,
    PanelWrapper
  },
  data() {
    return {
      name: '售后原因',
      id: '',
      inputName: '',
      inputValue: '',
      serviceDetailList: {},
      serviceTypeList: [
        {
          name: '退款退货',
          code: 10
        },
        {
          name: '换货',
          code: 20
        },
        {
          name: '维修',
          code: 30
        },
        {
          name: '漏发货物',
          code: 40
        }
      ],
      supplierList: [
        {
          id: 0,
          name: '京东'
        },
        {
          id: 1,
          name: '一只猫'
        },
        {
          id: 8,
          name: '鲜通'
        }
      ],
      selectReasonsType: null,
      reasonsList: [],
      isHeadSearch: false,
      // 基础信息
      searchObj: {
        afterSaleType: null,
        supplierType: null
      }
    }
  },
  mounted() {
    this.onGetAllReason()
  },
  methods: {
    // 获取可配置理由列表
    onGetAllReason() {
      this.$axios.get(this.$API.getAllReason).then(res => {
        this.reasonsList = res || []
      })
    },
    // search
    onGetDetailList() {
      if (!this.searchObj.afterSaleType) return this.$message.error('请选择售后类型')
      if (!this.searchObj.supplierType && !(this.searchObj.supplierType === 0)) return this.$message.error('请选择适用的服务商')
      this.$axios.get(this.$API.getAfterSaleReasonListBySupplierAndServiceType, {params: this.searchObj}).then(res => {
        this.isHeadSearch = true
        let { reasons = [], supplierType, afterSaleType} = res
        this.serviceDetailList = { reasons: reasons || [], supplierType, afterSaleType}
      })
    },
    addContent() {
      console.log(this.selectReasonsType, '----------------------- selectReasonsType ---------------')
      if (!this.searchObj.afterSaleType) return this.$message.error('请选择售后类型')
      if (!this.searchObj.supplierType && !(this.searchObj.supplierType === 0)) return this.$message.error('请选择适用的服务商')
      if (!this.isHeadSearch) return this.$message.error('请先点击搜索按钮')
      for (let item of this.reasonsList) {
        if (item.id === this.selectReasonsType) {
          for (let it of this.serviceDetailList.reasons || []) {
            if (it.id === this.selectReasonsType) return this.$message.error('当前售后原因已存在！')
          }
          this.serviceDetailList?.reasons.push(item)
          this.selectReasonsType = null
          return
        }
      }
    },
    removeContent(index) {
      this.serviceDetailList?.reasons.splice(index, 1)
    },
    scrollTo(id) {
      scrollToElementId(id)
    },
    onSubmit() {
      console.log('保存')
      if (!this.serviceDetailList.reasons?.length) return this.$message.error('请添加售后理由')
      this.$axios.post(this.$API.updateAfterSaleReason, this.serviceDetailList).then(res => {
        if (res) {
          const msg = '保存成功'
          this.$message({
            message: msg,
            type: 'success'
          })
          this.$router.back()
        }
      }).catch(error => {
        this.$message.error(error.message)
        console.log(error)

      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .home {
    padding: 20px;
    .base-detail {
      padding: 20px;
    }
  }
  .input-name {
    width: 300px !important;
  }
  .input-value {
    width: 700px !important;
  }
  .add-btn {
    width: 200px !important;
  }
  .label-tab {
    margin-top: 26px;
    margin-bottom: 16px;
    position: relative;
    left: -10px;
  }
  .elm-input {
    width: 300px;
  }
</style>
