<template>
  <el-container>
    <el-main>
      <el-form :model="contract">
        <el-form-item label="公司名称:">
          <div v-if="check">
            {{ contract.factoryName }}
          </div>
          <el-autocomplete
            v-else
            v-model="contract.factoryName"
            style="width: 400px;"
            :fetch-suggestions="queryBaseSearchAsync"
            placeholder="请输入公司ID"
            @select="handleBaseSelect"
          />
        </el-form-item>
        <el-form-item label="合同金额:">
          <div v-if="check">
            {{ contract.amount }}
          </div>
          <el-input
            v-else
            v-model="contract.amount"
            placeholder="请输入合同金额"
            class="input"
            type="number"
          />
        </el-form-item>
        <el-form-item label="学习次数:">
          <div v-if="check">
            {{ contract.degree }}
          </div>
          <el-input
            v-else
            v-model="contract.degree"
            placeholder="请输入学习次数"
            class="input"
            type="number"
          />
        </el-form-item>
        <el-form-item v-if="!check" label="上传合同:" class="contract">
          <el-upload
            :action="uploadApi"
            :headers="headers"
            :file-list="fileList"
            :limit="1"
            :multiple="false"
            :on-exceed="handleExceed"
            :before-upload="beforeUpload"
            :on-success="handleSuccess"
            :before-remove="beforeRemove"
          >
            <el-button size="small" type="primary"> 点击上传 </el-button>
          </el-upload>
          <div class="jump-pdf">
            只支持上传pdf文件，
            <el-button type="text">
              <a href="https://smallpdf.com/cn/pdf-converter">在线转换格式通道</a>
            </el-button>
          </div>
        </el-form-item>
        <el-form-item v-if="!check" label="选择课程:">
          <el-button @click="getCourse"> 添加 </el-button>
        </el-form-item>
        <el-form-item>
          <el-drawer
            title="添加课程"
            :visible.sync="table"
            direction="rtl"
            size="50%"
            :with-header="false"
          >
            <div class="drawer">
              <div class="search">
                <span class="title">添加课程</span>
                <el-input v-model="courseName" placeholder="搜索课程名称" class="input" />
                <el-button type="primary" @click="searchCourse(1)"> 搜索 </el-button>
              </div>
              <el-table
                ref="multipleTable"
                :data="courseList"
                tooltip-effect="dark"
                style="width: 100%;"
                border
                @selection-change="handleSelectionChange"
              >
                <el-table-column type="selection" width="55" />
                <el-table-column label="课程名称" width="120" prop="title" />
                <el-table-column label="课程原价" prop="originalPrice" width="120" />
                <el-table-column label="实际价格" prop="markingPrice" width="120" />
                <el-table-column prop="videoCount" label="视频数量" show-overflow-tooltip />
              </el-table>
              <div class="flex-start">
                <el-pagination
                  :current-page.sync="pageNo"
                  :page-size="pageSize"
                  layout="prev, pager, next, jumper"
                  :total="courseCount"
                  @current-change="handleCurrentChange"
                />
              </div>
              <div class="flex-start">
                <el-button @click="cancelDrawer"> 取 消 </el-button>
                <el-button type="primary" @click="submitDrawer"> 确 定 </el-button>
              </div>
            </div>
          </el-drawer>
          <el-table
            :data="multiple"
            border
            show-summary
            style="width: 100%;"
          >
            <el-table-column prop="title" label="课程名称" />
            <el-table-column :show-overflow-tooltip="true" prop="originalPrice" label="课程原价" />
            <el-table-column :show-overflow-tooltip="true" prop="markingPrice" label="实际价格" />
            <el-table-column :show-overflow-tooltip="true" prop="videoCount" label="视频数量" />
            <el-table-column v-if="!check" :show-overflow-tooltip="true" label="操作">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click.native.prevent="onDelCourse(scope)"> 删除 </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item v-if="check" label="拒绝原因:">
          <el-input v-model="remark" class="input" placeholder="请输入拒绝原因" />
        </el-form-item>
      </el-form>
    </el-main>
    <el-footer v-if="check" class="flex-start">
      <el-button @click="onCheckContract(2)"> 拒绝 </el-button>
      <el-button type="primary" @click="onCheckContract(1)"> 通过 </el-button>
    </el-footer>
    <el-footer v-else class="flex-start">
      <el-button @click="goBack"> 取消 </el-button>
      <el-button type="primary" @click="submit"> 保存 </el-button>
    </el-footer>
  </el-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { getToken } from '@/utils/auth'
import API from '@/apis/api-types'

export default {
  name: 'ContractManagement',
  data() {
    return {
      contract: {},
      headers: {
        token: getToken()
      },
      gridData: [],
      table: false,
      multipleSelection: [],
      multiple: [],
      courseIdArr: [],
      fileList: [],
      timeout: null,
      check: false,
      courseName: '',
      remark: '',
      pageNo: 1,
      pageSize: 8
    }
  },
  computed: {
    ...mapGetters(['courseCount', 'coursePage', 'courseList']),
    uploadApi() {
      return `${API.uploadPdf}?bucketType=104`
    },
    isPositiveInteger() {
      return /^[0-9]\d*$/.test(this.contract.amount)
    },
    isDegreePositiveInteger() {
      return /^[0-9]\d*$/.test(this.contract.degree)
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.check = true
      this.getContractData({ orderId: this.$route.query.id }).then(
        res => {
          this.contract = res
          res.courseList.forEach(item => {
            this.multiple.push({
              title: item.courseName,
              originalPrice: item.originalPrice,
              markingPrice: item.markingPrice,
              videoCount: item.videoCount
            })
          })
        },
        rea => {
          this.$message.error(rea.message)
        }
      )
    }
  },
  methods: {
    ...mapActions(['getFactoryList', 'saveContractData', 'getCourseList', 'getContractAudit', 'getContractData']),
    handleExceed() {
      this.$message.warning('只支持上传 1 个文件')
    },
    handleSuccess(response) {
      this.contract.attachmentUrl = response.data
    },
    beforeUpload(file) {
      const extension = file.name.split('.').slice(-1) == 'pdf'
      if (!extension) {
        this.$message.error('上传文件只能是 pdf 格式!')
      }
      return extension
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleSelectionChange(val) {
      const id = []
      this.multipleSelection.map(v => {
        id.push(v.id)
      })
      const select = []
      val.map(item => {
        if (!id.includes(item.id)) {
          select.push(item)
        }
      })
      this.multipleSelection = this.multipleSelection.concat(select)
    },
    submitDrawer() {
      this.multiple = this.multipleSelection
      this.table = false
    },
    cancelDrawer() {
      this.table = !this.table
    },
    selectList() {
      const id = []
      this.multiple.map(v => {
        id.push(v.id)
      })
      const select = []
      this.courseList.map(item => {
        if (id.includes(item.id)) {
          select.push(item)
        }
      })
      this.toggleSelection(select)
    },
    getCourse() {
      this.table = !this.table
      const req = {
        courseName: this.courseName,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }
      this.onGetCourseList(req)
    },
    onGetCourseList(req) {
      this.getCourseList(req).then(
        () => {
          this.selectList()
        },
        rea => {
          this.$message.error(rea.message)
        }
      )
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    queryBaseSearchAsync(queryString, cb) {
      clearTimeout(this.timeout)
      let results = []
      if (queryString == '') {
        cb(results)
      } else {
        this.getFactoryList({ factoryId: queryString }).then(res => {
          if (res) {
            const result = res
            // 循环放到一个远程搜索需要的数组
            for (let i = 0; i < result.length; i++) {
              const element = result[i]
              results.push({
                value: element.factoryName,
                id: element.factoryId
              })
            }
            cb(results)
          } else {
            results = []
            cb(results)
          }
        })
      }
    },
    handleBaseSelect(item) {
      this.contract.businessId = item.id
      this.contract.factoryName = item.value
    },
    onDelCourse(scope) {
      this.multiple.splice(scope.$index, 1)
      this.onCourseIdArr()
    },
    onCourseIdArr() {
      const arr = []
      for (const item of this.multiple) {
        arr.push(item.id)
      }
      this.courseIdArr = arr
    },
    handleCurrentChange(val) {
      this.searchCourse(val)
    },
    searchCourse(index) {
      const req = {
        courseName: this.courseName,
        pageNo: index || this.pageNo,
        pageSize: this.pageSize
      }
      this.onGetCourseList(req)
    },
    goBack() {
      this.$router.go(-1)
    },
    submit() {
      const courseInfo = []
      this.multiple.map(item => {
        courseInfo.push({
          courseId: item.id,
          originalPrice: item.originalPrice,
          markingPrice: item.markingPrice
        })
      })
      this.contract.courseInfo = courseInfo
      if (!this.contract.factoryName) {
        this.$message.error('请输入公司名称')
        return false
      } else if (!this.contract.amount) {
        this.$message.error('请输入合同金额')
        return false
      } else if (!this.isPositiveInteger) {
        this.$message.error('合同金额须为正整数')
        return false
      } else if (!this.contract.degree) {
        this.$message.error('请输入学习次数')
        return false
      } else if (!this.isDegreePositiveInteger) {
        this.$message.error('学习次数须为正整数')
        return false
      } else if (!this.contract.attachmentUrl) {
        this.$message.error('请上传合同')
        return false
      } else if (courseInfo.length <= 0) {
        this.$message.error('请添加课程')
        return false
      }
      this.contract.businessName = this.contract.factoryName
      this.saveContractData(this.contract).then(() => {
        this.$message.success('合同创建成功')
        setTimeout(() => {
          this.goBack()
        }, 1000)
      })
    },
    onCheckContract(state) {
      const req = {
        trainOrderId: this.$route.query.id,
        auditType: state,
        remark: this.remark
      }
      if (state === 1) {
        this.$confirm('确定通过审核吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.getContractAudit(req).then(() => {
              this.$message.success('已通过')
              this.goBack()
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            })
          })
      } else if (state === 2) {
        if (!this.remark) {
          this.$message.error('请输入拒绝原因')
          return false
        }
        this.$confirm('确定拒绝吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.getContractAudit(req).then(() => {
              this.$message.success('已拒绝')
              this.$router.go(-1)
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            })
          })
      }
    }
  }
}
</script>

<style scoped>
.flex-start {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
}
.input {
  width: 400px;
}
.drawer {
  margin: 0 40px;
}
.title {
  font-weight: bold;
  color: black;
  margin-right: 20px;
  padding: 0;
}
.search {
  margin: 30px 0;
}
.contract {
  position: relative;
}
.jump-pdf {
  position: absolute;
  left: 200px;
  top: 0;
  overflow: hidden;
}
</style>
