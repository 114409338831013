<template>
  <div class="scene-layout">
    <el-main v-loading="loading" title="更多设置">
      <el-row type="flex" class="tab-row">
        <el-button
          v-for="(tabItem, index) in tabs"
          :key="index"
          :type="tabItem.id === currentTab ? 'primary' : ''"
          :plain="tabItem.id !== currentTab"
          @click="currentTab = tabItem.id"
        >
          {{ tabItem.label }}
        </el-button>
      </el-row>
      <div v-show="currentTab === 'authenticity'" class="set-card-wrapper">
        <div>1.客户真实性认证等级是：</div>
        <div style="padding-left: 20px;">
          <!-- <div>
            <el-radio v-model="bindDoctorLimit" :label="0">无要求</el-radio>
          </div> -->
          <div>
            <el-radio v-model="bindDoctorLimit" :label="1"> 等级Ⅰ 三选一认证模式（允许从医院胸牌、医院公告栏照片、医院官网截图）三项中任选一项 + 工作证明成功即可绑定 </el-radio>
          </div>
          <div>
            <el-radio v-model="bindDoctorLimit" :label="2"> 等级Ⅱ 执业医师认证+人脸活体认证，身份认证成功即可绑定 </el-radio>
          </div>
          <div>
            <el-radio v-model="bindDoctorLimit" :label="3"> 等级Ⅲ 执业医师认证+人脸活体认证+手机号实名检测，三重认证都成功后，即可绑定 </el-radio>
          </div>
          <div class="label">备注：未满足绑定条件客户，将会进入代表的预绑定名单，代表可以编辑完善客户信息，协助其提交材料审核。</div>
        </div>
        <div>2.提示客户进行实名验证的位置：</div>
        <div style="padding-left: 20px;">
          <div>
            <el-radio v-model="certifyTipType" :label="0">注册流程中提示客户进行实名验证</el-radio>
          </div>
          <div>
            <el-radio v-model="certifyTipType" :label="1">使用积分时提示客户进行实名验证</el-radio>
          </div>
        </div>
        <div>3.工作证明未审核通过的医生，可以领取积分但领取15日内未通过工作证明审核的，对应的积分将会被自动退回到公司对应账号。</div>
        <div>
          <span>4.工作证明审核通过但是没有通过实名验证的医生，最多使用</span>
          <el-input-number v-model="money" class="number-step" :min="0" />
          <span>积分</span>
        </div>
        <div>5.实名验证中姓名和工作证明中姓名不匹配的医生，将被严格控制，无法使用任何积分，重新认证全部通过后恢复权限。</div>
      </div>
      <div v-show="currentTab === 'rationality'" class="set-card-wrapper">
        <div>1.同科会内容项目，同医生重复学习频率限制方案：</div>
        <div style="padding-left: 20px;">
          <div>
            <el-radio v-model="rationalityFormData.meetingMode" label="2">
              按自然月度控制，每月的重复项目任务不超过
              <el-input-number
                v-model="rationalityFormData.sameMeetingMonthLimit"
                class="number-step"
                :min="1"
                :max="3"
                step-strictly
                :disabled="rationalityFormData.meetingMode === '1'"
              />
              次
            </el-radio>
          </div>
          <div>
            <el-radio v-model="rationalityFormData.meetingMode" label="1">
              按自然季度控制，每季度的重复项目任务不超过
              <el-input-number
                v-model="rationalityFormData.sameMeetingQuarterLimit"
                class="number-step"
                :min="1"
                :max="6"
                step-strictly
                :disabled="rationalityFormData.meetingMode === '2'"
              />
              次
            </el-radio>
          </div>
        </div>
        <div>2.同问卷调研和病例互动项目，同医生重复学习频率限制方案：</div>
        <div style="padding-left: 20px;">
          <div>
            <el-radio v-model="rationalityFormData.questionnaireMode" label="2">
              按自然月度控制，每月的重复项目任务不超过
              <el-input-number
                v-model="rationalityFormData.sameQuestionnaireMonthLimit"
                class="number-step"
                :min="1"
                :max="3"
                step-strictly
                :disabled="rationalityFormData.questionnaireMode === '1'"
              />
              次
            </el-radio>
          </div>
          <div>
            <el-radio v-model="rationalityFormData.questionnaireMode" label="1">
              按自然季度控制，每季度的重复项目任务不超过
              <el-input-number
                v-model="rationalityFormData.sameQuestionnaireQuarterLimit"
                class="number-step"
                :min="1"
                :max="6"
                step-strictly
                :disabled="rationalityFormData.questionnaireMode === '2'"
              />
              次
            </el-radio>
          </div>
        </div>

        <div>
          3.直播会议观看时长默认值：<el-input-number v-model="liveWebinerForm.liveDefaultReviewSec" :min="10" :max="60" /> 分钟
        </div>

        <div>4.直播会议场次控制：</div>
        <div style="padding-left: 20px;">
          <el-radio v-model="controlType" :label="1">
            按产品线控制
          </el-radio>
          <el-radio v-model="controlType" :label="0">
            按全公司级别控制
          </el-radio>
          <div style="font-size: 14px; font-weight: 500; color: #606266;">
            单医生每天最多
            <el-input-number
              v-model="dayHighest"
              class="number-step"
              :min="1"
              :max="3"
              step-strictly
            />
            场，每月最多
            <el-input-number
              v-model="monthHighest"
              class="number-step"
              :min="1"
              :max="8"
              step-strictly
            />场，年直播场次最多
            <el-input-number
              v-model="yearHighest"
              class="number-step"
              :min="1"
              :max="36"
              step-strictly
            />场。
          </div>
        </div>

        <div>
          5.委托调研项目的科会视频，最低视频时长要求：<el-input-number v-model="meetingVideoMinTime" :min="0" /> 分钟
        </div>
      </div>
      <div v-show="currentTab === 'reimbursement'" class="set-card-wrapper">
        <div>是否开启“先票后款”报账模式</div>
        <div style="padding-left: 20px;">
          <el-switch
            :value="isPreReimbursement"
            :disabled="unpurchasedList?.length > 0"
            @change="onSaveData"
          />
          <span>当企业财务存在“预报账”且没有完成采购的明细数据，则不可关闭该模式，点击
            <el-button type="text" size="large" @click="openUnpurchasedDialog">查看详情</el-button>
          </span>
        </div>
      </div>

      <el-footer v-if="currentTab !== 'reimbursement'">
        <FooterWrapper>
          <el-button type="primary" @click="onSetOpenSubmit">保存</el-button>
        </FooterWrapper>
      </el-footer>
      <el-dialog
        width="500px"
        title="确认修改"
        :visible="showSubmit"
        top="30vh"
        @close="showSubmit = false"
      >
        <div class="font-weight align-center font-size-root">确认保存修改吗？</div>
        <el-row
          slot="footer"
          type="flex"
          align="center"
          justify="center"
        >
          <el-button @click="showSubmit = false">取消</el-button>
          <el-button type="primary" :loading="isSubmit" @click="onSaveData">确认</el-button>
        </el-row>
      </el-dialog>
    </el-main>
    <el-dialog
      width="500px"
      title="查看详情"
      :visible="showDialog"
      top="30vh"
      @close="showDialog = false"
    >
      <div>
        <el-table
          :data="unpurchasedList"
          border
        >
          <el-table-column label="合同编码" prop="contractId" />
          <el-table-column label="组织名称" prop="orgName" />
          <el-table-column label="未完成采购报账单数" prop="unpurchasedCount" />
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import FooterWrapper from '@/components/common/footerWrapper.vue'
export default {
  name: 'FactorySettings',
  components: {
    FooterWrapper
  },
  data() {
    return {
      rationalityFormData: {},
      certifyType: 'multiple', // multiple \ single
      certifyTipType: 0,
      bindDoctorLimit: 1,
      money: '',
      showSubmit: false,
      isSubmit: false,
      loading: false,
      liveWebinerForm: {
        liveDefaultReviewSec: 10
      },
      meetingVideoMinTime: 0,
      currentTab: 'authenticity',
      tabs: [
        { id: 'authenticity', label: '客户真实性权限设置' },
        { id: 'rationality', label: '学术推广合理性设置' },
        { id: 'reimbursement', label: '企业财务报账设置' }
      ],
      controlType: 1,
      dayHighest: 3,
      monthHighest: 8,
      yearHighest: 36,
      unpurchasedList: [],
      showDialog: false,
      isPreReimbursement: 0
    }
  },
  computed: {
    certifyRuleList() {
      switch (this.certifyType) {
        case 'multiple':
          return [0, 1, 2, 3]
        case 'single':
          return [2]
        default:
          return []
      }
    }
  },
  mounted() {
    this.factoryId = this.$route.query.id || ''
    this.initPage()
    this.onGetUnpurchasedList()
  },
  methods: {
    async initPage() {
      this.loading = true
      try {
        const data = await this.$axios.get(this.$API.getDoctorCertifyRule, { params: { factoryId: this.factoryId } })
        this.certifyTipType = data.noticeLocation
        this.certifyType = data.allowTypes?.length > 1 ? 'multiple' : 'single'
        this.money = data.unCertifyConsumeLimit
        this.bindDoctorLimit = data.bindDoctorLimit
        this.controlType = data.controlType
        this.dayHighest = data.dayHighest
        this.monthHighest = data.monthHighest
        this.yearHighest = data.yearHighest
        const { sameMeetingMonthLimit, sameMeetingQuarterLimit, sameQuestionnaireMonthLimit, sameQuestionnaireQuarterLimit } = data
        const meetingMode = sameMeetingQuarterLimit ? '1' : sameMeetingMonthLimit ? '2' : null
        const questionnaireMode = sameQuestionnaireQuarterLimit ? '1' : sameQuestionnaireMonthLimit ? '2' : null
        this.rationalityFormData = {
          meetingMode,
          sameMeetingMonthLimit: sameMeetingMonthLimit || 2,
          sameMeetingQuarterLimit: sameMeetingQuarterLimit || 6,
          questionnaireMode,
          sameQuestionnaireMonthLimit: sameQuestionnaireMonthLimit || 2,
          sameQuestionnaireQuarterLimit: sameQuestionnaireQuarterLimit || 6
        }
        this.liveWebinerForm.liveDefaultReviewSec = data.liveDefaultReviewSec / 60
        this.meetingVideoMinTime = data.meetingVideoMinTime / 60
        this.isPreReimbursement = data.isPreReimbursement === 1
        console.log('111', this.isPreReimbursement)
        this.loading = false
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },
    onSetOpenSubmit() {
      if (!this.onValid()) return
      this.showSubmit = true
    },
    onGetUnpurchasedList() {
      this.$axios.get(this.$API.getUnpurchasedList, {params: {
        factoryId: this.factoryId
      }}).then(res => {
        this.unpurchasedList = res.data
      }, rea => {
        this.$message.error(rea)
      })
    },
    openUnpurchasedDialog() {
      this.showDialog = true
    },
    onValid() {
      if (!this.money && this.money != 0) {
        this.$message.error('请输入使用积分上限')
        return false
      }
      return true
    },
    onSaveData() {
      if (this.isSubmit) return
      this.isSubmit = true
      const {
        meetingMode,
        sameMeetingMonthLimit,
        sameMeetingQuarterLimit,
        questionnaireMode,
        sameQuestionnaireMonthLimit,
        sameQuestionnaireQuarterLimit
      } = this.rationalityFormData
      let req = {
        factoryId: this.factoryId,
        bindDoctorLimit: this.bindDoctorLimit,
        allowTypes: this.certifyRuleList,
        noticeLocation: this.certifyTipType,
        unCertifyConsumeLimit: this.money,
        liveDefaultReviewSec: this.liveWebinerForm.liveDefaultReviewSec * 60,
        meetingVideoMinTime: this.meetingVideoMinTime * 60,
        controlType: this.controlType,
        dayHighest: this.dayHighest,
        monthHighest: this.monthHighest,
        yearHighest: this.yearHighest,
        isPreReimbursement: this.isPreReimbursement === true ? 0 : 1
      }
      if (meetingMode) {
        if (meetingMode === '1') req.sameMeetingQuarterLimit = sameMeetingQuarterLimit
        if (meetingMode === '2') req.sameMeetingMonthLimit = sameMeetingMonthLimit
      }
      if (questionnaireMode) {
        if (questionnaireMode === '1') req.sameQuestionnaireQuarterLimit = sameQuestionnaireQuarterLimit
        if (questionnaireMode === '2') req.sameQuestionnaireMonthLimit = sameQuestionnaireMonthLimit
      }
      this.$axios.post(this.$API.setDoctorCertifyRule, req).then(() => {
        this.isSubmit = false
        this.showSubmit = false
        this.isPreReimbursement = !this.isPreReimbursement
        this.$message.success('认证规则设置成功')
      }, rea => {
        this.isSubmit = false
        this.$message.error(rea)
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.scene-layout {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.set-card-wrapper {
  color: #0f0f0f;
  line-height: 3;
  font-size: 16px;
  padding: 20px 25px;
  font-weight: bold;
  flex: 1;
  .label {
    color: #999;
    font-size: 14px;
    font-weight: normal;
  }
}
.number-step {
  margin-left: 12px;
  margin-right: 12px;
}
.tab-row {
  margin-top: 20px;
  margin-left: 20px;
}
</style>

