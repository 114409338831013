<template>
  <el-container class="content">
    <div class="flex">
      <div class="grow-shrink0">
        课程标题：
      </div>
      <el-input
        v-model="course.title"
        maxlength="40"
        show-word-limit
        placeholder="请输入课程标题"
      />
    </div>
    <div class="flex">
      <div class="grow-shrink0">
        课程简介：
      </div>
      <el-input
        v-model="course.introduction"
        type="textarea"
        :rows="3"
        maxlength="270"
        show-word-limit
        placeholder="请输入课程简介"
      />
    </div>
    <div class="flex">
      <div class="grow-shrink0">
        课程封面：
      </div>
      <div class="w100v flex cover">
        <el-upload
          :action="uploadApi"
          :headers="headers"
          name="image"
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :on-success="handleAvatarSuccess"
          list-type="picture-card"
          accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
        >
          <img
            v-if="courseCover"
            :src="courseCover"
            style="width: 100%; height: 100%; border-radius: 6px;"
          >
          <div
            v-else
            slot="trigger"
            class="wrapper"
          >
            <div>
              <img
                src="../../assets/carmer.jpg"
                alt=""
                class="image-w"
              >
            </div>
            <div>上传封面</div>
          </div>
        </el-upload>
        <div class="tips">
          备注：图片建议尺寸16：9
        </div>
      </div>
    </div>
    <div class="flex">
      <div class="grow-shrink0">
        课程详情图：
      </div>
      <div class="w100v flex">
        <el-upload
          :action="uploadApi"
          :headers="headers"
          name="image"
          :show-file-list="false"
          :before-upload="beforeAvatarDetailUpload"
          :on-success="handleAvatarDetailSuccess"
          list-type="picture-card"
          accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
        >
          <img
            v-if="courseDetailCover"
            :src="courseDetailCover"
            style="width: 100%; height: 100%; border-radius: 6px;"
          >
          <div
            v-else
            slot="trigger"
            class="wrapper"
          >
            <div>
              <img
                src="../../assets/carmer.jpg"
                alt=""
                class="image-w"
              >
            </div>
            <div>上传封面</div>
          </div>
        </el-upload>
        <div class="tips">
          备注：详情图建议宽度750px，高度不限
        </div>
      </div>
    </div>
    <el-main>
      <div class="flex">
        <div class="grow-shrink0 flex">
          上传视频：
        </div>
        <div class="flex">
          <el-button
            class="cursor"
            @click="onOpenVideoPop(false)"
          >
            本地上传
          </el-button>
          <span class="tips">备注：可上传多个视频，建议将较长视频分段排序上传。</span>
        </div>
      </div>
      <div
        ref="table"
        class="prod-panel"
      >
        <el-table
          ref="multipleTable"
          :data="courseVideos"
          border
          style="width: 100%;"
        >
          <el-table-column
            prop=""
            label="视频"
            width="300"
          >
            <template slot-scope="scope">
              <div class="video-cover">
                <img
                  :src="scope.row.customCoverUrl || scope.row.coverUrl || courseCover"
                  alt=""
                  style="width: 100%; height: 100%;"
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            prop="title"
            label="视频名称"
          />
          <el-table-column
            prop=""
            label="排序"
            align="center"
            width="300"
          >
            <template slot-scope="scope">
              <div>
                <el-button
                  v-if="scope.$index===0"
                  class="mgn-r10"
                  type="text"
                  disabled
                >
                  上移
                </el-button>
                <el-button
                  v-else
                  type="text"
                  @click="onUp(scope)"
                >
                  上移
                </el-button>
                <el-button
                  v-if="scope.$index===(courseVideos.length-1)"
                  type="text"
                  disabled
                >
                  下移
                </el-button>
                <el-button
                  v-else
                  type="text"
                  @click="onDown(scope)"
                >
                  下移
                </el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop=""
            align="center"
            label="操作"
            width="300"
          >
            <template slot-scope="scope">
              <div>
                <el-button
                  class="mgn-r10"
                  type="text"
                  @click="onOpenVideoPop(true,scope)"
                >
                  编辑
                </el-button>
                <el-button
                  type="text"
                  @click="onDelVideo(scope)"
                >
                  删除
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-main>
    <el-main>
      <div class="flex">
        <div class="grow-shrink0 flex">
          题目问卷：
        </div>
        <div class="w100v flex">
          <el-button
            class="cursor"
            @click="onOpenQuestionPop"
          >
            添加题目
          </el-button>
          <span class="tips">备注：请至少添加课程一道测试题</span>
        </div>
      </div>
      <div
        ref="table"
        class="prod-panel"
      >
        <el-table
          :data="courseExams"
          border
          style="width: 100%;"
        >
          <el-table-column
            :show-overflow-tooltip="true"
            prop="title"
            label="题目名称"
          />
          <el-table-column
            prop=""
            label="题目类型"
            align="center"
            width="180"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.examTypeEnum === 'single' ? '单选' : '多选' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="createdAt"
            label="上传时间"
            align="center"
            width="180"
          />
          <el-table-column
            prop=""
            align="center"
            label="操作"
          >
            <template slot-scope="scope">
              <div>
                <el-button
                  type="text"
                  @click="onDelQuestion(scope)"
                >
                  删除
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-main>
    <el-main>
      <div class="">
        <div class="flex">
          <div class="grow-shrink0 flex">
            课程状态：
          </div>
          <el-select
            v-model="course.isSale"
            placeholder="请选择课程状态"
          >
            <el-option
              v-for="item in prodState"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </div>
        <div class="flex">
          <div class="grow-shrink0 flex">
            课程价格：
          </div>
          <div class="grow-shrink0 flex">
            原价：
          </div>
          <el-input
            v-model="course.originalPrice"
            placeholder="请输入课程原价"
            class="input"
            type="number"
            min="0"
          />
          <div class="grow-shrink0 flex">
            实际价格：
          </div>
          <el-input
            v-model="course.markingPrice"
            placeholder="请输入课程实际价格"
            class="input"
            type="number"
            min="0"
          />
        </div>
        <div class="flex">
          <div class="grow-shrink0">
            商品排序：
          </div>
          <el-input
            v-model="course.courseIndex"
            placeholder="请输入商品排序"
            class="input"
            type="number"
          />
          <span class="flex tips">备注：排序越大商品越靠前</span>
        </div>
        <div class="flex">
          <div class="grow-shrink0 flex">
            课程负责人：
          </div>
          <el-autocomplete
            v-model="course.contactName"
            :fetch-suggestions="queryBaseSearchAsync"
            placeholder="请输入课程负责人"
            @select="handleBaseSelect"
          />
        </div>
        <div class="flex">
          <el-button
            type="primary"
            @click="onGetVideoCut"
          >
            试看剪辑
          </el-button>
        </div>
      </div>
    </el-main>
    <div
      v-show="isShowToast"
      class="toast-box"
      :class="{'errorBg':isErrorBg}"
    >
      {{ toastMessage }}
    </div>
    <el-footer class="footer">
      <el-button @click="goBack">
        取消
      </el-button>
      <el-button type="primary" @click="saveCourse">
        保存
      </el-button>
    </el-footer>
    <VideoUpload
      :visible="isShowVideoPop"
      :is-disable-upload="isDisableUpload"
      :init-video-obj="videoObj"
      @onUpload="onSubmitMaterialVideo"
      @onClose="onClosePop"
    />

    <QuestionPop :visible="isShowQuestionPop" @onSubmit="onSubmitAddQuestion" @onClose="onClosePop" />
  </el-container>
</template>

<script>
import VideoUpload from '@/components/Course/VideoUpload.vue'
import QuestionPop from '@/components/Course/QuestionPop.vue'
import API from '@/apis/api-types'
import { mapActions } from 'vuex'

export default {
  name: 'CourseDetail',
  components: {
    VideoUpload,
    QuestionPop
  },
  data() {
    return {
      course: {},
      headers: {},
      courseVideos: [],
      prodState: [
        {
          id: 0,
          name: '下架'
        },
        {
          id: 1,
          name: '上架'
        }
      ],
      courseExams: [],
      isShowVideoPop: false,
      isDisableUpload: false,
      videoObj: {
        title: '',
        videoImgUrl: '',
        coverUrl: ''
      },
      isShowMaterialVideoPop: false,
      isShowQuestionPop: false,
      isShowAddQuestionPop: false,
      isShowQuestionPreview: false,
      isShowOperationConfirmPop: false,
      showVideoModal: false,
      courseIdArr: [],
      questionIdArr: [],
      timerId: null,
      isShowToast: false,
      isSaveConfirm: false,
      toastMessage: '',
      isErrorBg: false,
      courseCover: '',     // 课程封面
      courseDetailCover: '',     // 详情图封面
      timeout: null
    }
  },
  computed: {
    uploadApi() {
      return `${API.uploadImg}?bucketType=104`
    },
    isPositiveInteger() {
      return /^[0-9]\d*$/.test(this.course.originalPrice)
    },
    isRealPositiveInteger() {
      return /^[0-9]\d*$/.test(this.course.markingPrice)
    },
    isCourseIndex() {
      return /^[0-9]\d*$/.test(this.course.courseIndex)
    }
  },
  watch: {
    changeSaveData(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isSaveConfirm = false
      } else {
        this.isSaveConfirm = true
      }
    }
  },
  mounted() {
    if (this.$route.params.courseId) {
      this.onInitTrainData()
    }
  },
  methods: {
    ...mapActions(['saveCourseData', 'getCourseData', 'getContactList', 'getVideoCut']),
    onInitTrainData() {
      this.getCourseData({ courseId: this.$route.params.courseId }).then(res => {
        const resData = res || {}
        this.courseCover = resData.coverUrl.middle
        this.courseDetailCover = resData.speakerUrl.middle
        this.course = {
          id: resData.id,
          title: resData.title,
          isSale: resData.isSale,
          courseIndex: resData.courseIndex,
          originalPrice: resData.originalPrice,
          markingPrice: resData.markingPrice,
          introduction: resData.introduction,
          coverPath: resData.coverUrl.imageName,
          speakerPath: resData.speakerUrl.imageName,
          contactName: resData.contactName
        }
        this.courseVideos = resData.videoVos
        this.courseExams = resData.examVos
        this.onCourseIdArr()
        this.onQuestionIdArr()
      }, rea => {
        this.$message({
          message: rea.message,
          type: 'error'
        })
      })
    },
    beforeAvatarUpload(file) {
      const _this = this
      const isSize = new Promise(function(resolve, reject) {
        const width = 16 // 限制图片尺寸为16:9
        const height = 9
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.onload = function() {
          const valid = (img.width / img.height).toFixed(2) === (width / height).toFixed(2)
          valid ? resolve() : reject()
        }
        img.src = _URL.createObjectURL(file)
      }).then(() => {
        return file
      }, () => {
        _this.$message.warning('建议课程封面图片尺寸16:9')
        return Promise.resolve()
      })
      return isSize
    },
    handleAvatarSuccess(res, file) {
      this.courseCover = URL.createObjectURL(file.raw)
      this.$set(this.course, 'coverPath', res.data.imageName)
    },
    beforeAvatarDetailUpload(file) {
      const _this = this
      const isSize = new Promise(function(resolve, reject) {
        const width = 750 // 限制图片尺寸为16:9
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.onload = function() {
          const valid = img.width.toFixed(2) === width.toFixed(2)
          valid ? resolve() : reject()
        }
        img.src = _URL.createObjectURL(file)
      }).then(() => {
        return file
      }, () => {
        _this.$message.warning('建议课程详情图宽度为750px')
        return Promise.resolve()
      })
      return isSize
    },
    handleAvatarDetailSuccess(res, file) {
      this.courseDetailCover = URL.createObjectURL(file.raw)
      this.$set(this.course, 'speakerPath', res.data.imageName)
    },
    handleSecondExceed() {
      this.$message.warning(
        '只支持上传1个文件'
      )
    },
    handleSecondSuccess(response) {
      const { imageName, middle } = response.data
      this.course.licenseImg = imageName
      this.licenseUrl = middle
    },
    beforeSecondUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG、PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    beforeSecondRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    // 上传视频
    onOpenVideoPop(isDisable, scope) {
      if (isDisable) {
        this.videoObj = Object.assign({}, scope.row)
        this.isDisableUpload = true
      } else {
        this.videoObj = {}
        this.isDisableUpload = false
      }
      this.isShowVideoPop = true
    },
    onSubmitMaterialVideo(options) {
      options.map(it => {
        if (!this.courseIdArr.includes(it.videoId)) {
          this.courseVideos.push(it)
        } else {
          this.$set(this.courseVideos, this.courseIdArr.indexOf(it.videoId), it)
        }
      })
      this.onCourseIdArr()
    },
    onClosePop() {
      this.isShowVideoPop = false
      this.isShowMaterialVideoPop = false
      this.isShowQuestionPop = false
      this.isShowAddQuestionPop = false
      this.isShowQuestionPreview = false
      this.isShowOperationConfirmPop = false
      this.showVideoModal = false
    },
    onCourseIdArr() {
      const arr = []
      for (const item of this.courseVideos) {
        arr.push(item.videoId)
      }
      this.courseIdArr = arr
    },
    // 添加题目
    onOpenQuestionPop() {
      this.isShowQuestionPop = true
    },
    onSubmitAddQuestion(options) {
      options.map(it => {
        if (!this.questionIdArr.includes(it.examId)) {
          this.courseExams.push(it)
        } else {
          this.$set(this.courseExams, this.questionIdArr.indexOf(it.examId), it)
        }
      })
      this.onQuestionIdArr()
    },
    onQuestionIdArr() {
      const arr = []
      for (const item of this.courseExams) {
        const {examId} = item
        arr.push(examId)
      }
      this.questionIdArr = arr
    },
    queryBaseSearchAsync(queryString, cb) {
      clearTimeout(this.timeout)
      let results = []
      if (queryString == '') {
        cb(results)
      } else {
        this.getContactList({ contactName: queryString }).then(
          res => {
            if (res) {
              const result = res
              // 循环放到一个远程搜索需要的数组
              for (let i = 0; i < result.length; i++) {
                const element = result[i]
                results.push({
                  value: element.name,
                  id: element.id
                })
              }
              cb(results)
            } else {
              results = []
              cb(results)
            }
          }
        )
      }
    },
    handleBaseSelect(item) {
      this.course.contactId = item.id
      this.course.contactName = item.value
    },
    goBack() {
      this.$router.go(-1)
    },
    onClearTimeout() {
      this.timerId && clearTimeout(this.timerId)
      this.timerId = setTimeout(() => {
        this.isShowToast = false
      }, 3000)
    },
    onGetVideoCut() {
      this.getVideoCut({ videoId: this.courseVideos[0].videoId }).then(() => {
        this.$message.success('剪辑成功')
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    onCheckData() {
      this.onClearTimeout()
      if (!this.course.title) {
        this.isShowToast = true
        this.toastMessage = '请填写课程标题'
        this.isErrorBg = true
        return false
      } else if (!this.course.introduction) {
        this.isShowToast = true
        this.toastMessage = '请填写课程简介'
        this.isErrorBg = true
        return false
      } else if (!this.course.coverPath) {
        this.isShowToast = true
        this.toastMessage = '请上传课程封面'
        this.isErrorBg = true
        return false
      } else if (!this.course.speakerPath) {
        this.isShowToast = true
        this.toastMessage = '请上传课程详情图'
        this.isErrorBg = true
        return false
      } else if (this.course.originalPrice && !this.isPositiveInteger) {
        this.isShowToast = true
        this.toastMessage = '课程原价须为正整数'
        this.isErrorBg = true
        return false
      } else if (!this.course.markingPrice && this.course.markingPrice !== 0) {
        this.isShowToast = true
        this.toastMessage = '请填写课程实际价格'
        this.isErrorBg = true
        return false
      } else if (!this.isRealPositiveInteger) {
        this.isShowToast = true
        this.toastMessage = '课程实际价格须为正整数'
        this.isErrorBg = true
        return false
      } else if (!this.course.isSale && this.course.isSale !== 0) {
        this.isShowToast = true
        this.toastMessage = '请填写课程状态'
        this.isErrorBg = true
        return false
      } else if (!this.course.courseIndex && this.course.courseIndex !== 0) {
        this.isShowToast = true
        this.toastMessage = '请填写课程排序'
        this.isErrorBg = true
        return false
      } else if (!this.isCourseIndex) {
        this.isShowToast = true
        this.toastMessage = '课程排序须为正整数'
        this.isErrorBg = true
        return false
      } else if (!this.course.contactName) {
        this.isShowToast = true
        this.toastMessage = '请填写课程负责人'
        this.isErrorBg = true
        return false
      }
      if (!(this.courseVideos.length > 0)) {
        this.isShowToast = true
        this.toastMessage = '请上传视频'
        this.isErrorBg = true
        return false
      }
      if (!(this.courseExams.length >= 1)) {
        this.isShowToast = true
        this.toastMessage = '请至少添加1道问题'
        this.isErrorBg = true
        return false
      }
      return true
    },
    saveCourse() {
      const isSubmit = this.onCheckData()
      if (!isSubmit) return false
      const videoList = []
      this.courseVideos.map((item, index) => {
        videoList.push({
          videoId: item.videoId,
          videoIndex: index
        })
      })
      const questionList = []
      this.courseExams.map((item, index) => {
        questionList.push({
          examId: item.examId,
          examIndex: index
        })
      })
      const Data = Object.assign({}, {
        course: this.course,
        courseVideos: videoList,
        courseExams: questionList
      })
      if (!this.course.originalPrice) {
        this.course.originalPrice = 0
      }
      this.saveCourseData(Data).then(() => {
        this.isErrorBg = false
        this.isSaveConfirm = false
        this.$message.success('保存成功')
        setTimeout(() => {
          this.goBack()
        }, 1000)
      }, rea => {
        this.onClearTimeout()
        // this.isShowToast = true
        this.toastMessage = rea.message
        this.isErrorBg = true
        this.isSaveConfirm = false
      })
    },
    onUp(options) {
      this.courseVideos.splice(options.$index, 1)
      this.courseVideos.splice(options.$index - 1, 0, options.row)
      this.onCourseIdArr()
    },
    onDown(options) {
      this.courseVideos.splice(options.$index, 1)
      this.courseVideos.splice(options.$index + 1, 0, options.row)
      this.onCourseIdArr()
    },
    onDelVideo(scope) {
      this.courseVideos.splice(scope.$index, 1)
      this.onCourseIdArr()
    },
    onDelQuestion(scope) {
      this.courseExams.splice(scope.$index, 1)
      this.onQuestionIdArr()
    }
  }
}
</script>

<style>

.detail-img .el-upload--picture-card {
  width: 100px;
  height: 100px;
}
.cover .el-upload--picture-card {
  width: 160px;
  height: 90px;
}
</style>
<style scoped>
.wrapper {
  display: flex;
  align-content: center;
  justify-content: center;
  height: 100%;
  font-size: 12px;
  flex-direction: column;
  line-height: 1;
  color: #008075;
}
.video-cover {
  width: 107px;
  height: 60px;
  background: #d8d8d8;
  margin-right: 20px;
  text-align: center;
}
.grow-shrink0 {
  flex-grow: 0;
  flex-shrink: 0;
}
.content {
  padding: 0 15px;
}
.flex {
  display: flex;
  align-items: center;
  margin: 8px 0;
}
.tips {
  color: #c8c9ce;
  margin-left: 10px;
  font-size: 14px;
}
.prod-panel {
  margin: 0 30px;
}
.cursor {
  margin: 0 10px;
}
.input {
  width: 193px;
  margin-right: 10px;
}
.toast-box {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 100px;
  color: #fff;
  background: #6db61e;
  z-index: 10000;
}
.errorBg {
  background: #d8384a;
}
.footer {
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: center;
}
</style>
