<template>
  <div class="data-chart-area-wrap">
    <!-- <el-select
      v-if="isShowSelect"
      v-model="adcode"
      class="city-select"
      size="mini"
      @change="selectChina"
    >
      <el-option
        v-for="item in selectList"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select> -->
    <div ref="data-chart-area" class="data-chart-area" />
  </div>
</template>

<script>
import mixinsChart from '../../mixins/charts.js'
import { getGeoJsonData } from '@/utils/util'
import * as echarts from 'echarts'

export default {
  name: 'DataChartArea',
  mixins: [mixinsChart],
  data() {
    return {
      adcode: '中国',
      currentGeoJson: {}
    }
  },
  computed: {
    chartDOM() {
      return this.$refs['data-chart-area']
    },
    propsXgroupByInfos() {
      const { groupByDesc } = this.chartData
      const [row] = groupByDesc.filter(item => item.colDesc === '医院所在省')
      if (row) {
        return row
      } else {
        const [res] = groupByDesc.filter(item => item.colDesc === '医院所在市')
        return res
      }
    },
    selectList() {
      let arr = [{ label: '中国', value: '中国' }]
      if (this.chartData.groupByDesc.length === 1 && this.propsXgroupByInfos?.colDesc === '医院所在市') {
        const {
          data,
          xGroupByDesc: { colDesc }
        } = this.chartData
        arr = data.map(item => {
          return {
            label: item[colDesc],
            value: item[colDesc]
          }
        })
      }
      return arr
    },
    isShowSelect() {
      const { groupByDesc } = this.chartData
      return groupByDesc[0] && groupByDesc[0].colDesc !== '医院所在省' && groupByDesc.length === 1 && this.selectList.length
    },
    chartOptions() {
      const { xGroupByDesc, groupByDesc, colDesc, modelName, data } = this.chartData
      const allNums = []
      colDesc?.forEach(item => {
        data.forEach(dataItem => {
          allNums.push(dataItem[item])
        })
      })
      const config = {
        title: {
          text: modelName
        },
        tooltip: {
          formatter(params) {
            let res = `${params.name}<br/>`
            config.series?.forEach(colDescItem => {
              colDescItem.data?.forEach(item => {
                if (item.name == params.name) {
                  res += `${colDescItem.name}：${item.value}</br>`
                }
              })
              if (res.indexOf(colDescItem.name) === -1) {
                res += `${colDescItem.name}：-</br>`
              }
            })
            return res
          }
        },
        visualMap: {
          min: Math.min(...allNums, 0),
          max: Math.max(...allNums, 0)
        },
        series: colDesc?.map(colDescItem => {
          const seriesData = []
          const [row] = groupByDesc.filter(item => item.colDesc === (this.adcode === '中国' ? '医院所在省' : '医院所在市'))
          // 先用省份数据，如果没有再用市级数据

          const arr = {}
          data
            ?.map(item => {
              const sourceName = item[this.adcode === '中国' ? xGroupByDesc?.colDesc : row?.colDesc]
              const find = this.currentGeoJson.features?.find(({properties: {name}}) => name.includes(sourceName))
              return {
                name: find?.properties?.name,
                value: item[colDescItem]
              }
            })
            .forEach(item => {
              if (!arr[item.name]) {
                arr[item.name] = item.value
              } else {
                arr[item.name] = arr[item.name] + item.value
              }
            })
          for (const key in arr) {
            seriesData.push({
              name: key,
              value: arr[key]
            })
          }
          return {
            name: colDescItem,
            type: 'map',
            map: this.adcode,
            data: seriesData
          }
        })
      }

      return this.$parent.merge(config)
    }
  },
  methods: {
    async initAfter() {
      await this.registerMap()
      // charts 监听事件
      this.myCharts?.getZr()?.on('click', params => {
        if (!params.target && this.adcode !== '中国') {
          this.registerMap('中国')
        }
      })
      this.myCharts?.on('click', async params => {
        const flag = this.chartData.groupByDesc?.some(item => item.colDesc === '医院所在市')

        if (this.adcode === '中国' && flag) {
          await this.registerMap(params.name)
          this.myCharts.setOption(this.chartOptions)
        }
      })
    },
    selectChina() {
      this.registerMap(this.adcode)
    },
    async registerMap(keyword = '中国') {
      this.myCharts.showLoading()
      try {
        const geoJson = await getGeoJsonData({adcode: keyword})

        this.currentGeoJson = geoJson
        this.adcode = keyword
        return echarts.registerMap(keyword, geoJson)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.data-chart-area-wrap {
  position: relative;
  .city-select {
    width: 120px;
    position: absolute;
    right: 100px;
    top: 10px;
    z-index: 1;
  }
  .data-chart-area {
    height: 100%;
  }
}
</style>
