import createTcccSDKLoginToken from '../apis/createTcccSDKLoginToken'
const initTcccSDK = async() => {
  if (window.tccc) {
    console.warn('已经初始化SDK了')
    return
  }
  const scriptURL = await createTcccSDKLoginToken()
  if (scriptURL) {
    const script = document.createElement('script')
    script.setAttribute('crossorigin', 'anonymous')
    script.src = scriptURL
    document.body.appendChild(script)
    script.addEventListener('load', () => {
      // 加载JS SDK文件成功，此时可使用全局变量"tccc"
      window.tccc.on(window.tccc.events.ready, () => {
        console.log('初始化成功')
      })
      window.tccc.on(window.tccc.events.tokenExpired, ({message}) => {
        console.error('初始化失败', message)
      })
    })

  }
}
export default initTcccSDK
