<template>
  <div>
    <el-form ref="form" :model="formData" label-width="80px">
      <el-form-item label="推送标题:">
        <el-input
          v-model="formData.msgTitle	"
          type="textarea"
          :rows="2"
          maxlength="30"
          show-word-limit
          placeholder="请输入标题"
          style="width: 500px;"
        />
      </el-form-item>
      <el-form-item label="推送方式:">
        <el-radio-group v-model="formData.pushChannel">
          <el-radio :label="1">公众号推送</el-radio>
          <el-radio :label="2">短信推送</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="推送内容:">
        <template v-if="formData. pushChannel === 1">
          <div class="content-box">
            <div class="model-box">
              <el-form-item label="推送正文:">
                <el-input
                  v-model="formData.moduleTitle"
                  type="textarea"
                  :rows="2"
                  maxlength="50"
                  show-word-limit
                  placeholder="请输入推送正文"
                  style="width: 250px;"
                />
              </el-form-item>
              <el-form-item class="mgT10" label="参与活动:">
                <el-input
                  v-model="formData.moduleActive"
                  maxlength="20"
                  show-word-limit
                  placeholder="请输入参与活动"
                  style="width: 250px;"
                />
              </el-form-item>
              <el-form-item class="mgT10" label="申请商品:">
                <el-input
                  v-model="formData.moduleShop"
                  maxlength="20"
                  show-word-limit
                  placeholder="请输入申请商品"
                  style="width: 250px;"
                />
              </el-form-item>
              <el-form-item class="mgT10" label="领取时间:">
                <el-date-picker
                  v-model="formData.moduleDate"
                  type="datetime"
                  placeholder="选择领取时间"
                  style="width: 250px;"
                />
              </el-form-item>
            </div>
            <span class="tip-text">备注：固定公众号消息模版，将内容填入即可</span>
          </div>
          <el-form-item label="跳转链接:">
            <el-radio-group v-model="formData.urlType	">
              <el-radio :label="0">无</el-radio>
              <el-radio :label="1">内部链接</el-radio>
              <el-radio :label="2">外部链接</el-radio>
            </el-radio-group>
            <div>
              <el-input v-model="formData.url" placeholder="请输入链接" style="width: 500px;" />
            </div>
          </el-form-item>
        </template>
        <div v-else>
          <el-input
            v-model="formData.message"
            type="textarea"
            :rows="5"
            maxlength="100"
            show-word-limit
            placeholder="请输入推送内容"
            style="width: 500px;"
          />
        </div>
      </el-form-item>

      <el-form-item label="推送规则:">
        <el-radio-group v-model="formData.pushType	">
          <el-radio :label="1">立即推送</el-radio>
          <el-radio :label="2">
            <span style="margin-right: 10px;">定时推送</span>
            <el-date-picker
              v-model="formData.pushAt	"
              type="datetime"
              placeholder="选择推送时间"
              style="width: 250px;"
            />
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="接收人:">
        <span v-if="formData.mobiles.length" style="margin-right: 10px;">已导入{{ formData.mobiles.length }}个用户</span>
        <el-button
          v-debounce="[onOpenImport,'click']"
          plain
          type="primary"
          icon="el-icon-plus"
        >
          批量导入用户
        </el-button>
        <span class="tip-text">备注：公众号推送每次用户为2000人</span>
      </el-form-item>
    </el-form>
    <div class="footer-box">
      <el-button
        v-debounce="[onBack,'click']"
        plain
        type="primary"
        style="margin-right: 15px;"
      >
        取消
      </el-button>
      <el-button v-debounce="[onSubmit,'click']" type="primary">确定</el-button>
    </div>
    <!-- 弹窗 -->
    <ImportExcelDialog
      v-if="isShowPop"
      ref="importExcelDialog"
      destroy-on-close
      model-type="uploadPushDoctor"
      :is-show-pop="isShowPop"
      @on-complete="onComplete"
      @on-close="isShowPop = false"
    />
  </div>
</template>

<script>
import ImportExcelDialog from '@/components/common/ImportExcelDialog'
import apiObj from '@/apis/apiDescription/api-objs.js'
import tools from '@/utils/tools'
const {formCheck, formMaps} = tools

export default {
  name: 'AddNewPush',
  components: {
    ImportExcelDialog
  },
  data() {
    return {
      isShowPop: false,
      formData: {
        recordId: '',
        msgTitle: '',
        pushChannel: 1,
        moduleTitle: '',
        moduleActive: '',
        moduleShop: '',
        moduleDate: '',
        message: '',
        urlType: 0,
        url: '',
        pushType: 1,
        pushAt: '',
        mobiles: []
      }

    }
  },
  computed: {
    isEdit() {
      return !!this.$route.query.id
    },
    rules() {
      const isGong = this.formData.pushChannel === 1
      let rules = {
        msgTitle: [
          {required: true, message: '请填写推送标题'},
          {max: 30, message: '推送标题最大不能超过30字符'}
        ],
        message: () => {
          let r = !isGong
            ? [
              {required: true, message: '请填写推送内容'},
              {max: 100, message: '推送内容最大不能超过100字符'}
            ]
            : []
          return r
        },
        moduleTitle: () => {
          let r = isGong
            ? [
              {required: true, message: '请填写推送内容-推送正文'},
              {max: 50, message: '推送正文最大不能超过50字符'}
            ]
            : []
          return r
        },
        moduleActive: () => {
          let r = isGong
            ? [
              {required: true, message: '请填写推送内容-参与活动'},
              {max: 20, message: '参与活动最大不能超过20字符'}
            ]
            : []
          return r
        },
        moduleShop: () => {
          let r = isGong
            ? [
              {required: true, message: '请填写推送内容-申请商品'},
              {max: 20, message: '申请商品最大不能超过20字符'}
            ]
            : []
          return r
        },
        moduleDate: () => {
          let r = isGong
            ? [
              {required: true, message: '请选择推送内容-领取时间'}
            ]
            : []
          return r
        },
        url: () => {
          let r = this.formData.urlType !== 0 && isGong
            ? [
              {required: true, message: '请填写跳转链接'}
            ]
            : []
          return r
        },
        pushAt: () => {
          let r = this.formData.pushType === 2
            ? [
              {required: true, message: '请选择定时推送时间'}
            ]
            : []
          return r
        },
        mobiles: [
          {required: true, message: '请批量导入接收人'}
        ]

      }
      return rules
    }
  },
  mounted() {
    this.onGetEditMsg()
  },
  methods: {
    onGetEditMsg() {
      const id = this.$route.query.id
      if (!id) return
      this.$requireApi(apiObj.getPushRecordDetail, {recordId: id}).then(res => {
        const {data} = res
        this.onSetFormData(data)
      }, rea => this.$message.error(rea.message))
    },
    onSetFormData(outData) {
      const jsonParse = (outD, key) => {
        if (outD.pushChannel === 2) {
          return ''
        }
        const msg = JSON.parse(outD.msgContent)
        return msg[key]
      }
      const options = {
        data: this.formData,
        dataMaps: {
          recordId: 'id',
          message: outD => {
            if (outD.pushChannel === 2) {
              return outD.msgContent
            }
            return ''
          },
          moduleTitle: outD => {
            return jsonParse(outD, 'title')
          },
          moduleActive: outD => {
            return jsonParse(outD, '参与活动')
          },
          moduleShop: outD => {
            return jsonParse(outD, '申请商品')
          },
          moduleDate: outD => {
            return jsonParse(outD, '领取时间')
          },
          url: outD => {
            return jsonParse(outD, 'url')
          },
          pushAt: 'pushTime'
        }
      }
      this.formData = formMaps(options, outData)
    },
    onOpenImport() {
      this.isShowPop = true
    },
    onComplete(val) {
      this.formData.mobiles = val.data
    },
    onSubmit() {
      if (!formCheck(this.formData, this.rules)) return
      this.$confirm('推送后将不可修改，请确认所填信息是否正确！', '温馨提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        center: true
      }).then(() => {
        this.onConfirm()
      }).catch(() => {})

    },
    onConfirm() {
      const apiItem = this.isEdit ? apiObj.updatePushRecord : apiObj.addPushRecord
      const reqObj = {
        ...apiItem,
        dataMaps: {
          msgContent: outD => {
            if (outD.pushChannel === 2) return outD.message
            let msg = {
              'title': outD.moduleTitle,
              '参与活动': outD.moduleActive,
              '申请商品': outD.moduleShop,
              '领取时间': this.$moment(outD.moduleDate).format('YYYY-MM-DD HH:mm:ss'),
              'url': outD.url
            }
            return JSON.stringify(msg)
          },
          pushAt: {
            name: 'pushAt',
            fun: (val, outD) => {
              if (outD.pushType === 2) {
                return this.$moment(val).format('YYYY-MM-DD HH:mm:ss')
              }
            }
          }
        }
      }
      this.$requireApi(reqObj, this.formData).then(() => {
        this.$message.success('新增成功')
        this.onBack()
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    onBack() {
      this.$router.back()
    }
  }

}
</script>

<style lang="scss" scoped>
.content-box {
  display: flex;
}
.model-box {
  padding: 30px;
  border: 1px solid #eee;
  width: 500px;
}
.tip-text {
  color: #565656;
  padding-left: 15px;
}
.mgT10 {
  margin-top: 10px;
}
.footer-box {
  text-align: center;
  padding: 30px;
}
</style>
