<template>
  <div>
    <el-main>
      <span style="font-weight: bold;">标签管理：</span>
      <el-main>
        <div>
          所有标签 <span class="tips">课程标签和热门标签需要统一在这里添加和管理</span>
          <el-button
            class="button-new-tag"
            size="small"
            @click="addTagDialogVisible = true"
          >
            + 添加新标签
          </el-button>
        </div>
        <el-table
          :data="tagList"
          border
          style="width: 50%;"
        >
          <el-table-column
            prop="tagName"
            label="标签名称"
          />
          <el-table-column
            prop=""
            label="操作"
          >
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.hotTagStatus === 1"
                type="text"
                @click.native.prevent="setHotTag(scope.$index, tagList,'0')"
              >
                移除热门
              </el-button>
              <el-button
                v-if="scope.row.hotTagStatus === 0"
                type="text"
                @click.native.prevent="setHotTag(scope.$index, tagList,'1')"
              >
                设置热门
              </el-button>
              <el-button
                type="text"
                @click.native.prevent="handleClose(scope.$index, tagList)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :page-size="limit"
          layout="total, prev, pager, next"
          :total="total"
          @current-change="onCurrentChange"
        />
        <div>热门标签 <span class="tips">会在首页热门标签展示，不超过10个</span></div>
        <el-tag
          v-for="tag in hotTag"
          :key="tag.tagId"
          closable
          :disable-transitions="false"
          @close="updateHotTagList(tag.tagId,'0')"
        >
          {{ tag.tagName }}
        </el-tag>
      </el-main>
    </el-main>
    <el-main>
      <div>
        <span style="font-weight: bold;">热门课程管理：</span>
        <span class="tips">您可以在课程管理中，将课程设置为热门课程，热门课程会展示在首页热门推荐，最多10个，根据权重值排序</span>
      </div>
      <el-table
        :data="allSpeakerCourseList"
        border
        style="width: 100%;"
      >
        <el-table-column
          prop="courseName"
          label="课程名称"
        />
        <el-table-column
          prop="speakerName"
          label="主讲人"
        />
        <el-table-column
          prop="jobTitle"
          label="职称"
        />
        <el-table-column
          prop="department"
          label="科室"
        />
        <el-table-column
          prop="hospital"
          label="医院"
        />
        <el-table-column
          prop="videoCount"
          label="视频数量"
        >
          <template slot-scope="scoped">
            <div class="center-item">
              <div>{{ scoped.row.videoCount || 0 }}个</div>
              <div>（共{{ scoped.row.videoTime | changeTime }}）</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="purchaseAmount"
          label="采购价格"
        />
        <el-table-column
          prop="purchaseTime"
          label="采购时间"
        >
          <template slot-scope="scoped">
            {{ $dayjs(scoped.row.purchaseTime || '/').format('YYYY-MM-DD HH:mm:ss') }}
          </template>
        </el-table-column>
        <el-table-column
          prop="salePrice"
          label="价格"
        />
        <el-table-column
          prop="saleCount"
          label="本月售出"
        />
        <el-table-column
          prop="weight"
          label="权重"
        />
        <el-table-column
          prop="totalIncome"
          label="累计收益"
        >
          <template slot-scope="scope">
            <div>
              <div>平台:{{ scope.row.totalIncome || ' /' }}</div>
              <div>讲者:{{ scope.row.totalIncome || ' /' }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="currentMonthTotalIncome"
          label="本月收益"
        >
          <template slot-scope="scope">
            <div>
              <div>平台:{{ scope.row.currentMonthTotalIncome || ' /' }}</div>
              <div>讲者:{{ scope.row.currentMonthTotalIncome || ' /' }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          label="操作"
        >
          <template slot-scope="scope">
            <div>
              <el-button
                type="text"
                @click.native.prevent="checkDetail(scope.$index, allSpeakerCourseList)"
              >
                编辑
              </el-button>
            </div>
            <el-button
              type="text"
              @click.native.prevent="removeHotCourse(scope)"
            >
              移除热门
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog
        title="添加新标签"
        :visible.sync="addTagDialogVisible"
        width="30%"
        center
      >
        标签名称：
        <el-input
          v-model="tagName"
          style="width: 300px;"
        />
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button @click="addTagDialogVisible = false">取 消</el-button>
          <el-button
            type="primary"
            @click="addTag"
          >确 定</el-button>
        </span>
      </el-dialog>
    </el-main>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { changeTime } from '@/utils/util'

export default {
  name: 'OperateManagement',
  filters: {
    changeTime(num) {
      return changeTime(num)
    }
  },
  data() {
    return {
      page: 1,
      limit: 10,
      total: 0,
      tagList: [],
      inputVisible: false,
      tagName: '',
      tableData: [],
      addTagDialogVisible: false,
      deleteTagDialogVisible: false,
      hotTag: []
    }
  },
  computed: {
    ...mapGetters(['allSpeakerCourseList'])
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions(['getAllSpeakerCourseList', 'updateHotCourse', 'getTagList', 'deleteTagList', 'addTagList', 'updateHotTag', 'hotTagList']),
    init() {
      this.getAllSpeakerCourseList({ page: 1, limit: this.limit, courseType: 2 }).then()
      this.updateTagList()
      this.getHotTagList()
    },
    getHotTagList() {
      this.hotTagList().then(res => {
        this.hotTag = res
      })
    },
    updateTagList() {
      this.getTagList({ page: this.page, limit: this.limit }).then(res => {
        this.tagList = res.data
        this.total = res.totalCount
      })
    },
    onCurrentChange(options) {
      this.page = options
      this.updateTagList()
    },
    handleClose(index, data) {
      this.$confirm('删除标签后，所有课程对应的标签也会一并删除，删除操作不可逆，请谨慎操作，您确定继续删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.deleteTagList({ tagId: data[index].tagId }).then(() => {
            this.$message.success('已删除')
            this.updateTagList()
            this.getHotTagList()
          })
        })
    },
    setHotTag(index, data, type) {
      this.updateHotTag({ tagId: data[index].tagId, type: type }).then(() => {
        if (type === '0') {
          this.$message.success('移除热门成功')
        } else {
          this.$message.success('设置热门成功')
        }
        this.updateTagList()
        this.getHotTagList()
      })
    },
    updateHotTagList(tagId, type) {
      this.updateHotTag({ tagId: tagId, type: type }).then(() => {
        if (type === '0') {
          this.$message.success('移除热门成功')
        } else {
          this.$message.success('设置热门成功')
        }
        this.updateTagList()
        this.getHotTagList()
      })
    },
    addTag() {
      this.addTagList({ tagName: this.tagName }).then(() => {
        this.tagList.push(this.tagName)
        this.updateTagList()
        this.addTagDialogVisible = false
        this.$message.success('添加成功')
      })
    },
    checkDetail(index, data) {
      this.$router.push({ name: 'SpeakerCourseDetail', params: { courseId: data[index].id } })
    },
    removeHotCourse(options) {
      this.$confirm('确定将此课程移除热门吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.updateHotCourse({ courseId: options.row.id, type: 0 }).then(() => {
            this.$message.success('移除成功')
            this.init()
          })
        })
    }
  }
}
</script>

<style scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.center-item {
  display: flex;
  flex-flow: column;
  align-items: center;
}
.tips {
  display: inline-block;
  color: #8c939d;
  margin-top: 20px;
  margin-bottom: 30px;
}
</style>
