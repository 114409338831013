import DOMPurify from 'dompurify' // npm install dompurify

const camelToHyphen = str => {
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
}

export default {
  install(Vue) {
    /** *
    * 防抖 单位时间只触发最后一次
    *  @param {?Number|300} time - 间隔时间
    *  @param {Function} fn - 执行事件
    *  @param {?String|"click"} event - 事件类型 例："click"
    *  @param {Array} binding.value - [fn,event,time]
    *  例：<el-button v-debounce="[reset,`click`,300]">刷新</el-button>
    *  也可简写成：<el-button v-debounce="[reset]">刷新</el-button>
    *  例：<el-input v-debounce="[reset,`input`,300]"></el-button>
    */
    Vue.directive('debounce', {
      inserted: function(el, binding) {
        const [fn, event = 'click', time = 300, params] = binding.value
        let timer
        let flag = true// 输入法标记
        if (event === 'input') {
          el.addEventListener('compositionstart', () => {
            flag = false
          })
          el.addEventListener('compositionend', () => {
            flag = true
          })
        }
        el.addEventListener(event, e => {
          timer && clearTimeout(timer)
          timer = setTimeout(() => {
            if (flag)fn(params || e.target.value)
          }, time || 300)
        })
      }
    })
    /**
     * 注册v-safe-html指令
     * 防止xss攻击
     */
    Vue.directive('safe-html', (el, binding) => {
      el.innerHTML = DOMPurify.sanitize(binding.value)
    })

    Vue.directive('highlight', (el, binding) => {
      const { value } = binding
      if (value && typeof value === 'object') {
        const { hWord, word } = value
        let { style = 'background: yellow' } = value

        if (Object.prototype.toString.call(style) === '[object Object]') {
          const copyStyle = {}
          for (const key in style) {
            copyStyle[camelToHyphen(key)] = style[key]
          }
          style = JSON.stringify(copyStyle).replace(/{|}|"/g, '').replace(',', ';')
        }
        el.innerHTML = word.replace(new RegExp(hWord, 'ig'), a => {
          return `<span style="${style}">${a}</span>`
        })
      }
    })
  }
}
