<template>
  <div class="data-chart-table">
    <p class="title"><span>{{ chartData.modelName }}</span></p>
    <el-table
      ref="el-table"
      border
      stripe
      :data="chartData.data"
      height="100%"
      :default-sort="{ prop: 'date', order: 'descending' }"
    >
      <el-table-column
        v-for="(item) in chartData.groupByDesc"
        :key="item.colDesc + item.groupByDesc"
        align="center"
        show-overflow-tooltip
        sortable
        :min-width="`${baseWidth(item.colDesc)}px`"
        :prop="item.colDesc"
      >
        <template #header>{{ item.colDesc }}</template>
        <template slot-scope="scoped">
          {{ (yGroupByDesc && yGroupByDesc.colDesc === item.colDesc ? interpretEnu(scoped.row[item.colDesc]) : scoped.row[item.colDesc]) || '/' }}
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item,index) in chartData.colDesc"
        :key="item+index"
        align="center"
        :min-width="baseWidth(item) + 'px'"
        :label="item"
        show-overflow-tooltip
        sortable
        :prop="item"
      >
        <template #header>{{ item }}</template>
      </el-table-column>
      <template slot="empty">
        <Empty />
      </template>
    </el-table>
  </div>
</template>

<script>
import mixinWindowResize from '../../mixins/windowResize'
import Empty from '../Empty/index.vue'
export default {
  name: 'DataChartTable',
  components: {Empty},
  mixins: [mixinWindowResize],
  props: {
    chartData: {
      type: Object,
      default: Object
    },
    aggregation: {
      type: Object,
      default: Object
    }
  },
  computed: {
    columnList() {
      return Object.keys(this.chartData?.data)
    },
    yGroupByDesc() {
      const [yGroupByDesc] = this.chartData.groupByDesc.filter(item => !item.xaxis)
      return yGroupByDesc
    },
    interpretEnu() {
      return key => {
        if (this.yGroupByDesc.colValues) {
          return this.yGroupByDesc.colValuesDesc[key]
        } else {
          return key
        }
      }
    }
  },
  watch: {
    aggregation: {
      handler() {
        this.$nextTick(() => {
          this.$refs['el-table']?.doLayout()
        })
      },
      deep: true
    }
  },
  methods: {
    baseWidth(str) {
      const strWidth = str.length * 14 + 20 + 25
      return strWidth < 100 ? 100 : strWidth
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.data-chart-table {
  display: grid;
  grid-template-rows: auto 1fr auto;
  box-sizing: border-box;
  .title {
    font-size: 14px;
    color: #464646;
    font-weight: bolder;
    padding: 0 5px;
    margin-top: 3px;
    margin-bottom: 7px;
    font-family: sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  :deep(.el-table) {
    display: flex;
    flex-direction: column;
    position: relative;
    // top: 0;
    // bottom: 0;
    .el-table__body-wrapper {
      flex: 1;
      .warning-row {
        background: oldlace;
      }
      .success-row {
        background: #f0f9eb;
      }
    }
  }
}
</style>
