import request from '@/utils/request'
import API from './api-types'

// 获取商品池列表
export default function getAfterSaleOrderList({
  pageIndex,
  startTime,
  endTime,
  orderId,
  userType,
  reviewStatus,
  supplierType,
  productType
}) {
  return request({
    url: API.getAfterSaleOrderList,
    method: 'post',
    data: {
      pageIndex,
      startTime,
      endTime,
      orderId,
      userType,
      reviewStatus,
      supplierType,
      productType,
      pageSize: 20
    }
  })
}
