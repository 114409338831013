import request from '@/utils/request'
import API from './api-types'

// 获取商品池列表
export default function getPoolNumbers(data) {
  return request({
    url: API.getPoolNumbers,
    method: 'get',
    params: data
  })
}
