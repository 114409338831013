import request from '@/utils/request'
import API from './api-types'

// 下载商品列表文件
export default function downloadOrderList({
  startTime,
  endTime,
  orderId,
  userType,
  orderStatus,
  supplierType,
  productType
}) {
  return request({
    url: API.downloadOrderList,
    method: 'post',
    data: {
      startTime,
      endTime,
      orderId,
      userType,
      orderStatus,
      supplierType,
      productType
    }
  })
}
