<template>
  <div class="home">
    <div class="space-between">
      <div>
        <el-input
          v-model="searchData.activityTitle"
          placeholder="搜索活动名称"
          class="search-input"
          clearable
          @change="init"
        />
        <el-select
          v-model="searchData.status"
          class="mgn-r20"
          clearable
          placeholder="推送状态"
          @change="init"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div>
        <el-button
          type="primary"
          class="qxs-btn-md"
          icon="el-icon-plus"
          @click="addActivity"
        >
          新建推送
        </el-button>
      </div>
    </div>
    <div
      ref="table"
      class="prod-panel"
    >
      <el-table
        ref="multipleTable"
        v-loading="loading"
        :data="postList"
        border
        style="width: 100%;"
      >
        <el-table-column
          prop="pushTitle"
          label="活动专题名称"
        />
        <el-table-column
          label="活动推送状态"
          prop="status"
        >
          <template slot-scope="scoped">
            {{ scoped.row.status === 0? '草稿' : scoped.row.status === 1? '已推送' : scoped.row.status === 2? '待审核' : '审核失败' }}
          </template>
        </el-table-column>
        <el-table-column
          label="分发渠道"
          prop="department"
        >
          <template slot-scope="scoped">
            {{ scoped.row.sendType === 1? 'banner' : '学术交流圈' }}
          </template>
        </el-table-column>
        <el-table-column
          label="打开用户/推送用户"
          prop="hospital"
        >
          <template slot-scope="scoped">
            {{ scoped.row.openCount + ' / ' + scoped.row.pushCount }}
          </template>
        </el-table-column>
        <el-table-column
          label="推送时间"
          prop="dateTime"
        >
          <template slot-scope="scoped">
            {{ $dayjs(scoped.row.dateTime).format('YYYY-MM-DD HH:mm:ss') }}
          </template>
        </el-table-column>
        <el-table-column
          label="创建人"
          prop="creatorName"
        />
        <el-table-column
          label="审核人"
          prop="auditorName"
        />
        <el-table-column
          prop="zip"
          label="操作"
        >
          <template slot-scope="scoped">
            <el-button
              v-if="scoped.row.status === 1"
              type="text"
              @click.native.prevent="jumpDetail(scoped.row, '3')"
            >
              查看
            </el-button>
            <el-button
              v-if="scoped.row.status === 0 || scoped.row.status === 3"
              type="text"
              @click.native.prevent="jumpDetail(scoped.row, '0')"
            >
              编辑
            </el-button>
            <el-button
              v-if="scoped.row.status === 2"
              type="text"
              @click.native.prevent="jumpDetail(scoped.row, '2')"
            >
              审核
            </el-button>
            <el-button
              v-if="scoped.row.status !== 1"
              type="text"
              @click.native.prevent="onDel(scoped.row)"
            >
              删除
            </el-button>
            <el-button
              v-if="scoped.row.status === 1"
              type="text"
              @click.native.prevent="onCancelPush(scoped.row)"
            >
              取消推送
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="searchData.pageNo"
        :page-size="searchData.pageSize"
        layout="total,prev, pager, next, jumper"
        :total="totalCount"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'SpeakerManagement',
  components: {},
  data() {
    return {
      loading: true,
      postList: [],
      name: '讲者列表',
      searchData: {
        activityTitle: '',
        pageNo: 1,
        pageSize: 10,
        status: ''
      },
      totalCount: 0,
      options: [
        {
          value: '0',
          label: '草稿'
        },
        {
          value: '1',
          label: '已推送'
        },
        {
          value: '2',
          label: '审核中'
        },
        {
          value: '3',
          label: '审核失败'
        }
      ]
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.loading = true
      this.$axios.get(this.$API.getPostList, {params: this.searchData }).then(res => {
        this.loading = false
        this.postList = res?.data || []
        this.totalCount = res?.totalCount || 0
      }, rea => {
        this.loading = false
        this.$message.error(rea.message)
      })
    },
    jumpDetail(row, active) {
      this.$router.push({ name: 'PostDetail', query: { id: row.id, active: active } })
    },
    handleCurrentChange(val) {
      this.searchData.pageNo = val
      this.init()
    },
    addActivity() {
      this.$router.push({ name: 'PostDetail' })
    },
    onDel(row) {
      this.$confirm('请确认是否删除该推送信息', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post(this.$API.delPost, null, { params: { brandingPushId: row.id } }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功'
          })
          this.init()
        }, rea => {
          this.$message.error(rea.message)
        })
      })
    },
    onCancelPush(row) {
      this.$confirm('请确认是否取消该推送', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post(this.$API.changePostStatus, null, { params: { status: 0, brandingPushId: row.id } }).then(() => {
          this.$message({
            type: 'success',
            message: '取消推送成功'
          })
          this.init()
        }, rea => {
          this.$message.error(rea.message)
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.el-pagination {
  padding-top: 26px;
  text-align: center;
}
.space-between {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.search-input {
  width: 400px;
  margin: 0 10px;
}
</style>
