<template>
  <div>
    <div class="mgn-b30 flex">
      <el-input v-model="keyWord" placeholder="专区名称" class="search" />
      <el-button class="add-lan" type="primary" @click="onSearch">搜索</el-button>
      <el-button class="add-lan" @click="onClickDetail({}, 'new')">+ 新增</el-button>
    </div>
    <el-table :data="activityList" border>
      <el-table-column prop="title" label="专区名称" />
      <el-table-column prop="subTitle" label="专区子标题" />
      <el-table-column prop="url" label="跳转链接" />
      <el-table-column prop="iconUrl" label="专区子图片">
        <template slot-scope="scope">
          <span v-for="(v, i) in scope.row.images" :key="i">
            <img
              :src="v.middle"
              fit="cover"
              class="avatar-scope"
            >
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="activityIndex" label="权重" />
      <el-table-column prop="activityState" label="状态">
        <template slot-scope="scope">
          <span>{{ scope.row.activityState === 0? '下架': '上架' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <div>
            <el-button type="text" @click="onClickDetail(scope.row, 'edit')"> 编辑 </el-button>
            <el-button type="text" style="color: red;" @click="onClickDel(scope.row)">
              删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="pageSize"
      layout="prev, pager, next, jumper"
      :total="totalCount"
      @current-change="handleCurrentChange"
    />
    <el-dialog
      v-if="dialogFormVisible"
      :title="dialogTitle"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      width="40%"
    >
      <el-form ref="ruleForm" :model="form" :rules="rules">
        <el-form-item label="专区名称" prop="title" :label-width="formLabelWidth">
          <el-input
            v-model="form.title"
            maxlength="4"
            show-word-limit
          />
        </el-form-item>
        <el-form-item label="专区子标题" :label-width="formLabelWidth">
          <el-input
            v-model="form.subTitle"
            maxlength="4"
            show-word-limit
          />
        </el-form-item>
        <el-form-item label="子标题高亮" :label-width="formLabelWidth">
          <el-switch v-model="form.subTitleHighLight" />
        </el-form-item>
        <el-form-item label="跳转链接" prop="url" :label-width="formLabelWidth">
          <el-input v-model="form.url" />
        </el-form-item>
        <el-form-item label="选择链接类型" prop="urlType" :label-width="formLabelWidth">
          <el-select v-model="form.urlType" placeholder="请选择">
            <el-option
              v-for="item in urlList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="权重" prop="activityIndex" :label-width="formLabelWidth">
          <el-input v-model="form.activityIndex" />
        </el-form-item>
        <el-form-item label="状态" prop="activityState" :label-width="formLabelWidth">
          <el-select v-model="form.activityState" placeholder="请选择">
            <el-option
              v-for="item in optionsList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="专区图片" prop="images" :label-width="formLabelWidth">
          <!-- 上传图片接口，文章相关的传 115，反馈相关的传入116 -->
          <el-upload
            class="avatar-uploader"
            action="/manager/separate/v1/upload-image?bucketType=109"
            multiple
            name="image"
            list-type="picture-card"
            accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
            :headers="headers"
            :limit="4"
            :on-success="handleAvatarSuccess"
            :on-exceed="handleExceed"
            :on-remove="handleRemove"
            :file-list="fileList"
          >
            <i class="el-icon-plus" />
            <div slot="tip" class="el-upload__tip">备注：最多上传4张</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="onCancel"> 取 消 </el-button>
        <el-button type="primary" @click="onSubmit"> 确 定 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getToken } from '../../utils/auth'
import { urlObject } from '@/utils/util'
export default {
  name: 'ActivityZone',
  data() {
    return {
      keyWord: '',
      page: 1,
      pageSize: 10,
      totalCount: 0,
      activityList: [],
      // ----------------------------- 上传图片 ----------------
      images: [],
      fileList: [],
      headers: {},
      isSize: true,
      num: 0,
      fileNum: 0,
      // ----------------------------- 弹窗 ---------------------
      isLoading: false,
      dialogTitle: '新建专区',
      dialogFormVisible: false,
      formLabelWidth: '120px',
      form: {
        id: '',
        title: '',
        subTitle: '',
        subTitleHighLight: false,
        url: '',
        urlType: '',
        activityIndex: '',
        activityState: '',
        images: []
      },
      rules: {
        title: [
          {
            required: true,
            message: '请输入专区名称',
            trigger: 'blur'
          },
          { min: 1, max: 4, message: '长度在 1 到 4 个字符', trigger: 'blur' }
        ],
        url: [
          {
            required: true,
            message: '请输入链接',
            trigger: 'blur'
          }
        ],
        urlType: [
          {
            required: true,
            message: '请选择链接链接',
            trigger: 'change'
          }
        ],
        activityIndex: [
          {
            required: true,
            message: '请输入权重',
            trigger: 'blur'
          }
        ],
        activityState: [
          {
            required: true,
            message: '请选择状态',
            trigger: 'change'
          }
        ],
        images: [
          {
            required: true,
            message: '请上传图片',
            trigger: 'change'
          }
        ]
      },
      messageItem: null,
      optionsList: [
        {
          value: 1,
          label: '上架'
        },
        {
          value: 0,
          label: '下架'
        }
      ],
      urlList: [
        {
          value: 1,
          label: '适应页面'
        },
        {
          value: 2,
          label: '内部页面'
        },
        {
          value: 3,
          label: '外部页面'
        },
        {
          value: 4,
          label: '动态页面'
        }
      ]
    }
  },
  mounted() {
    this.headers = {
      token: getToken()
    }
    this.getList()
  },
  methods: {
    getList() {
      let params = {
        keyWord: this.keyWord,
        page: this.page,
        pageSize: this.pageSize
      }
      this.$axios.get(this.$API.getActivityAreaList, {params}).then(res => {
        this.activityList = res.data || []
        this.totalCount = res.totalCount
      }, rea => this.$message.error(rea.message))
    },
    onSearch() {
      this.page = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.page = val
      this.getList()
    },
    // ----------------------- 上传图片 -------------------
    handleAvatarSuccess(res) {
      this.form.images.push(res.data.imageName)
    },
    handleExceed() {
      this.$message({
        message: '最多只能上传4张图片',
        type: 'warning',
        duration: 1000
      })
    },
    handleRemove(file) {
      if (this.form.id) {
        this.form.images = this.form.images.filter(v => v !== file.name)
      } else {
        this.form.images = this.form.images.filter(v => v !== file.response.data.imageName)
      }
    },
    onClickDetail(row, type) {
      this.fileList = []
      if (type === 'edit') {
        const imgList = []
        if (row.images) {
          row.images.map(v => {
            if (v) {
              this.fileList.push({name: v.imageName, url: v.middle})
              imgList.push(v.imageName)
            }
          })
        }
        this.dialogTitle = '编辑专区'
        this.form = Object.assign({}, row, {images: imgList})
        this.dialogFormVisible = true
      } else {
        this.dialogTitle = '新建专区'
        this.form = {
          title: '',
          subTitle: '',
          subTitleHighLight: false,
          url: '',
          urlType: '',
          activityIndex: '',
          activityState: 1,
          images: []
        }
        this.dialogFormVisible = true
      }
    },
    onClickDel(options) {
      this.$confirm('是否删除该活动专区?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (this.isLoading) return
          this.isLoading = true
          this.$axios.get(this.$API.deleteActivityArea, { params: { activityAreaId: options.id } }).then(
            () => {
              this.isLoading = false
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getList()
            },
            err => {
              this.isLoading = false
              this.$message.error(err.message)
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    onCancel() {
      this.dialogFormVisible = false
    },
    onSubmit() {
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          const obj = urlObject(this.form.url) || {}
          if (!this.onCheckType(obj)) return
          // 给图片加上顺序
          const images = this.form.images.map((v, i) => {
            return {
              imageName: v,
              imageIndex: i + 1
            }
          })
          this.form.images = images
          const params = Object.assign({}, this.form)
          this.$axios.post(this.$API.saveActivityArea, params).then(
            () => {
              this.$message({
                type: 'success',
                message: '保存成功!'
              })
              this.onCancel()
              this.getList()
            },
            err => {
              this.$message.error(err.message)
            }
          )
        } else {
          return false
        }
      })
    },
    onCheckType(request = {}) {
      let isPass = false
      if (
        this.form.urlType === 3 &&
        (this.form.url.indexOf('http://') > -1 ||
          this.form.url.indexOf('https://') > -1)
      ) {
        // 外部链接 完整链接
        isPass = true
      } else if (this.form.urlType === 2 && request?.name) {
        // 内部页面 shop/list?name=&subjectName=&id=   name作为跳转页面名必填；
        isPass = true
      } else if (this.form.urlType === 1 && request?.name && request?.id) {
        // 适应页面 shop/list?name=&subjectName=&id=   name作为标签类型nav/newUp，subjectName作为标签名，id作为标签id，必填；
        isPass = true
      } else if (this.form.urlType === 4) {
        isPass = true
      }
      if (!isPass) {
        this.$message.error('链接格式不正确')
      }
      return isPass
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-scope {
  width: 50px;
  height: 50px;
  padding: 5px 5px;
}
.add-lan {
  margin-left: 30px;
}
.search {
  width: 200px;
}
.el-pagination {
  padding-top: 10px;
  text-align: center;
}
</style>
