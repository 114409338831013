const getters = {
  loading: state => state.loading,    // 是否正在加载
  doctor: state => state.doctor, // 医生端二进制
  representative: state => state.doctor << 1, // 代表端二进制
  manufacturer: state => state.doctor << 2, // 厂家端二进制
  speakerTerminal: state => state.doctor << 3, // 讲者端二进制
  currentPage: state => state.currentPage, // table 的当前页数
  pageSize: state => state.pageSize, // table 的单页条数
  prodListIndex: state => state.prodListIndex,
  prodList: state => state.prodList,
  prodTotalCount: state => state.prodTotalCount,
  total: state => state.total, // table 的总条数
  feedbackList: state => state.feedbackList, // 平台反馈列表
  categoryList: state => state.categoryList, // 文章管理分类列表
  articleList: state => state.articleList, // 获取趣学术文章列表
  guideDetailList: state => state.guideDetailList, // 获取新手引导详情
  showAt: state => state.showAt, // 选择显示端
  categoryId: state => state.categoryId, // 选择问题分类，一级分类ID
  secCategoryId: state => state.secCategoryId, // 选择问题分类，二级分类ID
  status: state => state.status, // 选择状态
  searchKey: state => state.searchKey, // 输入框搜索
  detailObj: state => state.detailObj // 详情数据
}

export default getters
