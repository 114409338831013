import { merge } from 'lodash-es'

// 折线图默认配置
const baseConfig = {
  table: {},
  card: {},
  line: {
    tooltip: {
      confine: false,
      trigger: 'axis',
      axisPointer: {
        type: 'line'
      }
    },
    toolbox: {
      show: false,
      showTitle: false,
      feature: {
        // dataView: { readOnly: true },
        // restore: {},
        saveAsImage: {}
      }
    },
    legend: {
      type: 'scroll',
      top: '25px'
    },
    grid: {
      left: '0',
      bottom: '10px',
      right: '30px',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false
    },
    yAxis: {
      type: 'value'
    },
    dataZoom: [
      {
        type: 'inside'
      }
    ]
  },
  bar: {
    legend: {
      type: 'scroll',
      top: '25px'
    },
    tooltip: {
      confine: false,
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    toolbox: {
      show: false,
      showTitle: false,
      feature: {
        // dataView: { readOnly: true },
        // restore: {},
        saveAsImage: {}
        // myFull: { // 全屏
        //   show: true,
        //   title: '全屏',
        //   icon: 'path://M256 874.666667a21.333333 21.333333 0 0 1-21.333333 21.333333H96a53.393333 53.393333 0 0 1-53.333333-53.333333v-138.666667a21.333333 21.333333 0 0 1 42.666666 0v138.666667a10.666667 10.666667 0 0 0 10.666667 10.666666h138.666667a21.333333 21.333333 0 0 1 21.333333 21.333334zM42.666667 320V181.333333a53.393333 53.393333 0 0 1 53.333333-53.333333h138.666667a21.333333 21.333333 0 0 1 0 42.666667H96a10.666667 10.666667 0 0 0-10.666667 10.666666v138.666667a21.333333 21.333333 0 0 1-42.666666 0z m938.666666-138.666667v138.666667a21.333333 21.333333 0 0 1-42.666666 0V181.333333a10.666667 10.666667 0 0 0-10.666667-10.666666h-138.666667a21.333333 21.333333 0 0 1 0-42.666667h138.666667a53.393333 53.393333 0 0 1 53.333333 53.333333z m0 522.666667v138.666667a53.393333 53.393333 0 0 1-53.333333 53.333333h-138.666667a21.333333 21.333333 0 0 1 0-42.666667h138.666667a10.666667 10.666667 0 0 0 10.666667-10.666666v-138.666667a21.333333 21.333333 0 0 1 42.666666 0z'
        // }
      }
    },
    grid: {
      left: '0',
      bottom: '10px',
      right: '30px',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      axisLabel: {
        showMaxLabel: true
      },
      axisTick: {
        alignWithLabel: true
      }
    },
    yAxis: {
      type: 'value'
    },
    dataZoom: [
      {
        type: 'inside'
      }
    ]
  },
  pie: {
    tooltip: {
      confine: true,
      formatter: '{a}<br>{b}: {c}({d}%)',
      trigger: 'item'
    },
    toolbox: {
      show: false,
      showTitle: false,
      feature: {
        // dataView: { readOnly: true },
        // restore: {},
        saveAsImage: {}
      }
    },
    legend: {
      type: 'scroll',
      top: '25px'
    }
  },
  area: {
    tooltip: {
      confine: true,
      trigger: 'item'
    },
    toolbox: {
      show: false,
      showTitle: false,
      feature: {
        // dataView: { readOnly: true },
        // restore: {},
        saveAsImage: {}
      }
    },
    visualMap: {
      text: ['高', '低'],
      realtime: false,
      calculable: true,
      inRange: {
        color: ['lightskyblue', 'yellow', 'orangered']
      }
    }
  },
  funnel: {
    tooltip: {
      confine: true,
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c}'
    },
    toolbox: {
      show: false,
      showTitle: false,
      feature: {
        // dataView: { readOnly: true },
        // restore: {},
        saveAsImage: {}
      }
    },
    legend: {
      type: 'scroll',
      top: 20
    }
  },
  radar: {
    legend: {
      type: 'scroll',
      orient: 'vertical',
      left: 'left',
      top: '25px'
    },
    tooltip: {
      confine: true,
      trigger: 'item'
    },
    toolbox: {
      show: false,
      showTitle: false,
      feature: {
        // dataView: { readOnly: true },
        // restore: {},
        saveAsImage: {}
      }
    },
    radar: {
      // shape: 'circle',
      center: ['50%', '55%']
    }
  },
  ['scatter-simple']: {
    grid: {
      left: '0',
      bottom: '10px',
      right: '30px',
      containLabel: true
    },
    tooltip: {
      showDelay: 0,
      axisPointer: {
        show: true,
        type: 'cross',
        lineStyle: {
          type: 'dashed',
          width: 1
        }
      }
    },
    toolbox: {
      show: false,
      showTitle: false,
      feature: {
        // dataView: { readOnly: true },
        // restore: {},
        saveAsImage: {}
      }
    },
    legend: {
      type: 'scroll',
      top: '25px'
    },
    xAxis: {
      splitLine: {
        lineStyle: {
          type: 'dashed'
        }
      }
    },
    yAxis: {
      splitLine: {
        lineStyle: {
          type: 'dashed'
        }
      }
    }
  }
}

// 修改默认配置
export const setDefaultChartOption = (option, changeConfig) => {
  for (const key in option) {
    if (option[key]) {
      option[key] = merge(option[key], changeConfig)
    }
  }
  return option
}

export const defaultChartOption = setDefaultChartOption(baseConfig, {
  title: {
    textStyle: {
      fontSize: 14,
      lineHeight: 16
    }
  }
})
