<template>
  <div class="search-wrap">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'SearchWrap',
  data() { return {} },
  mounted() { },
  methods: {}
}
</script>

<style lang="scss" scoped>
// scss
.search-wrap {
  padding: 15px;
  padding-bottom: 0;
  border-left: 5px solid #009688;
  border-radius: 0 2px 2px 0;
  background-color: #eee;
  margin-bottom: 10px;
}
</style>
