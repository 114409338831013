<template>
  <div>
    <div class="mgn-b30">
      <span>栏目管理</span>
      <el-button class="add-lan" @click="onClickDetail({}, 'new')">新增栏目</el-button>
    </div>
    <el-table :data="columnList" border>
      <el-table-column prop="columnSort" label="栏目序号" />
      <el-table-column prop="columnName" label="名称" />
      <el-table-column prop="iconUrl" label="图标">
        <template slot-scope="scope">
          <img
            v-if="scope.row.iconUrl"
            :src="scope.row.iconUrl.small"
            fit="cover"
            class="avatar-scope"
          >
        </template>
      </el-table-column>
      <el-table-column prop="cornerScript" label="角标文案" />
      <el-table-column prop="directUrl" label="指向链接" />
      <el-table-column label="操作">
        <template slot-scope="scope">
          <div>
            <el-button
              v-if="scope.$index !== 0"
              class="mgn-r10"
              type="text"
              icon="el-icon-top"
              @click="handleUp(scope)"
            />
            <el-button
              v-if="scope.$index !== columnList.length - 1"
              class="mgn-r10"
              type="text"
              icon="el-icon-bottom"
              @click="handleDown(scope)"
            />
            <el-button type="text" @click="onClickDetail(scope, 'editor')"> 编辑 </el-button>
            <el-button type="text" style="color: red;" @click="onClickDel(scope.row)">
              删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      class="ft-box"
      :title="dialogTitle"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      width="40%"
      center
    >
      <el-form ref="ruleForm" :model="form" :rules="rules">
        <el-form-item label="名称" prop="columnName" :label-width="formLabelWidth">
          <el-input v-model="form.columnName" @input="onInputName" />
        </el-form-item>
        <el-form-item label="图标" prop="iconUrl" :label-width="formLabelWidth">
          <!-- 上传图片接口，文章相关的传 115，反馈相关的传入116 -->
          <el-upload
            class="avatar-uploader"
            action="/manager/separate/v1/upload-image?bucketType=115"
            :headers="headers"
            name="image"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon" />
            <div slot="tip" class="el-upload__tip">大小限制为72*72-108*108</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="角标文案" prop="cornerScript" :label-width="formLabelWidth">
          <el-input v-model="form.cornerScript" @input="onInputIcon" />
        </el-form-item>
        <el-form-item label="链接" prop="directUrl" :label-width="formLabelWidth">
          <el-input v-model="form.directUrl" />
        </el-form-item>
        <el-form-item label="选择链接类型" prop="handleType" :label-width="formLabelWidth">
          <el-select v-model="form.handleType" placeholder="请选择">
            <el-option
              v-for="item in optionsList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false"> 取 消 </el-button>
        <el-button type="primary" @click="onSubmit('ruleForm')"> 确 定 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { getToken } from '../../utils/auth'
import { urlObject } from '@/utils/util'
export default {
  name: 'ProgramManagement',
  data() {
    return {
      // ----------------------------- 上传图片 ----------------
      imageUrl: '',
      headers: {},
      isSize: true,
      // ----------------------------- 弹窗 ---------------------
      isLoading: false,
      dialogTitle: '新增',
      dialogFormVisible: false,
      formLabelWidth: '120px',
      initForm: {
        id: '',
        columnName: '',
        iconUrl: '',
        cornerScript: '',
        directUrl: '',
        handleType: ''
      },
      form: {},
      rules: {
        columnName: [
          {
            required: true,
            message: '请输入名称',
            trigger: 'blur'
          }
        ],
        iconUrl: [
          {
            required: true,
            message: '请上传图标',
            trigger: 'blur'
          }
        ],
        directUrl: [
          {
            required: true,
            message: '请输入链接',
            trigger: 'blur'
          }
        ],
        handleType: [
          {
            required: true,
            message: '请输入链接',
            trigger: 'blur'
          }
        ]
      },
      messageItem: null,
      optionsList: [
        {
          value: 1,
          label: '适应页面'
        },
        {
          value: 2,
          label: '内部页面'
        },
        {
          value: 3,
          label: '外部页面'
        },
        {
          value: 4,
          label: '动态页面'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('ProgramManagementModules', ['columnList'])
  },
  mounted() {
    this.headers = {
      token: getToken()
    }
    this.managementColumnList()
  },
  methods: {
    ...mapActions('ProgramManagementModules', [
      'managementColumnList',
      'modifyColumn',
      'upColumn',
      'downColumn'
    ]),
    handleUp(scope) {
      console.log(scope, '----------- Up ----------')
      this.upColumn({ id: scope.row.id }).then(res => {
        if (res) {
          this.$message({ message: '操作成功', type: 'success' })
          setTimeout(() => {
            this.managementColumnList()
          }, 300)
        }
      })
    },
    handleDown(scope) {
      console.log(scope, '----------- Down ----------')
      this.downColumn({ id: scope.row.id }).then(res => {
        if (res) {
          this.$message({ message: '操作成功', type: 'success' })
          setTimeout(() => {
            this.managementColumnList()
          }, 300)
        }
      })
    },
    // ----------------------- 上传图片 -------------------
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
      this.$set(this.form, 'iconUrl', res.data.imageName)
    },
    beforeAvatarUpload(file) {
      console.log(file, '---------------- before ---------------')
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG/PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }

      // 校验宽高
      new Promise(function(resolve, reject) {
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.onload = function() {
          const valid =
            img.width >= 72 && img.height >= 72 && img.width <= 108 && img.height <= 108
          valid ? resolve() : reject()
        }
        img.src = _URL.createObjectURL(file)
      }).then(
        () => {
          this.isSize = true
        },
        () => {
          this.isSize = false
          this.$message.error('上传文件的图片大小不符合标准')
        }
      )
      return isJPG && isLt2M
    },
    // ----------------------- end ------------------------
    onClickDetail(options, type) {
      const row = options.row
      if (type === 'editor') {
        this.dialogTitle = '编辑'
        this.dialogFormVisible = true
        this.imageUrl = row.iconUrl && row.iconUrl.middle
        this.form = Object.assign(
          {},
          {
            id: row.id,
            columnName: row.columnName,
            iconUrl: row.iconUrl && row.iconUrl.imageName,
            cornerScript: row.cornerScript || '',
            directUrl: row.directUrl,
            handleType: row.handleType
          }
        )
      } else if (type === 'new') {
        if (this.columnList.length >= 16) {
          return this.$message.warning('最多只能添加16个栏目')
        }
        this.dialogTitle = '新增'
        this.dialogFormVisible = true
        this.imageUrl = ''
        this.form = Object.assign({}, this.initForm)
      }
    },
    onClickDel(options) {
      console.log(options)
      this.$confirm('是否删除该栏目?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (this.isLoading) return
          this.isLoading = true

          this.$axios.get(this.$API.delColumn, { params: { id: options.id } }).then(
            () => {
              this.isLoading = false
              this.managementColumnList()
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
            },
            err => {
              this.isLoading = false
              this.$message.error(err.message)
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    onInputName(val) {
      console.log(val)
      this.messageItem && this.messageItem.close()
      if (val) {
        let byteValLen = 0
        for (const item of val) {
          // eslint-disable-next-line no-control-regex
          if (item.match(/[^\x00-\xff]/gi) != null) {
            byteValLen += 2
          } else {
            byteValLen += 1
          }
          if (byteValLen > 8) {
            this.$set(this.form, 'columnName', val.slice(0, 4))
            this.messageItem = this.$message({
              message: '名称不能超过4个字',
              type: 'error'
            })
            return false
          }
        }
      }
    },
    onInputIcon(val) {
      console.log(val)
      this.messageItem && this.messageItem.close()
      if (val) {
        let byteValLen = 0
        for (const item of val) {
          // eslint-disable-next-line no-control-regex
          if (item.match(/[^\x00-\xff]/gi) != null) {
            byteValLen += 2
          } else {
            byteValLen += 1
          }
          if (byteValLen > 8) {
            this.$set(this.form, 'cornerScript', val.slice(0, 4))
            this.messageItem = this.$message({
              message: '角标长度不能超过4个汉字或8个字符',
              type: 'error'
            })
            return false
          }
        }
      }
    },
    // 检测栏目规则
    onCheckType(request = {}) {
      console.log(request)
      let isPass = false
      if (
        this.form.handleType === 3 &&
        (this.form.directUrl.indexOf('http://') > -1 ||
          this.form.directUrl.indexOf('https://') > -1)
      ) {
        // 外部链接 完整链接
        isPass = true
      } else if (this.form.handleType === 2 && request?.name) {
        // 内部页面 shop/list?name=&subjectName=&id=   name作为跳转页面名必填；
        isPass = true
      } else if (this.form.handleType === 1 && request?.name && request?.id) {
        // 适应页面 shop/list?name=&subjectName=&id=   name作为标签类型nav/newUp，subjectName作为标签名，id作为标签id，必填；
        isPass = true
      } else if (this.form.handleType === 4) {
        isPass = true
      }
      if (!isPass) {
        this.$message.error('链接格式不正确')
      }
      return isPass
    },
    // 创建/编辑栏目确认
    onSubmit(formName) {
      this.form = Object.assign({}, this.form)
      this.$refs[formName].validate(valid => {
        if (valid) {
          console.log(this.form)
          // return false;
          const { id, columnName, iconUrl, cornerScript = '', directUrl, handleType } = this.form
          let Data = { id, columnName, iconUrl, cornerScript, directUrl, handleType }
          if (!this.isSize) {
            this.$message.error('上传文件的图片大小不符合标准')
            return false
          }
          if (cornerScript) {
            let byteValLen = 0
            for (const item of cornerScript) {
              // eslint-disable-next-line no-control-regex
              if (item.match(/[^\x00-\xff]/gi) != null) {
                byteValLen += 2
              } else {
                byteValLen += 1
              }
              if (byteValLen > 8) {
                this.$message({
                  message: '角标长度超过4个字符，请重新输入',
                  type: 'error'
                })
                return false
              }
            }
          }
          const obj = urlObject(directUrl) || {}
          if (!this.onCheckType(obj)) return
          if (this.isLoading) return
          this.isLoading = true
          if (Data.id) {
            this.modifyColumn(Data).then(res => {
              this.isLoading = false
              if (res) {
                this.dialogFormVisible = false
                this.$message({ message: `${this.dialogTitle}成功`, type: 'success' })
                this.managementColumnList()
              }
            })
          } else {
            this.$axios.post(this.$API.addColumn, Data).then(
              res => {
                this.isLoading = false
                if (res) {
                  this.dialogFormVisible = false
                  this.$message({ message: `${this.dialogTitle}成功`, type: 'success' })
                  this.managementColumnList()
                }
              },
              err => {
                this.isLoading = false
                this.$message.error(err.message)
              }
            )
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-scope {
  width: 50px;
  height: 50px;
  padding: 5px 5px;
}
.add-lan {
  margin-left: 30px;
}
</style>
