<template>
  <div class="panel-wrapper">
    <slot />
  </div>
</template>

<script>

export default {
  name: 'PanelWrapper'
}
</script>
<style>
.panel-wrapper {
  padding: 10px 20px;
}
</style>
