<template>
  <el-container v-loading="loading" class="home">
    <el-header class="tab-panel">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal">
        <el-menu-item index="1" @click="scrollTo('1')"> 基础信息 </el-menu-item>
        <el-menu-item index="2" @click="scrollTo('2')"> 销售信息 </el-menu-item>
        <el-menu-item index="3" @click="scrollTo('3')"> SKU </el-menu-item>
        <el-menu-item index="4" @click="scrollTo('4')"> 供应商信息 </el-menu-item>
        <el-menu-item index="5" @click="scrollTo('5')"> 图文详情 </el-menu-item>
      </el-menu>
    </el-header>
    <el-main class="prod-panel">
      <el-form ref="form" label-width="80px">
        <div id="1" class="label-tab">| 基础信息</div>
        <el-form-item label="商品链接">
          {{ tempDetail.productUrl }}
        </el-form-item>
        <el-form-item label="商品图片">
          <el-table
            v-if="tempDetail.images && tempDetail.images.length > 0"
            :data="tempDetail.images"
            border
            style="width: 100%;"
          >
            <el-table-column prop="date" label="图片" width="180">
              <template slot-scope="scope">
                <el-image style="width: 100px; height: 100px;" :src="scope.row.imageUrl" />
              </template>
            </el-table-column>
            <el-table-column prop="imageUrl" label="路径" width="460" />
            <el-table-column prop="address" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click.native.prevent="removeProductImg(scope.$index)"
                >
                  删除
                </el-button>
                <el-button
                  v-if="!scope.row.isMain"
                  type="text"
                  size="small"
                  @click.native.prevent="
                    setProductMainImg(scope.row.id, scope.row.imageUrl, scope.$index)
                  "
                >
                  设为主图
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-upload
            v-if="tempDetail.supplier !== 0"
            class="upload-demo"
            action="/manager/separate/v1/upload-image?bucketType=109"
            name="image"
            :limit="15"
            :headers="headers"
            :on-exceed="handleExceed"
            :before-upload="beforeUpload"
            :on-success="handleSuccess"
            :show-file-list="false"
            :file-list="fileList"
          >
            <el-button class="qxs-btn-md"> +上传图片 </el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过2Mb</div>
          </el-upload>
        </el-form-item>

        <el-form-item label="主图">
          <el-image
            v-if="tempDetail.mainImage"
            style="width: 100px; height: 100px;"
            :src="tempDetail.mainImage"
          />
          <el-upload
            v-if="tempDetail.supplier !== 0"
            class="upload-demo"
            action="/manager/separate/v1/upload-image?bucketType=109"
            name="image"
            :limit="10"
            :multiple="false"
            :headers="headers"
            :on-exceed="handleExceed"
            :before-upload="beforeUpload"
            :on-success="handleMainImgSuccess"
            :file-list="fileList"
          >
            <el-button class="qxs-btn-md"> +上传图片 </el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过2Mb</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="商品名称">
          <el-input v-model="tempDetail.name" class="elm-input" placeholder="请输入内容" />
        </el-form-item>
        <el-form-item label="商品类型">
          <el-select v-model="tempDetail.productType" :disabled="isJd" placeholder="请选择">
            <el-option
              v-for="item in mainType"
              :key="item.index"
              :span="12"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="商品分类">
          <el-cascader
            v-if="isShow"
            v-model="typeId"
            :options="commodityOptions"
            :props="commodityProps"
            clearable
          />
        </el-form-item>
        <el-form-item label="商品编号">
          <el-input
            v-model="tempDetail.code"
            :disabled="isJd"
            class="elm-input"
            type="text"
            placeholder="请输入商品编号"
          />
        </el-form-item>
        <el-form-item label="品牌">
          <el-input
            v-model="tempDetail.brand"
            :disabled="isJd"
            class="elm-input"
            type="text"
            placeholder="请输入品牌"
          />
        </el-form-item>
        <el-form-item label="单位">
          <el-input
            v-model="tempDetail.saleUnit"
            :disabled="isJd"
            class="elm-input"
            type="text"
            placeholder="请输入单位"
          />
        </el-form-item>
        <el-form-item label="最低起购数量">
          <el-input
            v-model="tempDetail.lowestBuy"
            :disabled="isJd"
            class="elm-input"
            type="text"
            placeholder="请输入最低起购数量"
          />
        </el-form-item>
        <el-form-item label="最高购买数量">
          <el-input
            v-model="tempDetail.highestBuy"
            class="elm-input"
            type="text"
            :disabled="isJd"
            placeholder="请输入最高购买数量"
          />
        </el-form-item>
        <el-form-item label="权重值">
          <el-input
            v-model="tempDetail.productIndex"
            class="elm-input"
            placeholder="建议范围0~999"
          />
        </el-form-item>
        <div id="2" class="label-tab">| 销售信息</div>
        <el-form-item label="原价">
          <el-input
            v-model="tempDetail.originalPrice"
            :disabled="skuList.length > 0"
            class="elm-input"
            type="number"
            placeholder="请输入内容"
          />
        </el-form-item>
        <el-form-item label="供应商参考价">
          <el-input
            v-model="tempDetail.formalPrice"
            :disabled="skuList.length > 0"
            class="elm-input"
            type="number"
            placeholder="请输入内容"
          />
        </el-form-item>
        <el-form-item label="销售价">
          <el-input
            v-model="tempDetail.sellPrice"
            :disabled="skuList.length > 0"
            class="elm-input"
            type="number"
            step="0.01"
            placeholder="请输入内容"
            @input="onSetSellPrice($event, tempDetail)"
          />
        </el-form-item>
        <el-form-item label="采购价">
          <el-input
            v-model="tempDetail.channelPrice"
            :disabled="skuList.length > 0 || isJd"
            class="elm-input"
            type="number"
            placeholder="请输入内容"
          />
        </el-form-item>
        <div id="3" class="label-tab">| SKU</div>
        <PanelWrapper>
          <div v-for="(item, index) in skuAttr" :key="item.index" class="attr-name">
            <el-form-item label="名称">
              <span class="sku-attr-box">
                {{ item.attributeName }}
                <img
                  v-if="!tempDetail.id || tempDetail.supplier === 1"
                  class="sku-attr-del"
                  :src="require('@/assets/exam_error.png')"
                  alt=""
                  @click="onDelsuk(skuAttr, index)"
                >
              </span>
            </el-form-item>
            <el-form-item label="选项">
              <el-tag v-for="(tag, ind) in item.values" :key="tag.index" class="attr-value">
                <span class="sku-attr-box">
                  {{ tag.attributeValue }}
                  <img
                    v-if="!tempDetail.id || tempDetail.supplier === 1"
                    class="sku-attr-del"
                    :src="require('@/assets/exam_error.png')"
                    alt=""
                    @click="onDelsuk(item.values, ind)"
                  >
                </span>
              </el-tag>
              <div v-if="!tempDetail.id || tempDetail.supplier === 1" class="attr-value-control">
                <el-input
                  v-model="attrValue[index]"
                  size="small"
                  class="elm-input"
                  type="text"
                  placeholder="请输入选项值"
                />
                <el-button size="small" @click="addTag(index)"> 添加选项值 </el-button>
              </div>
            </el-form-item>
          </div>
          <div v-if="!tempDetail.id || tempDetail.supplier === 1" class="attr-name-control">
            <el-input
              v-model="attrName"
              class="elm-input"
              type="text"
              placeholder="请输入选项名"
            />
            <el-button @click="addAttr"> 添加选项名 </el-button>
          </div>
        </PanelWrapper>
        <PanelWrapper>
          <p class="sku-tips">
            根据所有SKU排列组合，自动生成，请务必填写售价、库存等信息，并勾选可售的sku，未勾选的将不被添加到商品
          </p>
          <el-table
            ref="multipleTable"
            :data="skuList"
            border
            style="width: 100%;"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" />
            <el-table-column label="图片" width="180">
              <template v-if="scope.row.imageUrl" slot-scope="scope">
                <el-image style="width: 100px; height: 100px;" :src="scope.row.imageUrl" />
              </template>
            </el-table-column>
            <el-table-column label="规格" min-width="20%">
              <template slot-scope="scope">
                <div v-for="attr in scope.row.skuAttributes" :key="attr.index" class="sku-show">
                  <span>{{ attr.attributeName }} : </span><el-tag class="attr-value">
                    {{ attr.attributeValue }}
                  </el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="售价" min-width="6%">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.sellPrice"
                  class="sku-elm-input"
                  placeholder="请输入内容"
                  type="number"
                  step="0.01"
                  @input="onSetSellPrice($event, scope.row)"
                />
              </template>
            </el-table-column>
            <el-table-column label="原价" min-width="6%">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.originalPrice"
                  class="sku-elm-input"
                  type="number"
                  placeholder="请输入内容"
                />
              </template>
            </el-table-column>
            <el-table-column label="采购价" min-width="6%">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.channelPrice"
                  type="number"
                  :disabled="isJd"
                  class="sku-elm-input"
                  placeholder="请输入内容"
                />
              </template>
            </el-table-column>
            <el-table-column prop="profit" label="利润空间" min-width="6%">
              <template slot-scope="scope">
                {{ (scope.row.sellPrice - scope.row.channelPrice).toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column v-if="!isJd" label="库存" min-width="6%">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.inventory"
                  class="sku-elm-input"
                  placeholder="请输入内容"
                />
              </template>
            </el-table-column>
            <el-table-column prop="zip" label="操作" min-width="10%">
              <template slot-scope="scope">
                <div class="operation-box">
                  <el-upload
                    v-if="tempDetail.supplier !== 0"
                    class="upload-demo"
                    action="/manager/separate/v1/upload-image?bucketType=109"
                    name="image"
                    :limit="10"
                    :headers="headers"
                    :multiple="false"
                    :on-exceed="handleExceed"
                    :before-upload="beforeUpload"
                    :show-file-list="false"
                    :on-success="
                      (response, file, fileList) =>
                        handleSkuImgSuccess(response, file, fileList, scope.$index)
                    "
                    :file-list="fileList"
                  >
                    <el-button class="qxs-btn-sm"> +上传图片 </el-button>
                  </el-upload>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </PanelWrapper>
        <div id="4" class="label-tab">| 供应商信息</div>
        <el-form-item label="供应商">
          <el-select
            v-model="tempDetail.supplier"
            :disabled="isJd"
            placeholder="请选择"
            @change="supplierChange"
            @visible-change="onChangeMgnt($event, supplierList)"
          >
            <el-option
              v-for="item in supplierList"
              :key="item.index"
              :span="12"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="关联服务政策">
          <el-select
            v-model="tempDetail.servicePolicyId"
            placeholder="请选择"
            @visible-change="onChangeMgnt($event, servicePolicy)"
          >
            <el-option
              v-for="item in servicePolicy"
              :key="item.index"
              :span="12"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <div id="5" class="label-tab" :style="{ 'margin-top': mgnT }">| 图文详情</div>
        <PanelWrapper>
          <editor :content="tempDetail.detailUrl" @update="upDataContent($event)" />
        </PanelWrapper>
      </el-form>
    </el-main>
    <el-footer>
      <FooterWrapper>
        <div v-if="model === 'add'">
          <el-button type="primary" @click="onSubmit(1)"> 创建并上架 </el-button>
          <el-button @click="onSubmit(0)"> 创建 </el-button>
        </div>
        <div v-else>
          <el-button type="primary" @click="onSave(tempDetail.state)"> 保存 </el-button>
          <el-button v-if="tempDetail.state === 0" @click="onSave(1)"> 上架 </el-button>
          <el-button v-else @click="onSave(0)"> 下架 </el-button>
        </div>
      </FooterWrapper>
    </el-footer>
  </el-container>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue';
import Editor from '@/components/Editor.vue'
import { scrollToElementId, objIndexOf, fenToYuan, fenToYuanInt, YuanToFen } from '@/utils/util'
import { getToken } from '@/utils/auth'
import PanelWrapper from '@/components/common/panelWrapper.vue'
import FooterWrapper from '@/components/common/footerWrapper.vue'
import createProduct from '@/apis/createProduct'
import updateProduct from '@/apis/updateProduct'
import getProductDetail from '@/apis/getProductDetail'
import { mapState } from 'vuex'

export default {
  components: {
    Editor,
    FooterWrapper,
    PanelWrapper
  },
  data() {
    return {
      mgnT: '26px',
      name: '商品详情',
      spuId: '',
      tempDetail: {},
      model: 'edit',
      loading: true,
      mainTypeId: '0',
      activeIndex: '1',
      attrName: '',
      attrValue: [],
      fileData: [],
      headers: {},
      fileList: [],
      skuAttr: [],
      skuList: [],
      selectedSku: [],
      skuIds: [],
      mainType: [
        {
          id: 0,
          name: '实体商品'
        },
        {
          id: 1,
          name: '虚拟商品'
        }
      ],
      isShow: false,
      typeId: [],
      commodityOptions: [],
      commodityProps: {
        lazy: true,
        lazyLoad: (node, resolve) => {
          const { level, value = '888888', children } = node
          console.log(node, '--- node ---')
          if (!children.length) {
            this.onGetCommodityList(level + 1, value).then(res => {
              resolve(res)
            })
          } else {
            resolve()
          }
        }
      }
    }
  },
  computed: {
    ...mapState(['productDetail', 'servicePolicy', 'supplierList']),
    originalPrice() {
      return fenToYuan(this.tempDetail.originalPrice)
    },
    sellPrice() {
      return fenToYuan(this.tempDetail.sellPrice)
    },
    channelPrice() {
      return fenToYuan(this.tempDetail.channelPrice)
    },
    isJd() {
      return this.tempDetail.supplier === 0
    }
  },
  mounted() {
    console.log('this.name ', this.name)
    const { spuId, model } = this.$route.query
    this.spuId = spuId
    this.model = model
    this.headers.token = getToken()
    if (model === 'add') {
      this.name = '创建商品'
      this.$store.dispatch('changeTitle', '创建商品')
      this.typeId = []
      this.tempDetail = {
        id: '',
        sellPrice: '',
        originalPrice: '',
        channelPrice: '',
        name: '',
        wareList: '',
        brand: '',
        saleUnit: '',
        images: [],
        lowestBuy: 1,
        highestBuy: 999,
        param: '',
        productIndex: 1,
        supplier: 1,
        mainImage: '',
        detailUrl: '',
        code: '',
        outsideLinker: '',
        skuDetailVOS: [],
        servicePolicyList: [],
        servicePolicyId: '',
        state: 1,
        productType: 0,
        afterSalePolicyCopyWriting: '',
        isSelf: 1
      }
      this.$store.dispatch('getServicePolicy', { supplier: 1 })
      this.onInitOptions()
      this.loading = false
    } else {
      this.$store.dispatch('changeTitle', this.name)
      getProductDetail({ spuId: spuId })
        .then(response => {
          if (response) {
            this.skuList.splice(0, this.skuList.length)
            this.skuAttr.splice(0, this.skuAttr.length)
            const selectedSku = []
            response.skuDetailVOS.forEach(item => {
              const newItem = {
                ...item,
                channelPrice: fenToYuan(item.channelPrice),
                formalPrice: fenToYuan(item.formalPrice),
                originalPrice: fenToYuanInt(item.originalPrice),
                sellPrice: fenToYuanInt(item.sellPrice)
              }
              this.skuList.push(newItem)
              if (newItem.state === 1) {
                selectedSku.push(newItem)
              }
              item.skuAttributes.forEach(
                ({ attributeName, attributeNameId, attributeValue, attributeValueId }) => {
                  const index = objIndexOf(this.skuAttr, 'attributeNameId', attributeNameId)
                  if (index >= 0) {
                    const childIndex = objIndexOf(
                      this.skuAttr[index].values,
                      'attributeValueId',
                      attributeValueId
                    )
                    if (childIndex === -1) {
                      this.skuAttr[index].values.push({
                        attributeValue,
                        attributeValueId
                      })
                    }
                  } else {
                    this.skuAttr.push({
                      attributeName,
                      attributeNameId,
                      values: [
                        {
                          attributeValue,
                          attributeValueId
                        }
                      ]
                    })
                  }
                }
              )
            })
            response.dicCommodityType && (this.typeId[0] = response.dicCommodityType)
            response.secondDicCommodityType && (this.typeId[1] = response.secondDicCommodityType)
            this.onInitOptions()
            this.tempDetail = {
              ...response,
              channelPrice: fenToYuan(response.channelPrice),
              formalPrice: fenToYuan(response.formalPrice),
              originalPrice: fenToYuanInt(response.originalPrice),
              sellPrice: fenToYuanInt(response.sellPrice)
            }
            this.loading = false
            this.$store.dispatch('getServicePolicy', { supplier: this.tempDetail.supplier })
            const self = this
            setTimeout(() => {
              self.toggleSelection(selectedSku)
            }, 1000)
          }
        })
        .catch(error => {
          // this.$message.error(error.message)
          console.log(error)
        })
    }
  },
  methods: {
    onGetCommodityList(level = 0, parentId = '888888') {
      const params = {level, parentId}
      return this.$axios.get(this.$API.getCommodityType, { params }).then(res => {
        const nodes = (res || []).map(item => ({
          value: item.id,
          label: item.name,
          leaf: level >= 2
        }))
        return nodes
      })
    },
    onInitOptions() {
      this.onGetCommodityList(1).then(res => {
        this.commodityOptions = res
        if (this.typeId[0]) {
          for (let item of this.commodityOptions) {
            if (item.value === this.typeId[0]) {
              this.onGetCommodityList(2, this.typeId[0]).then(res => {
                item['children'] = res
              })
              setTimeout(() => {
                this.isShow = true
              }, 50)
              return
            }
          }
        } else {
          this.isShow = true
        }
      })
    },
    onSetSellPrice(event, item) {
      let numArr = event.toString().split('.')
      // 允许带小数
      item.sellPrice = numArr[1] ? `${numArr[0]}.${numArr[1].slice(0, 2)}` : numArr[0]
      // 不允许带小数
      // item.sellPrice = numArr[0]
    },
    onChangeMgnt(val, list) {
      console.log(val)
      this.mgnT = val ? `${list?.length * 34}px` : '26px'
    },
    onDelsuk(list, index) {
      list.splice(index, 1)
      this.rtxSku()
    },
    scrollTo(id) {
      scrollToElementId(id)
    },
    fenToYuanTrans(fen) {
      return fenToYuan(fen)
    },
    handleSelectionChange(val) {
      console.log('val', val)
      this.selectedSku = val
    },
    supplierChange() {
      this.$store.dispatch('getServicePolicy', { supplier: this.tempDetail.supplier })
    },
    upDataContent(data) {
      console.log('hahahahahahahah', data)
      this.tempDetail.detailUrl = data
    },
    beforeUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG、PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    onSubmit(state) {
      // if (!this.sellPrice) {
      //   this.$message.error('请填写正确的销售价')
      //   return
      // }
      if (!this.tempDetail.servicePolicyId) {
        this.$message.error('请选择关联的服务政策')
        return
      }
      const { channelPrice, formalPrice, sellPrice, originalPrice, ...prod } = this.tempDetail
      const skuListTmp = []
      let num = 0
      console.log('this.skuList', this.skuList)
      console.log('this.selectedSku', this.selectedSku)

      this.skuList.forEach(item => {
        const index = objIndexOf(this.selectedSku, 'skuIdTmp', item.skuIdTmp)
        if (index < 0) {
          num += 1
        }
        skuListTmp.push({
          ...item,
          state: index >= 0 ? 1 : 0,
          channelPrice: YuanToFen(item.channelPrice),
          formalPrice: YuanToFen(item.formalPrice),
          originalPrice: YuanToFen(item.originalPrice),
          sellPrice: YuanToFen(item.sellPrice)
        })
      })
      if (num === skuListTmp.length && state === 1) {
        return this.$message.error('至少勾选一个SKU')
      }

      const data = {
        ...prod,
        dicCommodityType: this.typeId[0],
        secondDicCommodityType: this.typeId[1],
        channelPrice: YuanToFen(channelPrice),
        formalPrice: YuanToFen(formalPrice),
        originalPrice: YuanToFen(originalPrice),
        sellPrice: YuanToFen(sellPrice),
        skuDetailVOS: skuListTmp,
        state: state || 0
      }
      createProduct(data)
        .then(response => {
          if (response) {
            this.$message({
              message: '商品创建成功',
              type: 'success'
            })
            this.$router.push({ name: 'prodList' })
          } else {
            this.$message.error(response.message)
          }
        })
        .catch(error => {
          // this.$message.error(error.message)
          console.log(error)
        })
    },
    onSave(state) {
      // if (!this.sellPrice) {
      //   this.$message.error('请填写正确的销售价')
      //   return
      // }
      if (!this.tempDetail.servicePolicyId) {
        this.$message.error('请选择关联的服务政策')
        return
      }

      const { channelPrice, formalPrice, sellPrice, originalPrice, ...prod } = this.tempDetail
      const skuListTmp = []
      let num = 0
      let isWarm = false
      this.skuList.forEach(item => {
        const index = objIndexOf(this.selectedSku, 'id', item.id)
        if (index < 0) {
          num += 1
        }
        skuListTmp.push({
          ...item,
          state: index >= 0 ? 1 : 0,
          channelPrice: YuanToFen(item.channelPrice),
          formalPrice: YuanToFen(item.formalPrice),
          originalPrice: YuanToFen(item.originalPrice),
          sellPrice: YuanToFen(item.sellPrice)
        })
        if (YuanToFen(item.sellPrice) < YuanToFen(item.channelPrice)) {
          isWarm = true
        }
      })
      if (num === skuListTmp.length && state === 1) {
        return this.$message.error('至少勾选一个SKU')
      }

      const req = {
        ...prod,
        dicCommodityType: this.typeId[0],
        secondDicCommodityType: this.typeId[1],
        channelPrice: YuanToFen(channelPrice),
        formalPrice: YuanToFen(formalPrice),
        originalPrice: YuanToFen(originalPrice),
        sellPrice: YuanToFen(sellPrice),
        skuDetailVOS: skuListTmp,
        state: state || 0
      }
      if (isWarm) {
        this.$confirm('此商品售价低于采购价, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.updatePrice(req)
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            })
          })
      } else {
        this.updatePrice(req)
      }
    },
    updatePrice(req) {
      updateProduct(req)
        .then(response => {
          if (response) {
            this.$message({
              message: '商品更新成功',
              type: 'success'
            })
            this.$eventBus.$emit('reload-prod-list')
            this.$router.push({ name: 'prodList' })
          } else {
            this.$message.error(response.message)
          }
        })
        .catch(error => {
          this.$message.error(error.message)
          console.log(error)
        })
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 5 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      )
    },
    handleSuccess(response) {
      const { imageName, middle } = response.data
      this.tempDetail.images.push({
        imageUrl: middle,
        isMain: 0,
        imageId: imageName,
        id: ''
      })
    },
    handleMainImgSuccess(response) {
      const { imageName, middle } = response.data
      this.tempDetail.images.forEach(item => {
        item.isMain = 0
      })
      this.tempDetail.images.push({
        imageUrl: middle,
        isMain: 1,
        imageId: imageName,
        id: ''
      })
      this.tempDetail.mainImage = middle
    },
    handleSkuImgSuccess(response, file, fileList, index) {
      console.log('response, ', response)
      console.log('index, ', index)
      const { imageName, middle } = response.data
      this.skuList[index].imageUrl = middle
      this.skuList[index].imageId = imageName
    },
    setProductMainImg(id, imageUrl, index) {
      this.tempDetail.images.forEach((item, ind) => {
        item.isMain = index === ind ? 1 : 0
      })
      this.tempDetail.mainImage = imageUrl
    },
    removeProductImg(index) {
      this.tempDetail.images.splice(index, 1)
    },
    addAttr() {
      if (this.skuAttr.length >= 3) {
        this.$message.error('选项类型不能超过3个')
        return
      }
      this.skuAttr.push({
        attributeNameId: '',
        attributeName: this.attrName,
        values: []
      })
      this.attrName = ''
    },
    addTag(index) {
      console.log(index)
      this.skuAttr[index].values.push({
        attributeValue: this.attrValue[index],
        attributeValueId: ''
      })
      this.attrValue.splice(index, 1, '')
      this.rtxSku()
    },
    // sku选项生成sku列表
    copySkuList(attrs, idTmp, skuList, oldSkuList) {
      let oldSku = {}
      oldSkuList.forEach(old => {
        if (old.skuIdTmp === idTmp) {
          oldSku = old
        }
      })
      skuList.push({
        skuAttributes: attrs,
        channelPrice: oldSku.channelPrice || 0,
        formalPrice: oldSku.formalPrice || 0,
        imageUrl: oldSku.imageUrl || '',
        imageId: oldSku.imageId || '',
        inventory: oldSku.inventory || '',
        originalPrice: oldSku.originalPrice || 0,
        sellPrice: oldSku.sellPrice || 0,
        skuId: '',
        skuIdTmp: idTmp,
        state: oldSku.state || 0
      })
    },
    // sku选项生成sku列表
    rtxSku1() {
      console.log(this.skuAttr)
      // let list = []
    },
    rtxSku() {
      const skuList = []
      const aItem = this.skuAttr[0]
      aItem?.values.forEach((aValue, aIndex) => {
        const v = []
        const m = ''
        v.push({
          attributeName: aItem.attributeName,
          attributeNameId: aItem.attributeNameId,
          attributeValue: aValue.attributeValue,
          attributeValueId: aValue.attributeValueId
        })
        if (this.skuAttr.length >= 2) {
          const bItem = this.skuAttr[1]
          bItem.values.forEach((bValue, bIndex) => {
            v.splice(1, 1, {
              attributeName: bItem.attributeName,
              attributeNameId: bItem.attributeNameId,
              attributeValue: bValue.attributeValue,
              attributeValueId: bValue.attributeValueId
            })
            if (this.skuAttr.length >= 3) {
              const cItem = this.skuAttr[2]
              cItem.values.forEach((cValue, cIndex) => {
                v.splice(2, 1, {
                  attributeName: cItem.attributeName,
                  attributeNameId: cItem.attributeNameId,
                  attributeValue: cValue.attributeValue,
                  attributeValueId: cValue.attributeValueId
                })
                const [...attrs] = v
                const idTmp = m + aIndex.toString() + bIndex.toString() + cIndex.toString()
                this.copySkuList(attrs, idTmp, skuList, this.skuList)
              })
            } else {
              const [...attrs] = v
              const idTmp = m + aIndex.toString() + bIndex.toString()
              this.copySkuList(attrs, idTmp, skuList, this.skuList)
            }
          })
        } else {
          const [...attrs] = v
          const idTmp = m + aIndex.toString()
          this.copySkuList(attrs, idTmp, skuList, this.skuList)
        }
      })
      console.log('skuList: ', skuList)
      this.skuList.splice(0, this.skuList.length)
      skuList.forEach(sku => {
        this.skuList.push(sku)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  margin-top: 40px;
  padding: 0 20px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  .tab-panel {
    /* position: fixed; */

    /* top: 50px; */

    /* z-index: 100000; */
    width: 100%;

    /* background-color: white; */
  }
  .prod-panel {
    padding: 0 10px;

    /* margin-top: 40px; */
  }
}
.label-tab {
  margin-top: 26px;
  margin-bottom: 16px;
  position: relative;
  left: -10px;
}
.attr-name {
  position: relative;
  left: -20px;
  top: 20px;
}
.attr-name-control {
  display: inline-block;
  position: relative;
  left: 40px;
  top: 20px;
  .elm-input {
    width: 160px;
  }
}
.attr-value {
  display: inline-block;
  margin-right: 10px;
}
.attr-value-control {
  display: inline-block;
  .elm-input {
    width: 100px;
  }
}
.sku-elm-input {
  width: 90%;
}
.sku-tips {
  font-size: 12px;
  margin-top: 60px;
}
.sku-show {
  display: inline-block;
  margin-left: 10px;
}
.elm-input {
  width: 300px;
}
.operation-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.sku-attr-box {
  position: relative;
}
.sku-attr-del {
  position: absolute;
  top: -15px;
  right: -15px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
</style>
