import request from '@/utils/request'
import API from './api-types'
// 获取商品池列表

export default function getOrderRebate({
  orderId,
  subject,
  percent,
  rebateFee
}) {
  return request({
    url: API.getOrderRebate,
    method: 'get',
    params: {
      orderId,
      subject,
      percent,
      rebateFee
    }
  })
}
