<template>
  <div class="pagination">
    <el-pagination
      :current-page="currentPage"
      :page-size="pageSize"
      background
      hide-on-single-page
      layout="total, prev, pager, next, jumper"
      :total="total"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
export default {
  name: 'PaginationCom',
  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {}
  },
  computed: {},
  destroyed() { },
  methods: {
    handleCurrentChange(val) {
      this.$emit('current-change', val)
    }
  }
}
</script>

<style scoped>
.pagination {
  padding-top: 30px;
  text-align: center;
}
</style>
