import * as Type from './mutations-types'

const mutations = {
  [Type.CHANGE_TITLE](state, value) {
    state.title = value
  },
  [Type.SET_LOADING](state, value) {
    state.loading = value
  },
  // 商品池相关
  [Type.SET_POOL_NUMBERS](state, value) {
    state.poolNumbers = value
  },
  [Type.SET_COMMODITY_TYPE](state, value) {
    state.commodityType = state.commodityType.splice(0, state.commodityType.length)
    value.forEach(item => {
      state.commodityType.push(item)
    })
  },
  [Type.SET_PROD_POOL](state, value) {
    const { data, pageIndex, totalCount } = value
    state.prodPool.splice(0, state.prodPool.length)
    if (data) {
      data.forEach(item => {
        state.prodPool.push(item)
      })
    }
    state.poolIndex = pageIndex
    state.poolTotalCount = totalCount
  },
  [Type.SET_PROD_RELATED](state, data) {
    state.prodRelated.splice(0, state.prodRelated.length)
    data.forEach(item => {
      state.prodRelated.push(item)
    })
  },
  [Type.SET_PROD_LIST](state, { data, totalCount, pageNo }) {
    state.prodList.splice(0, state.prodList.length)
    data.forEach(item => {
      state.prodList.push(item)
    })
    state.prodTotalCount = totalCount
    state.prodListIndex = pageNo
  },
  [Type.SET_PROD_POOL_ONE](state, value) {
    state.prodPool.splice(0, state.prodPool.length)
    state.prodPool.push(value)
    state.poolIndex = 1
  },
  [Type.UPDATE_DETAIL_CONTENT](state, value) {
    state.prodDetail.detailUrl = value
  },
  [Type.SET_PROD_DETAIL](state, value) {
    state.prodDetail = value
  },
  [Type.REMOVE_IMG](state, { index }) {
    state.prodDetail.images.splice(index, 1)
  },
  [Type.SET_MAIN_IMG](state, { index }) {
    state.prodDetail.images.forEach((item, ind) => {
      if (ind === index) {
        state.prodDetail.mainImage = item.imageUrl
        item.isMain = 1
        return
      }
      item.isMain = 0
    })
  },
  [Type.ADD_IMG](state, { id, imageUrl }) {
    state.prodDetail.images.push({
      imageUrl: imageUrl,
      id: id
    })
  },

  // 商品管理相关
  [Type.ADD_PRODUCT_IMG](state, { id, imageUrl }) {
    state.productDetail.images.push({
      imageUrl: imageUrl,
      id: id
    })
  },
  [Type.SET_SERVICE_POLICY](state, value) {
    state.servicePolicy.splice(0, state.servicePolicy.length)
    value.forEach(item => {
      state.servicePolicy.push(item)
    })
  },
  [Type.SET_PRODUCT_MAIN_IMG](state, { id, imageUrl, index }) {
    state.productDetail.images.forEach((item, ind) => {
      item.isMain = index === ind
    })
    if (!index) {
      state.productDetail.images.push({
        imageUrl: imageUrl,
        isMain: true,
        id: id
      })
    }
    state.productDetail.mainImage = imageUrl
  },
  [Type.REMOVE_PRODUCT_IMG](state, value) {
    state.productDetail.images.splice(value, 1)
  },
  [Type.SET_PRODUCT_SKU_IMG](state, { index, id, imageUrl }) {
    state.productDetail.skuDetailVOS[index].imageUrl = imageUrl
    state.productDetail.skuDetailVOS[index].imageId = id
  },
  [Type.SET_PRODUCT_DETAIL](state, value) {
    state.productDetail = value
  },
  // 订单管理相关
  [Type.SET_ORDER_LIST](state, { data, totalCount, pageNo }) {
    state.orderList.splice(0, state.orderList.length)
    if (data) {
      data.forEach(item => {
        state.orderList.push(item)
      })
      state.orderTotalCount = totalCount
      state.orderListIndex = pageNo
    }
  },
  [Type.GET_ORDER_DETAIL](state, orderDetail) {
    state.orderDetail = orderDetail
  },
  // 售后单管理相关
  [Type.SET_AFTER_SALE_ORDER_LIST](state, { result, totalCount, pageIndex }) {
    state.afterSaleOrderList.splice(0, state.afterSaleOrderList.length)
    result.forEach(item => {
      state.afterSaleOrderList.push(item)
    })
    state.afterSaleOrderTotalCount = totalCount
    state.afterSaleOrderIndex = pageIndex
  },
  [Type.GET_AFTER_SALE_ORDER_DETAIL](state, afterSaleOrderDetail) {
    state.afterSaleOrderDetail = afterSaleOrderDetail
  },

  // 分页器相关
  [Type.SET_CURRENT_PAGE](state, options) {
    state.currentPage = options
  },
  [Type.SET_PAGE_SIZE](state, options) {
    state.pageSize = options
  },
  [Type.SET_TOTAL](state, options) {
    state.total = options
  },
  // 文章管理分类相关
  [Type.SET_FEEDBACK_LIST](state, options) {
    state.feedbackList = options
  },
  [Type.SET_CATEGORY_LIST](state, options) {
    state.categoryList = options
  },
  [Type.SET_ARTICLE_LIST](state, options) {
    state.articleList = options
  },
  [Type.SET_GUIDE_DETAIL_LIST](state, options) {
    state.guideDetailList = options
  },
  [Type.SET_SHOW_AT](state, options) {
    state.showAt = options
  },
  [Type.SET_CATEGORY_ID](state, options) {
    state.categoryId = options
  },
  [Type.SET_STATUS](state, options) {
    state.status = options
  },
  [Type.SET_SECCATEGORY_ID](state, options) {
    state.secCategoryId = options
  },
  [Type.SET_SEARCH_KEY](state, options) {
    state.searchKey = options
  },
  [Type.SET_DETAIL_OBJ](state, options) {
    state.detailObj = options
  },

  // 消息系统
  [Type.SET_MESSAGE_LIST](state, { records, total, pages }) {
    state.messageSearchList.splice(0, state.messageSearchList.length)
    if (records) {
      records.forEach(item => {
        state.messageSearchList.push(item)
      })
      state.orderTotalCount = total
      state.orderListIndex = pages
    }
  }
}

export default mutations
