import request from '@/utils/request'
import API from './api-types'

export function getCourseList(data) {
  return request({
    url: API.getCourseList,
    method: 'get',
    params: data
  })
}
export function saveCourseData(data) {
  return request({
    url: API.saveCourseData,
    method: 'post',
    data
  })
}
export function getCourseData(data) {
  return request({
    url: API.getCourseData,
    method: 'get',
    params: data
  })
}
export function getContactList(data) {
  return request({
    url: API.getContactList,
    method: 'get',
    params: data
  })
}
export function changeCourseSale(data) {
  return request({
    url: API.changeCourseSale,
    method: 'get',
    params: data
  })
}
export function onTrainCreateExam(data) {
  return request({
    url: API.onTrainCreateExam,
    method: 'post',
    data
  })
}
export function getCourseOrderList(data) {
  return request({
    url: API.getCourseOrderList,
    method: 'get',
    params: data
  })
}
export function getCourseOrderDetail(data) {
  return request({
    url: API.getCourseOrderDetail,
    method: 'get',
    params: data
  })
}
export function saveContractData(data) {
  return request({
    url: API.saveContractData,
    method: 'post',
    data
  })
}
export function getContractData(data) {
  return request({
    url: API.getContractData,
    method: 'get',
    params: data
  })
}
export function getContractAudit(data) {
  return request({
    url: API.getContractAudit,
    method: 'get',
    params: data
  })
}
export function getFactoryList(data) {
  return request({
    url: API.getFactoryList,
    method: 'get',
    params: data
  })
}
export function upLoadVideo(data) {      // 视频上传
  return request({
    url: API.getUploadVideoInfo,
    method: 'get',
    params: data
  })
}
export function updateTrainVideo(data) {    // 编辑视频
  return request({
    url: `${API.updateVideo}?coverUrl=${data.coverUrl}&title=${data.title}&videoId=${data.videoId}`,
    method: 'post'
  })
}
export function getTrainVideoDetail(data) {     // 视频详情
  return request({
    url: API.getVideoDetail,
    method: 'get',
    params: data
  })
}
export function getVideoCut(data) {
  return request({
    url: API.getVideoCut,
    method: 'get',
    params: data
  })
}
export function exportTrainOrderList(data) {
  return request({
    url: API.exportTrainOrderList,
    method: 'get',
    params: data
  })
}
