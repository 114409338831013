<template>
  <div>
    <div class="pdg-t30">
      <div class="textarea-box">
        <el-input
          v-model="textarea"
          type="textarea"
          :rows="6"
          readonly="readonly"
          resize="none"
        />
      </div>
      <div class="header-box">
        <el-row :gutter="20">
          <el-col :span="4">
            <el-select
              v-model="channelId"
              placeholder="全渠道"
            >
              <el-option
                v-for="item in channelType"
                :key="item.id"
                :span="12"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-select
              v-model="contentId"
              placeholder="文件类型"
            >
              <el-option
                v-for="item in contentType"
                :key="item.id"
                :span="12"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-select
              v-model="userId"
              placeholder="全部用户"
            >
              <el-option
                v-for="item in userType"
                :key="item.id"
                :span="12"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :span="12">
            <div class="search-box">
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-input
                    v-model="keyword"
                    width="200"
                    placeholder="请输入推送标题"
                    clearable
                  />
                </el-col>
                <el-col :span="16">
                  <el-button
                    class="mgn-r10"
                    @click="search(1)"
                  >
                    搜索
                  </el-button>
                  <el-button
                    class="mgn-r10"
                    @click="onClickAdd"
                  >
                    新增系统推送
                  </el-button>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="table-box">
        <el-table
          :data="messageSearchList"
          border
          style="width: 100%;"
        >
          <el-table-column
            prop="title"
            :show-overflow-tooltip="true"
            label="推送标题"
            min-width="120px"
          />
          <el-table-column
            prop="pushDateStr"
            :show-overflow-tooltip="true"
            label="推送时间"
            min-width="120px"
          />
          <el-table-column
            prop="channelTypeName"
            label="渠道"
          />
          <el-table-column
            prop="contentTypeName"
            label="推送内容类型"
          />
          <el-table-column
            prop="userTypeName"
            label="用户类型"
          />
          <el-table-column
            prop="credit"
            label="学分"
          />
          <el-table-column
            prop="pushNum"
            label="推送人数"
          />
          <el-table-column
            prop="deliveryNum"
            label="送达人数"
          />
          <el-table-column
            prop="statusName"
            label="状态"
          />
          <el-table-column
            label="操作"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.status === 0"
                class="mgn-r10"
                type="text"
                @click="onClickSend(scope)"
              >
                发送
              </el-button>
              <el-button
                v-if="scope.row.status === 1"
                type="text"
                @click="onClickBreak(scope)"
              >
                取消发送
              </el-button>
              <el-button
                v-if="scope.row.status !== 2"
                type="text"
                @click="onClickDelete(scope)"
              >
                删除
              </el-button>
              <el-button
                v-if="scope.row.status === 2"
                type="text"
                disabled
              >
                查看数据
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page">
        <el-pagination
          class="pageination"
          :current-page="orderListIndex"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="orderTotalCount"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { getToken } from '@/utils/auth'

export default {
  name: 'MessagePush',
  data() {
    return {
      textarea: '温馨提示：\n' +
        '1、为了降低风险并保证系统稳定运行，我们对信息推送做了如下限制：\n' +
        '① 信息推送量：系统信息计算单人单天总量不超过3条，超过单人、单天发送限制，系统自动发送失败。\n' +
        '② 推送频率限制，15分钟内，单人只能创建一条任务，存在重复覆盖，延迟发送信息。\n' +
        '③ 超过500人的系统推送，需要二人审核通过制，低于500人系统可以直接推送。\n' +
        '不便之处，敬请谅解，群发人员请尽量避免系统敏感词汇。',
      total: 0,
      channelId: '',
      channelType: [
        {
          id: null,
          name: '全部'
        },
        {
          id: 0,
          name: '短信通知'
        },
        {
          id: 1,
          name: '微信公众号'
        },
        {
          id: 3,
          name: '产品应用'
        }
      ],
      contentId: '',
      contentType: [
        {
          id: null,
          name: '全部'
        },
        {
          id: 0,
          name: '链接（文字）'
        },
        {
          id: 1,
          name: '图片'
        },
        {
          id: 2,
          name: '红包活动'
        }
      ],
      userId: '',
      userType: [
        {
          id: null,
          name: '全部'
        },
        {
          id: 3,
          name: '医生'
        },
        {
          id: 2,
          name: '医药代表'
        }
      ],
      keyword: '',
      headers: {}
    }
  },
  computed: {
    ...mapState([
      'messageSearchList',
      'orderListIndex',
      'orderTotalCount'
    ])
  },
  mounted() {
    this.headers.token = getToken()
    this.search(1)

  },
  methods: {
    ...mapActions(['sendMessage', 'breakMessage', 'deleteMessage']),
    handleCurrentChange(val) {
      this.search(val)
    },
    search(index) {
      this.$store.dispatch('getMessageList', {
        pageIndex: index,
        userType: this.userId,
        contentType: this.contentId,
        channelType: this.channelId,
        keyword: ''
      })
    },
    onClickAdd() {
      this.$router.push({ path: '/newMessage' })
    },
    onClickSend(options) {
      const msgId = {
        msgId: options.row.msgId
      }
      this.$confirm('确定发送该消息吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.sendMessage(msgId).then(() => {
            this.search(1)
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消发送'
          })
        })
    },
    onClickBreak(options) {
      const msgId = {
        msgId: options.row.msgId
      }
      this.$confirm('确定取消发送该消息吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.breakMessage(msgId).then(() => {
            this.search(1)
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消中断'
          })
        })
    },
    onClickDelete(options) {
      const msgId = {
        msgId: options.row.msgId
      }
      this.$confirm('确定删除该消息吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.deleteMessage(msgId).then(() => {
            this.search(1)
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>

<style scoped>
.header-box {
  padding: 30px 0;
}
.page {
  width: 100%;
  padding: 30px;
  text-align: center;
  position: fixed;
  bottom: 0;
  z-index: 999999999;
}
</style>
