import request from '@/utils/request'
import API from './api-types'
// 获取商品池列表

export function getMessageList(params) {
  return request({
    url: API.getMessageList,
    method: 'get',
    params
  })
}

export function creatMessage(data) {
  return request({
    url: API.creatMessage,
    method: 'post',
    data
  })
}

export function sendMessage(params) {
  return request({
    url: API.sendMessage,
    method: 'post',
    params
  })
}

export function breakMessage(params) {
  return request({
    url: API.breakMessage,
    method: 'post',
    params
  })
}

export function deleteMessage(params) {
  return request({
    url: API.deleteMessage,
    method: 'post',
    params
  })
}
