export default [
  {
    path: '/data-screen',
    name: 'DataScreen',
    component: () => import(/* webpackChunkName: "data-screen" */ '@/views/data-screen'),
    meta: {
      title: '平台数据大屏'
    }
  }
]
