import { render, staticRenderFns } from "./DepartmentClassify.vue?vue&type=template&id=d2428994&scoped=true&"
import script from "./DepartmentClassify.vue?vue&type=script&lang=js&"
export * from "./DepartmentClassify.vue?vue&type=script&lang=js&"
import style0 from "./DepartmentClassify.vue?vue&type=style&index=0&id=d2428994&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2428994",
  null
  
)

export default component.exports