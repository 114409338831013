
// import * as Type from '@/store/mutations-types'
import * as Service from '@/apis/hospitalService'
// import { Message } from 'element-ui'

const state = {
}

const getters = {
}

const mutations = {

}

const actions = {
  departmentQuery(_, data) {
    return Service.departmentQuery(data).then(
      res => {
        return Promise.resolve(res)
      },
      rea => {
        // Message({
        //     message: rea.message,
        //     type: 'error',
        //     duration: 5 * 1000
        // })
        return Promise.reject(rea)
      }
    )
  },
  departmentAdd(_, data) {
    return Service.departmentAdd(data)
      .then(res => {
        return Promise.resolve(res)
      })
      .catch(error => {
        // Message({
        //     message: error.message,
        //     type: 'error',
        //     duration: 5 * 1000
        // })
        return Promise.reject(error)
      })
  },
  departmentDelete(_, data) {
    return Service.departmentDelete(data)
      .then(res => {
        return Promise.resolve(res)
      })
      .catch(error => {
        // Message({
        //     message: error.message,
        //     type: 'error',
        //     duration: 5 * 1000
        // })
        return Promise.reject(error)
      })
  },
  departmentUpdate(_, data) {
    return Service.departmentUpdate(data)
      .then(res => {
        return Promise.resolve(res)
      })
      .catch(error => {
        // Message({
        //     message: error.message,
        //     type: 'error',
        //     duration: 5 * 1000
        // })
        return Promise.reject(error)
      })
  },
  platDepartmentList(_, data) {
    return Service.platDepartmentList(data)
      .then(res => {
        return Promise.resolve(res)
      })
      .catch(error => {
        // Message({
        //     message: error.message,
        //     type: 'error',
        //     duration: 5 * 1000
        // })
        return Promise.reject(error)
      })
  },
  waitPlatDepartmentList(_, data) {
    return Service.waitPlatDepartmentList(data)
      .then(res => {
        return Promise.resolve(res)
      })
      .catch(error => {
        // Message({
        //     message: error.message,
        //     type: 'error',
        //     duration: 5 * 1000
        // })
        return Promise.reject(error)
      })
  },
  platDepartmentAdd(_, data) {
    return Service.platDepartmentAdd(data)
      .then(res => {
        return Promise.resolve(res)
      })
      .catch(error => {
        // Message({
        //     message: error.message,
        //     type: 'error',
        //     duration: 5 * 1000
        // })
        return Promise.reject(error)
      })
  },
  platDepartmentUpdata(_, data) {
    return Service.platDepartmentUpdata(data)
      .then(res => {
        return Promise.resolve(res)
      })
      .catch(error => {
        // Message({
        //     message: error.message,
        //     type: 'error',
        //     duration: 5 * 1000
        // })
        return Promise.reject(error)
      })
  },
  platDepartmentDelete(_, data) {
    return Service.platDepartmentDelete(data)
      .then(res => {
        return Promise.resolve(res)
      })
      .catch(error => {
        // Message({
        //     message: error.message,
        //     type: 'error',
        //     duration: 5 * 1000
        // })
        return Promise.reject(error)
      })
  },
  platDepartmentDeleteBind(_, data) {
    return Service.platDepartmentDeleteBind(data)
      .then(res => {
        return Promise.resolve(res)
      })
      .catch(error => {
        // Message({
        //     message: error.message,
        //     type: 'error',
        //     duration: 5 * 1000
        // })
        return Promise.reject(error)
      })
  },
  tempPage(_, data) {
    return Service.tempPage(data)
      .then(res => {
        return Promise.resolve(res)
      })
      .catch(error => {
        // Message({
        //     message: error.message,
        //     type: 'error',
        //     duration: 5 * 1000
        // })
        return Promise.reject(error)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
