import request from '@/utils/request'
import API from './api-types'

// 获取商品池列表
export default function updatePolicy(data) {
  return request({
    url: API.updatePolicy,
    method: 'post',
    data: data
  })
}
