import request from '@/utils/request'
import API from './api-types'

// 获取商品池列表
export default function getOrderList({
  pageNo,
  startTime,
  endTime,
  orderId,
  doctorMobile,
  userPhone,
  userType,
  orderStatus,
  supplierType,
  productType
}) {
  return request({
    url: API.getOrderList,
    method: 'post',
    data: {
      pageNo,
      startTime,
      endTime,
      orderId,
      userPhone,
      doctorMobile,
      userType,
      orderStatus,
      supplierType,
      productType,
      pageSize: 20
    }
  })
}
