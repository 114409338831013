<template>
  <el-container v-loading="loading" class="home">
    <el-header class="tab-panel">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal">
        <el-menu-item index="1" @click="scrollTo('1')"> 基础信息 </el-menu-item>
        <el-menu-item index="2" @click="scrollTo('2')"> 销售信息 </el-menu-item>
        <el-menu-item index="3" @click="scrollTo('3')"> SKU </el-menu-item>
        <el-menu-item index="4" @click="scrollTo('4')"> 供应商信息 </el-menu-item>
        <el-menu-item index="5" @click="scrollTo('5')"> 图文详情 </el-menu-item>
      </el-menu>
    </el-header>
    <el-main class="prod-panel">
      <el-form ref="form" label-width="80px">
        <div id="1" class="label-tab">| 基础信息</div>
        <el-form-item label="商品图片">
          <el-table :data="prodDetail.images" border style="width: 100%;">
            <el-table-column prop="date" label="图片" width="180">
              <template slot-scope="scope">
                <el-image
                  style="width: 100px; height: 100px;"
                  :src="scope.row.imageUrl | imageUrlFilter"
                />
              </template>
            </el-table-column>
            <el-table-column prop="imageUrl" label="路径" width="460" />
            <el-table-column prop="address" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click.native.prevent="remove(scope.$index, prodDetail.images)"
                >
                  删除
                </el-button>
                <el-button
                  v-if="!scope.row.isMain"
                  type="text"
                  size="small"
                  @click.native.prevent="setMainImg(scope.$index, prodDetail.images)"
                >
                  设为主图
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="主图">
          <el-image
            v-if="prodDetail.mainImage"
            style="width: 100px; height: 100px;"
            :src="prodDetail.mainImage | imageUrlFilter"
          />
        </el-form-item>
        <el-form-item label="商品名称">
          <el-input v-model="prodDetail.name" class="elm-input" placeholder="请输入内容" />
        </el-form-item>
        <el-form-item label="商品类型">
          <el-select v-model="mainTypeId" placeholder="请选择">
            <el-option
              v-for="item in mainType"
              :key="item.id"
              :span="12"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="商品分类">
          <el-cascader
            v-model="typeId"
            :props="commodityProps"
            clearable
          />
        </el-form-item>
        <el-form-item label="商品编号">
          {{ prodDetail.id }}
        </el-form-item>
        <el-form-item label="品牌">
          {{ prodDetail.brand }}
        </el-form-item>
        <el-form-item label="单位">
          {{ prodDetail.saleUnit }}
        </el-form-item>
        <el-form-item label="最低起购数量">
          {{ prodDetail.lowestBuy }}
        </el-form-item>
        <el-form-item label="权重值">
          <el-input v-model="productIndex" class="elm-input" placeholder="建议范围0~999" />
        </el-form-item>
        <div id="2" class="label-tab">| 销售信息</div>
        <el-form-item label="原价"> ￥{{ originalPrice }} </el-form-item>
        <el-form-item label="供应商参考价"> ￥{{ formalPrice }} </el-form-item>
        <el-form-item label="销售价">
          <el-input
            v-model="sellPrice"
            type="number"
            step="0.01"
            class="elm-input"
            placeholder="请输入内容"
            @input="onSetSellPrice"
          />
        </el-form-item>
        <el-form-item label="采购价"> ￥{{ channelPrice }} </el-form-item>
        <el-form-item label="最大购买数量">
          <el-input
            v-model="highestBuy"
            type="number"
            :step="1"
            class="elm-input"
            placeholder="请输入最大购买数量"
          />
        </el-form-item>
        <div id="3" class="label-tab">| SKU</div>
        <PanelWrapper>
          <div v-for="item in skuAttr" :key="item.attributeName">
            <el-form-item label="名称">
              {{ item.attributeName }}
            </el-form-item>
            <el-form-item label="选项">
              <el-tag
                v-for="tag in item.values"
                :key="tag.attributeValue"
                type="success"
                class="attr-value"
              >
                {{ tag.attributeValue }}
              </el-tag>
            </el-form-item>
          </div>
        </PanelWrapper>
        <div id="4" class="label-tab">| 供应商信息</div>
        <div v-if="prodDetail.poolSupplierVO">
          <el-form-item label="供应商">
            {{ supplierStr(prodDetail.poolSupplierVO.supplier) }}
          </el-form-item>
          <el-form-item label="是否京东自营">
            {{ prodDetail.poolSupplierVO.isSelf === 1 ? "是" : "否" }}
          </el-form-item>
          <el-form-item label="是否京东配送">
            {{ prodDetail.poolSupplierVO.isOfficialDelivery === 1 ? "是" : "否" }}
          </el-form-item>
          <el-form-item label="是否支持专票">
            {{ prodDetail.poolSupplierVO.isVATEnable === 1 ? "是" : "否" }}
          </el-form-item>
          <el-form-item label="退换货类型">
            {{ prodDetail.poolSupplierVO.policyCopyWriting }}
          </el-form-item>
          <el-form-item label="关联服务政策">
            <el-select
              v-model="servicePolicyId"
              placeholder="请选择"
              @visible-change="onChangeMgnt"
            >
              <el-option
                v-for="item in prodDetail.servicePolicyList"
                :key="item.id"
                :span="12"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </div>
        <div id="5" class="label-tab" :style="{ 'margin-top': mgnT }">| 图文详情</div>
        <PanelWrapper>
          <editor :content="prodDetail.detailUrl" @update="upDataContent($event)" />
        </PanelWrapper>
      </el-form>
    </el-main>
    <el-footer>
      <FooterWrapper v-if="!prodDetail.isStore">
        <el-button type="primary" @click="onSubmit(1)"> 添加并上架 </el-button>
        <el-button @click="onSubmit(0)"> 添加到商品库 </el-button>
      </FooterWrapper>
      <FooterWrapper v-else>
        <el-button type="primary" @click="backToPool()"> 返回商品池 </el-button>
        <el-button v-if="prodRelated.length !== 0" @click="gotoRelated()"> 查询关联商品 </el-button>
      </FooterWrapper>
    </el-footer>
  </el-container>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue';
import Editor from '@/components/Editor.vue'
import { scrollToElementId, fenToYuan, objIndexOf } from '@/utils/util'
import PanelWrapper from '@/components/common/panelWrapper.vue'
import FooterWrapper from '@/components/common/footerWrapper.vue'
import addProduct from '@/apis/addProduct'
import { mapState } from 'vuex'

export default {
  components: {
    Editor,
    FooterWrapper,
    PanelWrapper
  },
  filters: {
    imageUrlFilter(url) {
      let index = url.indexOf('http')
      if (index > -1) {
        return url
      }
      return `https://img30.360buyimg.com/sku/${url}`
    }
  },
  data() {
    return {
      mgnT: '26px',
      name: '添加商品',
      skuId: '',
      supplier: '',
      loading: true,
      typeId: [],
      mainTypeId: '0',
      activeIndex: '1',
      servicePolicyId: '',
      productIndex: 300,
      sellPrice: 0,
      highestBuy: 999,
      mainType: [
        {
          id: '0',
          name: '实体商品'
        },
        {
          id: '1',
          name: '虚拟商品'
        }],
      skuAttr: [],
      commodityProps: {
        lazy: true,
        lazyLoad: (node, resolve) => {
          const { level, value = '888888' } = node
          this.onGetCommodityList(level + 1, value).then(res => {
            resolve(res)
          })
        }
      }
    }
  },
  computed: {
    ...mapState(['prodDetail', 'prodRelated']),
    originalPrice() {
      return fenToYuan(this.prodDetail.originalPrice)
    },
    formalPrice() {
      return fenToYuan(this.prodDetail.formalPrice)
    },
    channelPrice() {
      return fenToYuan(this.prodDetail.channelPrice)
    }
  },
  mounted() {

    console.log('this.name ', this.name)
    const { skuId, supplier } = this.$route.query
    this.skuId = skuId
    this.supplier = supplier
    this.$store.dispatch('changeTitle', this.name)
    this.$store.dispatch('getJdPoolProductDetail', {skuId: skuId, supplier: supplier}).then(res => {
      console.log('resssssssssssss: ', res)
      this.servicePolicyId = res?.servicePolicyId || ''
      this.sellPrice = fenToYuan(this.prodDetail.formalPrice)
      this.loading = false
      // ----------------------------- SKU 处理 --------------------------------
      this.skuAttr.splice(0, this.skuAttr.length)
      res?.skuList?.forEach(item => {
        item.skuAttributes.forEach(
          ({ attributeName, attributeNameId, attributeValue, attributeValueId }) => {
            const index = objIndexOf(this.skuAttr, 'attributeName', attributeName)
            if (index >= 0) {
              const childIndex = objIndexOf(this.skuAttr[index].values, 'attributeValue', attributeValue)
              if (childIndex === -1) {
                this.skuAttr[index].values.push({
                  attributeValue,
                  attributeValueId
                })
              }
            } else {
              this.skuAttr.push({
                attributeName,
                attributeNameId,
                values: [
                  {
                    attributeValue,
                    attributeValueId
                  }
                ]
              })
            }
          }
        )
      })
    })
    this.$store.dispatch('searchBySkuId', {'skuId': this.skuId, supplier: supplier})
  },
  methods: {
    onGetCommodityList(level = 0, parentId = '888888') {
      const params = {level, parentId}
      return this.$axios.get(this.$API.getCommodityType, { params }).then(res => {
        const nodes = (res || []).map(item => ({
          value: item.id,
          label: item.name,
          leaf: level >= 2
        }))
        return nodes
      })
    },
    supplierStr(value) {
      let obj = {
        JD: '京东',
        CAT: '一只猫',
        OFFICIAL: '红包话费',
        XT: '鲜通',
        '0': '京东',
        '1': '一只猫',
        '2': '红包话费',
        '8': '鲜通',
        '10': '十鹿'
      }
      return obj[String(value)] || '一只猫'
    },
    onSetSellPrice(event) {
      let numArr = event.toString().split('.')
      // 允许带小数
      this.sellPrice = numArr[1] ? (`${numArr[0]  }.${  numArr[1].slice(0, 2)}`) : numArr[0]
      // 不允许带小数
      // this.sellPrice = numArr[0]
    },
    onChangeMgnt(val) {
      console.log(val)
      this.mgnT = val ? `${this.prodDetail.servicePolicyList?.length * 34 }px` : '26px'
    },
    scrollTo(id) {
      scrollToElementId(id)
    },
    remove(index) {
      this.$store.dispatch('removeImg', {index: index})
    },
    upDataContent(data) {
      this.$store.dispatch('updateDetailContent', data)
    },
    setMainImg(index) {
      this.$store.dispatch('setMainImg', {index: index})
    },
    backToPool() {
      this.$router.push({ name: 'prodPool'})
    },
    gotoRelated() {
      this.$router.push({ name: 'prodRelated', query: { skuId: this.skuId, supplier: this.supplier }})
    },
    onSubmit(state) {
      if (!this.sellPrice) {
        this.$message.error('请填写正确的销售价')
        return
      }
      if (!this.highestBuy) {
        this.$message.error('请填写最大购买数量')
        return
      }
      if (!this.servicePolicyId) {
        this.$message.error('请选择关联的服务政策')
        return
      }
      const {
        skuList,
        detailUrl,
        poolSupplierVO,
        ...prod
      } = this.prodDetail
      const data = {
        ...prod,
        code: prod.id,
        id: prod.relationCode,
        dicCommodityType: this.typeId[0],
        secondDicCommodityType: this.typeId[1],
        commodityUserType: 1,
        productType: this.mainTypeId,
        detail: detailUrl,
        attributes: skuList?.length && skuList[0].skuAttributes,
        sellPrice: this.sellPrice * 100,
        highestBuy: this.highestBuy,
        productIndex: this.productIndex,
        servicePolicyId: this.servicePolicyId,
        state: state || 0,
        afterSalePolicyId: poolSupplierVO.afterSalePolicyId
      }
      delete data.commodityTypeVOS
      delete data.servicePolicyList
      addProduct(data).then(response => {
        if (response) {
          this.$message({
            message: '商品添加成功，请进入商品管理查看',
            type: 'success'
          })
          this.$store.dispatch('getJdPoolProductDetail', {skuId: this.skuId, supplier: this.supplier}).then(() => {
            this.loading = false
          })
        } else {
          this.$message.error('获取商品异常，请稍后重试')
        }
      }).catch(error => {
        if (error.code === 1000002021) {
          this.$message.error('此商品已经添加过了，请不要重复添加')
        } else {
          this.$message.error(error.message)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.attr-value {
  display: inline-block;
  margin-right: 10px;
}
.home {
  margin-top: 40px;
  padding: 0 20px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  .tab-panel {
    /* position: fixed; */

    /* top: 50px; */
    z-index: 100000;
    width: 100%;
    background-color: white;
  }
  .prod-panel {
    padding: 0 10px;

    /* margin-top: 40px; */
  }
}
.label-tab {
  margin-top: 26px;
  margin-bottom: 16px;
  position: relative;
  left: -10px;
}
.elm-input {
  width: 300px;
}
</style>
