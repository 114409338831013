import request from '@/utils/request'
import API from './api-types'

// 获取商品池列表
export default function getJdPoolProductDetail({ skuId, supplier }) {
  return request({
    url: API.getJdPoolProductDetail,
    method: 'get',
    params: { skuId, supplier }
  })
}
