<template>
  <div class="footer-wrapper">
    <slot />
  </div>
</template>

<script>

export default {
  name: 'FooterWrapper'
}
</script>
<style>
  .footer-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: #fff;
    padding: 10px 20px;
    z-index: 11000;
    box-shadow: #666 0 0 6px;
    text-align: center;
  }
</style>
