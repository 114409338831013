<template>
  <div class="page-box">
    <div class="header-box">
      <el-form inline>
        <el-form-item>
          <el-select v-model="sourceValue" placeholder="请选择显示端" @change="onSourceChange">
            <el-option
              v-for="item in sourceOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="statusVal" placeholder="请选择状态" @change="onSelectChange">
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="searchData.backType" placeholder="请选择反馈类型">
            <el-option label="全部反馈" value="" />
            <el-option label="业务反馈" :value="1" />
            <el-option label="数据反馈" :value="2" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="onSearch"> 搜索 </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="table-box">
      <el-table
        v-loading="loading"
        :data="feedbackList"
        border
        style="width: 100%;"
      >
        <el-table-column
          prop="content"
          :show-overflow-tooltip="true"
          label="用户反馈"
          min-width="180"
        >
          <template slot-scope="scope">
            {{ getTitle(scope.row.content) }}
          </template>
        </el-table-column>
        <el-table-column prop="imageUrls" label="反馈图片">
          <template slot-scope="scope">
            <img
              v-if="scope.row.imageUrls"
              :src="scope.row.imageUrls[0]"
              fit="cover"
              class="avatar-scope"
            >
          </template>
        </el-table-column>
        <el-table-column prop="createdAt" label="时间" min-width="130" />
        <el-table-column prop="userTypeStr" label="来源">
          <template slot-scope="scope">
            <p>{{ scope.row.userTypeStr }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="userName" label="用户名" />
        <el-table-column prop="mobile" label="手机号/厂家" />
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <div>
              {{ scope.row.status == 1 ? '已解决' : '待解决' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="remark"
          :show-overflow-tooltip="true"
          label="处理备注"
          min-width="180"
        />
        <el-table-column label="操作" fixed="right" min-width="180">
          <template slot-scope="scope">
            <el-button type="text" @click="onClickDetail(scope)"> 查看 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination-com
        :current-page="searchData.pageNo"
        :page-size="searchData.pageSize"
        :total="total"
        @current-change="onCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import paginationCom from '@/components/common/paginationCom'
import { mapActions, mapGetters } from 'vuex'
import { getShowAtStr, getShowAtNum } from '../utils/util'

export default {
  name: 'UserFeedback',
  components: {
    paginationCom
  },
  filters: {
    getShowAtStr
  },
  data() {
    return {
      loading: true,
      // ------------------------- searchData ------------
      searchData: {
        // 搜索参数
        pageNo: 1,
        pageSize: 10,
        showAt: null,
        status: null,
        backType: ''
      },
      total: 0,
      // ---------------------- source select --------------
      sourceValue: '',
      sourceOptions: [
        {
          value: 'all',
          label: '全部来源'
        },
        {
          value: 'speakerTerminal',
          label: '直播助手'
        },
        {
          value: 'manufacturer',
          label: '厂家端'
        },
        {
          value: 'representative',
          label: '代表端'
        },
        {
          value: 'doctor',
          label: '医生端'
        }
      ],
      // ------------------- input ---------------
      inputVal: '',
      // -------------------- statusOptions --------
      statusVal: '',
      statusOptions: [
        {
          value: 'all',
          label: '全部状态'
        },
        {
          value: '0',
          label: '待解决'
        },
        {
          value: '1',
          label: '已解决'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['feedbackList'])
  },
  activated() {
    this.onGetFeedbackList()
  },
  destroyed() {},
  methods: {
    ...mapActions(['getPlatformFeedbackList', 'setDetailObj']),
    getTitle(content) {
      if (!content) return '/'
      try {
        const detailVo = JSON.parse(content)
        if (detailVo.feedbackType === 'jsonVo') {
          return detailVo.title
        }
      } catch (e) {
        return content
      }
      return content
    },
    onGetFeedbackList() {
      this.getPlatformFeedbackList(this.searchData).then(res => {
        this.loading = false
        this.total = res.totalCount
      })
    },
    onClickDetail(options) {
      console.log(options)
      this.setDetailObj(options.row)
      this.$router.push({ path: '/userFeedbackDetail' })
    },
    onCurrentChange(options) {
      console.log(options, '-------- 选择页数 -----')
      this.$set(this.searchData, 'pageNo', options)
      this.onGetFeedbackList()
    },
    onInputChange(options) {
      console.log(options)
    },
    onSelectChange(options) {
      console.log(options)
      const status = options === 'all' ? '' : options
      this.$set(this.searchData, 'status', status)
    },
    onSourceChange(options) {
      const showAt = getShowAtNum([options])
      this.$set(this.searchData, 'showAt', showAt)
    },
    onSearch() {
      console.log('---------- 搜索 ---------')
      this.$set(this.searchData, 'pageNo', 1)
      this.onGetFeedbackList()
    }
  }
}
</script>

<style scoped>
.table-box {
  padding-top: 30px;
}
.avatar-scope {
  width: 50px;
  height: 50px;
  padding: 5px 5px;
}
</style>
