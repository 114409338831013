import Vue from 'vue'
import VueRouter from 'vue-router'
import { dealCache } from './featuresFn'
import store from '@/store/index'

import ProdPool from '../views/prodPool.vue'
import ProdRelated from '../views/prodRelated.vue'
import ProdList from '../views/prodList.vue'
import ProdDetail from '../views/prodDetail.vue'
import ProductDetail from '../views/productDetail.vue'
import ServicePolicy from '../views/servicePolicy.vue'
import PolicyDetail from '../views/policyDetail.vue'
import ServiceSalesDetail from '../views/serviceSalesDetail.vue'
import VideoPlayer from '../views/videoPlayer.vue'
import OrderList from '../views/orderList.vue'
import OrderDetail from '../views/orderDetail.vue'
import AfterSaleOrder from '../views/afterSaleOrder.vue'
import AfterSaleOrderDetail from '../views/afterSaleOrderDetail.vue'
import UserFeedback from '../views/user-feedback.vue'
import UserFeedbackDetail from '../views/user-feedback-detail.vue'
import ProblemClassify from '../views/problem-classify.vue'
import ManageClassify from '../views/manage-classify.vue'
import ProblemEditor from '../views/problem-editor.vue'
import FaceReviewList from '../views/face-review/FaceReviewList.vue'
import FaceReviewDetail from '../views/face-review/FaceReviewDetail.vue'
import NewbieGuide from '../views/newbie-guide.vue'
import LiveAuditList from '../views/live-audit/live-audit-list.vue'
import SpeakerWorkOrder from '../views/live-audit/speakerWorkOrder.vue'
import MessagePush from '../views/messagePush.vue'
import NewMessage from '../views/newMessage.vue'
import ProgramManagement from '../views/integral-shop-management/programManagement.vue'
import PushManagement from '../views/integral-shop-management/pushManagement.vue'
import AddNewPush from '../views/integral-shop-management/addNewPush.vue'
import doctorDetail from '../views/doctor-management/doctorDetail.vue'
import factoryDetail from '../views/doctor-management/factoryDetail.vue'
import factorySettings from '../views/doctor-management/factorySettings.vue'
import CourseManagement from '../views/training-management/CourseManagement.vue'
import OrderManagement from '../views/training-management/OrderManagement.vue'
import CourseDetail from '../views/training-management/CourseDetail.vue'
import ContractManagement from '../views/training-management/ContractManagement.vue'

import { setToken } from '@/utils/auth'
import DepartmentList from '../views/departmentManagement/DepartmentList.vue'
import DepartmentClassify from '../views/departmentManagement/DepartmentClassify.vue'
import SpeakerCourseManagement from '../views/speaker-management/SpeakerCourseManagement.vue'
import SpeakerManagement from '../views/speaker-management/SpeakerManagement.vue'
import SpeakerCourseDetail from '../views/speaker-management/SpeakerCourseDetail.vue'
import SpeakerDetail from '../views/speaker-management/SpeakerDetail.vue'
import OperateManagement from '../views/speaker-management/OperateManagement.vue'
import SpeakerCourseIndex from '../views/speaker-management/SpeakerCourseIndex.vue'

import LiveSquare from './modules/live-square'
import riskControl from './modules/risk-control'
import dataScreen from './modules/data-screen'
import chartConfig from './modules/chart-config'
import auxiliaryTool from './modules/auxiliary-tool'
import PostManagement from '@/views/brand/PostManagement'
import PostDetail from '@/views/brand/PostDetail'
import cloudBrain from './modules/cloud-brain'
import ActivityZone from '@/views/shop-activity-management/ActivityZone'
import MarketingManagement from '@/views/shop-activity-management/MarketingManagement'
import MarketingManagementDetail from '@/views/shop-activity-management/MarketingManagementDetail'

Vue.use(VueRouter)
const routes = [
  {
    path: '/prodPool',
    name: 'prodPool',
    component: ProdPool,
    meta: { keepAlive: true }
  },
  {
    path: '/prodRelated',
    name: 'prodRelated',
    component: ProdRelated,
    meta: { keepAlive: false }
  },
  {
    path: '/prodDetail',
    name: 'prodDetail',
    component: ProdDetail
  },
  {
    path: '/videoPlayer',
    name: 'videoPlayer',
    component: VideoPlayer
  },
  {
    // 商品管理
    path: '/prodList',
    name: 'prodList',
    component: ProdList,
    meta: {
      cache: ['productDetail']
    }
  },
  {
    // 商品详情
    path: '/productDetail',
    name: 'productDetail',
    component: ProductDetail
  },
  {
    // 订单列表
    path: '/orderList',
    name: 'orderList',
    component: OrderList,
    meta: { keepAlive: true }
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: OrderDetail
  },
  {
    // 售后单列表
    path: '/afterSaleOrder',
    name: 'afterSalesOrder',
    component: AfterSaleOrder
  },
  {
    // 售后单详情
    path: '/afterSaleOrderDetail',
    name: 'afterSaleOrderDetail',
    component: AfterSaleOrderDetail
  },
  {
    // 服务政策
    path: '/servicePolicy',
    name: 'servicePolicy',
    component: ServicePolicy,
    meta: { keepAlive: true }
  },
  {
    // 服务政策详情
    path: '/policyDetail',
    name: 'policyDetail',
    component: PolicyDetail
  },
  {
    // 售后原因详情
    path: '/service/sales/detail',
    name: 'ServiceSalesDetail',
    component: ServiceSalesDetail
  },
  {
    // 人脸识别审核列表
    path: '/face/reviewlist',
    name: 'FaceReviewList',
    component: FaceReviewList,
    meta: { keepAlive: true }
  },
  {
    // 人脸识别审核详情
    path: '/face/detail',
    name: 'FaceReviewDetail',
    component: FaceReviewDetail
  },
  // {
  //   path: '/about',
  //   name: 'about',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  // },
  {
    path: '/userFeedback',
    name: 'userFeedback',
    component: UserFeedback,
    meta: { keepAlive: true }
  },
  {
    path: '/userFeedbackDetail',
    name: 'userFeedbackDetail',
    component: UserFeedbackDetail
  },
  {
    path: '/problemClassify',
    name: 'problemClassify',
    component: ProblemClassify,
    meta: { keepAlive: true }
  },
  {
    path: '/manageClassify',
    name: 'manageClassify',
    component: ManageClassify
  },
  {
    path: '/problemEditor/:id',
    name: 'problemEditor',
    component: ProblemEditor
  },
  {
    path: '/newbieGuide',
    name: 'newbieGuide',
    component: NewbieGuide
  },
  // ------------------ 直播审核 --------------
  {
    path: '/liveAuditList',
    name: 'LiveAuditList',
    component: LiveAuditList
  },
  {
    path: '/speakerWorkOrder',
    name: 'SpeakerWorkOrder',
    component: SpeakerWorkOrder
  },
  //
  {
    path: '/messagePush',
    name: 'messagePush',
    component: MessagePush
  },
  {
    path: '/newMessage',
    name: 'newMessage',
    component: NewMessage
  },
  {
    path: '/prograManagement',
    name: 'PrograManagement',
    component: ProgramManagement
  },
  {
    path: '/push/management',
    name: 'PushManagement',
    component: PushManagement,
    meta: { keepAlive: true }
  },
  {
    path: '/new/push',
    name: 'AddNewPush',
    component: AddNewPush
  },
  // --------------------医生管理-----------------
  {
    path: '/doctorDetail',
    name: 'doctorDetail',
    component: doctorDetail
  },
  {
    path: '/factoryDetail',
    name: 'factoryDetail',
    component: factoryDetail
  },
  {
    path: '/factorySettings',
    name: 'factorySettings',
    component: factorySettings
  },
  // --------------------培训管理-----------------
  {
    path: '/courseManagement',
    name: 'CourseManagement',
    component: CourseManagement
  },
  {
    path: '/orderManagement',
    name: 'OrderManagement',
    component: OrderManagement
  },
  {
    path: '/courseDetail',
    name: 'CourseDetail',
    component: CourseDetail
  },
  {
    path: '/contractManagement',
    name: 'ContractManagement',
    component: ContractManagement
  },
  {
    path: '/department/list/:hospitalId',           // 科室新增、编辑列表
    name: 'DepartmentList',
    component: DepartmentList
  },
  {
    path: '/department/classify',                   // 平台科室管理分类
    name: 'DepartmentClassify',
    component: DepartmentClassify
  },
  // 医学知识商城
  {
    path: '/speakerManagement',
    name: 'SpeakerManagement',
    component: SpeakerManagement
  },
  {
    path: '/speakerCourseManagement',
    name: 'SpeakerCourseManagement',
    component: SpeakerCourseManagement
  },
  {
    path: '/speakerCourseDetail',
    name: 'SpeakerCourseDetail',
    component: SpeakerCourseDetail
  },
  {
    path: '/speakerDetail',
    name: 'SpeakerDetail',
    component: SpeakerDetail,
    meta: { keepAlive: true }
  },
  {
    path: '/operateManagement',
    name: 'OperateManagement',
    component: OperateManagement
  },
  {
    path: '/speakerCourseIndex',
    name: 'SpeakerCourseIndex',
    component: SpeakerCourseIndex
  },
  //  品牌宣传
  {
    path: '/activityManagement',
    component: () => import('@/views/brand/ActivityManagement.vue'),
    children: [
      {
        path: '',
        name: 'ActivityManagement',
        component: () => import('@/views/brand/activity/list.vue')
      }, {
        path: 'edit/:brandingId',
        name: 'EditActivityManagement',
        component: () => import('@/views/brand/activity/edit.vue')
      }, {
        path: 'create',
        name: 'CreateActivityManagement',
        component: () => import('@/views/brand/activity/edit.vue')
      },
      {
        path: 'detail/:id',
        name: 'ActivityDetail',
        component: () => import('@/views/brand/activity/detail.vue')
      }
    ]
  },
  {
    path: '/postManagement',
    name: 'PostManagement',
    component: PostManagement
  },
  {
    path: '/postDetail',
    name: 'PostDetail',
    component: PostDetail
  },
  {
    path: '/activityZone',
    name: 'ActivityZone',
    component: ActivityZone
  },
  {
    path: '/marketingManagement',
    name: 'MarketingManagement',
    component: MarketingManagement
  },
  {
    path: '/marketingManagementDetail',
    name: 'MarketingManagementDetail',
    component: MarketingManagementDetail
  },
  {
    path: '/custom-auth',
    name: 'CustomAuth',
    component: () => import('@/views/custom-auth/index.vue')
  },
  ...LiveSquare,
  ...riskControl, // 风控系统
  ...dataScreen, // 数据大屏
  ...chartConfig, // 数据管理
  ...auxiliaryTool, // 运营人员辅助工具
  ...cloudBrain // 云脑2.0
]
const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 解决路由 push/replace 相同地址报错的问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

router.beforeEach(async(to, from, next) => {
  const AUTH_TOKEN = to.query.token || ''
  console.log('token: ', AUTH_TOKEN)
  if (AUTH_TOKEN) {
    setToken(AUTH_TOKEN)
  }

  // 处理缓存
  await dealCache(to, from, next, store)
  next()
})
export default router
