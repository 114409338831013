<template>
  <div class="department-list-box">
    <div>科室列表</div>
    <div class="add-btn">
      <el-button
        type="text"
        size="large"
        icon="el-icon-circle-plus-outline"
        @click="onAddDepartment"
      >
        新增一级科室
      </el-button>
    </div>
    <el-tree
      :data="departmentList"
      node-key="id"
      default-expand-all
      :expand-on-click-node="false"
      :props="defaultProps"
    >
      <div
        slot-scope="{ node, data }"
        class="custom-tree-node"
      >
        <span>{{ data.name }}{{ data.doctorCount ? ( '(' + (data.doctorCount + '人') + ')') : '' }}</span>
        <div>
          <!-- <el-button
                        type="text"
                        size="large"
                        @click="() => lookDoctor(node,data)"
                    >
                        <span>查看</span>
                    </el-button> -->
          <el-button
            v-if="node.level !== 3"
            type="text"
            size="large"
            @click="() => append(node,data)"
          >
            <span>新增</span>
          </el-button>
          <el-button
            type="text"
            size="large"
            @click="() => editNode(node, data)"
          >
            <span>编辑</span>
          </el-button>
          <el-button
            type="text"
            size="large"
            @click="() => remove(node, data)"
          >
            <span>删除</span>
          </el-button>
        </div>
      </div>
    </el-tree>

    <!-- <div class="footer-btn-box">
            <el-button plain size="large" style="margin-right: 100px;" @click="onCannel">取消</el-button>
            <el-button type="primary" size="large" @click="onSaveDepartment">保存</el-button>
        </div> -->
    <el-dialog
      :title="titleName"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <div class="department-input-box">
        <label class="label-name">科室名称：</label>
        <el-input
          v-model="inputVal"
          placeholder="请填写科室名称"
        />
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="centerDialogVisible = false">
          取 消
        </el-button>
        <el-button
          type="primary"
          @click="onConfirmBtn"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="删除科室"
      :visible.sync="isHasDoctor"
      width="30%"
      center
    >
      <div class="department-input-box">
        该科室下有医生，请将医生移除当前科室后重试。您也可以尝试修改科室名称
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="isHasDoctor = false"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      :title="delTitle"
      :visible.sync="isShowDelDepartment"
      width="30%"
      center
    >
      <div class="content-box">
        <div class="department-input-box">
          <label class="label-name">科室名称：</label>
          <span>{{ inputVal }}</span>
        </div>
        <div class="department-input-box mgn-t30">
          {{ delTipMsg }}
        </div>
      </div>

      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="isShowDelDepartment = false">
          取 消
        </el-button>
        <el-button
          type="primary"
          @click="onDelDepartmentCon"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'DepartmentList',
  data() {
    return {
      defaultProps: {
        children: 'childList',
        label: 'name'
      },
      departmentList: [],
      centerDialogVisible: false,
      isEdit: false,
      level: 1,
      inputVal: '',
      initData: {},
      initNode: {},
      isRoot: true,
      isHasDoctor: false,
      isShowDelDepartment: false,

      hospitalId: '',
      hospitalName: '',
      parentId: '',
      departmentId: ''
    }
  },
  computed: {
    titleName() {
      if (this.isEdit) {
        if (this.isRoot || this.level === 1) {
          return  '编辑一级科室'
        } else if (this.level === 2) {
          return '编辑二级科室'
        } else {
          return  '编辑三级科室'
        }
      } else {
        if (this.isRoot) {
          return '新增一级科室'
        } else if (this.level === 1) {
          return  '新增二级科室'
        } else {
          return '新增三级科室'
        }
      }

    },
    delTitle() {
      if (this.level === 1) {
        return '删除一级科室'
      } else if (this.level === 2) {
        return '删除二级科室'
      } else {
        return '删除三级科室'
      }
    },
    delTipMsg() {
      if (this.level === 1) {
        return '删除后，该科室下的所有二级和三级科室都将被删除。且无法恢复，请谨慎操作。'
      } else if (this.level === 2) {
        return '删除后，该科室下的所有二级和三级科室都将被删除。且无法恢复，请谨慎操作。'
      } else {
        return '删除后无法恢复，请谨慎操作。'
      }
    }
  },
  mounted() {
    this.hospitalId = this.$route.params.hospitalId
    this.hospitalName = this.$route.query.hospitalName
    this.onGetDepartmentList()
  },
  methods: {
    ...mapActions('HospitalStore', ['departmentAdd', 'departmentQuery', 'departmentUpdate', 'departmentDelete', 'tempPage']),
    onAddDepartment() {
      console.log('新增一级科室')
      this.level = 1
      this.inputVal = ''
      this.isEdit = false
      this.centerDialogVisible = true
      this.isRoot = true
      this.parentId = ''
    },
    lookDoctor() {
      const data = {
        path: `ksh/doctor/listUrl?hospitalName=${this.hospitalName}`,
        type: 2,
        tabName: '医生列表'
      }
      this.tempPage(data)
    },
    append(node, data) {
      console.log(node, data)
      this.initData = data
      this.initNode = node
      this.level = node.level
      this.inputVal = ''
      this.isEdit = false
      this.centerDialogVisible = true
      this.isRoot = false
      this.parentId = data.id
    },
    editNode(node, data) {
      console.log(node, data)
      this.initData = data
      this.initNode = node
      this.level = node.level
      this.inputVal = data.name
      this.isEdit = true
      this.centerDialogVisible = true
      if (this.level === 1) {
        this.isRoot = true
      } else {
        this.isRoot = false
      }
      this.departmentId = data.id
    },
    remove(node, data) {
      console.log(node, data)
      this.initData = data
      this.initNode = node
      this.level = node.level
      this.inputVal = data.name
      this.isShowDelDepartment = true
      if (this.level === 1) {
        this.isRoot = true
      } else {
        this.isRoot = false

      }
      console.log('需判断科室下是否有医生')
      this.departmentId = data.id
    },
    onDelDepartmentCon() {
      const data = {
        hospitalId: this.hospitalId,
        id: this.departmentId
      }
      this.departmentDelete(data).then(() => {
        this.onGetDepartmentList()
        this.isShowDelDepartment = false
      }, rea => {
        if (rea.code === 10007) {
          this.isHasDoctor = true
        }
      })
    },
    onAddChildren() {

      const data = {
        hospitalId: this.hospitalId,
        name: this.inputVal,
        parentId: this.parentId
      }
      this.departmentAdd(data).then(() => {
        this.onGetDepartmentList()
      })
    },
    onGetDepartmentList() {
      this.departmentQuery({hospitalId: this.hospitalId, isCount: true}).then(res => {
        this.departmentList = res
      })
    },
    onEditChildren() {
      const data = {
        hospitalId: this.hospitalId,
        id: this.departmentId,
        name: this.inputVal
      }
      this.departmentUpdate(data).then(() => {
        this.onGetDepartmentList()
      })
    },
    onConfirmBtn() {
      if (!this.inputVal) return this.$message.error('请填写科室名称！')
      if (this.isEdit) {
        this.onEditChildren()
      } else {
        this.onAddChildren()
      }
      this.centerDialogVisible = false

    },
    onCannel() {

    },
    onSaveDepartment() {
      console.log(this.departmentList, '保存')
    }
  }

}
</script>

<style lang="scss" scoped>

.custom-tree-node {
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.department-input-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.label-name {
  flex-shrink: 0;
  flex-grow: 0;
}
.footer-btn-box {
  margin-top: 100px;
  text-align: center;
}
.mgn-t30 {
  margin-top: 30px;
}
</style>
