<template>
  <el-dialog
    :title="title"
    width="40%"
    :visible.sync="visible"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    destroy-on-close
  >
    <video controls class="video-item">
      <source v-if="visible" :src="videoUrl" type="video/mp4">
      <source v-if="visible" :src="videoUrl" type="video/webm">
      <p>Your browser doesn't support HTML5 video. Here is a <a href="myVideo.mp4">link to the video</a> instead.</p>
    </video>
    <div slot="footer" class="dialog-footer">
      <el-button size="medium" class="btn-width" @click="onClose"> 关闭 </el-button>
      <el-button
        v-if="isDown"
        type="primary"
        size="medium"
        class="btn-width"
        @click="onSubmit"
      >
        下载视频
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { saveAs } from 'file-saver'
export default {
  name: 'VideoPlayModal',
  props: {
    videoUrl: String,
    visible: Boolean,
    title: {
      type: String,
      default: '直播视频'
    },
    isDown: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {}
  },
  methods: {
    onClose() {
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('onClose')
    },
    onSubmit() {
      saveAs(this.videoUrl)
    }
  }
}
</script>
<style lang="scss" scoped>
.video-item {
  width: 100%;
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
.btn-width {
  min-width: 120px;
}
</style>
