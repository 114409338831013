<template>
  <div ref="AceCodeEditor" class="ace-code-editor">{{ value }}</div>
</template>

<script>
import ace from 'ace-builds'
import 'ace-builds/webpack-resolver'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/mode-javascript'
import 'ace-builds/src-noconflict/mode-django'
export default {
  name: 'AceCodeEditor',
  props: {
    value: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: () => ({
        mode: 'ace/mode/javascript',
        selectionStyle: 'text',
        tabSize: 2,
        wrap: false // 开启换行
      })
    }
  },
  data() {
    return {
      editor: null
    }
  },
  watch: {
    // value(newVal) {
    // this.editor.setValue(newVal, newVal.length)
    // }
  },
  mounted() {
    this.initEditor()
    this.editor.getSession().on('change', this.handleChange)
  },
  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy()
      this.editor.container.remove()
    }
  },
  methods: {
    handleChange() {
      this.$emit('input', this.editor.getValue())
    },
    initEditor() {
      ace.config.set('basePath', '/node_modules/ace-builds/src-min-noconflict')
      this.editor = ace.edit(this.$refs['AceCodeEditor'], this.options)
      this.editor.execCommand('showKeyboardShortcuts')
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.ace-code-editor {
  height: 100%;
}
</style>
