<template>
  <div>
    <div class="search-box">
      <el-input
        v-model="searchObj.msgTitle"
        v-debounce="[onSearch,'input']"
        class="search-input"
        placeholder="请输入标题"
      />
      <el-select
        v-model="searchObj.pushStatus"
        clearable
        class="search-input"
        placeholder="请选择状态"
        @change="onSearch"
      >
        <el-option
          v-for="item in statusList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-button v-debounce="[onSearch,'click']" type="primary">搜索</el-button>
      <el-button v-debounce="[onJump,'click']" plain type="primary">新增</el-button>
      <el-button v-debounce="[onOpenCheck,'click']" plain type="primary">医生推送校验</el-button>
    </div>
    <el-table
      v-loading="isLoading"
      :data="tableList"
      border
      style="width: 100%; margin-top: 15px;"
    >
      <el-table-column
        label="序号"
        type="index"
        width="100"
        align="center"
      />
      <el-table-column
        prop="msgTitle"
        show-overflow-tooltip
        label="推送标题"
        align="center"
      />
      <el-table-column
        prop="msgContent"
        show-overflow-tooltip
        label="推送内容"
        align="center"
      />
      <el-table-column
        label="推送类型"
        align="center"
      >
        <template slot-scope="scope">
          <p>{{ scope.row.pushType === 1 ? '立即推送' : '定时推送' }}</p>
        </template>
      </el-table-column>
      <el-table-column
        label="推送方式"
        align="center"
      >
        <template slot-scope="scope">
          <p>{{ scope.row.pushChannel === 1 ? '公众号' : '短信' }}</p>
        </template>
      </el-table-column>
      <el-table-column
        label="推送/送达/失败"
        align="center"
      >
        <template slot-scope="scope">
          <p>{{ `${scope.row.pushTotalCount}/${scope.row.pushSuccessCount}/${scope.row.pushFailCount}` }}</p>
        </template>
      </el-table-column>
      <el-table-column
        label="状态"
        align="center"
      >
        <template slot-scope="scope">
          <p>{{ scope.row.pushStatus | pushStatusFilter }}</p>
        </template>
      </el-table-column>
      <el-table-column
        prop="pushTime"
        label="推送时间"
        align="center"
      />
      <el-table-column
        prop="id"
        label="操作"
        align="center"
      >
        <template slot-scope="scope">
          <template v-if="scope.row.pushType !== 1 && scope.row.pushStatus === 0">
            <el-button
              v-debounce="[onDelPushMsg,'click',null,scope.row.id]"
              type="text"
            >
              删除
            </el-button>
            <el-button
              v-debounce="[onEditPushMsg,'click',null,scope.row.id]"
              type="text"
            >
              编辑
            </el-button>
          </template>
          <el-button :key="scope.row.id" v-debounce="[onDownPushMsg,'click',null,scope.row]" type="text">下载数据</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="searchObj.pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="searchObj.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalCount"
      background
      style="margin-top: 15px; text-align: center;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!-- 弹窗 -->
    <ImportExcelCheckDialog
      v-if="isShowPop"
      ref="importExcelCheckDialog"
      destroy-on-close
      model-type="checkPushDoctor"
      :is-show-pop="isShowPop"
      @on-complete="onComplete"
      @on-close="isShowPop = false"
    />
  </div>
</template>

<script>
import ImportExcelCheckDialog from '@/components/common/ImportExcelCheckDialog'
import apiObj from '@/apis/apiDescription/api-objs.js'
export default {
  name: 'PushManagement',
  components: {
    ImportExcelCheckDialog
  },
  filters: {
    pushStatusFilter(val) {
      let obj = {
        '0': '即将推送',
        '1': '已推送',
        '2': '正在推送'
      }
      return obj[String(val)]
    }
  },
  data() {
    return {
      isShowPop: false,
      isLoading: false,
      searchObj: {
        msgTitle: '',
        pushStatus: '',
        pageIndex: 1,
        pageSize: 10
      },
      statusList: [
        {
          label: '即将推送',
          value: 0
        },
        {
          label: '正在推送',
          value: 2
        },
        {
          label: '已推送',
          value: 1
        }
      ],
      totalCount: 0,
      tableList: [],
      isEdit: false
    }
  },
  mounted() {

  },
  activated() {
    this.onSearch()
  },
  methods: {
    onOpenCheck() {
      this.isShowPop = true
    },
    onComplete() {
      this.isShowPop = false
    },
    handleSizeChange(size) {
      this.searchObj.pageIndex = 1
      this.searchObj.pageSize = size
      this.onGetTableList()
    },
    handleCurrentChange(val) {
      this.searchObj.pageIndex = val
      this.onGetTableList()
    },
    onGetTableList() {
      if (this.isLoading) return
      this.isLoading = true
      this.isEdit = false
      this.$requireApi(apiObj.pushRecordList, this.searchObj).then(res => {
        console.log(res)
        this.isLoading = false
        const {data = [], totalCount} = res.data
        this.tableList = data
        this.totalCount = totalCount
      }, rea => {
        this.isLoading = false
        this.$message.error(rea.message)
      })
    },
    onSearch() {
      !this.isEdit && (this.searchObj.pageIndex = 1)
      this.onGetTableList()
    },
    onJump() {
      this.$router.push({name: 'AddNewPush'})
    },
    onDownPushMsg(val) {
      this.$requireApi(apiObj.downPushMsg, {recordId: val.id}).then(res => {
        this.$message.success(res.data)
      }, rea => this.$message.error(rea.message))
    },
    onEditPushMsg(id) {
      this.isEdit = true
      this.$router.push({name: 'AddNewPush', query: {id}})
    },
    onDelPushMsg(id) {
      this.$confirm('确定要删除该条推送消息吗？', '温馨提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        center: true
      }).then(() => {
        this.$requireApi(apiObj.delPushMsg, {recordId: id}).then(() => {
          this.$message.success('删除成功')
          this.onGetTableList()
        }, rea => this.$message.error(rea.message))
      }).catch(() => {})

    }
  }

}
</script>

<style lang="scss" scoped>
.search-box {
  display: flex;
  .search-input {
    width: 200px;
    margin-right: 15px;
  }
}
</style>
