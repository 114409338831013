import request from '@/utils/request'
import API from './api-types'

// 添加商品到商品池
export default function updateProduct(data) {
  return request({
    url: API.updateProduct,
    method: 'post',
    data
  })
}
