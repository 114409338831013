import request from '@/utils/request'
import API from './api-types'

// 获取商品池列表
export default function getJdProductPool({
  poolNumber,
  pageNo,
  pageSize,
  name,
  brandName,
  productTag,
  minChannelPrice,
  maxChannelPrice,
  minFormalPrice,
  maxFormalPrice,
  isSelf,
  sku,
  isVatable,
  supplier
}) {
  return request({
    url: API.getJdProductPool,
    method: 'post',
    data: {
      poolNumber,
      pageNo,
      name,
      brandName,
      productTag,
      minChannelPrice,
      maxChannelPrice,
      minFormalPrice,
      maxFormalPrice,
      isSelf,
      sku,
      isVatable,
      supplier,
      pageSize
    }
  })
}
