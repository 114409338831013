<template>
  <div class="home">
    <div class="search">
      <span>讲者列表</span>
      <div class="search">
        <el-button
          type="primary"
          class="qxs-btn-md"
          :plain="orderBy !== 0"
          @click="search(0)"
        >
          按累计收入排序
        </el-button>
        <el-button
          type="primary"
          class="qxs-btn-md"
          :plain="orderBy !== 1"
          @click="search(1)"
        >
          按本月收入排序
        </el-button>
        <el-input
          v-model="speakerName"
          placeholder="搜索医生姓名"
          class="search-input"
        />
        <el-button
          type="primary"
          class="qxs-btn-md"
          icon="el-icon-search"
          @click="search(orderBy)"
        >
          搜索
        </el-button>
      </div>
    </div>
    <div
      ref="table"
      class="prod-panel"
    >
      <el-table
        ref="multipleTable"
        v-loading="loading"
        :data="speakerList"
        border
        style="width: 100%;"
      >
        <el-table-column
          prop="name"
          label="主讲人"
          min-width="12%"
        />
        <el-table-column
          label="职称"
          prop="titleName"
          min-width="10%"
        />
        <el-table-column
          label="科室"
          prop="department"
          min-width="10%"
        />
        <el-table-column
          label="医院"
          prop="hospital"
          min-width="25%"
        />
        <el-table-column
          label="签约时间"
          prop="signTime"
          min-width="25%"
        >
          <template slot-scope="scoped">
            {{ $dayjs(scoped.row.signTime).format('YYYY-MM-DD HH:mm:ss') }}
          </template>
        </el-table-column>
        <el-table-column
          label="采购课程数"
          prop="purchaseCourseNum"
          min-width="10%"
        />
        <el-table-column
          label="总采购费"
          prop="totalPurchaseFee"
          min-width="10%"
        />
        <el-table-column
          label="总销售分成"
          prop="totalSaleShareFee"
          min-width="10%"
        />
        <el-table-column
          label="累计收入"
          prop="totalIncome"
          min-width="10%"
        />
        <el-table-column
          label="上月收入"
          prop="lastMonthIncome"
          min-width="10%"
        >
          <template slot-scope="scoped">
            {{ scoped.row.lastMonthIncome || 0 }}
          </template>
        </el-table-column>
        <el-table-column
          label="当月累计"
          prop="currentMonthIncome"
          min-width="10%"
        >
          <template slot-scope="scoped">
            {{ scoped.row.currentMonthIncome || 0 }}
          </template>
        </el-table-column>
        <el-table-column
          label="KPI"
          prop="kpi"
          min-width="10%"
        />
        <el-table-column
          prop="zip"
          label="操作"
          min-width="15%"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              @click.native.prevent="checkDetail(scope.$index, speakerList)"
            >
              查看详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-size="limit"
        layout="total,prev, pager, next, jumper"
        :total="totalCount"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SpeakerManagement',
  components: {},
  data() {
    return {
      loading: true,
      name: '讲者列表',
      speakerName: '',
      page: 1,
      limit: 20,
      orderBy: ''
    }
  },
  computed: {
    ...mapGetters(['totalCount', 'speakerList'])
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions(['getSpeakerList', 'changeCourseSale']),
    init() {
      this.getSpeakerList({ page: 1, limit: this.limit }).then(() => {
        this.loading = false
      }
      )
    },
    checkDetail(index, data) {
      this.$router.push({ name: 'SpeakerDetail', params: { speakerId: data[index].id } })
    },
    handleCurrentChange(val) {
      this.page = val
      this.search()
    },
    addProd() {
      this.$router.push({ name: 'CourseDetail' })
    },
    search(orderBy) {
      this.orderBy = orderBy
      this.loading = true
      const req = {
        speakerName: this.speakerName,
        page: this.page,
        limit: this.limit,
        orderBy: this.orderBy
      }
      this.getSpeakerList(req).then(() => {
        this.loading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  padding: 20px;
  .search-panel {
    padding: 20px;
    .search-name {
      position: relative;
      top: 10px;
    }
  }
}
.el-row {
  padding-top: 10px;
}
.item-title {
  display: inline-block;
}
.el-input__inner {
  display: inline-block;
}
.clean-btn {
  text-align: right;
}
.el-pagination {
  padding-top: 26px;
  text-align: center;
}
.price-name {
  padding: 10px 0 0 20px;
}
.search {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.search-input {
  margin: 0 10px;
}
.search-button {
  display: flex;
  flex-direction: column;
  float: right;
}
</style>
