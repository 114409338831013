<template>
  <div class="home">
    <div class="search">
      <span>课程列表</span>
      <div class="search">
        <el-input
          v-model="courseName"
          placeholder="搜索课程名称"
          class="search-input"
        />
        <el-input
          v-model="doctorName"
          placeholder="搜索医生姓名"
          class="search-input"
        />
        <el-button
          type="primary"
          class="qxs-btn-md"
          icon="el-icon-search"
          @click="search(1)"
        >
          搜索
        </el-button>
      </div>
    </div>
    <el-tabs
      type="border-card"
      @tab-click="handleClick"
    >
      <el-tab-pane
        label="已购课程"
        :disabled="loading"
      >
        <div
          ref="table"
          class="prod-panel"
        >
          <el-table
            ref="multipleTable"
            v-loading="loading"
            :data="allSpeakerCourseList"
            border
            style="width: 100%;"
          >
            <el-table-column
              prop="courseName"
              label="课程名称"
              min-width="10%"
            />
            <el-table-column
              prop="speakerName"
              label="主讲人"
              min-width="10%"
            />
            <el-table-column
              label="职称"
              prop="jobTitle"
              min-width="6%"
            />
            <el-table-column
              label="科室"
              min-width="6%"
              prop="department"
            />
            <el-table-column
              label="医院"
              min-width="10%"
              prop="hospital"
            />
            <el-table-column
              prop="courseIndex"
              label="视频"
              min-width="12%"
            >
              <template slot-scope="scoped">
                <div>{{ scoped.row.videoCount || 0 }}个（共{{ scoped.row.videoTime | changeTime }}）</div>
              </template>
            </el-table-column>
            <el-table-column
              label="可见范围"
              min-width="6%"
            >
              <template slot-scope="scoped">
                {{ scoped.row.limitedStatus === 1 ? '限定课' : '公开课' }}
              </template>
            </el-table-column>
            <el-table-column
              label="采购价格"
              min-width="6%"
              prop="purchaseAmount"
            />
            <el-table-column
              label="采购时间"
              min-width="13%"
            >
              <template slot-scope="scoped">
                {{
                  scoped.row.purchaseTime ? $dayjs(scoped.row.purchaseTime).format('YYYY-MM-DD HH:mm:ss') : '/'
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="课程价格"
              min-width="6%"
              prop="salePrice"
            />
            <el-table-column
              label="售出"
              min-width="6%"
              prop="saleCount"
            >
              <template slot-scope="scoped">
                {{ scoped.row.saleCount || 0 }}次
              </template>
            </el-table-column>
            <el-table-column
              label="累计收益"
              min-width="8%"
              prop="totalIncome"
            >
              <template slot-scope="scope">
                <div>
                  <div>平台:{{ scope.row.totalIncome || ' /' }}</div>
                  <div>讲者:{{ scope.row.totalIncome || ' /' }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="状态"
              min-width="6%"
              prop="upShelfStatus"
            >
              <template slot-scope="scoped">
                {{
                  scoped.row.upShelfStatus === 1 ? '已上架' : '未上架'
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="权重"
              min-width="5%"
              prop="weight"
            />
            <el-table-column
              prop="zip"
              label="操作"
              min-width="12%"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click.native.prevent="checkDetail(scope.$index, allSpeakerCourseList)"
                >
                  编辑
                </el-button>
                <el-button
                  type="text"
                  :disabled="scope.row.upShelfStatus === 1"
                  @click="onClickDetail(scope, '1')"
                >
                  上架
                </el-button>
                <el-button
                  type="text"
                  :disabled="scope.row.upShelfStatus === 0"
                  @click="onClickDetail(scope, '0')"
                >
                  下架
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :current-page.sync="pageNo"
            :page-size="limit"
            layout="total, prev, pager, next, jumper"
            :total="totalCount"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="待购课程"
        :disabled="loading"
      >
        <div
          ref="table"
          class="prod-panel"
        >
          <el-table
            ref="multipleTable"
            v-loading="loading"
            :data="allSpeakerCourseList"
            border
            style="width: 100%;"
          >
            <el-table-column
              prop="courseName"
              label="课程名称"
              min-width="10%"
            />
            <el-table-column
              prop="speakerName"
              label="主讲人"
              min-width="8%"
            />
            <el-table-column
              label="职称"
              min-width="6%"
              prop="jobTitle"
            />
            <el-table-column
              label="科室"
              min-width="6%"
              prop="department"
            />
            <el-table-column
              label="医院"
              min-width="10%"
              prop="hospital"
            />
            <el-table-column
              prop="videoCount"
              label="视频数量"
              min-width="6%"
            />
            <el-table-column
              label="总时长"
              min-width="6%"
              prop="videoTime"
            >
              <template slot-scope="scoped">
                {{ scoped.row.videoTime | changeTime }}
              </template>
            </el-table-column>
            <el-table-column
              label="提交时间"
              min-width="10%"
              prop="submitTime"
            >
              <template slot-scope="scoped">
                {{
                  scoped.row.submitTime ? $dayjs(scoped.row.submitTime).format('YYYY-MM-DD HH:mm:ss') : '/'
                }}
              </template>
            </el-table-column>
            <el-table-column
              prop="zip"
              label="操作"
              min-width="10%"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click.native.prevent="checkDetail(scope.$index, allSpeakerCourseList)"
                >
                  编辑
                </el-button>
                <el-button
                  type="text"
                  @click="onRefuseCourse(scope.$index, allSpeakerCourseList)"
                >
                  拒绝采购
                </el-button>
                <el-button
                  v-if="scope.row.purchaseStatus === -1"
                  type="text"
                >
                  已拒绝
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :current-page.sync="page"
            :page-size="limit"
            layout="total, prev, pager, next, jumper"
            :total="totalCount"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      title="拒绝原因"
      :visible.sync="dialogVisible"
      width="30%"
      center
    >
      <el-input v-model="rejectReason" />
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="rejectCourse"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { changeTime } from '@/utils/util'

export default {
  name: 'SpeakerCourseManagement',
  components: {},
  filters: {
    changeTime(num) {
      return changeTime(num)
    }
  },
  data() {
    return {
      loading: false,
      name: '课程列表',
      doctorName: '',
      courseName: '',
      rejectReason: '',
      courseType: 1,
      page: 1,
      pageNo: 1,
      limit: 20,
      totalCount: 0,
      dialogVisible: false
    }
  },
  computed: {
    ...mapGetters(['allSpeakerCourseList'])
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions(['getCourseList', 'changeCourseSale', 'getAllSpeakerCourseList', 'updateCourseState', 'refuseCourse']),
    init() {
      this.loading = true
      this.getAllSpeakerCourseList({
        page: 1,
        limit: this.limit,
        speakerName: this.doctorName,
        courseName: this.courseName,
        courseType: this.courseType
      }).then(res => {
        this.totalCount = res.totalCount
        this.loading = false
      }, () => {
        this.loading = false
      })
    },
    handleClick(tab) {
      if (this.loading) return
      if (tab.label === '待购课程') {
        this.courseType = 0
      } else {
        this.courseType = 1
      }
      this.init()
    },
    checkDetail(index, data) {
      this.$router.push({ name: 'SpeakerCourseDetail', params: { courseId: data[index].id } })
    },
    onRefuseCourse(index, data) {
      this.dialogVisible = true
      this.courseId = data[index].id
      this.purchaseAmount = data[index].purchaseAmount
    },
    rejectCourse() {
      const req = {
        courseId: this.courseId,
        type: -1,
        rejectReason: this.rejectReason
      }
      this.refuseCourse(req).then(() => {
        this.$message.success('已拒绝采购')
        this.dialogVisible = false
        this.init()
      })
    },
    onClickDetail(options, type) {
      const Data = {
        courseId: options.row.id,
        salePrice: options.row.salePrice,
        type: type
      }
      if (type === '1') {
        this.$confirm('确定上架该课程吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.updateCourseState(Data).then(() => {
              this.$message.success('上架成功')
              this.search(this.page)
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消上架'
            })
          })
      } else if (type === '0') {
        this.$confirm('确定下架该课程吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.updateCourseState(Data).then(() => {
              this.$message.success('下架成功')
              this.search(this.page)
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消下架'
            })
          })
      }
    },
    handleCurrentChange(val) {
      this.page = val
      this.search(val)
    },
    addProd() {
      this.$router.push({ name: 'CourseDetail' })
    },
    search(index) {
      this.loading = true
      const req = {
        speakerName: this.doctorName,
        courseName: this.courseName,
        page: index || 1,
        limit: this.limit,
        courseType: this.courseType
      }
      this.getAllSpeakerCourseList(req).then(res => {
        this.totalCount = res.totalCount
        this.loading = false
      }
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  padding: 20px;
  .search-panel {
    padding: 20px;
    .search-name {
      position: relative;
      top: 10px;
    }
  }
}
.el-row {
  padding-top: 10px;
}
.item-title {
  display: inline-block;
}
.el-input__inner {
  display: inline-block;
}
.clean-btn {
  text-align: right;
}
.el-pagination {
  padding-top: 26px;
  text-align: center;
}
.price-name {
  padding: 10px 0 0 20px;
}
.search {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.search-input {
  margin-right: 10px;
}
.search-button {
  display: flex;
  flex-direction: column;
  float: right;
}
.select-item {
  width: 500px;
  margin-right: 10px;
}
</style>
