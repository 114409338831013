import request from '@/utils/request'
import API from './api-types'

// 获取商品池列表
export default function saveDeliverData(data) {
  return request({
    url: API.saveDeliverData,
    method: 'post',
    params: data
  })
}
