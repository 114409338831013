import request from '@/utils/request'
import API from './api-types'

// 设置默认服务政策
export default function setDefaultPolicy(data) {
  return request({
    url: API.setDefaultPolicy,
    method: 'get',
    params: data
  })
}
