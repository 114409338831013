import request from '@/utils/request'
import API from './api-types'

export function departmentQuery(data) {
  return request({
    url: API.departmentQuery,
    method: 'get',
    params: data
  })

}
export function departmentAdd(data) {
  return request({
    url: API.departmentAdd,
    method: 'post',
    params: data
  })
}

export function departmentDelete(data) {
  return request({
    url: API.departmentDelete,
    method: 'post',
    params: data
  })
}

export function departmentUpdate(data) {
  return request({
    url: API.departmentUpdate,
    method: 'post',
    params: data
  })
}

export function platDepartmentList(data) {
  return request({
    url: API.platDepartmentList,
    method: 'get',
    params: data
  })
}

export function waitPlatDepartmentList(data) {
  return request({
    url: API.waitPlatDepartmentList,
    method: 'get',
    params: data
  })
}

export function platDepartmentAdd(data) {
  return request({
    url: API.platDepartmentAdd,
    method: 'post',
    params: data
  })
}

export function platDepartmentUpdata(data) {
  return request({
    url: API.platDepartmentUpdata,
    method: 'post',
    params: data
  })
}

export function platDepartmentDelete(data) {
  return request({
    url: API.platDepartmentDelete,
    method: 'post',
    params: data
  })
}

export function platDepartmentDeleteBind(data) {
  return request({
    url: API.platDepartmentDeleteBind,
    method: 'post',
    params: data
  })
}

export function tempPage(data) {
  return request({
    url: API.tempPage,
    method: 'post',
    data: data
  })
}
