import { Message } from 'element-ui'
// ====================== 数据校验 end =========================
/**
 * 数据校验
 * 对象strategyObj： 策略集
 * 函数strategy：策略校验
 * @ruleList: 单个校验规则
 * @val: 需要校验的数据
 *
 * 函数checkData
 * @rules: {name:[{required:true,message:'请填写姓名'}]}
 * @data: {} 需要校验的数据
 *
 * */
const strategyObj = {
  required(val, item) {
    if (!item.required) return true
    if (!val) return false
    return true
  },
  min(val, item) {
    if (val.length < item.min) return false
    return true
  },
  max(val, item) {
    if (val.length > item.max) return false
    return true
  },
  numMin(val, item) {
    if (Number(val) < item.numMin) return false
    return true
  },
  numMax(val, item) {
    if (Number(val) > item.numMax) return false
    return true
  }
}
// 策略校验
function strategy(ruleList = [], val) {
  for (let item of ruleList) {
    let keys = Object.keys(item)
    for (let k of keys) {
      if (strategyObj[k] && !strategyObj[k](val, item)) {
        Message.error(item.message)
        return false
      }
    }
  }
  return true
}
// 数据校验
function formCheck(data, rules = {}) {
  let ruleKeys = Object.keys(rules)
  for (let key of ruleKeys) {
    let res = true
    if (typeof rules[key] === 'function') {
      res = strategy(rules[key](data[key], data), data[key])
    } else {
      res = strategy(rules[key], data[key])
    }
    if (!res) return false
  }
  return true
}
export default formCheck
// ====================== 数据校验 end =========================
