<template>
  <div class="footer-bottom-box">
    <div class="bottom-button">
      <slot />
    </div>
    <div class="placeholder" />
  </div>
</template>

<script>
export default {
  name: 'FooterBottomBox',
  components: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">
// scss
.footer-bottom-box {
  .bottom-button {
    // height: 80px;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2000;
    padding: 20px;
    // box-shadow: 0 8px 16px rgb(0 0 0 / 18%);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.18);
  }
  .placeholder {
    height: 80px;
  }
}
</style>
