<template>
  <div class="page-body-box">
    <el-form
      ref="form"
      :model="searchData"
      inline
      label-width="80px"
    >
      <el-form-item>
        <el-input v-model="searchData.corpName" placeholder="请输入企业名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-input v-model="searchData.orgName" placeholder="请输入厂家名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-input v-model="searchData.title" placeholder="请输入直播名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-input v-model="searchData.speakerName" placeholder="请输入讲者姓名" clearable />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="searchData.status"
          class="mgn-r20"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <el-select v-model="searchData.sceneType" clearable placeholder="请选择类型">
          <el-option
            v-for="item in sceneTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch"> 搜索 </el-button>
      </el-form-item>
    </el-form>
    <div class="table-box">
      <el-table :data="liveTable" border style="width: 100%;">
        <el-table-column prop="title" min-width="180" label="直播频道">
          <template slot-scope="scope">
            <span>{{ scope.row.title || '—' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="doctorName" min-width="180" label="讲者">
          <template slot-scope="scope">
            <span>{{ scope.row.doctorName || '—' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="sceneType" min-width="180" label="类型">
          <template slot-scope="scope">
            <span>{{ scope.row.sceneType == '5' ? '单人直播' : scope.row.sceneType == '6' ? '多人会议' : '—' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="titleName" min-width="150" label="职称">
          <template slot-scope="scope">
            <span>{{ scope.row.titleName || '—' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="fee" min-width="150" label="讲课费">
          <template slot-scope="scope">
            <span>{{ scope.row.fee || '0' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="startAt" min-width="180" label="开始时间">
          <template slot-scope="scope">
            <span>{{ scope.row.startAt || '—' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="finishAt" min-width="180" label="结束时间">
          <template slot-scope="scope">
            <span>{{ scope.row.finishAt || '—' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="orgName" min-width="180" label="厂家">
          <template slot-scope="scope">
            <span>{{ scope.row.orgName || '—' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="reviewStatus" min-width="180" label="审核状态">
          <template slot-scope="scope">
            <span>{{ scope.row.reviewStatus | onReviewStatusFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="reviewStatus" min-width="180" label="打款状态">
          <template slot-scope="scope">
            <span>{{ scope.row.reviewStatus === '4' ? '已打款' : '未打款' }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="150">
          <template slot-scope="scope">
            <el-button
              class="default"
              :class="{ reject: scope.row.reviewStatus === 3 || scope.row.reviewStatus === 5 || scope.row.reviewStatus === 6, success: scope.row.reviewStatus === 2 }"
              type="text"
              @click="handleClick(scope.row)"
            >
              {{ scope.row.reviewStatus | onReviewStatusFilter }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination-com
      :current-page="searchData.pageNo"
      :page-size="searchData.pageSize"
      :total="liveTableTotal"
      @current-change="onCurrentChange"
    />
    <!-- 审核弹窗 -->
    <el-dialog title="审核" :visible.sync="isShowAuditDialog" class="audit-dialog">
      <el-tabs v-model="activeId" @tab-click="getDetail">
        <el-tab-pane
          v-for="item in liveDetail.liveSpeakerVOS"
          :key="item.id"
          :label="item.name"
          :name="item.id"
        />
      </el-tabs>
      <div v-loading="detailDialogLoading" class="live-audit-box">
        <div class="live-video-box">
          <div class="live-info">
            <div class="video-background" :style="{ backgroundImage: 'url(' + (liveDetail.coverUrl || '') + ')' }">
              <img src="@/assets/video.png" alt="" class="img-click">
              <el-tooltip
                v-if="!(liveDetail.playVo && liveDetail.playVo.mp4)"
                class="tooltip"
                effect="dark"
                content="暂无视频"
                placement="top"
              >
                <a :href="liveDetail.playUrl" target="_bank" />
              </el-tooltip>
              <a v-else :href="playBack" target="_bank" />
            </div>
            <div>
              <div class="video-title">
                <span>{{ liveDetail.sceneType == '5' ? '直播' : '多人会议' }}频道：</span>
                <span>{{ liveDetail.title }}</span>
              </div>
              <div>
                <span>直播开始时间：</span>
                <span>{{ liveDetail.startAt || '暂无' }}</span>
              </div>
              <div>
                <span>直播结束时间：</span>
                <span>{{ liveDetail.finishAt || '暂无' }}</span>
              </div>
              <div>
                <span>视频回放时间：</span>
                <span>{{ liveDetail.duration | timeMinutes }}</span>
              </div>
              <div>
                <span>厂家：</span>
                <span>{{ liveDetail.orgName || '暂无' }}</span>
              </div>
              <div>
                <span>讲者年龄：</span>
                <span>{{ getAge(liveDetail.idCardNo) }}</span>
              </div>
            </div>
          </div>
          <div class="speack-info">
            <el-image :src="liveDetail.faceRecordImg" :preview-src-list="[liveDetail.faceRecordImg]" />
          </div>
        </div>
        <div>
          <h4 style="color: red;">审核直播风险项</h4>
          <div class="audit-risk">
            <div>
              <svg-icon :name="!liveDetail.feeAndTitleMatchFlag ? 'pass' : 'error'" />
              <span>讲者费与职称合规</span>
            </div>
            <!-- <div>
              <svg-icon :name="!liveDetail.dayCountFlag ? 'pass' : 'error'" />
              <span>讲者单日场次合规</span>
            </div>
            <div>
              <svg-icon :name="!liveDetail.monthCountFlag ? 'pass' : 'error'" />
              <span>讲者单月场次合规</span>
            </div> -->
            <div v-if="liveDetail.liveSpeakerVOS&&liveDetail.liveSpeakerVOS.length === 1">
              <svg-icon :name="originalFilmDurationFlag ? 'pass' : 'error'" />
              <span>原片时长大于20分钟</span>
            </div>
          </div>
          <div class="live-risk">
            <svg-icon :name="getLiveStatus(liveDetail.dayHighestStatus, liveDetail.monthHighestStatus, liveDetail.yearHighestStatus)" />
            <span>该讲者在该<span class="success">日直播场次</span>审核通过：<span class="success">{{ liveDetail.dayHighest || 0 }}场</span>，
              该{{ liveDetail.controlType === 0? '公司' : '产品线' }}<span class="success">月场次</span>审核通过：<span class="success">{{ liveDetail.monthHighest|| 0 }}场</span>，
              该{{ liveDetail.controlType === 0? '公司' : '产品线' }}<span class="success">年场次</span>审核通过：<span class="success">{{ liveDetail.yearHighest|| 0 }}场</span>
            </span>
          </div>
        </div>
        <div v-if="liveDetail.otherNotValidPushList&&liveDetail.otherNotValidPushList.length" style="padding-top: 15px;">
          <h4 style="color: red;">疑似讲者同时直播多场</h4>
          <el-table
            :data="liveDetail.otherNotValidPushList"
            border
            stripe
            size="small"
            :max-height="250"
          >
            <el-table-column prop="title" width="200" label="直播频道" />
            <el-table-column prop="pushAt" width="180" label="讲者上线时间" />
            <el-table-column prop="cutOffAt" width="180" label="讲者最后下线时间" />
            <el-table-column prop="reviewStatus" min-width="100" label="审核状态">
              <template slot-scope="scope">
                <span>{{ scope.row.reviewStatus | onReviewStatusFilter }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="videoUrl"
              fixed="right"
              width="180"
              label="操作"
            >
              <template slot-scope="{row:{playVo}}">
                <el-link
                  v-if="!playVo.mp4.sd&&!playVo.mp4.hd"
                  type="primary"
                  target="_blank"
                  :href="playVo.mp4.hd || playVo.mp4.sd"
                >
                  查看直播回放
                </el-link>
                <el-link
                  v-else
                  target="_blank"
                  type="primary"
                  :href="`http://tool.liumingye.cn/m3u8/#${playVo.hls.hd || playVo.hls.sd}`"
                >
                  查看直播回放
                </el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="padding-top: 15px;">
          <el-table
            :data="liveDetail.videoShowVOS"
            border
            stripe
            size="small"
            :max-height="250"
          >
            <el-table-column prop="title" min-width="120" label="视频素材标题">
              <template slot-scope="scope">
                <span>{{ scope.row.title || '—' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="saveType" width="50" label="分类">
              <template slot-scope="scope">
                <span>{{ scope.row.saveType === 1 ? '原片' : '合成' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="duration" width="120" label="视频时长">
              <template slot-scope="scope">
                <span>{{ scope.row.duration | changeTime }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="videoUrl"
              fixed="right"
              min-width="180"
              label="观看链接"
            >
              <template slot-scope="scope">
                <el-link type="primary" target="_blank" :href="`http://tool.liumingye.cn/m3u8/#${scope.row.videoUrl}`">
                  {{ scope.row.videoUrl }}
                </el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="speaker-info-box">
          <el-table
            :data="liveDetail.liveSpeakerVOS"
            border
            stripe
            size="small"
            style="width: 100%;"
            :max-height="400"
          >
            <el-table-column prop="name" min-width="180" label="讲者">
              <template slot-scope="scope">
                <span>{{ scope.row.name || '—' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="titleName" min-width="180" label="职称">
              <template slot-scope="scope">
                <span>{{ scope.row.titleName || '—' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="fee" min-width="100" label="讲课费">
              <template slot-scope="scope">
                <span>{{ scope.row.fee || 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="reviewStatus" min-width="100" label="状态">
              <template slot-scope="scope">
                <span>{{ scope.row.reviewStatus | onReviewStatusFilter }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="firstPushAt" min-width="180" label="讲者上线时间">
              <template slot-scope="scope">
                <span>{{ scope.row.firstPushAt || '—' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="lastCutOffAt" min-width="180" label="讲者最后下线时间">
              <template slot-scope="scope">
                <span>{{ scope.row.lastCutOffAt || '—' }}</span>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="150">
              <template slot-scope="scope">
                <template v-if="scope.row.reviewStatus === 1">
                  <el-button class="default" type="text" @click="submitDataSet(scope.row, 'pass')"> 通过 </el-button>
                  <el-button class="default" type="text" @click="submitDataSet(scope.row, 'refuse')"> 拒绝 </el-button>
                </template>
                <template v-else>
                  <el-tooltip
                    effect="dark"
                    :disabled="!scope.row.remark"
                    :content="scope.row.remark"
                    placement="top-start"
                  >
                    <span :class="{ reject: scope.row.reviewStatus === 3 || scope.row.reviewStatus === 5 || scope.row.reviewStatus === 6, success: scope.row.reviewStatus === 2 }">
                      {{ scope.row.reviewStatus | onReviewStatusFilter }}
                    </span>
                  </el-tooltip>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="拒绝理由" :visible.sync="isShowTextarea" :close-on-click-modal="false">
      <el-select v-model="rejectReason" placeholder="请选择拒绝原因" style="margin-bottom: 10px;">
        <el-option
          v-for="(item, index) in rejectReasonList"
          :key="item.id"
          :label="item.name | orderFilter(index + 1)"
          :value="item.name"
        />
      </el-select>
      <el-input
        v-if="rejectReason === '其他'"
        v-model="textarea"
        type="textarea"
        :autosize="{ minRows: 4, maxRows: 4 }"
        :rows="4"
        placeholder="请输入理由"
      />
      <div class="input-btn-row">
        <el-button @click="isShowTextarea = false"> 取消 </el-button>
        <el-button :disabled="isCanBtn" type="primary" @click="onsubmit"> 确认 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import paginationCom from '@/components/common/paginationCom'
import { mapActions, mapGetters } from 'vuex'
import { timeMinutes, changeTime } from '@/utils/util'

const reviewStatusMap = new Map([
  ['1', '待审核'],
  ['2', '已通过'],
  ['3', '已拒绝'],
  ['4', '已支付'],
  ['5', '拒绝支付'],
  ['6', '支付过期']
])
export default {
  name: 'LiveAuditList',
  components: {
    paginationCom
  },
  filters: {
    onReviewStatusFilter(options) {
      return reviewStatusMap.get(`${options}`)
    },
    changeTime,
    timeMinutes(num) {
      return timeMinutes(num)
    },
    orderFilter(name, index) {
      return `${index}.${name}`
    }
  },
  data() {
    return {
      // ------------------- selectList ---------------
      options: [
        {
          value: '',
          label: '全部状态'
        },
        {
          value: '1',
          label: '待审核'
        },
        {
          value: '2',
          label: '审核成功待支付'
        },
        {
          value: '3',
          label: '审核失败'
        },
        {
          value: '4',
          label: '已支付'
        },
        {
          value: '5',
          label: '拒绝支付'
        },
        {
          value: '6',
          label: '支付过期'
        }
      ],
      sceneTypeOptions: [
        {
          value: '',
          label: '全部类型'
        },
        {
          value: '5',
          label: '单人直播'
        },
        {
          value: '6',
          label: '多人会议'
        }
      ],
      // -------------------- 搜索、页数 -----------------
      timerId: null,
      searchData: {
        orgName: '',
        corpName: '',
        title: '',
        speakerName: '',
        status: '',
        sceneType: '',
        pageNo: 1,
        pageSize: 10
      },
      // ------------------ tableData -----------------
      tableData: [],
      // ------------------- dialog 弹窗 -------------------
      isCanBtn: false,
      reviewReq: true,
      reviewId: '',
      isShowAuditDialog: false,
      auditOptions: [
        {
          value: '1',
          label: '通过'
        },
        {
          value: '-1',
          label: '拒绝'
        }
      ],
      auditVal: '',
      textarea: '',
      rejectReason: '',
      isShowTextarea: false,
      detailDialogLoading: false,
      rejectReasonList: [
        {
          id: 1,
          name: '医师直播服务费标准超额，请修改后重新提交审核。'
        },
        {
          id: 2,
          name: '视频有公司logo，请重新剪辑后提交。'
        },
        {
          id: 3,
          name: '视频有商品名，请重新剪辑后提交。'
        },
        {
          id: 4,
          name: '视频全程无声音。'
        },
        {
          id: 5,
          name: '视频全程无课件。'
        },
        {
          id: 6,
          name: '有效录制时长不足30分。'
        },
        {
          id: 7,
          name: '视频全程黑屏。'
        },
        {
          id: 8,
          name: '讲者面貌无法辨别。'
        },
        {
          id: 9,
          name: '讲者发言时长不足。'
        },
        {
          id: 10,
          name: '讲者没有参与讨。'
        },
        {
          id: 11,
          name: '其他'
        }
      ],
      activeId: ''
    }
  },
  computed: {
    ...mapGetters('LiveAudit', ['liveTable', 'liveDetail', 'liveTableTotal']),
    initSearchData() {
      return JSON.stringify(this.searchData)
    },
    originalFilmDurationFlag() {
      let totalDuration = this.liveDetail?.videoShowVOS?.filter(item => item.saveType === 1).map(item => item.duration).reduce((a, b) => a + b, 0)
      return (totalDuration / 60) > 20
    },
    playBack() {
      const {playVo} = this.liveDetail
      const prefix = 'http://tool.liumingye.cn/m3u8/#'
      return playVo.mp4.hd || playVo.mp4.sd || (playVo.hls.hd ? prefix + playVo.hls.hd : null) || (playVo.hls.sd ? prefix + playVo.hls.sd : null)
    }
  },
  watch: {
    initSearchData: {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal)
        if (JSON.parse(newVal).pageNo === JSON.parse(oldVal).pageNo) {
          this.$set(this.searchData, 'pageNo', 1)
        }
        clearTimeout(this.timerId)
        this.timerId = setTimeout(() => {
          console.log(this.searchData, '-------- watch serchData -----')
          this.getLiveTable(this.searchData)
        }, 500)
      },
      deep: true
    }
  },
  mounted() {
    this.getLiveTable(this.searchData)
  },
  methods: {
    ...mapActions('LiveAudit', ['getLiveTable', 'getLiveDetail', 'reviewDeal']),
    getLiveStatus(day, month, year) {
      // 三个参数任意一个等于2，返回‘error’， 全部都等于0，返回‘pass’，否则返回‘warning’
      if (day === 2 || month === 2 || year === 2) {
        return 'error'
      } else if (day === 0 && month === 0 && year === 0) {
        return 'pass'
      } else {
        return 'warning'
      }
    },
    onSearch() {
      if (this.searchData.pageNo === 1) {
        this.getLiveTable(this.searchData)
      }
      this.$set(this.searchData, 'pageNo', 1)
      console.log(this.searchData, '---------------- onSearch 搜索 ----------------')
    },
    handleClick(row) {
      this.activeId = row.id
      if (row.reviewStatus == 1) {
        this.isCanBtn = false
      } else {
        this.isCanBtn = true
      }
      this.isShowAuditDialog = true
      this.detailDialogLoading = true
      this.reviewId = row.id
      this.auditVal = ''
      this.textarea = ''
      this.rejectReason = ''
      this.getDetail()
    },
    getDetail() {
      this.getLiveDetail({ id: this.activeId })
        .then(res => {
          this.auditVal = res.reviewStatus === 2 ? '1' : res.reviewStatus === 3 ? '-1' : ''
          this.isShowTextarea = res.reviewStatus === 3
          this.textarea = res.remark || ''
        })
        .finally(() => {
          this.detailDialogLoading = false
        })
    },
    onCurrentChange(options) {
      this.$set(this.searchData, 'pageNo', options)
    },
    onDialogSelected(val) {
      console.log(val, '---------- onDialogSelected ---------------')
      if (val === '1') {
        this.isShowTextarea = false
      } else {
        this.isShowTextarea = true
      }
    },
    onClose() {
      this.isShowAuditDialog = false
    },
    submitDataSet(params, actionType) {
      this.auditVal = actionType === 'pass' ? this.auditOptions[0].value : this.auditOptions[1].value
      this.reviewId = params.id
      if (actionType === 'pass') {
        this.onsubmit()
      } else {
        this.isShowTextarea = true
      }
    },
    onsubmit() {
      if (!this.reviewReq) {
        return false
      }
      this.reviewReq = true
      if (!this.auditVal) {
        this.$message({
          message: '请选择操作！',
          type: 'warning'
        })
        return false
      }
      if (this.auditVal !== '1' && !this.rejectReason) {
        this.$message({
          message: '请填写拒绝的理由！',
          type: 'warning'
        })
        return false
      }
      this.$confirm('该操作执行后，无法再更改，请谨慎操作！是否确认？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'warnMessage',
        closeOnClickModal: false
      })
        .then(() => {
          const Data = {
            id: this.reviewId,
            status: this.auditVal,
            remark: this.auditVal === '1' ? '' : this.rejectReason === '其他' ? this.textarea : this.rejectReason
          }
          this.$message({
            type: 'success',
            message: '操作成功'
          })
          this.isShowTextarea = false
          this.reviewDeal(Data).then(() => {
            // this.onClose()
            this.detailDialogLoading = true
            this.getLiveDetail({ id: this.reviewId }).finally(() => {
              this.detailDialogLoading = false
            })
            setTimeout(() => {
              this.getLiveTable(this.searchData)
            }, 500)
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消确认'
          })
        })
    },
    // 调用 getAge方法传参即可
    getAge(identityCard) {
      let len = `${identityCard}`.length
      if (len == 0) {
        return 0
      } else {
        if (len != 15 && len != 18) {
          // 身份证号码只能为15位或18位其它不合法
          return 0
        }
      }
      let strBirthday = ''
      if (len == 18) {
        // 处理18位的身份证号码从号码中得到生日和性别代码
        strBirthday = `${identityCard.substr(6, 4)}/${identityCard.substr(10, 2)}/${identityCard.substr(12, 2)}`
      }
      if (len == 15) {
        strBirthday = `19${identityCard.substr(6, 2)}/${identityCard.substr(8, 2)}/${identityCard.substr(10, 2)}`
      }
      // 时间字符串里，必须是“/”
      let birthDate = new Date(strBirthday)
      let nowDateTime = new Date()
      let age = nowDateTime.getFullYear() - birthDate.getFullYear()
      // 再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
      if (nowDateTime.getMonth() < birthDate.getMonth() || (nowDateTime.getMonth() == birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())) {
        age--
      }
      return age
    }
  }
}
</script>
<style>
.warnMessage .el-message-box__content {
  font-size: 16px;
  color: red;
}
</style>
<style scoped lang="scss">
.audit-dialog {
  :deep(.el-dialog) {
    min-width: 600px;
    width: 70%;
    .el-dialog__body {
      padding-top: 0;
      .live-audit-box {
        font-weight: bold;
        .live-video-box {
          display: flex;
          justify-content: space-between;
          .speack-info {
            .el-image {
              width: 200px;
              height: 150px;
              img {
                object-fit: cover;
              }
            }
          }
          .live-info {
            display: flex;
          }
        }
        .audit-risk {
          display: grid;
          gap: 5px;
          grid-template-columns: repeat(4, 200px);
          .svg-icon {
            font-size: 16px;
            margin-right: 10px;
          }
        }
        .live-risk {
          margin-top: 10px;
          .svg-icon {
            font-size: 16px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
.table-box {
  margin-top: 60px;
}
.live-video-box {
  display: flex;
  flex-wrap: wrap;
}
.video-background {
  width: 200px;
  margin-right: 15px;
  height: 150px;
  background: rgba(0, 0, 0, 0.5);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: cover;
  border: 1px solid #f5f5f5;
}
.img-click {
  width: 80px;
  height: 80px;
  position: absolute;
  z-index: 100;
}
.video-background video {
  width: 100%;
  height: 100%;
}
.video-background a {
  display: block;
  width: 100px;
  height: 100px;
  position: absolute;
  z-index: 1000;
}
.tooltip {
  width: 100px;
  height: 100px;
  position: absolute;
  z-index: 10000;
}
.speaker-info-box {
  margin-top: 20px;
}
.live-info-box {
  margin-top: 20px;
}
.default {
  color: #198cff;
}
.reject {
  color: crimson;
}
.success {
  color: #008075;
}
.input-btn-row {
  margin-top: 20px;
  text-align: right;
}
</style>
