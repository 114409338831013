export default [
  {
    path: '/cloud-brain',
    name: 'CloudBrain',
    component: () => import(/* webpackChunkName: "cloud-brain" */ '@/views/cloud-brain/index.vue'),
    meta: {
      title: '盘古云脑2.0'
    }
  },
  {
    path: '/cloud-brain/data-module',
    name: 'CloudBrainDataModule',
    component: () => import(/* webpackChunkName: "cloud-brain" */ '@/views/cloud-brain/dataModule.vue'),
    meta: {
      title: '数据模块管理'
    }
  },
  {
    path: '/cloud-brain/data-source',
    name: 'CloudBrainDataSource',
    component: () => import(/* webpackChunkName: "cloud-brain" */ '@/views/cloud-brain/dataSource.vue'),
    meta: {
      title: '数据元管理'
    }
  },
  {
    path: '/cloud-brain/factory-detail',
    name: 'CloudBrainFactoryDetail',
    component: () => import(/* webpackChunkName: "cloud-brain" */ '@/views/cloud-brain/factory-detail.vue'),
    meta: {
      title: '厂家详情'
    }
  }
]
