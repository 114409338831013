import request from '@/utils/request'
import API from './api-types'

// 更新订单备注
export default function deliverBack(data) {
  return request({
    url: API.deliverBack,
    method: 'post',
    params: data
  })
}
