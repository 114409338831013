<template>
  <div class="home">
    <div class="tab-panel">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item :disabled="loading" index="0">
          京东商品
        </el-menu-item>
        <el-menu-item :disabled="loading" index="8">
          鲜通商品
        </el-menu-item>
      </el-menu>
    </div>
    <div class="search-panel">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-row>
            <el-col
              :span="8"
              class="search-name"
            >
              品牌名称
            </el-col>
            <el-col :span="16">
              <el-input
                v-model="brandName"
                clearable
                placeholder="请输入品牌名称"
              />
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="6">
          <el-row>
            <el-col
              :span="8"
              class="search-name"
            >
              采购价格区间
            </el-col>
            <el-col :span="6">
              <el-input
                v-model="minChannelPrice"
                type="number"
                min="0"
                @change="channelChange"
              />
            </el-col>
            <el-col
              :span="4"
              class="price-name"
            >
              到
            </el-col>
            <el-col :span="6">
              <el-input
                v-model="maxChannelPrice"
                type="number"
                min="0"
                @change="channelChange"
              />
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="6">
          <el-row>
            <el-col
              :span="8"
              class="search-name"
            >
              参考售价区间
            </el-col>
            <el-col :span="6">
              <el-input
                v-model="minFormalPrice"
                type="number"
                min="0"
                @change="formalChange"
              />
            </el-col>
            <el-col
              :span="4"
              class="price-name"
            >
              到
            </el-col>
            <el-col :span="6">
              <el-input
                v-model="maxFormalPrice"
                type="number"
                min="0"
                @change="formalChange"
              />
            </el-col>
          </el-row>
        </el-col>
        <el-col v-if="activeIndex != 0" :span="6">
          <el-row>
            <el-col
              :span="8"
              class="search-name"
            >
              商品来源
            </el-col>
            <el-col :span="16">
              <el-select
                v-model="goodsSource"
                clearable
                placeholder="请选择商品来源"
              >
                <el-option
                  v-for="item in goodsSourceList"
                  :key="item.id"
                  :span="12"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div class="search-panel">
      <el-row :gutter="20">
        <el-col :span="5">
          <el-row>
            <el-col
              :span="8"
              class="search-name"
            >
              商品名称
            </el-col>
            <el-col :span="16">
              <el-input
                v-model="prodName"
                clearable
                placeholder="请输入商品名称"
              />
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="5">
          <el-row>
            <el-col
              :span="8"
              class="search-name"
            >
              SKUID
            </el-col>
            <el-col :span="16">
              <el-input
                v-model="skuId"
                clearable
                placeholder="请输入商品SKUID"
              />
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="5">
          <el-row>
            <el-col
              :span="8"
              class="search-name"
            >
              商品分类
            </el-col>
            <el-col :span="16">
              <el-select
                v-model="poolId"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="(item,index) in poolNumbers"
                  :key="item.id+index"
                  :span="12"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="9">
          <el-row>
            <el-col :span="10">
              <el-button
                class="qxs-btn-sm"
                @click="clear"
              >
                清空
              </el-button>
              <el-button
                type="primary"
                class="qxs-btn-sm"
                icon="el-icon-search"
                @click="onSearch(1)"
              >
                搜索
              </el-button>
            </el-col>
            <el-col :span="14">
              <el-button
                type="primary"
                class="qxs-btn-sm"
                @click="onOpenSelect"
              >
                {{ isShowSelectDol ? '取消批量操作' : '批量操作' }}
              </el-button>
              <el-button
                v-show="isShowSelectDol"
                type="primary"
                plain
                class="qxs-btn-sm"
                @click="onOpenActionDol"
              >
                批量上架
              </el-button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div
      ref="table"
      class="prod-panel"
    >
      <el-table
        ref="multipleTable"
        v-loading="loading"
        :data="prodPool"
        border
        style="width: 100%;"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          v-if="isShowSelectDol"
          type="selection"
          width="55"
        />
        <el-table-column
          prop="skuId"
          label="SKUID"
          min-width="8%"
        />
        <el-table-column
          prop="imageUrl"
          label="商品图片"
          min-width="8%"
        >
          <template slot-scope="scope">
            <el-image
              style="width: 100px; height: 100px;"
              :src="scope.row.imageUrl | imageUrlFilter"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="spuId"
          label="商品编号"
          min-width="8%"
        />
        <el-table-column
          prop="brandName"
          label="品牌名称"
          min-width="6%"
        />
        <el-table-column
          prop="name"
          label="名称"
          min-width="20%"
        />
        <el-table-column
          prop="firstCid"
          label="分类"
          min-width="6%"
        />
        <el-table-column
          prop="isSelf"
          label="京东自营"
          min-width="6%"
        />
        <el-table-column
          label="参考售价"
          min-width="6%"
        >
          <template slot-scope="scope">
            {{ toYuan(scope.row.formalPrice) }}
          </template>
        </el-table-column>
        <el-table-column
          label="原价"
          min-width="6%"
        >
          <template slot-scope="scope">
            {{ toYuan(scope.row.marketPrice) }}
          </template>
        </el-table-column>
        <el-table-column
          label="采购价"
          min-width="6%"
        >
          <template slot-scope="scope">
            {{ toYuan(scope.row.price) }}
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          prop="profit"
          label="利润空间"
          min-width="6%"
        />
        <el-table-column
          fixed="right"
          prop="zip"
          label="操作"
          min-width="10%"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click.native.prevent="checkDetail(scope.row)"
            >
              添加到商品库
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pageination"
        :current-page="currentPage"
        :page-size="currentPageSize"
        :page-sizes="[20, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="poolTotalCount"
        @current-change="handleCurrentChange"
        @size-change="handleCurrentSizeChange"
      />
    </div>
    <el-dialog
      title="批量上架-商品策略"
      :visible.sync="isOpenDialog"
      :before-close="dialogBeforeClose"
      center
    >
      <el-form :model="uploadForm">
        <el-form-item label="售价策略：">
          <el-radio-group v-model="uploadForm.percentageType" @input="onChangeRadio">
            <el-radio label="percent">百分比</el-radio>
            <el-radio label="int">单价</el-radio>
          </el-radio-group>

          <div v-if="uploadForm.percentageType === 'percent'" style="padding-left: 82px;">
            <span>售价=采购价x(100%+</span>
            <el-input-number
              v-model="uploadForm.inputVal"
              :min="0"
              :step="1"
              size="small"
              label="label"
            />
            <span>%)</span>
            <span style="padding-left: 30px;">备注：若有小数，向上取整</span>
          </div>
          <div v-else style="padding-left: 82px;">
            <span>售价=采购价+</span>
            <el-input-number
              v-model="uploadForm.inputVal"
              :min="0"
              :step="1"
              :precision="0"
              size="small"
              label="label"
            />
            <span style="padding-left: 30px;">备注：只能输入正整数</span>
          </div>
        </el-form-item>
        <el-form-item label="关联服务政策：">
          <el-select v-model="uploadForm.servicePolicyId" placeholder="请选择服务政策">
            <el-option
              v-for="item in servicePolicy"
              :key="item.id"
              :span="12"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="商品分类：">
          <el-cascader
            v-model="uploadForm.commoditySelect"
            :props="commodityProps"
            clearable
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="isOpenDialog = false">取 消</el-button>
        <el-button type="primary" :loading="isLoading" @click="onUploadAll">批量上架</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue';
import { mapActions, mapState } from 'vuex'
import { fenToYuan } from '@/utils/util'

export default {
  name: 'ProdPool',
  components: {},
  filters: {
    imageUrlFilter(url) {
      let index = url.indexOf('http')
      if (index > -1) {
        return url
      }
      return `https://img30.360buyimg.com/sku/${url}`
    }
  },
  data() {
    return {
      loading: true,
      total: 0,
      activeIndex: '0',
      imgUrl: 'https://img30.360buyimg.com/sku/g7/M03/08/0C/rBEHZVBziAMIAAAAAABUEjw0EYQAABo1gF1VYEAAFQq465.jpg',
      name: '商品池',
      prodName: '',
      skuId: '',
      currentPage: 1,
      currentPageSize: 20,
      poolId: null,
      brandName: '',
      minChannelPrice: '',
      maxChannelPrice: '',
      minFormalPrice: '',
      maxFormalPrice: '',
      goodsSource: null,
      goodsSourceList: [
        {
          name: '鲜通自营',
          id: 0
        },
        {
          name: '鲜通京东自营',
          id: 1
        },
        {
          name: '跨境海淘',
          id: 2
        },
        {
          name: '本来生活',
          id: 4
        },
        {
          name: '虚拟卡券',
          id: 5
        },
        {
          name: '京东自营',
          id: 6
        },
        {
          name: '天猫自营',
          id: 8
        }
      ],
      isShowSelectDol: false,
      isOpenDialog: false,
      isLoading: false,
      selectList: [],
      uploadForm: {
        servicePolicyId: '',
        commoditySelect: [],
        percentageType: 'percent',
        inputVal: 0
      },
      commodityProps: {
        lazy: true,
        lazyLoad: (node, resolve) => {
          const { level, value = '888888' } = node
          this.onGetCommodityList(level + 1, value).then(res => {
            resolve(res)
          }, rea => console.log(rea))
        }
      }
    }
  },
  computed: {
    ...mapState(['poolNumbers', 'prodPool', 'poolIndex', 'poolTotalCount', 'servicePolicy'])
  },
  mounted() {
    this.$store.dispatch('changeTitle', this.name)
    this.onGetPoolNumbers()
    this.onSearch(1)
    this.supplierChange()
  },
  updated() {
    window.scrollTo(0, 0)
  },
  methods: {
    ...mapActions(['getPoolNumbers']),
    onUploadAll() {
      if (!this.selectList.length) { return this.$message.error('请先选择上架的商品') }
      this.isLoading = true
      const data = {
        codeList: this.selectList.map(item => item.skuId),
        servicePolicyId: this.uploadForm.servicePolicyId,
        supplierType: this.activeIndex,
        dicCommodityType: this.uploadForm.commoditySelect[0],
        secondDicCommodityType: this.uploadForm.commoditySelect[1],
        percentage: this.uploadForm.percentageType === 'percent',
        priceIncrementCents: this.uploadForm.percentageType === 'percent' ? 0 : this.uploadForm.inputVal,
        priceIncrementPercent: this.uploadForm.percentageType === 'percent' ? this.uploadForm.inputVal : 0
      }
      this.$axios.post(this.$API.batchAddProduct, data).then(() => {
        this.isOpenDialog = false
        this.isLoading = false
        this.onSearch(1)
      }, rea => {
        this.isLoading = false
        this.$message.error(rea.message)
      })
    },
    handleSelectionChange(val) {
      console.log(val, '====== handleSelectionChange ========')
      this.selectList = val
    },
    supplierChange() {
      this.$store.dispatch('getServicePolicy', { supplier: this.activeIndex })
    },
    onGetCommodityList(level = 0, parentId = '888888') {
      const params = {level, parentId}
      return this.$axios.get(this.$API.getCommodityType, { params }).then(res => {
        const nodes = (res || []).map(item => ({
          value: item.id,
          label: item.name,
          leaf: level >= 2
        }))
        return nodes
      })
    },
    onOpenSelect() {
      this.isShowSelectDol = !this.isShowSelectDol
      this.selectList = []
    },
    onOpenActionDol() {
      console.log('批量上传')
      if (!this.selectList.length) { return this.$message.error('请先选择上架的商品') }
      this.isOpenDialog = true
      this.uploadForm = {
        servicePolicyId: '',
        commoditySelect: [],
        percentageType: 'percent',
        inputVal: 0
      }
    },
    dialogBeforeClose(hide) {
      hide(true)
    },
    onChangeRadio() {
      this.uploadForm.inputVal = 0
    },
    handleSelect(value) {
      this.activeIndex = value
      this.clear()
      this.onGetPoolNumbers()
      this.onSearch(1)
      this.supplierChange()
    },
    onGetPoolNumbers() {
      this.getPoolNumbers({supplierType: this.activeIndex})
    },
    checkDetail(row) {
      console.log('SKUID:', row)
      this.$router.push({ name: 'prodDetail', query: { skuId: row.skuId, supplier: row.supplier } })
    },
    handleCurrentChange(val) {
      this.onSearch(val)
    },
    handleCurrentSizeChange(val) {
      this.onSearch(1, val)
    },
    clear() {
      this.prodName = ''
      this.skuId = ''
      this.poolId = null
      this.brandName = ''
      this.goodsSource = null
      this.minChannelPrice = ''
      this.maxChannelPrice = ''
      this.minFormalPrice = ''
      this.maxFormalPrice = ''
    },
    channelChange() {
      this.minFormalPrice = ''
      this.maxFormalPrice = ''
    },
    formalChange() {
      this.minChannelPrice = ''
      this.maxChannelPrice = ''
    },
    onSearch(index, size = this.currentPageSize) {
      this.loading = true
      this.currentPage = index
      this.currentPageSize = size
      this.selectList = []
      if (this.skuId) {
        this.$store.dispatch('getJdProductPool', {
          sku: this.skuId,
          supplier: this.activeIndex,
          pageNo: 1,
          pageSize: size
        }).then(() => {
          this.loading = false
        })
      } else {
        console.log(this.goodsSource, '-----------------------')
        this.$store.dispatch('getJdProductPool', {
          supplier: this.activeIndex,
          poolNumber: this.poolId,
          name: this.prodName,
          brandName: this.brandName,
          productTag: this.goodsSource,
          minChannelPrice: this.minChannelPrice,
          maxChannelPrice: this.maxChannelPrice,
          minFormalPrice: this.minFormalPrice,
          maxFormalPrice: this.maxFormalPrice,
          pageNo: index || 1,
          pageSize: size
        }).then(() => {
          this.loading = false
        })
      }
    },
    toYuan(value) {
      return fenToYuan(value)
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  padding: 20px;
  .search-panel {
    padding: 10px;
    .search-name {
      position: relative;
      top: 10px;
    }
  }
}
.item-title {
  display: inline-block;
}
.el-input__inner {
  display: inline-block;
}
.el-pagination {
  padding-top: 26px;
  text-align: center;
}
.price-name {
  padding: 10px 0 0 20px;
}
</style>
