<template>
  <el-container class="content">
    <el-main>
      <el-button
        type="text"
        class="footer-button"
        @click="showPriceList = true"
      >
        课程采购和定价参考标准
      </el-button>
      <div class="clear" />
      <div class="flex">
        <div class="grow-shrink0">
          课程标题：
        </div>
        <el-input
          v-model="course.courseName"
          maxlength="40"
          show-word-limit
          placeholder="请输入课程标题"
        />
      </div>
      <div class="flex">
        <div class="grow-shrink0">
          课程简介：
        </div>
        <el-input
          v-model="course.courseIntroduction"
          type="textarea"
          :rows="3"
          maxlength="270"
          show-word-limit
          placeholder="请输入课程简介"
        />
      </div>
      <div class="flex">
        <div class="grow-shrink0">
          课程封面：
        </div>
        <div class="w100v flex cover">
          <el-upload
            :action="uploadApi"
            :headers="headers"
            name="image"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :on-success="handleAvatarSuccess"
            list-type="picture-card"
            accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
          >
            <img
              v-if="course.courseUrl"
              :src="course.courseUrl"
              style="width: 100%; height: 100%; border-radius: 6px;"
            >
            <div
              v-else
              slot="trigger"
              class="wrapper"
            >
              <div>
                <img
                  src="../../assets/carmer.jpg"
                  alt=""
                  class="image-w"
                >
              </div>
              <div>上传封面</div>
            </div>
          </el-upload>
          <div class="tips">
            备注：课程封面可添加课程主题/主讲人等信息，建议尺寸750*420
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="grow-shrink0 rich-text">
          课程详情：
          <editor
            :content="course.richTextOssUrl"
            classify="115"
            @update="upDataContent($event)"
          />
        </div>
      </div>
      <div class="flex">
        <div
          v-if="course.knowledgeDoctorVO"
          class="grow-shrink0"
        >
          课程讲者：
          {{ course.knowledgeDoctorVO.doctorName }}
          {{ course.knowledgeDoctorVO.department }}
          {{ course.knowledgeDoctorVO.jobTitle }}
          {{ course.knowledgeDoctorVO.hospital }}
        </div>
      </div>
      <div class="flex">
        <div class="grow-shrink0">
          课程标签：
        </div>
        <el-tag
          v-for="tag in selectedTagList"
          :key="tag.tagId"
          closable
          :disable-transitions="false"
          class="tag-list"
          @close="handleClose(tag)"
        >
          {{ tag.tagName }}
        </el-tag>
        <el-button
          class="button-new-tag"
          size="small"
          @click="showTagList"
        >
          + 添加新标签
        </el-button>
      </div>
      <div class="flex">
        <div class="grow-shrink0">
          可见范围：
        </div>
        <el-radio
          v-model="course.limitedStatus"
          :label="1"
        >
          限定课
        </el-radio>
        <el-radio
          v-model="course.limitedStatus"
          :label="0"
        >
          公开课
        </el-radio>
      </div>
      <div class="flex">
        <div class="grow-shrink0">
          上传视频：
        </div>
        <div>
          <el-button
            class="cursor"
            @click="onOpenVideoPop(false)"
          >
            本地上传
          </el-button>
          <span class="tips">备注：建议每个视频不超过30分钟（最大600Mb），较长的视频建议剪切为多段上传，每段视频为本课程的一个小节。</span>
        </div>
      </div>
      <div ref="table">
        <el-table
          ref="multipleTable"
          :data="course.courseChapterVOS"
          border
          style="width: 100%;"
        >
          <el-table-column
            prop="index"
            label="视频"
          >
            <template slot-scope="scope">
              第{{ `${scope.$index + 1}` }}节
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            prop="chapterName"
            label="视频名称"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            prop="videoDuration"
            label="视频时长"
          >
            <template slot-scope="scoped">
              {{ scoped.row.videoDuration | changeTime }}
            </template>
          </el-table-column>
          <el-table-column
            prop="chapterIndex"
            label="排序"
            align="center"
          >
            <template slot-scope="scope">
              <div>
                <el-button
                  v-if="scope.$index===0"
                  class="mgn-r10"
                  type="text"
                  disabled
                >
                  上移
                </el-button>
                <el-button
                  v-else
                  type="text"
                  @click="onUp(scope)"
                >
                  上移
                </el-button>
                <el-button
                  v-if="scope.$index===(course.courseChapterVOS.length-1)"
                  type="text"
                  disabled
                >
                  下移
                </el-button>
                <el-button
                  v-else
                  type="text"
                  @click="onDown(scope)"
                >
                  下移
                </el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop=""
            align="center"
            label="操作"
          >
            <template slot-scope="scope">
              <div>
                <el-button
                  class="mgn-r10"
                  type="text"
                  @click="onOpenShowVideoModal(scope.row.videoId)"
                >
                  预览
                </el-button>
                <el-button
                  type="text"
                  @click="onDownloadVideo(scope.row.videoId)"
                >
                  下载
                </el-button>
              </div>
              <div>
                <el-button
                  class="mgn-r10"
                  type="text"
                  @click="onOpenVideoPop(true,scope)"
                >
                  编辑
                </el-button>
                <el-button
                  type="text"
                  @click="onDelVideo(scope)"
                >
                  删除
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-main>
    <div
      v-show="isShowToast"
      class="toast-box"
      :class="{'errorBg':isErrorBg}"
    >
      {{ toastMessage }}
    </div>
    <el-footer>
      <FooterWrapper class="footer">
        <span v-if="course.purchaseStatus === 0">待平台采购</span>
        <span v-if="course.purchaseStatus === 1">平台已采购</span>
        <span v-if="course.purchaseStatus === -1">已拒绝采购</span>
        <span v-if="course.upShelfStatus === 0">未上架</span>
        <span v-if="course.upShelfStatus === 1">已上架</span>
        <span
          v-if="course.operateRecommendStatus === 0 && course.upShelfStatus === 1"
          :disable="course.limitedStatus === 1"
          style="color: #008075; cursor: pointer;"
          @click="setHotCourse('1')"
        >设置为热门课程</span>
        <span
          v-if="course.operateRecommendStatus === 1"
          style="color: #008075; cursor: pointer;"
          @click="setHotCourse('0')"
        >移除热门课程</span>
        <span v-if="course.purchaseStatus === 1">版权采购价：{{ course.purchaseAmount }}</span>
        <span v-if="course.purchaseStatus === 0">版权采购价：<el-input
          v-model="course.purchaseAmount"
          type="number"
          :min="0"
          class="footer-input"
        /></span>
        <span>课程定价：<el-input
          v-model="course.salePrice"
          type="number"
          :min="0"
          class="footer-input"
        /></span>
        <span>课程权重：<el-input
          v-model="course.weight"
          type="number"
          :min="0"
          class="footer-input"
        /></span>
        <span>分成比例：5/5</span>
        <el-button @click="goBack">
          返回
        </el-button>
        <el-button
          type="primary"
          @click="saveCourse"
        >
          保存
        </el-button>
        <el-button
          v-if="course.upShelfStatus === 1"
          type="primary"
          @click="onUpdateCourseState(0)"
        >
          下架
        </el-button>
        <el-button
          v-if="course.upShelfStatus === 0 && course.purchaseStatus === 1"
          type="primary"
          @click="onUpdateCourseState(1)"
        >
          上架
        </el-button>
        <el-button
          v-if="course.purchaseStatus == 0"
          type="primary"
          @click="inputRefuseReason = true"
        >
          拒绝采购
        </el-button>
        <el-button
          v-if="course.purchaseStatus === 0"
          type="primary"
          @click="rejectCourse(1)"
        >
          确认采购
        </el-button>
      </FooterWrapper>
    </el-footer>
    <VideoUpload
      :visible="isShowVideoPop"
      :is-disable-upload="isDisableUpload"
      :init-video-obj="videoObj"
      :show-cover="false"
      @onUpload="onSubmitMaterialVideo"
      @onClose="onClosePop"
    />
    <el-dialog
      title="课程采购和定价参考标准"
      :visible.sync="showPriceList"
      width="50%"
      center
    >
      <span>请根据课程内容质量和学术价值进行专业评定后确定采购价格和销售价格，最终价格必须在下表范围内：</span>
      <el-table :data="gridData">
        <el-table-column
          property="title"
          label="讲师职级"
        />
        <el-table-column
          property="requirement"
          label="要求"
          min-width="150"
          show-overflow-tooltip
        />
        <el-table-column
          property="buyPrice"
          label="采购价格区间"
        />
        <el-table-column
          property="sellPrice"
          label="课程定价区间"
        />
        <el-table-column
          property="reward"
          label="课程售出后可以获得的奖励比例"
        />
      </el-table>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="showPriceList = false">关 闭</el-button>
      </span>
    </el-dialog>
    <select-tag
      :show-add-t-ag="showAddTAg"
      :all-tag-list="allTagList"
      @onClose="onAddTag"
    />
    <el-dialog
      :visible="inputRefuseReason"
      title="拒绝原因"
      :show-close="false"
      center
      width="30%"
    >
      <el-row>
        <el-input v-model="rejectReason" />
      </el-row>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="inputRefuseReason = false">关 闭</el-button>
        <el-button
          type="primary"
          @click="rejectCourse(-1)"
        >保 存</el-button>
      </span>
    </el-dialog>
    <VideoPlayModal
      title="预览视频"
      :is-down="false"
      :visible="showPreviewModal"
      :video-url="videoUrl"
      @onClose="onClosePop"
    />
  </el-container>
</template>

<script>
import Editor from '@/components/Editor.vue'
import VideoUpload from '@/components/Course/VideoUpload.vue'
import VideoPlayModal from '@/components/Course/VideoPlayModal.vue'
import FooterWrapper from '@/components/common/footerWrapper.vue'
import { saveAs } from 'file-saver'
import { changeTime } from '@/utils/util'
import API from '@/apis/api-types'
import { mapActions } from 'vuex'
import SelectTag from '@/components/common/SelectTag'

export default {
  name: 'SpeakerCourseDetail',
  filters: {
    changeTime(num) {
      return changeTime(num)
    }
  },
  components: {
    SelectTag,
    VideoUpload,
    VideoPlayModal,
    FooterWrapper,
    Editor
  },
  data() {
    return {
      course: {},
      headers: {},
      isShowVideoPop: false,
      isDisableUpload: false,
      videoObj: {
        title: '',
        videoImgUrl: '',
        coverUrl: ''
      },
      courseIdArr: [],
      timerId: null,
      isShowToast: false,
      isSaveConfirm: false,
      toastMessage: '',
      isErrorBg: false,
      timeout: null,
      radio: 1,
      showPriceList: false,
      showPreviewModal: false,
      videoUrl: '',
      inputVisible: false,
      allTagList: [],
      tagList: [],
      showAddTAg: false,
      inputRefuseReason: false,
      rejectReason: '',
      gridData: [
        {
          title: '主任/副主任',
          requirement: '20min < 课程所有视频时长 < 50min',
          buyPrice: '5000~8000',
          sellPrice: '50~100',
          reward: '50%'
        }, {
          title: '主任/副主任',
          requirement: '课程所有视频时长 > 50min',
          buyPrice: '8000~10000',
          sellPrice: '100~200',
          reward: '50%'
        }, {
          title: '组委',
          requirement: '20min < 课程所有视频时长 < 50min',
          buyPrice: '8000~10000',
          sellPrice: '150~200',
          reward: '50%'
        }, {
          title: '组委',
          requirement: '课程所有视频时长 > 50min',
          buyPrice: '10000~15000',
          sellPrice: '150~300',
          reward: '50%'
        }, {
          title: '院士',
          requirement: '20min < 课程所有视频时长 < 50min',
          buyPrice: '15000~30000',
          sellPrice: '150~400',
          reward: '50%'
        }, {
          title: '院士',
          requirement: '课程所有视频时长 > 50min',
          buyPrice: '20000~50000',
          sellPrice: '150~500',
          reward: '50%'
        }]
    }
  },
  computed: {
    uploadApi() {
      return `${API.uploadImg}?bucketType=104`
    },
    selectedTagList() {
      return [...this.allTagList].filter(v => v.selected)
    }
  },
  watch: {
    changeSaveData(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isSaveConfirm = false
      } else {
        this.isSaveConfirm = true
      }
    }
  },
  mounted() {
    this.onInitTrainData()
  },
  methods: {
    ...mapActions(['speakerCourseDetail', 'updateHotCourse', 'onGetTrainVideoDetail', 'addCourseTagList', 'getCourseTagList', 'getAllTagList', 'saveSpeakerCourseDetail', 'updateCourseState', 'refuseCourse']),
    onInitTrainData() {
      this.speakerCourseDetail({ courseId: this.$route.params.courseId }).then(res => {
        this.course = res || {}
        this.course.tagList = (this.course.tagList || []).map(v => {
          v = { ...v, disabled: true }
          return v
        })
        this.course.courseUrl = res.courseUrl.middle
        this.onCourseIdArr()
        this.getTagList(res.tagList || [])
      }, rea => {
        this.$message({
          message: rea.message,
          type: 'error'
        })
      })
    },
    getTagList(selectedList) {
      this.getAllTagList().then(res => {
        this.allTagList = (res || []).map(v => {
          selectedList.map(i => {
            if (v.tagId === i.tagId) {
              v = { ...v, disabled: true, selected: true }
            }
          })
          return v
        })
      })
    },
    showTagList() {
      this.showAddTAg = true
    },
    onAddTag(val) {
      this.showAddTAg = false
      this.allTagList = val
    },
    upDataContent(data) {
      this.course.richTextOssUrl = data
    },
    handleClose(tag) {
      if (tag.disabled) return this.$message.error('课程详情原有标签不允许删除')
      this.allTagList = this.allTagList.map(i => {
        if (i.tagId === tag.tagId) {
          i.selected = false
        }
        return i
      })
    },
    showInput() {
      this.inputVisible = true
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    handleInputConfirm() {
      const tagName = this.tagName
      if (tagName) {
        this.deleteTagList({ tagName: this.tagName }).then(() => {
          this.updateTagList()
        })
      }
      this.inputVisible = false
      this.tagName = ''
    },
    beforeAvatarUpload(file) {
      const _this = this
      const isSize = new Promise(function(resolve, reject) {
        const width = 16 // 限制图片尺寸为16:9
        const height = 9
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.onload = function() {
          const valid = (img.width / img.height).toFixed(2) === (width / height).toFixed(2)
          valid ? resolve() : reject()
        }
        img.src = _URL.createObjectURL(file)
      }).then(() => {
        return file
      }, () => {
        _this.$message.warning('建议课程封面图片尺寸16:9')
        return Promise.resolve()
      })
      return isSize
    },
    handleAvatarSuccess(res, file) {
      this.course.courseUrl = URL.createObjectURL(file.raw)
      this.$set(this.course, 'coverUrl', res.data.imageName)
    },
    beforeAvatarDetailUpload(file) {
      const _this = this
      const isSize = new Promise(function(resolve, reject) {
        const width = 750 // 限制图片尺寸为16:9
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.onload = function() {
          const valid = img.width.toFixed(2) === width.toFixed(2)
          valid ? resolve() : reject()
        }
        img.src = _URL.createObjectURL(file)
      }).then(() => {
        return file
      }, () => {
        _this.$message.warning('建议课程详情图宽度为750px')
        return Promise.resolve()
      })
      return isSize
    },
    onOpenShowVideoModal(videoId) {
      this.onGetTrainVideoDetail({ videoId: videoId }).then(res => {
        const resData = res.playUrl || []
        if (!resData.length > 0) {
          this.$message.error('暂无视频资源')
        }
        for (let item of resData) {
          if (item.indexOf('.mp4') > 0) {
            this.videoUrl = item
            this.showPreviewModal = true
            return false
          }
          this.videoUrl = ''
        }
        this.$message.error('暂无视频资源')
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    onDownloadVideo(videoId) {
      this.onGetTrainVideoDetail({ videoId: videoId }).then(res => {
        const resData = res.playUrl || []
        if (!resData.length > 0) {
          this.$message.error('暂无视频资源')
        }
        for (const item of resData) {
          if (item.indexOf('.mp4') > 0) {
            this.videoUrl = item
            saveAs(this.videoUrl)
            return false
          }
          this.videoUrl = ''
        }
        this.$message.error('暂无视频资源')
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    // 上传视频
    onOpenVideoPop(isDisable, scope) {
      if (isDisable) {
        this.videoObj = Object.assign({}, scope.row)
        this.isDisableUpload = true
      } else {
        this.videoObj = {}
        this.isDisableUpload = false
      }
      this.isShowVideoPop = true
    },
    onSubmitMaterialVideo(options) {
      options.map(it => {
        const obj = {}
        obj.chapterName = it.title
        obj.videoDuration = it.duration
        obj.videoId = it.videoId
        if (!this.courseIdArr.includes(it.videoId)) {
          this.course.courseChapterVOS.push(obj)
        } else {
          this.$set(this.course.courseChapterVOS, this.courseIdArr.indexOf(it.videoId), obj)
        }
      })
      this.onCourseIdArr()
    },
    onClosePop() {
      this.isShowVideoPop = false
      this.showPreviewModal = false
    },
    onCourseIdArr() {
      const arr = []
      for (const item of this.course.courseChapterVOS) {
        arr.push(item.videoId)
      }
      this.courseIdArr = arr
    },
    goBack() {
      this.$confirm('返回将不会保存当前页面的修改，确定继续吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$router.go(-1)
        })
        .catch(() => {
        })
    },
    onClearTimeout() {
      this.timerId && clearTimeout(this.timerId)
      this.timerId = setTimeout(() => {
        this.isShowToast = false
      }, 3000)
    },
    isPositiveInteger(num) {
      return /^[0-9]\d*$/.test(num)
    },
    onCheckData() {
      this.onClearTimeout()
      if (!this.course.courseName) {
        this.isShowToast = true
        this.toastMessage = '请填写课程标题'
        this.isErrorBg = true
        return false
      } else if (!this.course.courseIntroduction) {
        this.isShowToast = true
        this.toastMessage = '请填写课程简介'
        this.isErrorBg = true
        return false
      } else if (!this.course.courseUrl) {
        this.isShowToast = true
        this.toastMessage = '请上传课程封面'
        this.isErrorBg = true
        return false
      } else if (!this.course.salePrice && this.course.salePrice !== 0) {
        this.isShowToast = true
        this.toastMessage = '请填写课程定价'
        this.isErrorBg = true
        return false
      } else if (!this.course.weight && this.course.weight !== 0) {
        this.isShowToast = true
        this.toastMessage = '请填写课程权重'
        this.isErrorBg = true
        return false
      } else if (!this.isPositiveInteger(this.course.salePrice)) {
        this.isShowToast = true
        this.toastMessage = '课程定价需为正整数'
        this.isErrorBg = true
        return false
      } else if (this.course.weight < 0) {
        this.isShowToast = true
        this.toastMessage = '课程权重需为正数'
        this.isErrorBg = true
        return false
      } else if (this.course.purchaseStatus === 0 && !this.isPositiveInteger(this.course.purchaseAmount)) {
        this.isShowToast = true
        this.toastMessage = '版权采购价需为大于等于0的整数'
        this.isErrorBg = true
        return false
      }
      if (!(this.course.courseChapterVOS.length > 0)) {
        this.isShowToast = true
        this.toastMessage = '请上传视频'
        this.isErrorBg = true
        return false
      }
      return true
    },
    saveCourse() {
      const isSubmit = this.onCheckData()
      if (!isSubmit) return false
      let tagIdList = []
      tagIdList = this.selectedTagList.map(v => v.tagId)
      const courseChapterBOS = []
      this.course.courseChapterVOS.map((item, index) => {
        courseChapterBOS.push({
          chapterId: item.chapterId,
          chapterName: item.chapterName,
          videoId: item.videoId,
          chapterIndex: index
        })
      })
      const req = { ...this.course, tagIdList: tagIdList, courseChapterBOS: courseChapterBOS }
      delete req.chapterNum
      delete req.courseChapterVOS
      delete req.courseUrl
      this.saveSpeakerCourseDetail(req).then(() => {
        this.isErrorBg = false
        this.isSaveConfirm = false
        this.$message.success('保存成功')
        this.onInitTrainData()
      }, rea => {
        this.onClearTimeout()
        this.toastMessage = rea.message
        this.isSaveConfirm = false
      })
    },
    onUpdateCourseState(type) {
      const Data = {
        courseId: this.$route.params.courseId,
        type: type
      }
      if (type === 1) {
        this.$confirm('确定上架该课程吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.updateCourseState(Data).then(() => {
              this.$message.success('上架成功')
              this.course.upShelfStatus = 1
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消上架'
            })
          })
      } else if (type === 0) {
        this.$confirm('确定下架该课程吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.updateCourseState(Data).then(() => {
              this.$message.success('下架成功')
              this.course.upShelfStatus = 0
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消下架'
            })
          })
      }
    },
    rejectCourse(type) {
      if (type === 1) {
        const isSubmit = this.onCheckData()
        if (!isSubmit) return false
      }
      if (type === -1 && !this.rejectReason) {
        return this.$message.error('请输入拒绝原因')
      }
      // if (type === 1 && !this.course.purchaseAmount) {
      //     return this.$message.error('请输入版权采购价')
      // }
      // if (type === 1 && !this.course.salePrice) {
      //     return this.$message.error('请输入课程定价')
      // }
      const req = {
        courseId: this.$route.params.courseId,
        type: type,
        rejectReason: this.rejectReason,
        purchaseAmount: this.course.purchaseAmount,
        salePrice: this.course.salePrice
      }
      this.refuseCourse(req).then(() => {
        if (type === -1) {
          this.$message.success('拒绝采购成功')
          this.course.purchaseStatus = -1
        } else {
          this.$message.success('确认采购成功')
          this.course.purchaseStatus = 1
        }
        this.inputRefuseReason = false
      })
    },
    onUp(options) {
      this.course.courseChapterVOS.splice(options.$index, 1)
      this.course.courseChapterVOS.splice(options.$index - 1, 0, options.row)
      this.onCourseIdArr()
    },
    onDown(options) {
      this.course.courseChapterVOS.splice(options.$index, 1)
      this.course.courseChapterVOS.splice(options.$index + 1, 0, options.row)
      this.onCourseIdArr()
    },
    onDelVideo(scope) {
      this.course.courseChapterVOS.splice(scope.$index, 1)
      this.onCourseIdArr()
    },
    setHotCourse(type) {
      if (this.course.limitedStatus === 1) {
        return this.$message.error('限定课不能设置为热门课程')
      }
      this.updateHotCourse({ courseId: this.$route.params.courseId, type: type }).then(() => {
        if (type === '1') {
          this.$message.success('设置热门成功')
          this.course.operateRecommendStatus = 1
        } else {
          this.$message.success('移除热门成功')
          this.course.operateRecommendStatus = 0
        }
      })
    }
  }
}
</script>

<style>
.detail-img .el-upload--picture-card {
  width: 100px;
  height: 100px;
}
.cover .el-upload--picture-card {
  width: 160px;
  height: 90px;
}
</style>
<style scoped>
.wrapper {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  font-size: 12px;
  line-height: 1;
  color: #008075;
}
.video-cover {
  width: 107px;
  height: 60px;
  background: #d8d8d8;
  margin-right: 20px;
  text-align: center;
}
.grow-shrink0 {
  flex-grow: 0;
  flex-shrink: 0;
}
.content {
  padding: 0 15px;
}
.flex {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.tips {
  color: #c8c9ce;
  margin-left: 10px;
  font-size: 14px;
}
.cursor {
  margin: 0 10px;
}
.input {
  width: 193px;
  margin-right: 10px;
}
.toast-box {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 100px;
  color: #fff;
  background: #6db61e;
  z-index: 10000;
}
.errorBg {
  background: #d8384a;
}
.footer {
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: space-between;
}
.footer span {
  margin-right: 10px;
}
.footer-button {
  float: right;
}
.clear {
  clear: both;
}
.footer-input {
  width: 80px;
}
.rich-text {
  z-index: 0;
}
.rich-text :deep(.w-e-text-container) {
  height: 500px !important;
  max-width: 900px;
}
.tag-list {
  margin-right: 10px;
}
.el-checkbox {
  margin-bottom: 10px;
}

</style>
