<template>
  <div style="margin-bottom: 100px;">
    <el-tabs
      v-model="activeName"
      type="card"
    >
      <el-tab-pane
        :label="labelName"
        name="activeName"
      />
    </el-tabs>
    <el-form
      ref="formData"
      :model="formData"
      :rules="rules"
      label-width="120px"
      :class="{ mgnB200: isMgn, mgnB30: !isMgn }"
      style="width: 500px;"
    >
      <el-form-item
        label="标题"
        prop="title"
      >
        <el-input
          v-model="formData.title"
          placeholder="请输入标题"
        />
      </el-form-item>
      <el-form-item
        label="分类"
        prop="classifyArr"
      >
        <el-cascader
          v-model="formData.classifyArr"
          :options="classifyList"
          @change="onCascaderChange"
          @visible-change="onVisibleChange"
        />
      </el-form-item>
      <el-form-item
        label="权重"
        prop="position"
      >
        <el-input
          v-model="formData.position"
          type="number"
          min="0"
        />
      </el-form-item>
      <el-form-item
        label="显示端"
        prop="showPort"
      >
        <el-checkbox-group v-model="formData.showPort">
          <el-checkbox label="直播助手" />
          <el-checkbox label="厂家端" />
          <el-checkbox label="代表端" />
          <el-checkbox label="医生端" />
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <editor
      :content="initCopyWriting"
      classify="115"
      @update="upDataContent($event)"
    />
    <div class="button-box">
      <el-button @click="onClear">
        取消
      </el-button>
      <el-button
        type="primary"
        @click="onSubmit('formData')"
      >
        保存
      </el-button>
    </div>
  </div>
</template>

<script>
import Editor from '@/components/Editor.vue'
import { mapActions, mapGetters } from 'vuex'
import { getShowAtStr, getShowAtNum } from '../utils/util'

export default {
  name: 'ProblemEditor',
  components: {
    Editor
  },
  data() {
    return {
      activeName: 'activeName',
      labelName: '编辑文档',
      tagger: true,
      // ------------------- formData ---------------------
      formLabelWidth: '120px',
      formData: {
        id: '',
        title: '',
        classifyArr: [],
        position: '',
        showPort: []
      },
      isMgn: false,
      rules: {
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        classifyArr: [{ required: true, message: '请选择分类', trigger: 'blur' }],
        position: [{ required: true, message: '请输入权重', trigger: 'blur' }],
        showPort: [
          {
            required: true,
            message: '请选择显示端',
            trigger: 'blur'
          }
        ]
      },
      // ------------------- editor ---------------
      initCopyWriting: ''
    }
  },
  computed: {
    ...mapGetters(['categoryList', 'articleList', 'categoryId', 'secCategoryId']),
    classifyList() {
      let newList = []
      if (this.categoryList && this.categoryList.length > 0) {
        newList = this.categoryList.map(item => {
          let childrenList = []
          if (item.childes) {
            childrenList = item.childes.map(it => {
              return {
                value: it.id,
                label: it.name
              }
            })
          }
          return childrenList.length > 0
            ? {
              value: item.id,
              label: item.name,
              children: childrenList
            }
            : {
              value: item.id,
              label: item.name
            }
        })
      }
      return [].concat(newList)
    }
  },
  mounted() {
    console.log(this.$route.params)
    this.initPageData()
  },
  methods: {
    ...mapActions(['getArticleDetail', 'getCategoryList', 'getArticleSave']),
    initPageData() {
      this.getCategoryList()
      this.onGetArticleDetail()
    },
    onGetArticleDetail() {
      if (this.$route.params.id === 'new') {
        this.labelName = '新建文档'
        this.formData = {
          id: '',
          title: '',
          classifyArr: [],
          position: '',
          showPort: [],
          categoryId: '',
          secCategoryId: ''
        }
        this.initCopyWriting = ''
        this.$set(this.formData, 'content', '')
        return false
      }
      this.labelName = '编辑文档'
      const Data = { articleId: this.$route.params.id }
      this.getArticleDetail(Data).then(res => {
        const sources = getShowAtStr(res.showAt)
        this.formData = {
          id: res.id,
          title: res.title || '',
          classifyArr: res.categoryId && res.secCategoryId
            ? [`${res.categoryId  }`, `${res.secCategoryId  }`]
            : [`${res.categoryId  }`],
          position: res.position,
          showPort: sources.split('、'),
          categoryId: res.categoryId,
          secCategoryId: res.secCategoryId
        }
        this.initCopyWriting = res.content
        this.$set(this.formData, 'content', res.content)
      })
    },
    onCascaderChange(options) {
      this.$set(this.formData, 'categoryId', '')
      this.$set(this.formData, 'secCategoryId', '')
      if (options.length === 1 && options[0] !== 'all') {
        this.$set(this.formData, 'categoryId', options[0])
      } else if (options.length === 2) {
        this.$set(this.formData, 'categoryId', options[0])
        this.$set(this.formData, 'secCategoryId', options[1])
      }
    },
    onVisibleChange() {
      this.isMgn = !this.isMgn
    },
    upDataContent(data) {
      console.log(data)
      this.$set(this.formData, 'content', data)
    },
    onClear() {
      this.$router.go(-1)
    },
    onSubmit(formName) {
      console.log(this.formData, '--------- 发布 -------------')

      this.formData = Object.assign({}, this.formData, {
        showAt: getShowAtNum(this.formData.showPort)
      })
      this.$refs[formName].validate(valid => {
        if (valid) {
          const { id, title, content, showAt, position, categoryId, secCategoryId } = this.formData
          const Data = {
            id,
            title,
            content,
            showAt,
            position,
            categoryId,
            secCategoryId
          }
          if (content === '<p><br></p>' || !content) {
            this.$message({ message: '请输入文章内容', type: 'fail' })
            return false
          }
          if (!this.tagger) {
            return false
          }
          this.tagger = false
          this.getArticleSave(Data)
            .then(res => {
              this.onClear()
              this.tagger = true
              if (res) {

                this.$message({ message: '操作成功', type: 'success' })
              }
            })
            .catch(() => (this.tagger = true))
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style scoped>
.mgnB30 {
  margin-bottom: 30px;
}
.mgnB200 {
  margin-bottom: 200px;
}
.mgnB300 {
  margin-bottom: 300px;
}
.button-box {
  width: 100%;
  background: #fff;
  padding: 30px;
  text-align: center;
  position: fixed;
  bottom: 0;
  z-index: 999999999;
}
</style>
