import request from '@/utils/request'
import API from './api-types'

export function getLiveTable(data) {
  return request({
    url: API.getLiveTable,
    method: 'get',
    params: data
  })
}
export function getThirdSpeakerList(data) {
  return request({
    url: API.getThirdSpeakerList,
    method: 'get',
    params: data
  })
}

export function getLiveDetail(data) {
  return request({
    url: API.getLiveDetail,
    method: 'get',
    params: data
  })
}

export function reviewDeal(data) {
  return request({
    url: API.reviewDeal,
    method: 'get',
    params: data
  })
}

export function dealWorkOrder(data) {
  return request({
    url: API.dealWorkOrder,
    method: 'post',
    params: data
  })
}
