import request from '@/utils/request'
import API from './api-types'
// 获取商品池列表
export default function confirmJdOrder({ orderId }) {
  return request({
    url: API.confirmJdOrder,
    method: 'get',
    params: {
      orderId
    }
  })
}
