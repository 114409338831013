
import mixinWindowResize from './windowResize'
import * as echarts from 'echarts'
export default {
  props: {
    chartData: {
      type: Object,
      default: Object
    },
    form: {
      type: Object,
      default: Object
    }
  },
  mixins: [mixinWindowResize],
  watch: {
    chartData: {
      handler: function(newVal) {
        if (!newVal.data.length) {
          this.myCharts && this.myCharts.clear()
          this.setEmpty()
        } else {
          this.initChart(this.chartOptions, { notMerge: true, lazyUpdate: true })
        }
      },
      deep: true,
      immediate: true
    },
    chartOptions: {
      handler: function(newVal) {
        if (newVal.length) {
          this.myCharts && this.myCharts.clear()
          this.setEmpty()
        } else {
          this.initChart(newVal, { notMerge: true, lazyUpdate: true })
        }
      },
      deep: true
    },
    mouseInChart(newVal) {
      // 判断 newVal 是否是 boolean 类型
      if (typeof newVal !== 'boolean') return
      this.toggleToolbox(newVal)
    }
  },
  data() {
    return {
      initChartSuccess: false,
      mouseInChart: false
    }
  },
  async mounted() {
    this.mountedBefore && await this.mountedBefore()

    this.myCharts = this.chartDOM && echarts.init(this.chartDOM)
    this.initAfter && await this.initAfter()
    this.initChartSuccess = true

    if (this.chartData.data.length) {
      this.initChart(this.chartOptions)
    } else {
      this.setEmpty()
    }

    // 当鼠标移动到图表上时，显示工具栏, 做好防抖处理
    this.myCharts && this.myCharts.getZr().on('mousemove', () => {
      if (this.mouseInChart) return
      this.mouseInChart = true
    })
    this.myCharts && this.myCharts.getZr().on('mouseout', () => {
      if (!this.mouseInChart) return
      this.mouseInChart = false
    })
  },
  methods: {
    initChart(...opt) {
      if (!this.myCharts || !this.initChartSuccess) return

      this.myCharts.showLoading()
      try {
        this.myCharts.setOption(...opt)
      } catch (error) {
        if (!this.myCharts.isDisposed()) {
          this.myCharts.dispose()
        }
        this.myCharts = echarts.init(this.chartDOM)
        this.setEmpty('配置项错误')
      } finally {
        this.myCharts.hideLoading()
      }
    },
    setEmpty(subtext = '暂无数据') {
      const obj = {
        title: [{
          text: this.chartData?.modelName,
          ...this.chartOptions.title
        }, {
          subtext,
          x: 'center',
          y: 'center',
          text: ' {a|}',
          itemGap: -20,
          textStyle: {
            align: 'center',
            rich: {
              a: {
                color: '#000',
                fontSize: '16',
                height: 80,
                width: 160,
                backgroundColor: {
                  image: this.emptyImg
                }
              }
            }
          },
          subtextStyle: {
            fontSize: 16
          }
        }]
      }
      this.initChart(obj, {
        notMerge: true,
        replaceMerge: ['xAxis', 'yAxis', 'series'],
        lazyUpdate: false
      })
    },
    // 切换图表toolbox 显示隐藏
    toggleToolbox(show) {
      this.myCharts.setOption({
        toolbox: {
          show
        }
      })
    }
  },
  beforeDestroy() {
    // 移除监听
    this.myCharts.getZr().off('mousemove')
    this.myCharts.getZr().off('mouseout')
    if (this.myCharts && !this.myCharts.isDisposed()) {
      this.myCharts.dispose()
    }
  }
}
