import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import FaceReview from './face-review'
import DoctorDetail from './doctor-detail'
import LiveAudit from './live-audit'
import CourseManagement from './course-management'
import SpeakerManagement from './speaker-management'
import programManagementModules from './modules/programManagementModules'
import HospitalStore from './modules/hospitalStore'
import keepAlive from './modules/keepAlive'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    title: '首页',
    loading: true, // 是否正在加载  是：true
    doctor: 1, // 医生端二进制
    representative: null, // 代表端二进制
    manufacturer: null, // 厂家端二进制
    speakerTerminal: null, // 讲者端二进制
    currentPage: 1, // table 的当前页数
    pageSize: 2, // table 的单页条数
    total: 0, // table 的总条数
    defaultPaginationData: {
      // 分页器的默认值---即初始值
      currentPage: 1, // table 的当前页数
      pageSize: 2, // table 的单页条数
      total: 0 // table 的总条数
    },
    feedbackList: [], // 平台反馈列表
    categoryList: [], // 文章管理分类列表
    articleList: [], // 获取趣学术文章列表
    guideDetailList: [], // 获取新手引导详情
    showAt: null, // 选择显示端
    categoryId: '', // 选择问题分类，一级分类ID
    secCategoryId: '', // 选择问题分类，二级分类ID
    status: '', // 选择状态
    searchKey: '', // 输入框搜索
    detailObj: {}, // 详情对象
    poolNumbers: [],
    commodityType: [],
    prodPool: [],
    prodRelated: [],
    poolTotalCount: 0,
    prodList: [],
    poolIndex: 1,
    prodTotalCount: 0,
    servicePolicy: [],
    prodListIndex: 1,
    prodDetail: {}, // 商品池商品详情
    productDetail: {}, // 商品库商品详情
    orderList: [], // 订单相关
    messageSearchList: [], // 消息系统
    orderTotalCount: 0,
    orderListIndex: 1,
    orderDetail: {},
    afterSaleOrderList: [], // 售后单详情
    afterSaleOrderTotalCount: 0,
    afterSaleOrderIndex: 1,
    afterSaleOrderDetail: {},
    defaultOrderStatus: [
      {
        id: null,
        name: '全部'
      }
    ],
    orderStatus: [
      {
        id: 0,
        name: '已下单，未付款'
      },
      {
        id: 1,
        name: '已付款'
      },
      {
        id: 2,
        name: '已下单，待发货'
      },
      {
        id: 3,
        name: '已发货，待收货'
      },
      {
        id: 4,
        name: '已完成'
      },
      {
        id: 5,
        name: '已关闭'
      }, {
        id: 6,
        name: '部分退款'
      }
    ],
    afterSaleOrderStatus: [
      {
        id: 0,
        name: '待审核'
      },
      {
        id: 1,
        name: '审核未通过'
      },
      {
        id: 2,
        name: '审核通过，待寄件'
      },
      {
        id: 3,
        name: '审核通过，待取件'
      },
      {
        id: 4,
        name: '售后处理中'
      },
      {
        id: 5,
        name: '售后已回寄'
      },
      {
        id: 6,
        name: '售后已完成'
      },
      {
        id: 7,
        name: '已取消'
      }
    ],
    serviceType: {
      '10': '退货',
      '20': '换货',
      '30': '维修'
    },
    reasonType: {
      '0': '质量问题',
      '1': '商品和描述不一致',
      '2': '规格选错',
      '3': '地址信息填写错误',
      '4': '7天无理由',
      '5': '其他原因'
    },
    packageType: {
      '0': '无包装',
      '10': '包装完好',
      '20': '包装破损'
    },
    supplierList: [
      {
        id: 0,
        name: '京东'
      },
      {
        id: 1,
        name: '一只猫'
      },
      {
        id: 2,
        name: '红包话费'
      },
      {
        id: 8,
        name: '鲜通'
      },
      {
        id: 10,
        name: '十鹿'
      }
    ]
  },
  getters,
  mutations,
  actions,
  modules: {
    FaceReview,
    DoctorDetail,
    CourseManagement,
    SpeakerManagement,
    LiveAudit,
    ProgramManagementModules: programManagementModules,
    HospitalStore: HospitalStore,
    keepAlive
  }
  // strict: true
})
