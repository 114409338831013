import request from '@/utils/request'
import API from './api-types'

export function knowledgeIndexData(data) {
  return request({
    url: API.knowledgeIndexData,
    method: 'get',
    params: data
  })
}
export function funBeanPutList(data) {
  return request({
    url: API.funBeanPutList,
    method: 'get',
    params: data
  })
}
export function funBeanRule(data) {
  return request({
    url: API.funBeanRule,
    method: 'get',
    params: data
  })
}
export function updateFunBeanRule(data) {
  return request({
    url: API.updateFunBeanRule,
    method: 'post',
    data
  })
}
export function addPutFunBean(data) {
  return request({
    url: API.addPutFunBean,
    method: 'post',
    params: data
  })
}
export function addPutTargetedFunBean(data) {
  return request({
    url: API.addPutTargetedFunBean,
    method: 'post',
    data
  })
}
export function getSpeakerList(data) {
  return request({
    url: API.getSpeakerList,
    method: 'get',
    params: data
  })
}
export function getSpeakerDetail(data) {
  return request({
    url: API.getSpeakerDetail,
    method: 'get',
    params: data
  })
}
export function getSpeakerCourseList(data) {
  return request({
    url: API.getSpeakerCourseList,
    method: 'get',
    params: data
  })
}
export function getAllSpeakerCourseList(data) {
  return request({
    url: API.getAllSpeakerCourseList,
    method: 'get',
    params: data
  })
}
export function speakerCourseDetail(data) {
  return request({
    url: API.speakerCourseDetail,
    method: 'get',
    params: data
  })
}
export function getTagList(data) {
  return request({
    url: API.getTagList,
    method: 'get',
    params: data
  })
}
export function getCourseTagList(data) {
  return request({
    url: API.getCourseTagList,
    method: 'get',
    params: data
  })
}
export function hotTagList(data) {
  return request({
    url: API.hotTagList,
    method: 'get',
    params: data
  })
}
export function getAllTagList(data) {
  return request({
    url: API.getAllTagList,
    method: 'get',
    params: data
  })
}
export function downloadDoctorList(data) {
  return request({
    url: API.downloadDoctorList,
    method: 'get',
    params: data
  })
}
export function deleteSpeakerCourse(data) {
  return request({
    url: API.deleteSpeakerCourse,
    method: 'post',
    data
  })
}
export function updateIntroOrKpi(data) {
  return request({
    url: API.updateIntroOrKpi,
    method: 'post',
    data
  })
}
export function updateCourseState(data) {
  return request({
    url: API.updateCourseState,
    method: 'post',
    data
  })
}
export function refuseCourse(data) {
  return request({
    url: API.refuseCourse,
    method: 'post',
    data
  })
}
export function updateHotCourse(data) {
  return request({
    url: API.updateHotCourse,
    method: 'post',
    data
  })
}
export function deleteTagList(data) {
  return request({
    url: API.deleteTagList,
    method: 'post',
    params: data
  })
}
export function addTagList(data) {
  return request({
    url: API.addTagList,
    method: 'post',
    params: data
  })
}
export function saveSpeakerCourseDetail(data) {
  return request({
    url: API.saveSpeakerCourseDetail,
    method: 'post',
    data
  })
}
export function checkDoctorList(data) {
  return request({
    url: API.checkDoctorList,
    method: 'post',
    data
  })
}
export function addNewDoctor(data) {
  return request({
    url: API.addNewDoctor,
    method: 'post',
    data
  })
}
export function addCourseTagList(data) {
  return request({
    url: API.addCourseTagList,
    method: 'post',
    data
  })
}
export function updateHotTag(data) {
  return request({
    url: API.updateHotTag,
    method: 'post',
    data
  })
}
