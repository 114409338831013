<template>
  <div class="question-pop-box font-s14">
    <el-dialog
      :visible.sync="visible"
      :close-on-click-modal="false"
      :show-close="false"
      title="新建题目"
    >
      <div class="content">
        <template v-for="(item,index) in questionList">
          <div
            :key="index"
            class="mgn-b20 border-blue"
            :class="{'border-red':item.isComplete}"
          >
            <div class="flex-between pdg-lr30 question-name-box">
              <div class="title">
                {{ item.indexName }}
              </div>
              <div class="flex-start">
                <div
                  style="text-align: center;"
                  @click="onOpenList(item,index)"
                >
                  <img
                    v-if="!item.isOpen"
                    src="../../assets/icon-blod-down.png"
                    alt=""
                  >
                  <img
                    v-else
                    src="../../assets/icon-blod-up.png"
                    alt=""
                  >
                </div>
                <div
                  style="text-align: center; margin: 10px 10px 0;"
                  @click="onDelQuestion(item,index)"
                >
                  <img
                    src="../../assets/icon-del-white.png"
                    alt=""
                  >
                </div>
              </div>
            </div>
            <div
              v-show="item.isOpen"
              class="content"
            >
              <el-input
                v-model="item.title"
                type="textarea"
                placeholder="请输入题目内容"
              />
              <div class="mgn-t20">
                <el-radio
                  v-model="item.answerType"
                  label="single"
                >
                  单选
                </el-radio>
                <el-radio
                  v-model="item.answerType"
                  label="multiple"
                >
                  多选
                </el-radio>
              </div>
              <template v-for="(it,ind) in item.answers">
                <div
                  :key="ind"
                  class="flex-start mgn-t20"
                >
                  <div class="mgn-r10">
                    {{ abcList[ind] }}
                  </div>
                  <div style="width: 400px;">
                    <el-input
                      v-model="it.title"
                      type="text"
                      placeholder="请输入内容"
                      maxlength="200"
                      show-word-limit
                    />
                  </div>
                  <div
                    class="col198cff answer-operation"
                    :class="{'answer-surce':it.isCorrect}"
                    @click="onSetAnswer(item,it)"
                  >
                    {{ it.isCorrect ? '正确答案' : '设为答案' }}
                  </div>
                  <div>
                    <img
                      v-show="ind===item.answers.length-1"
                      class="mgn-r10"
                      src="../../assets/add.png"
                      @click="onAddAnswer(item,ind)"
                    >
                    <img
                      v-show="item.answers.length>1"
                      src="../../assets/del.png"
                      @click="onDelAnswer(item,ind)"
                    >
                  </div>
                </div>
              </template>
              <div class="mgn-t20 flex-between">
                <el-input
                  v-model="item.analysis"
                  type="textarea"
                  placeholder="请输入题目解析"
                />
              </div>
            </div>
          </div>
        </template>
        <div
          class="add-question cursor mgn-t30"
          @click="onAddQuestion"
        >
          <el-button
            class="font-s20 colc8c9ce"
            type="text"
          >
            +添加题目
          </el-button>
        </div>
        <div class="template">
          你可以
          <el-button type="text">
            <a
              href="./exam_excel.xlsx"
              download="题目模版"
            >点击这里下载
            </a>
          </el-button>
          模版，填写完成后，
          <el-upload
            class="upload-demo"
            action=""
            :limit="10"
            :multiple="false"
            :on-exceed="handleExceed"
            :before-upload="beforeUpload"
            :show-file-list="false"
            :file-list="fileList"
          >
            <el-button type="text">
              点击这里上传
            </el-button>
          </el-upload>
          批量导入题目
        </div>
      </div>
      <div
        slot="footer"
        class="flex-center mgn-t20"
      >
        <el-button @click="onClose">
          取消
        </el-button>
        <el-button
          :loading="btnLoading"
          class="w140"
          type="primary"
          @click="onSubmit"
        >
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import XLSX from 'xlsx'

export default {
  name: 'MaterialVideo',
  components: {},
  props: {
    visible: {       // 是否显示，默认true
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // -------------------- submit ----------------
      btnLoading: false,
      // -------------------- questionList --------------
      examClassifyList: [],
      abcList: ['A.', 'B.', 'C.', 'D.', 'E.', 'F.', 'G.', 'H.', 'I.', 'J.', 'K.', 'L.', 'M.', 'N.'],
      itemObj: {
        indexName: '题目1',
        isOpen: true,
        title: '',
        answerType: '',
        type: 0,
        answers: [
          {
            title: '',
            isCorrect: false
          }
        ],
        analysis: ''
      },
      answerItemObj: {
        title: '',
        isCorrect: false
      },
      initQuestionList: [
        {
          indexName: '题目1',
          isOpen: true,
          title: '',
          answerType: '',
          type: 0,
          answers: [
            {
              title: '',
              isCorrect: false
            }
          ],
          analysis: ''
        }
      ],
      questionList: [],
      addClassify: '',
      fileList: []
    }
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.questionList = JSON.parse(JSON.stringify(this.initQuestionList))
      }
    }
  },
  methods: {
    ...mapActions(['onTrainCreateExam']),
    onClose() {
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('onClose', false)
    },
    onSubmit() {
      console.log(this.questionList, '------------------- onSubmit -------------------------')
      const isSubmit = this.onSurceQuestion()
      if (!isSubmit) return false
      const list = []
      for (const item of this.questionList) {
        list.push({
          title: item.title,
          type: 0,
          answerType: item.answerType,        // multiple:多选；single：单选
          answers: item.answers,
          analysis: item.analysis
        })
      }
      this.onTrainCreateExam(list).then(res => {
        // eslint-disable-next-line vue/custom-event-name-casing
        this.$emit('onSubmit', res || [])
        this.$message({
          message: '题目已添加成功',
          type: 'success',
          duration: 2000
        })
        this.onClose()
      })
    },
    onAddQuestion() {
      const isAddQuestion = this.onSurceQuestion()
      if (!isAddQuestion) {
        return false
      }
      const obj = JSON.parse(JSON.stringify(this.itemObj))
      this.questionList.push(obj)
      this.questionList.map((item, index) => {
        this.$set(item, 'indexName', `题目${  index + 1}`)
      })
    },
    onSurceQuestion() {
      for (const Item of this.questionList) {
        if (!(Item.title && Item.answerType)) {
          this.$set(Item, 'isComplete', true)
          this.$message({
            message: '请将题目填写完整！',
            type: 'warning',
            duration: 2000
          })
          return false
        }
        if (Item.answers.length < 2) {
          this.$message({
            message: '请添加至少两个选择！',
            type: 'warning',
            duration: 2000
          })
          return false
        }
        let isSetAnswer = false
        let answerNum = 0
        for (const item of Item.answers) {
          if (!item.title) {
            this.$set(Item, 'isComplete', true)
            this.$message({
              message: '请将题目答案填写完整！',
              type: 'warning',
              duration: 2000
            })
            return false
          }
          if (item.isCorrect) {
            isSetAnswer = true
            answerNum += 1
          }
        }
        if (!isSetAnswer) {
          this.$set(Item, 'isComplete', true)
          this.$message({
            message: '请设置题目答案！',
            type: 'warning',
            duration: 2000
          })
          return false
        }
        if (Item.answerType === 'multiple' && answerNum < 2) {
          this.$message({
            message: '请设置至少两个答案！',
            type: 'warning',
            duration: 2000
          })
          return false
        }
        this.$set(Item, 'isComplete', false)
      }
      return true
    },
    onDelQuestion(item, index) {
      this.questionList.splice(index, 1)
      this.questionList.map((item, index) => {
        this.$set(item, 'indexName', `问题${  index + 1}`)
      })
    },
    onOpenList(item) {
      this.$set(item, 'isOpen', !item.isOpen)
    },
    onAddAnswer(item) {
      const obj = JSON.parse(JSON.stringify(this.answerItemObj))
      const list = item.answers
      list.push(obj)
      this.$set(item, 'answers', list)
    },
    onDelAnswer(item, ind) {
      const list = item.answers
      list.splice(ind, 1)
    },
    onSetAnswer(item, it) {
      if (item.answerType === 'single') {
        for (const It of item.answers) {
          this.$set(It, 'isCorrect', false)
        }
        this.$set(it, 'isCorrect', !it.isCorrect)
      } else if (item.answerType === 'multiple') {
        this.$set(it, 'isCorrect', !it.isCorrect)
      } else {
        this.$message({
          message: '请先选择单选或多选！',
          type: 'warning',
          duration: 2000
        })
      }
    },
    handleExceed() {
      this.$message.warning(
        '只支持上传 1 个文件'
      )
    },
    beforeUpload(file) {
      // const self = this
      // 用FileReader来读取
      const fileReader = new FileReader()
      fileReader.onload = ev => {
        try {
          const data = ev.target.result
          const workbook = XLSX.read(data, {
            type: 'binary'
          }) // 以二进制流方式读取得到整份excel表格对象
          let persons = [] // 存储获取到的数据
          // 表格的表格范围，可用于判断表头是否数量是否正确
          let fromTo = ''
          // 遍历每张表读取
          for (const sheet in workbook.Sheets) {
            // eslint-disable-next-line no-prototype-builtins
            if (workbook.Sheets.hasOwnProperty(sheet)) {
              fromTo = workbook.Sheets[sheet]['!ref']
              console.log(fromTo)
              persons = persons.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]))
              break // 如果只取第一张表，就取消注释这行
            }
          }
          this.questionList = persons.map(item => {
            const newData = {
              title: (item['题目'] || '').toString(),
              answerType: (item['类型'] || '').toString() === '多选' ? 'multiple' : 'single',
              answers: [],
              analysis: (item['题目解析'] || '').toString(),
              isOpen: true
            }
            Object.keys(item).map(i => {
              i = i || ''
              if (i.indexOf('选项') > -1 && item[i]) {
                const isCorrect = (item['正确答案'] || '').indexOf(i) > -1
                const answers = newData.answers || []
                newData.answers = [...answers, { title: `${item[i]  }`, isCorrect: isCorrect }]
              }
            })
            return newData
          })
        } catch (e) {
          console.log(e)
        }
      }
      // 以二进制方式打开文件
      fileReader.readAsBinaryString(file)
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.question-pop-box .el-dialog {
  width: 850px;
}
.question-pop-box .el-dialog__body {
  padding: 0;
}
</style>
<style scoped>
.add-question {
  height: 80px;
  line-height: 80px;
  text-align: center;
  border-radius: 4px;
  background: #f6f7fb;
  border: 1px dashed #008075;
}
.question-name-box {
  height: 60px;
  line-height: 60px;
  background: #008075;

  /* background: #E7FCE6; */
}
.answer-operation {
  width: 80px;
  height: 26px;
  line-height: 26px;
  border-radius: 13px;
  text-align: center;
  margin: 0 20px;
}
.answer-surce {
  background: #008075;
  color: #fff;
}
.border-blue {
  border: 1px solid #008075;
  border-radius: 4px;
  margin-bottom: 20px;
}
.border-red {
  border: 1px solid #f6625e;
}
.content {
  padding: 20px 30px;
}
.title {
  font-size: 18px;
  font-weight: bold;
  padding-left: 30px;
  color: white;
}
.flex-between {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.template {
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: center;
}
</style>
