<template>
  <div class="home">
    <div class="search-panel">
      <el-row :gutter="24">
        <el-col :span="6">
          <el-row>
            <el-col
              :span="8"
              class="search-name"
            >
              供应商
            </el-col>
            <el-col :span="16">
              <el-select
                v-model="supplierId"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in supplier"
                  :key="item.id"
                  :span="12"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-col>
          </el-row>
          <el-row>
            <el-col
              :span="8"
              class="search-name"
            >
              原始订单号
            </el-col>
            <el-col :span="15">
              <el-input
                v-model="orderId"
                placeholder="请输入原始订单号"
              />
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="6">
          <el-row>
            <el-col
              :span="8"
              class="search-name"
            >
              售后单状态
            </el-col>
            <el-col :span="16">
              <el-select
                v-model="afterSaleOrderStatusId"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in afterSaleOrderStatus"
                  :key="item.id"
                  :span="12"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="7">
          <el-row>
            <el-date-picker
              v-model="timeRange"
              type="datetimerange"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right"
            />
          </el-row>
        </el-col>
        <el-col :span="5">
          <el-row class="clean-btn">
            <el-button
              class="qxs-btn-md"
              @click="clear"
            >
              清空
            </el-button>
          </el-row>
          <el-row class="clean-btn">
            <el-button
              type="primary"
              class="qxs-btn-md"
              icon="el-icon-search"
              @click="search(1)"
            >
              搜索
            </el-button>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div
      ref="table"
      class="prod-panel"
    >
      <el-table
        ref="multipleTable"
        v-loading="loading"
        :data="afterSaleOrderList"
        border
        style="width: 100%;"
      >
        <el-table-column
          prop="createTime"
          label="创建时间"
          min-width="8%"
        />
        <el-table-column
          prop="id"
          label="售后单号"
          min-width="12%"
        />
        <el-table-column
          prop="reviewStatus"
          label="状态"
          min-width="8%"
        >
          <template
            v-if="afterSaleOrderStatus[scope.row.reviewStatus]"
            slot-scope="scope"
          >
            {{ afterSaleOrderStatus[scope.row.reviewStatus].name }}
          </template>
        </el-table-column>
        <el-table-column
          label="商品"
          min-width="25%"
        >
          <template slot-scope="scope">
            <div
              v-if="scope.row.itemVO"
              class="prod-detail"
            >
              <el-image
                class="prod-img"
                style="width: 20px; height: 20px;"
                :src="scope.row.itemVO.itemImg"
              />
              <span class="prod-name">{{ scope.row.itemVO.itemName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="商品类别"
          min-width="8%"
        >
          <template
            v-if="scope.row.itemVO"
            slot-scope="scope"
          >
            {{ scope.row.itemVO.itemType === 0 ? '实体商品' : '虚拟商品' }}
          </template>
        </el-table-column>
        <el-table-column
          label="供应商"
          min-width="6%"
        >
          <template
            v-if="scope.row.itemVO"
            slot-scope="scope"
          >
            {{ supplierStr(scope.row.supplierType) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="price"
          label="售后类别"
          min-width="6%"
        >
          <template slot-scope="scope">
            {{ serviceType[scope.row.serviceType] }}
          </template>
        </el-table-column>
        <el-table-column
          label="收货人"
          min-width="6%"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.userName }}</p>
            <p>{{ scope.row.phone }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="reviewDesc"
          label="审核内容"
          min-width="6%"
        />
        <el-table-column
          prop="zip"
          label="操作"
          min-width="10%"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click.native.prevent="checkDetail(scope.$index, afterSaleOrderList)"
            >
              编辑/查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pageination"
        :current-page="afterSaleOrderIndex"
        :page-size="20"
        layout="total, prev, pager, next, jumper"
        :total="afterSaleOrderTotalCount"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue';
import { mapState } from 'vuex'
import { fenToYuan } from '@/utils/util'

export default {
  components: {},
  data() {
    return {
      loading: true,
      name: '售后单管理',
      orderId: null,
      supplier: [
        {
          id: -1,
          name: '全部'
        },
        {
          id: 0,
          name: '京东'
        },
        {
          id: 1,
          name: '一只猫'
        },
        {
          id: 8,
          name: '鲜通'
        },
        {
          id: 10,
          name: '十鹿'
        }
      ],
      afterSaleOrderStatusId: null,
      supplierId: null,
      productTypeId: null,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      timeRange: []
    }
  },
  computed: {
    ...mapState(['afterSaleOrderList', 'afterSaleOrderTotalCount', 'afterSaleOrderIndex', 'afterSaleOrderStatus', 'serviceType'])
  },
  mounted() {
    this.$store.dispatch('changeTitle', this.name)
    this.search(1)
  },
  updated() {
    window.scrollTo(0, 0)
  },
  methods: {
    supplierStr(value) {
      let obj = {
        JD: '京东',
        CAT: '一只猫',
        OFFICIAL: '红包话费',
        XT: '鲜通',
        '0': '京东',
        '1': '一只猫',
        '2': '红包话费',
        '8': '鲜通',
        '10': '十鹿'
      }
      return obj[String(value)] || '一只猫'
    },
    fenToYuan(fen) {
      return fenToYuan(fen)
    },
    checkDetail(index, data) {
      console.log(`orderId: ${data[index].orderId}`)
      this.$router.push({ name: 'afterSaleOrderDetail', query: { id: data[index].id }})
    },
    handleCurrentChange(val) {
      this.search(val)
    },
    clear() {
      this.timeRange.splice(0, this.timeRange.length)
      this.orderId = null
      this.afterSaleOrderStatusId = null
      this.supplierId = null
      this.productTypeId = null
    },
    addProd() {
      this.$router.push({ name: 'productDetail', query: { model: 'add' }})
    },
    search(index) {
      console.log('timeRange： ，', this.timeRange)
      console.log('start： ，', this.timeRange[0])
      console.log('end： ，', this.timeRange[1])
      const startTime = this.timeRange[0]
      const endTime = this.timeRange[1]
      this.$store.dispatch('getAfterSaleOrderList', {
        pageIndex: index,
        startTime: startTime ? startTime.getTime() : null,
        endTime: endTime ? endTime.getTime() : null,
        orderId: this.orderId,
        reviewStatus: this.afterSaleOrderStatusId,
        supplierType: this.supplierId
      }).then(() => {
        this.loading = false
      })
    },
    toYuan(value) {
      return fenToYuan(value)
    }
  }
}
</script>
<style lang="scss" scoped>
  .home {
    padding: 20px;
    .search-panel {
      padding: 20px;
      .search-name {
        position: relative;
        top: 10px;
      }
    }
  }
  .prod-detail {
    height: 40px;
    line-height: 40px;
    white-space: nowrap;
    overflow: hidden;
    .prod-name {
      position: relative;
      top: -5px;
    }
  }
  .el-input__inner {
    line-height: 30px;
    display: inline-block;
  }
  .el-row {
    padding-top: 10px;
  }
  .item-title {
    display: inline-block;
  }
  .clean-btn {
    text-align: right;
  }
  .el-pagination {
    padding-top: 26px;
    text-align: center;
  }
</style>
