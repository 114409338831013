import Vue from 'vue'
import ElementUI from 'element-ui'

import './styles/element-variables.scss'

import 'video.js/dist/video-js.css'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from '@/utils/request'
import API from '@/apis/api-types'
import { requireApi } from '@/apis/api-require'

Vue.prototype.$axios = axios
Vue.prototype.$API = API
Vue.prototype.$requireApi = requireApi

// 引入高德地图
import AMapLoader from '@amap/amap-jsapi-loader'
Vue.prototype.$AMap = AMapLoader.load({
  key: '2e820fac2a528b016a4cb95bb463e69e',
  version: '1.4.15',
  plugins: ['AMap.DistrictSearch'],
  AMapUI: {
    version: '1.1',
    plugins: ['geo/DistrictExplorer']
  }
})

// 引入Echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

Vue.prototype.$eventBus = new Vue()

// 引入nanoid
import {
  nanoid
} from 'nanoid'
Vue.prototype.$nanoid = nanoid

import dayjs from 'dayjs'
Vue.prototype.$dayjs = dayjs

import momentjs from 'moment'
Vue.prototype.$moment = momentjs

Vue.config.productionTip = false
// 全局组件自动注册
import '@/components/autoRegister'

// 自动加载 svg 图标
const req = require.context('./assets/icons', false, /\.svg$/)
const requireAll = requireContext => requireContext.keys().map(requireContext)
requireAll(req)

Vue.use(ElementUI)

// 自定义全局指令注册
import directive from '@/utils/directive.js'
Vue.use(directive)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
