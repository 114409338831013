<template>
  <div class="page-box">
    <div class="user-info-box">
      <div class="user-info-item">
        <span>用户姓名：</span>
        <span>{{ detailObj.userName }}</span>
        <template v-if="detailObj.sceneType === 6">
          <span style="margin-left: 50px;">会议名称：{{ detailObj.sceneTitle }}</span>
        </template>
      </div>
      <div class="user-info-item">
        <span>用户手机号：</span>
        <span>{{ detailObj.mobile }}</span>
        <template v-if="detailObj.sceneType === 6">
          <span style="margin-left: 50px;">会议ID：{{ detailObj.sceneId }}</span>
        </template>
      </div>
      <div class="user-info-item">
        <span>反馈端口：</span>
        <span>{{ detailObj.sceneType === 6 ? '直播助手' : detailObj.userTypeStr }}</span>
      </div>
    </div>
    <div>
      <div class="problem-title">
        反馈问题
      </div>
      <div v-if="detailObj.sceneType === 6">
        <h5 class="problem-second-title">音频&视频</h5>
        <el-checkbox
          v-for="(item, index) in audioAndVideoFeedback"
          :key="index"
          :label="item.label"
          :checked="item.checked"
          disabled
        />
        <h5 class="problem-second-title">屏幕分享</h5>
        <el-checkbox
          v-for="(item, index) in shareScreenFeedback"
          :key="index"
          :label="item.label"
          :checked="item.checked"
          disabled
        />
        <h5 class="problem-second-title">其他问题:</h5>
        <span>{{ detailObj.detail.desc }}</span>
      </div>
      <div v-else-if="isShowJsonVo">
        <div v-if="detailVo.keywords" style="margin-top: 10px;">关键字：{{ detailVo.keywords }}</div>
        <div v-if="detailVo.searchType" style="margin-top: 10px;">搜索方式：{{ detailVo.searchType }}</div>
        <template v-for="item in detailVo.contentList||[]">
          <div v-if="detailVo[item].isShow" :key="item" style="margin-top: 10px;">
            <div>
              <span v-if="detailVo[item].isRequired" style="color: #ff4921;">*</span>
              <span>{{ detailVo[item].title }}</span>
              <span v-if="!detailVo[item].isRequired">（选填）</span>
            </div>
            <template v-if="item === 'rating'">
              <div style="margin-top: 10px;">{{ detailVo[item].value }}星</div>
            </template>
            <template v-if="item === 'checkbox'">
              <el-checkbox-group v-model="detailVo[item].value" disabled>
                <template v-for="it in detailVo[item].content">
                  <div :key="it.id" style="margin-top: 10px;">
                    <el-checkbox
                      :label="it.id"
                    >
                      {{ it.title }}
                    </el-checkbox>
                  </div>
                </template>
              </el-checkbox-group>
            </template>
            <template v-if="item === 'textarea'">
              <el-input
                v-model="detailVo[item].value"
                type="textarea"
                maxlength="50"
                placeholder="请输入建议"
                show-word-limit
                disabled
                style="margin-top: 10px;"
              />
            </template>
          </div>
        </template>
      </div>
      <template v-else>
        <div class="problem-message">
          {{ detailObj.content }}
        </div>
        <div class="problem-message">
          <label
            v-for="(item, index) in detailObj.imageUrls"
            :key="index"
          >
            <el-image
              style="width: 100px; height: 100px;"
              :src="item"
              fit="cover"
              :preview-src-list="srcList"
            />
          </label>
        </div>
      </template>
      <div class="remark-box">
        <el-input
          v-model="detailObj.remark"
          type="textarea"
          placeholder="请输入备注"
          @input="onChangeInput"
        />
      </div>
      <div class="button-box">
        <el-button
          class="button-item"
          @click="onClear"
        >
          取消
        </el-button>
        <el-button
          class="button-item"
          @click="onChangeStatus"
        >
          解决
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { getShowAtStr } from '../utils/util'
export default {
  name: 'UserFeedbackDetail',
  filters: {
    getShowAtStr
  },
  data() {
    return {
      srcList: [],
      textareaVal: '',
      audioAndVideoFeedback: [],
      shareScreenFeedback: []
    }
  },
  computed: {
    ...mapGetters(['detailObj']),
    detailVo() {
      try {
        return JSON.parse(this.detailObj.content)
      } catch (e) {
        return {}
      }
    },
    isShowJsonVo() {
      return this.detailVo.feedbackType === 'jsonVo'
    }
  },
  mounted() {
    this.srcList = this.detailObj.imageUrls || []
    this.textareaVal = this.detailObj.remark
    if (this.detailObj.sceneType === 6) {
      const { detail }  = this.detailObj
      this.audioAndVideoFeedback = Object.keys(detail?.webinarAudioAndVideoQuestionnaireBO || {}).map(key => {
        let label = ''
        switch (key) {
          case 'audioStutter':
            label = '音频卡顿'
            break
          case 'echoOrNoise':
            label = '有回声噪音'
            break
          case 'noAudio':
            label = '没有声音'
            break
          case 'noVideo':
            label = '没有视频画面'
            break
          case 'videoStutter':
            label = '视频卡顿'
            break
        }
        return {
          label,
          checked: detail.webinarAudioAndVideoQuestionnaireBO[key]
        }
      })

      this.shareScreenFeedback = Object.keys(detail?.webinarShareQuestionnaireBO || {}).map(key => {
        let label = ''
        switch (key) {
          case 'cantShareScreen':
            label = '无法共享屏幕'
            break
          case 'shareContentInvisible':
            label = '看不到共享内容'
            break
          case 'shareScreenDelay':
            label = '共享内容延迟'
            break
          case 'shareUnexpectedStop':
            label = '共享意外停止'
            break
          case 'videoStutter':
            label = '视频卡顿'
            break
        }
        return {
          label,
          checked: detail.webinarShareQuestionnaireBO[key]
        }
      })
    }
  },
  methods: {
    ...mapActions(['setDetailObj', 'getMarkFixed']),
    onChangeStatus() {
      if (!this.detailObj.remark) {
        this.$message({ message: '请输入备注', type: 'fail' })
        return false
      }
      const Data = {
        feedBackId: this.detailObj.id,
        remark: this.detailObj.remark
      }
      this.getMarkFixed(Data).then(res => {
        if (res) {
          this.$message({ message: '操作成功', type: 'success' })
          this.onClear()
        }
      })
    },
    onClear() {
      this.$router.go(-1)
    },
    onChangeInput(options) {
      this.setDetailObj({ remark: options })
    }
  }
}
</script>

<style scoped>
.user-info-box {
  padding-bottom: 30px;
}
.user-info-item,
.problem-title {
  font-weight: 600;
  line-height: 26px;
}
.problem-second-title {
  color: cornflowerblue;
}
.problem-message {
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
}
.button-box {
  padding-top: 20px;
  text-align: right;
}
.button-item {
  margin: 0 10px;
}
.remark-box {
  margin-top: 30px;
}
</style>
