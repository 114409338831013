import { Message } from 'element-ui'
// dataMaps[key]类型策略
const mapTypes = {
  '[object Function]': (fun, outData) => {
    const val = fun(outData)
    return val
  },
  '[object Object]': (obj, outData, key) => {
    const { name, fun } = obj || {}
    const mapKey = name || key
    const val = fun ? fun(outData[mapKey], outData) : outData[mapKey]
    return val
  },
  '[object String]': (str, outData, key) => {
    const mapKey = str || key
    const val = outData[mapKey]
    return val
  },
  '[object Undefined]': (str, outData, key) => {
    const mapKey = str || key
    const val = outData[mapKey]
    return val
  }
}
// 获取dataMaps获取outData里key对应的值
function getVal(mapVal, outData, key) {
  const type = Object.prototype.toString.call(mapVal)
  const fun = mapTypes[type]
  if (fun) {
    return fun(mapVal, outData, key)
  }
  return null
}

// 根据data和dataMaps解析outData生成新的对象
function dataOperation(data, dataMaps, outData) {
  const obj = {}
  try {
    Object.keys(data).forEach(key => {
      let val = null
      val = getVal(dataMaps[key], outData, key)
      obj[key] = val || data[key]
    })
  } catch {
    Message.error('请检查参数格式是否正确')
  }
  return obj
}
/**
 * 作用：对外接口，对data属性key赋值
 * 参数：
 * options: 接口配置信息{
 *                      data: 类型1对象: {};
 *                      dataMaps: 参数映射
 *                               {
 *                                  data参数key: 外部传入key1,
 *                                  data参数key: {
 *                                    name: 外部传入key1
 *                                    fun: (val:外部传入key1的值,outData:外部传入数据) => { ... return newVal}
 *                                  }
 *                                  data参数key: (outData:外部传入数据) => { ... return newVal}
 *                              }
 *                      ,
 *                    }
 * outData: 外部传入参数对象
 *
 * 返回: 对象
 * */
function formMaps(options, outData) {
  const {
    data,
    dataMaps = {}
  } = options
  return dataOperation(data, dataMaps, outData)
}

export default formMaps
