export default [
  {
    path: '/live-square',
    name: 'LiveSquare',
    component: () => import(/* webpackChunkName: "live-square" */ '@/views/live-square/index.vue'),
    meta: {
      title: '直播广场'
    }
  }
]
