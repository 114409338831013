<template>
  <el-dialog
    :visible="showAddTAg"
    title="选择标签"
    class="tag-dialog"
    :show-close="false"
    destroy-on-close
  >
    <el-row v-if="tagList.length != 0">
      <el-checkbox
        v-for="item in tagList"
        :key="item.tagId"
        v-model="item.selected"
        :value="item.selected"
        :disabled="item.disabled"
      >
        {{ item.tagName }}
      </el-checkbox>
    </el-row>
    <div v-else>
      您还没有课程标签，请前往运营设置页面添加
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="onAddTag">关 闭</el-button>
      <el-button
        type="primary"
        @click="onAddTag"
      >保 存</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'SelectTag',
  props: {
    showAddTAg: {
      type: Boolean,
      default: false
    },
    allTagList: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data() {
    return {
      tagList: [],
      selectedTag: []
    }
  },
  watch: {
    showAddTAg(visible) {
      if (visible) {
        this.tagList = JSON.parse(JSON.stringify(this.allTagList))
      }
    }
  },
  methods: {
    onAddTag() {
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('onClose', this.tagList)
    }
  }
}
</script>
