import * as Type from '@/store/mutations-types'
import * as Service from '@/apis/programManagementService'
import { Message } from 'element-ui'

const state = {
  columnList: []
}

const getters = {
  columnList: state => state.columnList
}

const mutations = {
  [Type.MANAGEMENT_COLUMN_LIST](state, data) {
    state.columnList = data
  }
}

const actions = {
  managementColumnList({ commit }, options) {
    return Service.managementColumnList(options).then(res => {
      commit(Type.MANAGEMENT_COLUMN_LIST, res || [])
      return Promise.resolve(res)
    }).catch(error => {
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })
    })
  },
  modifyColumn(_, options) {
    return Service.modifyColumn(options).then(res => {
      return Promise.resolve(res)
    }).catch(error => {
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })
    })
  },
  upColumn(_, options) {
    return Service.upColumn(options).then(res => {
      return Promise.resolve(res)
    }).catch(error => {
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })
    })
  },
  downColumn(_, options) {
    return Service.downColumn(options).then(res => {
      return Promise.resolve(res)
    }).catch(error => {
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}