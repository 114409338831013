import * as Type from './mutations-types'
import { Message } from 'element-ui'

import getPoolNumbers from '../apis/getPoolNumbers'
import getCommodityType from '../apis/getCommodityType'
import getJdPoolProductDetail from '../apis/getJdPoolProductDetail'
import getProductDetail from '../apis/getProductDetail'
import searchBySkuId from '../apis/searchBySkuId'
import getJdProductPool from '../apis/getJdProductPool'
import getProductList from '../apis/getProductList'
import getServicePolicy from '../apis/getServicePolicy'
import getOrderDetail from '../apis/getOrderDetail'
import getOrderRebate from '../apis/getOrderRebate'
import getOrderList from '../apis/getOrderList'
import {
  getMessageList,
  creatMessage,
  sendMessage,
  breakMessage,
  deleteMessage
} from '../apis/getMessageList'
import getAfterSaleOrderList from '../apis/getAfterSaleOrderList'
import getAfterSaleOrderDetail from '../apis/getAfterSaleOrderDetail'
import {
  categoryList,
  categoryAdd,
  categoryDel,
  articleList,
  articleDetail,
  articleSave,
  articleChangeStatus,
  platformFeedbackList,
  markFixed,
  guideDetail,
  guideSave
} from '../apis/managerClassify'
// import getters from './getters'

const actions = {
  changeTitle({ commit }, value) {
    commit(Type.CHANGE_TITLE, value)
  },
  // 获取京东商品分类
  getPoolNumbers({ commit }, data) {
    return getPoolNumbers(data)
      .then(response => {
        if (response) {
          commit(Type.SET_POOL_NUMBERS, response)
        }
      })
      .catch(error => {
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      })
  },
  // 获取商品分类
  getCommodityType({ commit }) {
    return getCommodityType()
      .then(response => {
        if (response) {
          commit(Type.SET_COMMODITY_TYPE, response)
        }
      })
      .catch(error => {
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      })
  },
  // 获取服务政策列表
  getServicePolicy({ commit }, value) {
    return getServicePolicy(value)
      .then(response => {
        if (response) {
          commit(Type.SET_SERVICE_POLICY, response)
        }
      })
      .catch(error => {
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      })
  },
  // 获取京东商品池商品
  getJdProductPool({ commit }, value) {
    return getJdProductPool(value)
      .then(response => {
        if (response) {
          commit(Type.SET_PROD_POOL, response)
        }
      })
      .catch(error => {
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      })
  },
  // 获取商品列表
  getProductList({ commit }, value) {
    return getProductList(value)
      .then(response => {
        if (response) {
          commit(Type.SET_PROD_LIST, {...response, pageNo: value.pageNo})
        }
      })
      .catch(error => {
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      })
  },
  // 通过SKUID搜索商品池商品
  searchBySkuId({ commit }, value) {
    return searchBySkuId(value)
      .then(response => {
        if (response) {
          commit(Type.SET_PROD_RELATED, response)
        }
      })
      .catch(error => {
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      })
  },
  // 获取京东商品池商品详情
  getJdPoolProductDetail({ commit }, value) {
    return getJdPoolProductDetail(value)
      .then(response => {
        if (response) {
          commit(Type.SET_PROD_DETAIL, response)
        }
        return Promise.resolve(response)
      })
      .catch(error => {
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      })
  },
  // 设置商品主图
  setMainImg({ commit }, value) {
    commit(Type.SET_MAIN_IMG, value)
  },
  // 删除商品图片
  removeImg({ commit }, value) {
    commit(Type.REMOVE_IMG, value)
  },
  // 获取商品池商品详情
  getProductDetail({ commit }, value) {
    return getProductDetail(value)
      .then(response => {
        if (response) {
          commit(Type.SET_PRODUCT_DETAIL, response)
        }
      })
      .catch(error => {
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      })
  },
  // 新增商品
  addNewProduct({ commit }, value) {
    commit(Type.SET_PRODUCT_DETAIL, value)
  },

  // 新增商品图片
  addImages({ commit }, value) {
    commit(Type.ADD_IMG, value)
  },
  // 新增商品主图
  setProductMainImg({ commit }, value) {
    commit(Type.SET_PRODUCT_MAIN_IMG, value)
  },
  // 新增商品主图
  addProductImg({ commit }, value) {
    commit(Type.ADD_PRODUCT_IMG, value)
  },
  // 删除商品图
  removeProductImg({ commit }, value) {
    commit(Type.REMOVE_PRODUCT_IMG, value)
  },
  // 设置SKU图片
  setSkuImg({ commit }, value) {
    commit(Type.SET_PRODUCT_SKU_IMG, value)
  },
  // 升级商品详情html文本
  updateDetailContent({ commit }, value) {
    commit(Type.UPDATE_DETAIL_CONTENT, value)
  },

  // 消息系统
  getMessageList({ commit }, value) {
    return getMessageList(value)
      .then(response => {
        if (response) {
          commit(Type.SET_MESSAGE_LIST, response)
        }
      })
      .catch(error => {
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      })
  },
  creatMessage(_, options) {
    const data = Object.assign({}, options)
    return creatMessage(data)
      .then(res => {
        return res
      })
      .catch(error => {
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      })
  },
  sendMessage(_, options) {
    const data = Object.assign({}, options)
    return sendMessage(data)
      .then(res => {
        return res
      })
      .catch(error => {
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      })
  },
  breakMessage(_, options) {
    const data = Object.assign({}, options)
    return breakMessage(data)
      .then(res => {
        return res
      })
      .catch(error => {
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      })
  },
  deleteMessage(_, options) {
    const data = Object.assign({}, options)
    return deleteMessage(data)
      .then(res => {
        return res
      })
      .catch(error => {
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      })
  },
  // 获取订单列表
  getOrderList({ commit }, value) {
    return getOrderList(value)
      .then(response => {
        if (response) {
          commit(Type.SET_ORDER_LIST, response)
        }
      })
      .catch(error => {
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      })
  },
  getOrderDetail({ commit }, value) {
    return getOrderDetail(value)
      .then(response => {
        if (response) {
          commit(Type.GET_ORDER_DETAIL, response)
        }
      })
      .catch(error => {
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      })
  },
  getOrderRebate(_, value) {
    return getOrderRebate(value)
      .then(res => {
        return Promise.resolve(res)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  // 售后单相关

  // 获取订单列表
  getAfterSaleOrderList({ commit }, value) {
    return getAfterSaleOrderList(value)
      .then(response => {
        if (response) {
          commit(Type.SET_AFTER_SALE_ORDER_LIST, response)
          return Promise.resolve(response)
        }
      })
      .catch(error => {
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      })
  },
  getAfterSaleOrderDetail({ commit }, value) {
    return getAfterSaleOrderDetail(value)
      .then(response => {
        if (response) {
          commit(Type.GET_AFTER_SALE_ORDER_DETAIL, response)
        }
      })
      .catch(error => {
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      })
  },

  // 分页器相关
  setCurrentPage({ commit }, options = 1) {
    commit(Type.SET_CURRENT_PAGE, options)
  },
  setPageSize({ commit }, options = 20) {
    commit(Type.SET_PAGE_SIZE, options)
  },
  setTotal({ commit }, options = 0) {
    commit(Type.SET_TOTAL, options)
  },
  initPagination({ dispatch, state }) {
    dispatch('setCurrentPage', state.defaultPaginationData.currentPage)
    dispatch('setPageSize', state.defaultPaginationData.pageSize)
    dispatch('setTotal', state.defaultPaginationData.total)
  },
  getTestList({ dispatch }, options) {
    dispatch('setTotal', options)
  },
  // 搜索相关
  setShowAt({ commit }, options = null) {
    commit(Type.SET_SHOW_AT, options)
  },
  setCategoryId({ commit }, options = '') {
    commit(Type.SET_CATEGORY_ID, options)
  },
  setSecCategoryId({ commit }, options = '') {
    commit(Type.SET_SECCATEGORY_ID, options)
  },
  setStatus({ commit }, options = '') {
    commit(Type.SET_STATUS, options)
  },
  setSearchKey({ commit }, options = '') {
    commit(Type.SET_SEARCH_KEY, options)
  },
  // 详情相关
  setDetailObj({ getters, commit }, options = {}) {
    const data = Object.assign({}, getters.detailObj, options)
    commit(Type.SET_DETAIL_OBJ, data)
  },
  // 趣学术文章管理分类相关
  getCategoryList({ commit }) {
    // 趣学术文章管理分类列表
    return categoryList()
      .then(res => {
        commit(Type.SET_CATEGORY_LIST, res)
        return Promise.resolve(res)
      })
      .catch(error => {
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      })
  },

  postCategoryAdd(_, options) {
    // 编辑趣学术文章分类
    return categoryAdd(options)
      .then(res => {
        return Promise.resolve(res)
      })
      .catch(error => {
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      })
  },
  getCategoryDel(_, options) {
    // 删除趣学术文章分类
    return categoryDel(options)
      .then(res => {
        return Promise.resolve(res)
      })
      .catch(error => {
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      })
  },
  getArticleList({ commit, dispatch }, options = {}) {
    // 获取趣学术文章列表
    return articleList(options)
      .then(res => {
        commit(Type.SET_ARTICLE_LIST, res.data || [])
        dispatch('setTotal', res.totalCount)
        return Promise.resolve(res)
      })
      .catch(error => {
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      })
  },
  getArticleDetail(_, options = {}) {
    // 获取趣学术文章内容
    const data = Object.assign({}, options)
    return articleDetail(data)
      .then(res => {
        return Promise.resolve(res)
      })
      .catch(error => {
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      })
  },
  getArticleSave(_, options) {
    // POST 保存趣学术文章
    const data = Object.assign({}, options)
    return articleSave(data)
      .then(res => {
        return Promise.resolve(res)
      })
      .catch(error => {
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      })
  },
  getArticleChangeStatus(_, options) {
    // GET 发布|下架趣学术文章
    const data = Object.assign({}, options)
    return articleChangeStatus(data)
      .then(res => {
        return Promise.resolve(res)
      })
      .catch(error => {
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      })
  },
  getPlatformFeedbackList({ commit, dispatch }, options) {
    // GET 获取平台反馈列表
    return platformFeedbackList(options)
      .then(res => {
        commit(Type.SET_FEEDBACK_LIST, res.data || [])
        dispatch('setTotal', res.totalCount)
        return Promise.resolve(res)
      })
      .catch(error => {
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      })
  },
  getMarkFixed({ dispatch }, options) {
    // GET 标记为已解决
    const data = Object.assign({}, options)
    return markFixed(data)
      .then(res => {
        dispatch('setDetailObj', { status: res ? 1 : 0 })
        return Promise.resolve(res)
      })
      .catch(error => {
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      })
  },
  getGuideDetail({ commit }, options) {
    // GET 获取新手引导详情
    return guideDetail(options)
      .then(res => {
        commit(Type.SET_GUIDE_DETAIL_LIST, res ? res.items : [])
        return Promise.resolve(res)
      })
      .catch(error => {
        commit(Type.SET_GUIDE_DETAIL_LIST, [])
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      })
  },
  postGuideSave(_, options) {
    return guideSave(options)
      .then(res => {
        console.log(res)
      })
      .catch(error => {
        Message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      })
  }
}

export default actions
