import request from '@/utils/request'
import API from './api-types'

// 获取商品池列表
export default function searchBySkuId({ skuId, supplier }) {
  console.log('skuId', skuId)
  return request({
    url: API.searchBySkuId,
    method: 'get',
    params: {
      code: skuId,
      supplier
    }
  })
}
