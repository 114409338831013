<template>
  <div class="home">
    <div class="prod-panel">
      <div class="base-detail">
        <el-form
          ref="form"
          label-width="80px"
        >
          <div class="label-tab">
            | 售后单状态
          </div>
          <div v-if="afterSaleOrderDetail.reviewStatus === 1">
            <el-steps
              :active="status"
              :space="400"
            >
              <!--<el-step title="已下单" description="这是一段很长很长很长的描述性文字"></el-step>-->
              <el-step title="待审核" />
              <el-step title="审核未通过" />
            </el-steps>
          </div>
          <div v-else-if="afterSaleOrderDetail.deliverType === 4 && (afterSaleOrderDetail.serviceType === 20 || afterSaleOrderDetail.serviceType === 30)">
            <el-steps :active="status">
              <el-step title="待审核" />
              <el-step title="审核通过，待取件" />
              <el-step title="售后处理中" />
              <el-step title="售后已回寄" />
              <el-step title="售后完成" />
              <el-step
                v-if="afterSaleOrderDetail.reviewStatus === 7"
                title="已取消"
              />
            </el-steps>
          </div>
          <div v-else-if="afterSaleOrderDetail.deliverType === 40 && (afterSaleOrderDetail.serviceType === 20 || afterSaleOrderDetail.serviceType === 30)">
            <el-steps :active="status">
              <el-step title="待审核" />
              <el-step title="审核通过，待寄件" />
              <el-step title="售后处理中" />
              <el-step title="售后已回寄" />
              <el-step title="售后完成" />
              <el-step
                v-if="afterSaleOrderDetail.reviewStatus === 7"
                title="已取消"
              />
            </el-steps>
          </div>
          <div v-else-if="afterSaleOrderDetail.deliverType === 4">
            <el-steps :active="status">
              <el-step title="待审核" />
              <el-step title="审核通过，待取件" />
              <el-step title="售后处理中" />
              <el-step title="售后完成" />
              <el-step
                v-if="afterSaleOrderDetail.reviewStatus === 7"
                title="已取消"
              />
            </el-steps>
          </div>
          <div v-else>
            <el-steps :active="status">
              <el-step title="待审核" />
              <el-step title="审核通过，待寄件" />
              <el-step title="售后处理中" />
              <el-step title="售后完成" />
              <el-step
                v-if="afterSaleOrderDetail.reviewStatus === 7"
                title="已取消"
              />
            </el-steps>
          </div>
          <div class="label-tab">
            | 售后单信息
          </div>
          <el-row>
            <el-col :span="8">
              <el-form-item label="售后单编号">
                {{ afterSaleOrderDetail.id }}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="发起时间">
                {{ afterSaleOrderDetail.createTime }}
              </el-form-item>
            </el-col>
          </el-row>
          <!--<el-row>-->
          <!--<el-col :span="8">-->
          <!--<el-form-item label="客户姓名">-->
          <!--{{ afterSaleOrderDetail.userName }}-->
          <!--</el-form-item>-->
          <!--</el-col>-->
          <!--<el-col :span="8">-->
          <!--<el-form-item label="客户手机">-->
          <!--{{ afterSaleOrderDetail.phone }}-->
          <!--</el-form-item>-->
          <!--</el-col>-->
          <!--</el-row>-->

          <div class="label-tab">
            | 原始订单信息
          </div>
          <el-row>
            <el-col :span="8">
              <el-form-item label="订单号">
                <a
                  class="a-link"
                  @click="goOderDetail"
                >{{ afterSaleOrderDetail.orderId }}</a>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="创建时间">
                {{ afterSaleOrderDetail.orderCreateTime }}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                v-if="[0,8].includes(afterSaleOrderDetail.supplierType)"
                label="第三方订单号"
              >
                {{ afterSaleOrderDetail.outOrderId }}
              </el-form-item>
            </el-col>
          </el-row>

          <div class="label-tab">
            | 供应商信息
          </div>
          <el-row>
            <el-col :span="8">
              <el-form-item label="供应商">
                {{ supplierStr(afterSaleOrderDetail.supplierType) }}
              </el-form-item>
            </el-col>
          </el-row>

          <div class="label-tab">
            | 商品信息
          </div>
          <el-table
            v-if="afterSaleOrderDetail.itemVO"
            ref="multipleTable"
            :data="[afterSaleOrderDetail.itemVO]"
            border
            style="width: 100%;"
          >
            <el-table-column
              prop="skuId"
              label="商品ID"
              min-width="8%"
            />
            <el-table-column
              prop="code"
              label="商品编号"
              min-width="8%"
            />
            <el-table-column
              label="商品图片"
              min-width="12%"
            >
              <template slot-scope="scope">
                <el-image
                  style="width: 100px; height: 100px;"
                  :src="scope.row.itemImg"
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="itemName"
              label="商品名称"
              min-width="20%"
            />
            <el-table-column
              label="商品规格"
              min-width="6%"
            >
              <template slot-scope="scope">
                {{ scope.row.desc }}
              </template>
            </el-table-column>
            <el-table-column
              prop="count"
              label="数量"
              min-width="6%"
            />
            <!--<el-table-column-->
            <!--prop="catName"-->
            <!--label="分类"-->
            <!--min-width="6%">-->
            <!--</el-table-column>-->
            <el-table-column
              label="销售价"
              min-width="6%"
            >
              <template slot-scope="scope">
                {{ fenToYuan(scope.row.price) }}
              </template>
            </el-table-column>
            <el-table-column
              label="采购价"
              min-width="6%"
            >
              <template slot-scope="scope">
                {{ fenToYuan(scope.row.channelPrice) }}
              </template>
            </el-table-column>
            <!--<el-table-column-->
            <!--label="利润"-->
            <!--min-width="6%">-->
            <!--<template slot-scope="scope">-->
            <!--{{  fenToYuan(scope.row.originalPrice) }}-->
            <!--</template>-->
            <!--</el-table-column>-->
            <el-table-column
              prop="itemComponentExportDesc"
              label="售后政策"
              min-width="6%"
            />
            <el-table-column
              prop="remark"
              label="用户备注"
              min-width="6%"
            />
          </el-table>

          <div class="label-tab">
            | 售后信息
          </div>
          <el-row>
            <el-col :span="8">
              <el-form-item label="售后单类型">
                {{ serviceType[afterSaleOrderDetail.serviceType] }}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="售后原因">
                {{ reasonType[afterSaleOrderDetail.reasonType] }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="包装是否完好">
                {{ packageType[afterSaleOrderDetail.packageStatus] }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="问题描述">
                {{ afterSaleOrderDetail.afterSaleDesc || "无" }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="图片描述">
                <div v-if="afterSaleOrderDetail.images && afterSaleOrderDetail.images.length > 0">
                  <el-image
                    v-for="(url,ind) in afterSaleOrderDetail.images"
                    :key="ind"
                    style="width: 100px; height: 100px;"
                    :src="url"
                    :fit="fit"
                  />
                </div>
                <div v-else>
                  无
                </div>
              </el-form-item>
            </el-col>
          </el-row>

          <div class="label-tab">
            | 用户收货地址
          </div>
          <el-row>
            <el-col :span="8">
              <el-form-item label="收货人">
                {{ afterSaleOrderDetail.userName + ": " + afterSaleOrderDetail.phone }}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="收货地址">
                {{ afterSaleOrderDetail.address }}
              </el-form-item>
            </el-col>
          </el-row>

          <div
            v-if="afterSaleOrderDetail.reviewStatus >= 2"
            class="label-tab"
          >
            | 退换货售后地址
          </div>
          <div v-if="afterSaleOrderDetail.returnAddress">
            <el-form-item label="收货人">
              {{ afterSaleOrderDetail.returnName }}
            </el-form-item>
            <el-form-item label="手机号">
              {{ afterSaleOrderDetail.returnPhone }}
            </el-form-item>
            <el-form-item label="地址">
              {{ afterSaleOrderDetail.returnAddress }}
            </el-form-item>
          </div>

          <div
            v-if="afterSaleOrderDetail.reviewStatus === 0 && afterSaleOrderDetail.supplierType !== 0"
            class="label-tab"
          >
            | 退换货售后地址
          </div>
          <div v-if="afterSaleOrderDetail.reviewStatus === 0 && afterSaleOrderDetail.supplierType !== 0">
            <el-form-item label="收货人">
              <el-input
                v-model="returnName"
                class="elm-input"
                placeholder="收货人"
              />
            </el-form-item>
            <el-form-item label="手机号">
              <el-input
                v-model="returnPhone"
                class="elm-input"
                placeholder="手机号"
              />
            </el-form-item>
            <el-form-item label="地址">
              <el-input
                v-model="returnAddress"
                class="elm-input"
                placeholder="地址"
              />
            </el-form-item>
            <el-button
              class="save-deliver-btn"
              @click="verifyAfterSaleOrder(true)"
            >
              审核通过(需填写退换货售后地址)
            </el-button>
          </div>

          <div
            v-if="afterSaleOrderDetail.reviewStatus >= 4"
            class="label-tab"
          >
            | 用户发货单号
          </div>
          <div v-if="afterSaleOrderDetail.reviewStatus >= 4 && afterSaleOrderDetail.returnName">
            <el-form-item label="物流公司">
              {{ afterSaleOrderDetail.deliverName }}
            </el-form-item>
            <el-form-item label="物流单号">
              {{ afterSaleOrderDetail.deliverNo }}
            </el-form-item>
          </div>

          <div v-if="afterSaleOrderDetail.reviewStatus === 0 && afterSaleOrderDetail.supplierType !== 0">
            <div class="label-tab">
              | 售后审批
            </div>
            <div>
              <el-input
                v-model="reviewDesc"
                class="elm-input"
                placeholder="请输入未通过原因"
              />
              <el-button @click="verifyAfterSaleOrder(false)">
                审核不通过(需要填写原因)
              </el-button>
            </div>
          </div>

          <div v-if="afterSaleOrderDetail.reviewStatus === 1">
            <div class="label-tab">
              | 售后审批
            </div>
            <div>
              未通过，原因：{{ afterSaleOrderDetail.reviewDesc }}
            </div>
          </div>
          <FooterWrapper>
            <el-button
              v-if="afterSaleOrderDetail.reviewStatus === 4"
              type="primary"
              @click="deliverBack"
            >
              已寄出
            </el-button>
            <el-button @click="onSubmit">
              返回售后单列表
            </el-button>
          </FooterWrapper>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import FooterWrapper from '@/components/common/footerWrapper.vue'
import { mapState } from 'vuex'
import { fenToYuan } from '@/utils/util'
import deliverBack from '@/apis/deliverBack'
import verifyAfterSaleOrder from '@/apis/verifyAfterSaleOrder'

export default {
  components: {
    FooterWrapper
  },
  data() {
    return {
      id: '',
      name: '售后单详情',
      activeIndex: '1',
      reviewDesc: '',
      returnName: '',
      returnPhone: '',
      returnAddress: '',
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      }
    }
  },
  computed: {
    ...mapState(['afterSaleOrderDetail', 'afterSaleOrderStatus', 'serviceType', 'packageType', 'reasonType']),
    status() {
      if (this.afterSaleOrderDetail.reviewStatus === 1) {
        return 2
      } else if (this.afterSaleOrderDetail.reviewStatus >= 4 && (this.afterSaleOrderDetail.serviceType === 20 || this.afterSaleOrderDetail.serviceType === 30)) {
        return this.afterSaleOrderDetail.reviewStatus - 1
      } else if (this.afterSaleOrderDetail.reviewStatus >= 4) {
        return this.afterSaleOrderDetail.reviewStatus - 2
      } else {
        return this.afterSaleOrderDetail.reviewStatus
      }
    }
  },
  mounted() {
    console.log('this.name ', this.name)
    this.$store.dispatch('changeTitle', this.name)
    this.id = this.$route.query.id
    this.$store.dispatch('getAfterSaleOrderDetail', { orderId: this.id})
  },
  methods: {
    supplierStr(value) {
      let obj = {
        JD: '京东',
        CAT: '一只猫',
        OFFICIAL: '红包话费',
        XT: '鲜通',
        '0': '京东',
        '1': '一只猫',
        '2': '红包话费',
        '8': '鲜通',
        '10': '十鹿'
      }
      return obj[String(value)] || '一只猫'
    },
    fenToYuan(fen) {
      return fenToYuan(fen)
    },
    handleSelect() {
      this.set('activeIndex', '2')
    },
    goOderDetail() {
      this.$router.push({
        name: 'orderDetail',
        query: { orderId: this.afterSaleOrderDetail.orderId }
      })
    },
    onSubmit() {
      this.$router.push({
        name: 'afterSalesOrder'
      })
    },
    verifyAfterSaleOrder(result) {
      verifyAfterSaleOrder({
        id: this.id,
        returnUserName: this.returnName,
        returnPhone: this.returnPhone,
        returnAddress: this.returnAddress,
        reviewStatus: result,
        reviewDesc: this.reviewDesc
      }).then(response => {
        if (response) {
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.$store.dispatch('getAfterSaleOrderDetail', { orderId: this.id})
        }
      }).catch(error => {
        // this.$message.error(error.message)
        console.log(error)

      })
    },
    deliverBack() {
      deliverBack({
        afterSaleOrderId: this.id
      }).then(response => {
        if (response) {
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.$store.dispatch('getAfterSaleOrderDetail', { orderId: this.id})
        }
      }).catch(error => {
        console.log(error)

        // this.$message.error(error.message)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .home {
    padding: 20px;
    .base-detail {
      padding: 20px;
    }
  }
  .save-deliver-btn {
    position: relative;
    left: 100px;
  }
  .a-link {
    color: blue;
  }
  .btn-verify {
    margin-bottom: 10px;
    width: 410px;
  }
  .label-tab {
    margin-top: 26px;
    margin-bottom: 16px;
    position: relative;
    left: -10px;
  }
  .elm-input {
    width: 300px;
  }
</style>
