import {Manager} from '../api-types'
export default {
  // 推送列表
  pushRecordList: {
    apiPath: `${Manager}/ksh/message/v1/push-record-list`,
    api: 'pushRecordList',
    method: 'get',
    params: {
      msgTitle: '',
      pushStatus: '',
      pageIndex: 1,
      pageSize: 10
    }
  },
  // 获取推送校验模版
  downPushCheckModelFile: {
    apiPath: `${Manager}/ksh/message/v1/get/model-file`,
    api: 'downPushCheckModelFile',
    method: 'post'
  },
  // 上传校验用户模版
  uploadPushCheckModelFile: {
    apiPath: `${Manager}/ksh/message/v1/valid/model-file`,
    api: 'uploadPushCheckModelFile',
    method: 'post',
    data: {
      file: null    // 上传的Excel
    }
  },
  // 新增推送
  addPushRecord: {
    apiPath: `${Manager}/ksh/message/v1/add-push-record`,
    api: 'addPushRecord',
    method: 'post',
    data: {
      msgTitle: '',     // 推送标题
      pushChannel: 1,   // 推送渠道   1 :消息推送渠道 2 :短信
      msgContent: '',   // json字符串
      urlType: 0,   // 链接类型  链接类型 1-内部链接 2-外部链接
      url: '',      // 链接
      pushType: '', // 推送类型   1 :消息推送类型 2 :延时推送
      pushAt: '',   // 设定推送时间
      mobiles: '' // 触达用户列表
    }
  },
  // 更新推送
  updatePushRecord: {
    apiPath: `${Manager}/ksh/message/v1/update-push-record`,
    api: 'updatePushRecord',
    method: 'post',
    data: {
      recordId: '',           // 推送ID
      msgTitle: '',     // 推送标题
      pushChannel: 1,   // 推送渠道   1 :消息推送渠道 2 :短信
      msgContent: '',   // json字符串
      urlType: 0,   // 链接类型  链接类型 1-内部链接 2-外部链接
      url: '',      // 链接
      pushType: '', // 推送类型   1 :消息推送类型 2 :延时推送
      pushAt: '',   // 设定推送时间
      mobiles: '' // 触达用户列表
    }
  },
  // 获取上传的推送用户信息
  getUploadPushDoctorInfo: {
    apiPath: `${Manager}/ksh/message/v1/get/data-by-id`,
    api: 'getUploadPushDoctorInfo',
    method: 'post',
    params: {
      dataId: ''        // 上传的记录ID
    }
  },
  // 下载推送数据
  downPushMsg: {
    apiPath: `${Manager}/ksh/message/v1/download-push-msg`,
    api: 'downPushMsg',
    method: 'get',
    params: {
      recordId: ''        // 推送ID
    }
  },
  // 获取推送数据
  getPushRecordDetail: {
    apiPath: `${Manager}/ksh/message/v1/get-record-detail`,
    api: 'getPushRecordDetail',
    method: 'get',
    params: {
      recordId: ''        // 推送ID
    }
  },
  // 删除推送数据
  delPushMsg: {
    apiPath: `${Manager}/ksh/message/v1/delete-push-record`,
    api: 'delPushMsg',
    method: 'get',
    params: {
      recordId: ''        // 推送ID
    }
  },
  // 获取物流信息
  getOrderDistributeInfo: {
    apiPath: `${Manager}/points-mall/get-order-distribute-info`,
    api: 'getOrderDistributeInfo',
    method: 'post',
    params: {
      orderId: ''        // 订单ID
    }
  }

}
